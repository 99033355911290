import {Component, TemplateRef} from '@angular/core';
import {ToastService} from './toast-service';


@Component({
  selector: 'app-toasts',
  template: `
    <ngb-toast
      *ngFor="let toast of toastService.toasts"
      [class]="toast.classname"
      [delay]="toast.delay || 30000000 "

    >
      <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
      </ng-template>

      <ng-template #text class="alert-success">
        <div class="row">
          <div class="col-2"><img src='{{ toast.textOrTpl.image }}' style='width: 40px; height: 40px;' /></div>
          <div class="col-8"><b>{{ toast.textOrTpl.title }}</b><br>{{ toast.textOrTpl.body }}</div>
          <div class="col-2 alert-close-button">
          <a (click)="toastService.remove(toast)">&times;</a>
          </div> 
        </div>
      </ng-template>
    </ngb-toast>
    
  `,
  host: {'class': 'toast-container position-fixed top-0 end-0 p-3', 'style': 'z-index: 99999'}
})
export class ToastsContainer {
  constructor(public toastService: ToastService) {}
  isTemplate(toast: any) { return toast.textOrTpl instanceof TemplateRef; }
}