import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChartComponent, ApexAxisChartSeries, ApexResponsive, ApexChart, ApexXAxis, ApexDataLabels, ApexStroke, ApexMarkers, ApexYAxis, ApexGrid, ApexLegend, ApexNonAxisChartSeries, ApexPlotOptions, } from "ng-apexcharts";
import { Funciones } from '../../classes/funciones';


export type ChartOptions = {
    series: ApexAxisChartSeries;
    seriesPri: ApexNonAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    stroke: ApexStroke;
    dataLabels: ApexDataLabels;
    markers: ApexMarkers;
    colors: string[];
    yaxis: ApexYAxis;
    grid: ApexGrid;
    legend: ApexLegend;
    labels: string[];
    plotOptions: ApexPlotOptions;
    responsive: ApexResponsive[];
};

@Component({
    selector: 'incentivos_dashboard_perfil',
    templateUrl: './dashboard_perfil.component.html',
    styleUrls: ['./dashboard_perfil.component.css']
})
export class DashboardPerfilComponent implements OnInit {
    @ViewChild("chart") chart: ChartComponent = {} as ChartComponent;
    public chartPerfilCompleto: ChartOptions = {} as ChartOptions;
    public chartDonautPerfil: ChartOptions = {} as ChartOptions;


    @Input() fechaIP: any;
    @Input() fechaFP: any;
    @Input() inputDataPerfil: any = [];

    periodo_perfil: string = "";
    public filtroselected_perfil:number = 0;
    public filtroidIni_perfil:number = 0;
    public esPrimeraVez_perfil: boolean= true;
    public filtros_perfil = [
        { "id": 0, "value": "Dirección"},
        { "id": 1, "value": "Puesto"},
        { "id": 2, "value": "Oficina"},
        { "id": 3, "value": "Colaborador"},
      ];
    public filtroPerfil:boolean=false;

    constructor(
        public snackBar: MatSnackBar,
        public funcion: Funciones
    ) {
    }

    ngOnInit(): void {
        
        this.filtroidIni_perfil = this.filtroselected_perfil;
        this.periodo_perfil = this.funcion.getFechaFormat2(this.fechaIP) + " - " + this.funcion.getFechaFormat2(this.fechaFP);
        this.dataDonutPerfil();
        this.dataColaboradoresCompleto();
    }


    public dataDonutPerfil() {
        if (this.inputDataPerfil) {
             this.chartDonautPerfil = {
                seriesPri: [this.inputDataPerfil.miperfil.completado, this.inputDataPerfil.miperfil.foto,this.inputDataPerfil.miperfil.portada,
                            this.inputDataPerfil.miperfil.misueno, this.inputDataPerfil.miperfil.incompleto],
                series: [],
                colors: ["#4DAB9E","#8C6CA6","#DB699C","#EBA875", "#2C678D"],
                chart: {
                    type: "donut",
                    height: 350,
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    pie: {
                      donut: {
                        size: '88%',

                        labels: {
                          show: true,
                          name: {
                            show: true,
                            offsetY: 30,
                          },
                          value: {
                            show: true,
                            fontSize: '60px',
                            fontFamily: 'BreeRegular',
                            fontWeight: 400,
                            color: '#49454F',
                            offsetY: -25,
                            formatter: function (value) {
                              return Number(value).toLocaleString('en');
                            }
                          },
                          total: {
                            show: true,
                            showAlways: false,
                            label: 'Total de colaboradores',
                            fontSize: '15px',
                            fontFamily: 'BreeRegular',
                            fontWeight: 600,
                            color: '#49454F',
                            formatter: function (w) {
                                let sum = 0;
                                w.globals.seriesTotals.forEach((v: any) => {
                                    sum = sum + v;
                                });
                                return Number(sum).toLocaleString('en');
                              }
                          }

                        }
                      }
                    }
                },
                labels: ["Perfil completado", "Foto de Perfil", "Foto de Portada",
                        "Sueño", "Colaboradores sin perfil completado"],
                responsive: [
                    {
                      breakpoint: 350,
                      options: {
                        chart: {
                          width: 86
                        },
                      }
                    }
                  ],
                dataLabels: {
                    enabled: false
                },
                legend: {

                    position: "bottom",
                    horizontalAlign: "center",
                    floating: false,
                    fontSize: '10px',
                    show: true,
                },
                xaxis: {
                },
                yaxis: {
                },
                stroke: {
                },
                grid: {
                },
                markers: {
                },

            };
        }
    }

    public dataColaboradoresCompleto() {
        let serie;
        let categoria;
        if (this.inputDataPerfil) {
            if(this.filtroidIni_perfil !== this.filtroselected_perfil && !this.esPrimeraVez_perfil){
                if(this.filtroselected_perfil== 0){
                    serie = this.inputDataPerfil.colaboradores.direccion.serie;
                    categoria = this.inputDataPerfil.colaboradores.direccion.categoria;
                }else if(this.filtroselected_perfil== 1){
                    serie = this.inputDataPerfil.colaboradores.puesto.serie;
                    categoria = this.inputDataPerfil.colaboradores.puesto.categoria;
                }else if(this.filtroselected_perfil== 2){
                    serie = this.inputDataPerfil.colaboradores.subdireccion.serie;
                    categoria = this.inputDataPerfil.colaboradores.subdireccion.categoria;
                }else if(this.filtroselected_perfil== 3){
                    serie = this.inputDataPerfil.colaboradores.ubicacion.serie;
                    categoria = this.inputDataPerfil.colaboradores.ubicacion.categoria;
                }
                this.filtroidIni_perfil = this.filtroselected_perfil;
                this.filtroPerfil = false;
            }else if(this.esPrimeraVez_perfil){
                    serie = this.inputDataPerfil.colaboradores.direccion.serie;
                    categoria = this.inputDataPerfil.colaboradores.direccion.categoria;
            }else{
                return;
            }
            this.chartPerfilCompleto = {
                seriesPri:[],
                plotOptions: {
                },
                labels: [" "],
                series: [serie],
                chart: {
                    height: 240,
                    type: "line",
                    toolbar: {
                        show: false
                    }
                },
                colors: ["#EBA875"],
                dataLabels: {
                    enabled: true,
                    style:{
                      fontSize: "8px"
                    }
                },
                stroke: {
                    curve: "smooth"
                },
                grid: {
                    borderColor: "#e7e7e7",
                    row: {
                        colors: ["#f3f3f3", "transparent"],
                        opacity: 0.5
                    }
                },
                markers: {
                    size: 1
                },
                xaxis: {
                    categories:  categoria,
                    labels: {
                        style: {
                            //colors: ['#9EA0AA'],
                            fontSize: '8px',
                            fontFamily: 'BreeRegular'

                        }

                    }
                },
                yaxis: {
                    title: {
                        text: "Total de colaboradores",
                        style: {
                            color: 'rgba(44, 103, 141, 0.7)',
                            fontSize: '12px',
                            fontFamily: 'BreeRegular'
                        }
                    },
                  labels:{
                    offsetX: -8
                  }
                },
                legend: {
                    position: "top",
                    horizontalAlign: "center",
                    floating: false,
                    fontSize: '7px',
                    offsetY: -25,
                    offsetX: -5
                },
                responsive:[]
            };
            this.esPrimeraVez_perfil = false;
        }
    }

    public activaFiltro(){
        if(!this.filtroPerfil){
            this.filtroPerfil = true;
        }else{
            this.filtroPerfil = false;
        }

    }
}
