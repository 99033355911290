<div class="loading-indicator" *ngIf="funcion.visibility | async">
    <img src="/assets/img/spinner.gif" alt="">
</div>

<div class="container-fluid">
    <div class="row no-gutter">

        <!-- Login -->
        <div class="col-md-6 bg-white">
            <div class="login d-flex align-items-center py-5">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-10 mx-auto">
                         <img src="../../assets/img/logo_si.png" alt="Logo Consola" class="logoC">
                          <div class="formulario">
                            <h3 class="etiqueta">SI+</h3>
                            <p class="mb-4 text-sesion">Inicia sesión con tus datos</p>
                            <form class="form" [formGroup]="myForm" (ngSubmit)="onLogin()">
                                <div class="mb-4">
                                    <input id="inputEmail" formControlName="usn" usn name="uN" type="text" placeholder="Usuario" autocomplete="off" class="form-control px-4">
                                </div>
                                <div class="mb-4">
                                    <input id="inputPassword" formControlName="pss" pss name="uP" type="password" placeholder="Contraseña" autocomplete="Off"  class="form-control px-4 text-primary">
                                </div>
                                <div class="d-grid gap-2 d-md-flex justify-content-md-end boton">
                                    <button type="submit" class="btn btn-primary mb-2 shadow-sm" (click)="onLogin()">Iniciar sesión</button>
                                </div>
                            </form>
                          </div>
                          <div class="alert alert-danger" role="alert" *ngIf="errorStatus === true">
                            <p class="mb-0">{{ errorMsj }}</p>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Image Background Compartamos-->
        <div class="col-md-6 d-none d-md-flex bg-image"></div>

    </div>
</div>
