<div class="loading-indicator" *ngIf="funcion.visibility | async">
    <img src="/assets/img/spinner.gif" alt="">
</div>


<div style="padding: 3.5em 4em 1em 5em;">
    <div class="overlay">
        <div class="row " style="padding-left: 0.5em;">
            <div class="row">
                <div class="col-6 titulo" style="text-align: left;">
                    <span>Reporte NPS</span>
                </div>
                <div class="col-6 subtitulo" style="text-align: right;">
                    <span>Periodo: {{periodo}}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row m-1" style="padding: 1em 2em 1em 2em;">
    <div class="col-12" style="padding-top: 2em;">
        <div class="row segundaseccion" style="left: 10px;">
            <div class="row" style="padding-left: 1em;">
                <div class="col-12" style="margin-top: 13px; border-bottom: 1px solid #2C678D;">
                    <span class="cardTitle">Calificación total acumulada</span>
                </div>
                <div class="col-5" style="padding-right: 0em;">
                    <div class="row" style="padding-top: 1.5em;">
                        <div class="col-3">
                            <div class="row" style="padding-top: 4em;">
                                <div class="col-12 d-flex justify-content-center">
                                    <span class="lblUsuarios">{{inputData.acumulada.promedio}}</span>
                                </div>
                                <div class="col-12 d-flex justify-content-center" style="padding-top: 2em;">
                                    <img *ngFor="let e of estrellas" alt="" class="imgCalif" src="{{e.ruta}}">
                                </div>
                                <div class="col-12 d-flex justify-content-center" style="padding-top: 1em;"><span
                                  class="lblTotales">Calificación Promedio</span></div>
                            </div>
                        </div>
                        <div class="col-1 vl"></div>
                        <div class="col-3">
                            <div class="row" style="padding-top: 4em;">
                                <div class="col-12 d-flex justify-content-center">
                                    <span class="lblUsuarios">{{inputData.acumulada.perfiles |
                                        number:'1.0':'en-US'}}</span>
                                </div>
                                <div class="col-12 d-flex justify-content-center" style="padding-top: 2em;"><span
                                        class="lblTotales">Usuarios Totales</span></div>
                                <div class="col-12 d-flex justify-content-center"><span
                                        class="lblTotales">{{inputData.acumulada.total |
                                        number:'1.0':'en-US'}} / {{inputData.acumulada.total_perfil |
                                        number:'1.0':'en-US'}}</span></div>
                            </div>
                        </div>
                        <div class="col-1 v2"></div>
                        <div class="col-4" style="padding-right: 0em;padding-left: 2em;">
                            <div style="padding-top: 1em;">
                                <img alt="" src="/assets/images/star.png" style="padding-right: 1em;">
                                <img alt="" src="/assets/images/star.png" style="padding-right: 1em;">
                                <img alt="" src="/assets/images/star.png" style="padding-right: 1em;">
                                <img alt="" src="/assets/images/star.png" style="padding-right: 1em;">
                                <img alt="" src="/assets/images/star.png">
                            </div>
                            <div style="padding-top: 0.8em;">
                                <img alt="" src="/assets/images/star.png" style="padding-right: 1em;padding-left: 2em;">
                                <img alt="" src="/assets/images/star.png" style="padding-right: 1em;">
                                <img alt="" src="/assets/images/star.png" style="padding-right: 1em;">
                                <img alt="" src="/assets/images/star.png">
                            </div>
                            <div style="padding-top: 0.8em;">
                                <img alt="" src="/assets/images/star.png" style="padding-right: 1em;padding-left: 4em;">
                                <img alt="" src="/assets/images/star.png" style="padding-right: 1em;">
                                <img alt="" src="/assets/images/star.png">
                            </div>
                            <div style="padding-top: 0.8em;">
                                <img alt="" src="/assets/images/star.png" style="padding-right: 1em;padding-left: 6em;">
                                <img alt="" src="/assets/images/star.png">
                            </div>
                            <div style="padding-top: 0.8em;">
                                <img alt="" src="/assets/images/star.png" style="padding-left: 8em;">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6" style="    padding-left: 0em;">
                    <div *ngIf="inputData">
                        <apx-chart [series]="chartCalificacionAcumulada.series!"
                            [chart]="chartCalificacionAcumulada.chart!"
                            [dataLabels]="chartCalificacionAcumulada.dataLabels!"
                            [plotOptions]="chartCalificacionAcumulada.plotOptions!"
                            [xaxis]="chartCalificacionAcumulada.xaxis!" [colors]="chartCalificacionAcumulada.colors!"
                            [yaxis]="chartCalificacionAcumulada.yaxis!"></apx-chart>
                    </div>
                </div>
                <div class="col-1" style="padding-top: 1.8em;">
                    <div style="padding-top: 0.8em" *ngFor="let c of inputData.historial.series[0].data">
                        <span class="lblCP">{{c}} %</span>
                    </div>
                </div>
            </div>

        </div>

    </div>

    <div class="col-12">
        <div class="row segundaseccion" style="left: 10px;">
            <div class="row" style="padding-left: 1em;">
                <div class="col-12" style="margin-top: 13px; border-bottom: 1px solid #2C678D;">
                    <span class="cardTitle">Calificación promedio historial</span>
                </div>
                <div class="col-12">
                    <div *ngIf="inputData">
                        <apx-chart [series]="chartCalificacionPromedio.series" [chart]="chartCalificacionPromedio.chart"
                            [xaxis]="chartCalificacionPromedio.xaxis" [stroke]="chartCalificacionPromedio.stroke"
                            [colors]="chartCalificacionPromedio.colors"
                            [dataLabels]="chartCalificacionPromedio.dataLabels"
                            [legend]="chartCalificacionPromedio.legend" [markers]="chartCalificacionPromedio.markers"
                            [grid]="chartCalificacionPromedio.grid" [yaxis]="chartCalificacionPromedio.yaxis"
                            [title]="chartCalificacionPromedio.title"></apx-chart>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
