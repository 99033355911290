import { Component, OnInit, ViewChild, ElementRef, Pipe } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServiciosImp } from '../../services/services';
import { Funciones } from '../../classes/funciones';
import {
  IColumns
} from '../../models/grid.interfaces';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'incentivos-new-empresa',
  templateUrl: './nueva_empresa.component.html',
  styleUrls: ['./nueva_empresa.component.css']
})
export class NewEmpresaComponent implements OnInit {

  @Pipe({ name: 'safe' })

  @ViewChild('modalincentivos') modal: ElementRef | undefined;
  nombre: any = sessionStorage.getItem('nombre');
  nomina: any = sessionStorage.getItem('nomina');
  nombreL: any;
  usuarioL: any;
  nominaL: any;
  empresa: any[] = [];
  public status_empresa: any[] = [];

  filePrev1: any;
  filePrev2: any;
  filePrev3: any;
  filePrev4: any;
  filePrev5: any;
  files: any = [];


  // Campos en el front
  public idEmpresa2: number = 0;
  public accion: boolean = false;
  public accion_nivel: boolean = false;
  wait: boolean = false;
  color1: string = '#000';
  color2: string = '#000';
  color3: string = '#000';
  color4: string = '#000';
  color5: string = '#000';
  color6: string = '#000';
  color7: string = '#000';
  public si: string = '';
  public clara: string = '';
  public home: string = '';
  public pleca: string = '';
  public grafica: string = '';
  public graficaSi: string = '';
  public nombreEmpresa: string = '';
  public descripcion: string = '';
  public bannerHome: string = '';
  public bannerhome: string = '';
  public logoClara: string = '';
  public logoclara: string = '';
  public iconSI: string = '';
  public iconsi: string = '';
  public plecaBack: string = '';
  public plecaback: string = '';
  public statusEmpresa: number = 0;
  public idEmpresa: number = 0;
  public img_si: any;
  public img_clara: any;
  public img_banner: any;
  public img_pleca: any;
  public img_grafica: any;
  public statusEmpresaNivel: number = 0;
  public nombreEmpresaNivel: string = '';
  public descripcionEmpresaNivel: string = '';
  public colorNivel: string = '#000';
  public imagenNivel: string = '';
  public orden: string = '';
  public titulo = 'Nueva';


  /* *************** Parametros del Grid de datos ***************** */
  columns_nueva_empresa: IColumns[] = [];


  constructor(
    private router: Router,
    public snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    public servicios: ServiciosImp,
    public funcion: Funciones
  ) {
    this.nombreL = this.nombre;
    this.nominaL = this.nomina;


    /* *************** Parametros del Grid de datos ***************** */
    this.columns_nueva_empresa = [ // Columnas
      {
        caption: 'Estatus',
        dataField: 'status_desc',
      },
      {
        caption: 'Categoría',
        dataField: 'nombre',
      },
      {
        caption: 'Descripción',
        dataField: 'descripcion',
      },
      {
        caption: 'Color',
        dataField: 'color',
      },

    ];
  }

  ngOnInit(): void {

    this.inicio();

    this.servicios.getService('status-empresa/').toPromise().then(
      (res: any) => {
        if (res.status == 401) {
          this.router.navigate(['login']);
        }
        this.status_empresa = res.data;
        this.funcion.hide();
      },
      (error: any) => {
        this.msgError(error);
      }
    )
  }

  public inicio() {
    this.activatedRoute.params.subscribe(parametros => {
      this.idEmpresa = parametros.id;
      if (this.idEmpresa != 0) {
        this.wait = true;
        this.titulo = 'Edita'
        let url = 'empresa/' + this.idEmpresa;
        this.servicios.getService(url).toPromise().then(
          (res: any) => {
            if (res.status == 401) {
              this.router.navigate(['login']);
            }
            this.color1 = res.data.color_letra;
            this.color2 = res.data.color_primario;
            this.color3 = res.data.color_secundario;
            this.color4 = res.data.color_iconos;
            this.nombreEmpresa = res.data.nombre;
            this.descripcion = res.data.descripcion;
            this.statusEmpresa = res.data.status;
            this.logoClara = res.data.nombre_icono_clara;
            this.img_clara = res.data.icono_clara;
            this.clara = res.data.icono_clara;
            this.filePrev2 = res.data.icono_clara;
            this.iconSI = res.data.nombre_iconosi;
            this.img_si = res.data.iconosi;
            this.si = res.data.iconosi;
            this.filePrev1 = res.data.iconosi;
            this.bannerHome = res.data.nombre_banner;
            this.img_banner = res.data.banner;
            this.home = res.data.banner;
            this.filePrev3 = res.data.banner;
            this.plecaBack = res.data.nombre_pleca;
            this.img_pleca = res.data.pleca;
            this.pleca = res.data.pleca;
            this.filePrev4 = res.data.pleca;
            this.graficaSi = res.data.nombre_logo_grafica;
            this.img_grafica = res.data.logo_grafica;
            this.grafica = res.data.logo_grafica;
            this.filePrev5 = res.data.logo_grafica;
            this.color5 = res.data.color_avance;
            this.color6 = res.data.color_compromiso;
            this.color7 = res.data.color_proyectado;
            this.servicios.getService('empresa/' + res.data.id + '/incentivo/').toPromise().then(
              (resp: any) => {
                if (res.status == 401) {
                  this.router.navigate(['login']);
                }
                this.wait = false;
                this.empresa = resp.data === undefined ? null : resp.data;
                this.funcion.hide();
              },
              (error: any) => {
                this.wait = false;
                this.msgError(error);
              }
            )
          },
          (error: any) => {
            this.msgError(error);
          }
        )
      } else {
        this.wait = true;
      }
    });
  }

  msgError(error: any) {
    this.snackBar.open(error.message, 'close', { duration: 2500 });
    this.funcion.hide();
  }

  public dataDef() {
    this.color1 = '#000';
    this.color2 = '#000';
    this.color3 = '#000';
    this.color4 = '#000';
    this.color5 = '#000';
    this.color6 = '#000';
    this.color7 = '#000';
    this.nombreEmpresa = '';
    this.descripcion = '';
    this.home = '';
    this.clara = '';
    this.si = '';
    this.pleca = '';
    this.grafica = '';
    this.img_si = '';
    this.img_clara = '';
    this.img_banner = '';
    this.img_pleca = '';
    this.img_grafica = '';
    this.statusEmpresa = 0;
  }

  public guardaEmpresa() {

    if (this.validacion1() && this.validacion2()) {
      if (this.idEmpresa == 0) {
        this.nuevaEmpresa(this.getBody());
      } else {
        this.actualizaEmpresa(this.getBody());
      }
    } else {
      this.snackBar.open('Por favor, ingrese todos los campos', 'close', { duration: 2500 });
    }
  }

  validacion1() {
    if (this.nombreEmpresa !== '' && this.descripcion !== '' && this.statusEmpresa !== 0
      && this.si != "" && this.clara != "" && this.home != "" && this.pleca != "")
      return true;
    else
      return false;
  }

  validacion2() {

    let elemento: any ;
    elemento = document.getElementById("color1");
    this.color1 = elemento.value;
    elemento = document.getElementById("color2");
    this.color2 = elemento.value;
    elemento = document.getElementById("color3");
    this.color3 = elemento.value;
    elemento = document.getElementById("color4");
    this.color4 = elemento.value;
    elemento = document.getElementById("color5");
    this.color5 = elemento.value;
    elemento = document.getElementById("color6");
    this.color6 = elemento.value;
    elemento = document.getElementById("color7");
    this.color7 = elemento.value;
    if (this.color1 !== '' && this.color2 !== '' && this.color3 !== '' && this.color4 !== ''
      && this.grafica !== "" && this.color5 !== '' && this.color6 !== '' && this.color7 !== '')
      return true;
    else
      return false;
  }

  getBody() {
    
    return {
      "nombre": this.nombreEmpresa,
      "descripcion": this.descripcion,
      "status": this.statusEmpresa,
      "iconosi": this.img_si,
      "nombre_iconosi": this.iconSI === '' ? this.iconsi : this.iconSI,
      "banner": this.img_banner,
      "nombre_banner": this.bannerHome === '' ? this.bannerhome : this.bannerHome,
      "icono_clara": this.img_clara,
      "nombre_icono_clara": this.logoClara === '' ? this.logoclara : this.logoClara,
      "pleca": this.img_pleca,
      "nombre_pleca": this.plecaBack === '' ? this.plecaback : this.plecaBack,
      "color_letra": this.color1,
      "color_primario": this.color2,
      "color_secundario": this.color3,
      "color_iconos": this.color4,
      "nombre_logo_grafica": this.graficaSi === '' ? this.grafica : this.graficaSi,
      "logo_grafica": this.img_grafica,
      "color_avance": this.color5,
      "color_compromiso": this.color6,
      "color_proyectado": this.color7
    }
  }

  nuevaEmpresa(body: any) {
    this.servicios.regEmp(body).subscribe(datoempreg => {
      if (!datoempreg.error) {
        this.snackBar.open('Datos guardados correctamente', 'close', {
          duration: 2500,
        });
        this.router.navigate(['catalogo_empresa']);
        this.funcion.hide();
      } else {
        this.snackBar.open('Error al guardar los datos', 'close', {
          duration: 2500,
        });
        this.funcion.hide();
      }
    });
  }

  actualizaEmpresa(body: any) {
    this.servicios.actEmp(body, this.idEmpresa).subscribe(datoempact => {
      if (!datoempact.error) {
        this.snackBar.open('Datos actualizados correctamente', 'close', { duration: 2500 });
        this.funcion.hide();
      } else {
        this.snackBar.open('Error al actualizar los datos', 'close', { duration: 2500 });
        this.funcion.hide();
      }
    });
  }

  /*Guarda incentivo nivel de la empresa*/
  public guardaEmpresaNivel() {
    if (this.validaNvl()) {
      if (this.accion_nivel !== true) {
        let aux = 1;
        this.getCatalogoCategorias();
        this.configOrdn(aux);
      }
      let body = {
        "status": this.statusEmpresaNivel,
        "nombre": this.nombreEmpresaNivel,
        "descripcion": this.descripcionEmpresaNivel,
        "color": this.colorNivel,
        "orden": this.orden
      }
      if (this.accion !== true) {
        this.nuevaEmpresaNvl(body);
      } else {
        this.actualizaEmpresaNvl(body);
      }
    } else {
      this.snackBar.open('Por favor, ingrese todos los campos', 'close', { duration: 2500 });
    }
  }

  validaNvl() {
    let colorNiv: any ;
    colorNiv = document.getElementById("colorNv");
    if(colorNiv){
      this.colorNivel = colorNiv.value;
    }
    if (this.nombreEmpresa != "" && this.descripcion != "" &&
      this.statusEmpresaNivel !== 0 && this.nombreEmpresaNivel !== ''
      && this.descripcionEmpresaNivel !== '' && this.colorNivel !== '')
      return true;
    else
      return false;
  }

  configOrdn(aux: number) {
    if (this.empresa == undefined) {
      this.orden = String(aux);
    } else {
      for (let max in this.empresa) {
        if (Number(this.empresa[max].orden) == aux) {
          aux = Number(this.empresa[max].orden) + 1;
        }
      }
      this.orden = String(aux);
    }
  }

  getCatalogoCategorias() {
    this.servicios.getCatalogoCategorias(this.idEmpresa).subscribe(datosnewempc => {
      if (!datosnewempc.error) {
        this.empresa = datosnewempc.data;
        this.funcion.hide();
      } else {
        this.wait = false;
        this.snackBar.open('Error al consultar', 'close', {
          duration: 2500,
        });
        this.funcion.hide();
      }
    });
  }

  nuevaEmpresaNvl(body: any) {
    this.servicios.regnvlEmp(body, this.idEmpresa).subscribe(datosnewemp => {
      if (!datosnewemp.error) {
        this.funcion.hide();
        this.modalService.dismissAll();
        this.inicio();
      } else {
        this.wait = false;
        this.snackBar.open('Error al guardar los datos', 'close', {
          duration: 2500,
        });
        this.funcion.hide();
      }
    });
  }

  actualizaEmpresaNvl(body: any) {
    this.servicios.actnvlEmp(body, this.idEmpresa, this.idEmpresa2).subscribe(datosnewactemp => {
      if (!datosnewactemp.error) {
        this.funcion.hide();
        this.modalService.dismissAll();
        this.inicio();
      } else {
        this.wait = false;
        this.snackBar.open('Error al guardar los datos', 'close', {
          duration: 2500,
        });
        this.funcion.hide();
      }
    });
  }

  /*base64 de imagenes cargadas en los inputs*/

  public changeSI($event: any): void {
    
    if(this.funcion.extensionValida($event)){
    let file: File = $event.target.files[0];
    this.iconsi = file.name;
    let myReader: FileReader = new FileReader();
    myReader.readAsDataURL(file);
    myReader.onloadend = () => {
      this.img_si = this.sanitizer.bypassSecurityTrustUrl(String(myReader.result));
      this.img_si = this.img_si.changingThisBreaksApplicationSecurity;
      this.filePrev1 = this.img_si;
    }
    }else{
      this.si = '';
    }
  }

  public changeClara($event: any): void {
    if(this.funcion.extensionValida($event)){
    let file: File = $event.target.files[0];
    this.logoclara = file.name;
    let myReader: FileReader = new FileReader();
    myReader.readAsDataURL(file);
    myReader.onloadend = () => {
      this.img_clara = this.sanitizer.bypassSecurityTrustUrl(String(myReader.result));
      this.filePrev2 = this.img_clara;
    }
    }else{
      this.clara = '';
    }
  }

  public changeBanner($event: any): void {
    if(this.funcion.extensionValida($event)){
    let file: File = $event.target.files[0];
    this.bannerhome = file.name;
    let myReader: FileReader = new FileReader();
    myReader.readAsDataURL(file);
    myReader.onloadend = () => {
      this.img_banner = this.sanitizer.bypassSecurityTrustUrl(String(myReader.result));
      this.filePrev3 = this.img_banner;
    }
    }else{
      this.home = '';
    }
  }

  public changePleca($event: any): void {
    if(this.funcion.extensionValida($event)){
    let file: File = $event.target.files[0];
    this.plecaback = file.name;
    let myReader: FileReader = new FileReader();
    myReader.readAsDataURL(file);
    myReader.onloadend = () => {
      this.img_pleca = this.sanitizer.bypassSecurityTrustUrl(String(myReader.result));
      this.filePrev4 = this.img_pleca;
    }
    }else{
      this.pleca = '';
    }
  }

  public changeGrafica($event: any): void {
    if(this.funcion.extensionValida($event)){
    let file: File = $event.target.files[0];
    this.graficaSi = file.name;
    let myReader: FileReader = new FileReader();
    myReader.readAsDataURL(file);
    myReader.onloadend = () => {
      this.img_grafica = this.sanitizer.bypassSecurityTrustUrl(String(myReader.result));
      this.filePrev5 = this.img_grafica;
    }
    }else{
      this.grafica = '';
    }
  }



  datosEmpresa(senddata: any) {
    this.abrirModalEditar(this.modal, senddata);
  }

  datosEmpresaDelete(senddata: any) {
    this.servicios.deleteCatalogoEmpresa(senddata).subscribe(datonewemp => {
      if (!datonewemp.error) {
        this.snackBar.open('Datos eliminados correctamente', 'close');
        this.funcion.hide();
        this.inicio();
      } else {
        this.snackBar.open('Error al eliminar los registros', 'close', {
          duration: 2500,
        });
        this.funcion.hide();
      }
    });
  }


  /*** Test icono en base 64 */
  abrirModalEditar(modal: any, senddata: any) {
    this.modalService.open(modal, {
      centered: true,
      size: 'lg',
      ariaLabelledBy: 'modal-basic-title',
      scrollable: true,
    });
    this.idEmpresa2 = senddata.data[0].id;
    this.statusEmpresaNivel = senddata.data[0].status;
    this.nombreEmpresaNivel = senddata.data[0].nombre;
    this.descripcionEmpresaNivel = senddata.data[0].descripcion;
    this.colorNivel = senddata.data[0].color;
    this.orden = senddata.data[0].orden;
    this.accion_nivel = true;
    this.accion = true;
  }

  /** Abrir modal */
  abrirModal(modal: any) {
    this.modalService.open(modal, {
      centered: true,
      size: 'lg',
      ariaLabelledBy: 'modal-basic-title',
      scrollable: true,
    });
  }


  /** Cerrar Modal */
  cerrarModal() {
    this.modalService.dismissAll();
  }


  onClickConfsi() {
    this.router.navigate(['resumen_post']);
  }

  onClickConfcat() {
    this.router.navigate(['home']);
  }

  onClickCatemp() {
    this.router.navigate(['catalogo_empresa']);
  }

}
