<incentivos-navbar [nombre]="nombreL" [nomina]="nominaL"></incentivos-navbar>


<!-- Nuevo Post -->
<div class="loading-indicator" *ngIf="funcion.visibility | async">
    <img src="/assets/img/spinner.gif" alt="">
</div>

<div class="d-flex justify-content-center align-box2">
    <div class="box2">
        <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
            aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item b1" href="javascript:;" (click)="onClickPubcont()">Publicadores de contenido
                </li>
                <li class="breadcrumb-item b1" href="javascript:;" (click)="onClickResumenp()">Resumen posts</li>
                <li class="breadcrumb-item b2">{{titulop}} post</li>
            </ol>
        </nav>
    </div>
</div>
<div class="d-flex justify-content-center align-box">
    <div class="box">
        <div style="color: white;">4.0.1</div>
        <p class="f-t1">{{titulop}} Post</p>
        <p class="f-t2">*Campos Obligatorios</p>
        <div class="container cuerpo_post">
            <div class="row mb-5">
                <div class="col-md-6">
                    <input type="text" placeholder="Título*" class="form-control f-size-1" required [(ngModel)]="titulo"
                        name="titulo">
                </div>
                <div class="col-md-6">
                    <input type="text" placeholder="Texto corto*" class="form-control f-size-1" required
                        [(ngModel)]="texto_corto" name="texto_corto">
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-md-3">
                    <label for="input1" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                        <span class="h6 small bg-white text-muted px-1">Módulo a publicar*</span>
                    </label>
                    <select class="form-control form-select mt-2 f-size-2" id="input1" required [(ngModel)]="modulo"
                        name="modulo">
                        <option *ngFor="let modulo of modulosnpost" [value]="modulo.id">{{modulo.nombre}}</option>
                    </select>
                </div>
                <div class="col-md-3">
                    <label for="input2" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                        <span class="h6 small bg-white text-muted px-1">Empresa*</span>
                    </label>
                    <select class="form-control form-select mt-2 f-size-2" id="input2" required [(ngModel)]="empresa"
                        name="empresa">
                        <option *ngFor="let empresa of empnpost" [value]="empresa.id">{{empresa.nombre}}</option>
                    </select>
                </div>
                <div class="col-md-3">
                    <label for="input3" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                        <span class="h6 small bg-white text-muted px-1">Estatus*</span>
                    </label>
                    <select class="form-control form-select mt-2 f-size-2" id="input3" required [(ngModel)]="estatus"
                        name="estatus">
                        <option *ngFor="let st of statusnpost" [value]="st.id">{{st.nombre}}</option>
                    </select>
                </div>
                <div class="col-md-3">
                    <label for="input4" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                        <span class="h6 small bg-white text-muted px-1">Marca de actualización*</span>
                    </label>
                    <select class="form-control form-select mt-2 f-size-2" id="input4" required [(ngModel)]="marca_act"
                        name="marca_act">
                        <option *ngFor="let marca of mcnpost" [value]="marca.id">{{marca.nombre}}</option>
                    </select>
                </div>
            </div>
            <label class="mb-4 etiqueta">Contenido multimedia*:</label>
            <div class="row mb-5">
                <div class="col-md-5">
                    <label for="input5" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                        <span class="h6 small bg-white text-muted px-1">Tipo de contenido*</span>
                    </label>
                    <select class="form-control form-select f-size-3 mt-2" id="input5" required [(ngModel)]="tipo_cont"
                        (change)="selecType($event)" name="tipo_cont">
                        <option *ngFor="let tipo of tcnpost" [value]="tipo.id">{{tipo.nombre}}</option>
                    </select>
                </div>
                <div class="col-md-5" *ngIf="mostrar === '1'">
                    <input class="form-control f-size-3 mt-2" type="file" [(ngModel)]="tipo_cont_sel"
                        (change)="upload($event)" accept=".jpg, .jpeg, .png">
                </div>
                <div class="col-md-5" *ngIf="mostrar ==='2'">
                    <input class="form-control f-size-3 mt-2" type="file" [(ngModel)]="tipo_cont_sel"
                        (change)="upload($event)" accept=".mp4">
                </div>
                <div class="col-md-5" *ngIf="mostrar === '5'">
                    <input class="form-control f-size-3 mt-2" type="file" [(ngModel)]="tipo_cont_sel"
                        (change)="upload($event)" accept=".txt, .pdf, .docx, .xlsx, .pptx, .doc, .xls, .ppt">
                </div>
                <div class="col-md-2"
                    *ngIf="mostrar === '1' && botonStatus === true || mostrar ==='2' && botonStatus === true || mostrar === '5' && botonStatus === true">
                    <button class="button mt-2 boton3" id="btnc" type="button" data-bs-toggle="modal"
                        data-bs-target="#openModal" [disabled]="!tipo_cont_sel"><em class="bi bi-eye-fill"
                            style="pointer-events: none;"></em>&nbsp;&nbsp;Previsualizar</button>
                </div>
            </div>
            <label class="mb-4 etiqueta" *ngIf="mostrar === '3' || mostrar ==='4'">URL de acción:</label>
            <div class="row mb-5" *ngIf="mostrar === '3' || mostrar ==='4'">
                <div class="col-md-6" *ngIf="mostrar === '3'">
                    <input type="text" placeholder="URL" class="form-control f-size-1" required [(ngModel)]="url_accion"
                        name="url_accion">
                </div>
                <div class="col-md-6" *ngIf="mostrar === '4'">
                    <input type="text" placeholder="Texto" class="form-control f-size-1" required
                        [(ngModel)]="url_accion" name="url_accion">
                </div>
                <div class="col-md-6">
                    <input type="text" placeholder="Mensaje acción" class="form-control f-size-1" required
                        [(ngModel)]="msj_accion" name="msj_accion">
                </div>
            </div>
            <div class="row mb-5">
                <div [ngClass]="tipo_cont == 1 || tipo_cont == 2 || tipo_cont == 5? 'col-md-4': 'col-md-6'">
                    <mat-slide-toggle name="banner" (change)="onChangeBanner($event)" [(ngModel)]="banner">Banner
                        principal</mat-slide-toggle>
                </div>
                <div [ngClass]="tipo_cont == 1 || tipo_cont == 2 || tipo_cont == 5? 'col-md-4': 'col-md-6'">
                    <mat-slide-toggle name="notificacion" (change)="onChangeNot($event)" [(ngModel)]="notificacion">
                        Envío notificación</mat-slide-toggle>
                </div>
                <div class="col-md-4" *ngIf="tipo_cont == 1 || tipo_cont == 2 || tipo_cont == 5">
                    <mat-slide-toggle name="enviarClara" (change)="onChangeClara()" [(ngModel)]="cargarClara">
                        Cargar a @Clara</mat-slide-toggle>
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-md-6">
                    <label class="etiqueta">Miniatura para mostrar en favoritos*:</label>
                </div>
                <div class="col-md-6" *ngIf="val1 == 1">
                    <label class="etiqueta"
                        *ngIf="tipo_cont == 1 || tipo_cont == 3 || tipo_cont == 4 || tipo_cont == 5 || tipo_cont == 0">Miniatura
                        del banner:</label>
                    <label class="etiqueta" *ngIf="tipo_cont == 2">Miniatura del banner:*</label>
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-md-3 mb-4">
                    <input class="form-control f-size-2 mt-2" type="file" id="filec3" (change)="changeMinPost($event)"
                        [(ngModel)]="miniatura_post" name="miniatura_post" accept=".jpg, .jpeg, .png">
                </div>
                <div class="col-md-3 mb-4">
                    <button class="button mt-2 boton3" type="button" data-bs-toggle="modal"
                        data-bs-target="#openModalMinPost" [disabled]="!miniatura_post">
                        <em class="bi bi-eye-fill" style="pointer-events: none;"></em>&nbsp;&nbsp;Previsualizar</button>
                </div>
                <div class="col-md-3 mb-4" *ngIf="val1 == 1">
                    <input class="form-control f-size-2 mt-2" type="file" id="filec4" (change)="changeMinBanner($event)"
                        [(ngModel)]="miniatura_banner" name="miniatura_banner" accept="image/*">
                </div>
                <div class="col-md-3 mb-4" *ngIf="val1 == 1">
                    <button class="button mt-2 boton3" type="button" data-bs-toggle="modal"
                        data-bs-target="#openModalMinBanner" [disabled]="!miniatura_banner">
                        <em class="bi bi-eye-fill" style="pointer-events: none;"></em>&nbsp;&nbsp;Previsualizar</button>
                </div>
            </div>

            <div class="row mb-5" *ngIf="cargarClara">
                <div class="col-md-12">
                    <label for="inputClara" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                        <span class="h6 small bg-white text-muted px-1">Carga en @Clara</span>
                    </label>
                    <label class="form-control" id="inputClara" style="padding-top: 1em;">
                        <span> {{uri_clara}}</span>
                    </label>
                </div>
            </div>

            <div class="row mb-5">
                <div class="col-md-6">
                    <label for="input5" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                        <span class="h6 small bg-white text-muted px-1">Fecha publicación*</span>
                    </label>
                    <input id="fechap" type="text" class="mt-2 form-control" min="{{fecha}}"
                        onfocus="(this.type='date')" onkeydown="return false"
                        onblur="if(this.value==''){this.type='text'}" (change)="changeFechaPublicacion()" required
                        [(ngModel)]="fecha_publicacion" name="fecha_publicacion">
                </div>
                <div class="col-md-6">
                    <label for="input6" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                        <span class="h6 small bg-white text-muted px-1">Fecha vigencia*</span>
                    </label>
                    <input id="fechav" type="text" required class="mt-2 form-control" min="{{fecha_publicacion}}"
                        onfocus="(this.type='date')" onkeydown="return false"
                        onblur="if(this.value==''){this.type='text'}" required [(ngModel)]="fecha_vigencia"
                        name="fecha_vigencia">
                </div>
            </div>
            <br>
            <br>
            <div class="row">
                <div class="col-md-6">
                    <label class="mb-4 etiqueta"><strong>Audiencia:</strong></label>
                </div>
            </div>
            <div *ngIf="!datosaudiencia">
                <button class="button mt-2 boton3" type="button" (click)="abrirModal(this.modal)">&nbsp;&nbsp;Cargar
                    nominas</button>
            </div>
            <incentivos-audiencia *ngIf="!cargaNominas" (dtaudiencia)="array($event)" (dtaudienciaOne)="arrayOne($event)"></incentivos-audiencia>
            <br><br>
            <div class="row">
                <div class="col d-grid gap-5 d-md-flex justify-content-md-end">
                    <button class="button mt-2 boton" type="button" (click)="dataDef()">&nbsp;&nbsp;Limpiar</button>
                    <button class="button mt-2 boton2" type="button" (click)="guardar()">&nbsp;&nbsp;Guardar</button>
                    <button [disabled]="!notificacion" class="button mt-2 boton3"
                        [style.background-color]="colorInactiva + '!important'" type="button" (click)="publicar()"><em
                            class="bi bi-window-desktop"></em>&nbsp;&nbsp;Enviar Notificación</button>
                </div>
            </div>
            <br><br>
        </div>
    </div>
</div>

<!-- Modal de previsualización Archivos-->
<div class="modal fade" id="openModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-title" id="exampleModalLabel">
                <p class="f-t3">Validación de contenido</p>
                <p class="f-t4">Verifica el contenido que está subiendo</p>
            </div>
            <div class="modal-body mt-3" *ngIf="id_post == 0  && tipo_cont != 2">
                <iframe *ngIf="filePrev" [src]="filePrev" title="Imagen Prev" height="80%" width="100%"
                    ></iframe>
            </div>
            <div class="modal-body mt-3" *ngIf="id_post == 0  && tipo_cont == 2">
                <video #video *ngIf="filePrev" [vgMedia]="$any(media)" #media height="80%" width="100%" controls
                    src="{{filePrev}}" type="video/mp4">
                    <track label="Español" kind="captions" src="" default>
                </video>
            </div>
            <div class="modal-body mt-3" *ngIf="id_post != 0 && tipo_cont != 2">
                <iframe *ngIf="filePrev" [src]="filePrev" title="Imagen Prev" height="80%" width="100%"
                    ></iframe>
            </div>
            <div class="modal-body mt-3" *ngIf="id_post != 0 && tipo_cont == 2">
                <video #video *ngIf="filePrev" [vgMedia]="$any(media)" #media height="80%" width="100%" controls
                    [src]="filePrev" type="video/mp4">
                    <track label="Español" kind="captions" src="" default>
                </video>
            </div>
            <div class="modal-footer" style="border-top: 0;">
                <div class="col d-grid gap-5 d-md-flex justify-content-md-end">
                    <button class="button mt-2 boton2" data-bs-dismiss="modal" type="button">&nbsp;&nbsp;Cerrar</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal de previsualización image Miniatura del Post base64-->
<div class="modal fade" id="openModalMinPost" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-title" id="exampleModalLabel">
                <p class="f-t3">Validación de contenido</p>
                <p class="f-t4">Verifica el contenido que está subiendo</p>
            </div>
            <div class="modal-body mt-3">
                <iframe *ngIf="filePrevMinPost" [src]="filePrevMinPost" title="Imagen Prev" height="80%" width="100%"
                    sandbox></iframe>
            </div>
            <div class="modal-footer" style="border-top: 0;">
                <div class="col d-grid gap-5 d-md-flex justify-content-md-end">
                    <button class="button mt-2 boton2" data-bs-dismiss="modal" type="button">&nbsp;&nbsp;Cerrar</button>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Modal de previsualización image Miniatura del banner base64-->
<div class="modal fade" id="openModalMinBanner" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-title" id="exampleModalLabel">
                <p class="f-t3">Validación de contenido</p>
                <p class="f-t4">Verifica el contenido que está subiendo</p>
            </div>
            <div class="modal-body mt-3">
                <iframe *ngIf="filePrevMinBanner" [src]="filePrevMinBanner" title="Imagen Prev" height="80%"
                    width="100%" sandbox></iframe>
            </div>
            <div class="modal-footer" style="border-top: 0;">
                <div class="col d-grid gap-5 d-md-flex justify-content-md-end">
                    <button class="button mt-2 boton2" data-bs-dismiss="modal" type="button">&nbsp;&nbsp;Cerrar</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal de incentivos-->
<ng-template #modalnominas>
    <div class="modal-content">
        <div class="modal-title">
            <p class="f-t3">Agrega el listado de nominas</p>
        </div>
        <br><br>
        <div class="modal-body">
            <div class="row mb-2 mt-4">
                <div class="col-12">
                    <textarea class="textarea" cols=90 rows=12 [(ngModel)]="listaNominas"></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer" style="border-top: 0;">
            <div class="col d-grid gap-5 d-md-flex justify-content-md-end">
                <button class="button mt-2 boton" data-bs-dismiss="modal" type="button" (click)="cerrarModal()">&nbsp;&nbsp;Cerrar</button>
                <button class="btn mt-2 boton2" type="button" (click)="guardarNominas()">&nbsp;&nbsp;Aceptar</button>
            </div>
        </div>
    </div>
</ng-template>