import { Component } from '@angular/core';


@Component({
  selector: 'incentivos-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent {

  nombre: any = sessionStorage.getItem('nombre');
  nomina: any = sessionStorage.getItem('nomina');
  nombreL: any;
  nominaL: any;

  constructor( ) {
    this.nombreL = this.nombre;
    this.nominaL = this.nomina;
  }
}
