import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Funciones } from '../../classes/funciones';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServiciosImp } from '../../services/services';

@Component({
  selector: 'incentivos-audiencia',
  templateUrl: './audiencia.component.html'
})
export class AudienciaComponent implements OnInit {

  arbol_empresa: any[] = [];
  datos_matriz: any[] = [];
  datos_matriz_mod: any[] = [];
  datos_audiencia: any[] = [];
  @Output() dtaudiencia: EventEmitter<any> = new EventEmitter();
  @Output() dtaudienciaOne: EventEmitter<any> = new EventEmitter();

  constructor(
    public servicios: ServiciosImp,
    public snackBar: MatSnackBar,
    private router: Router,
    public funcion: Funciones
  ) { }

  ngOnInit(): void {

    this.servicios.getService('audiencia/arbol/').toPromise().then(
      (res: any) => {
        if (res.status == 401) {
          this.router.navigate(['login']);
        }
        this.arbol_empresa = res.data;
        this.datos_matriz = this.arbol_empresa.map((item: any) => {
          item.id = item.id === "0" ? null : item.id;
          return item;
        });
        for (let val of this.datos_matriz) {
          let array = {
            "id": val.id,
            "id_padre": val.id_padre,
            "descripcion": val.descripcion == null ? "" : val.descripcion,
            "descripcion_nivel": val.descripcion_nivel == null ? "" : val.descripcion_nivel,
            "nomina": val.nomina,
            "id_empresa": val.id_empresa,
            "id_puesto": val.id_puesto,
            "id_ubicacion": val.id_ubicacion,
            "id_udn": val.id_udn,
            "id_direccion": val.id_direccion,
            "id_subdireccion": val.id_subdireccion,
          }
          this.datos_matriz_mod.push(array);

        }
        this.funcion.hide();
        this.dtaudienciaOne.emit({ "arrayOne": this.datos_matriz_mod });
      },
      (error: any) => {
        this.snackBar.open(error.message, 'close', {
          duration: 2500,
        });
        this.funcion.hide();
      })
  }

  public getSelection(e: any) {
    if (e.currentSelectedRowKeys.length > 0) {
      if (e.selectedRowsData.length > 1) {
        e.selectedRowsData.forEach((item: any) => {
          if (item.descripcion !== '' && item.descripcion !== "Compartamos" && item.descripcion !== "Ventas") {

            this.datos_audiencia.push(item);
            this.dtaudiencia.emit({ "array": this.datos_audiencia });
          }

        });
      } else {

        this.datos_audiencia.push(e.selectedRowsData[0]);
        this.dtaudiencia.emit({ "array": this.datos_audiencia });
      }

    } else {
      this.datos_audiencia = [];
      this.dtaudiencia.emit({ "array": this.datos_audiencia });
    }
  }

  matriz_audiencia = this.datos_matriz_mod
  plain = [
    {
      "descripcion": "Audiencia",
      "orden": 1,
      "activo": true,
      "ultimoNivel": 1
    }
  ]
  descripcion = "Error"
  searchPanel = { visible: true, width: 200, placeholder: 'Buscar...', filterRow: true };

}
