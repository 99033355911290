import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServiciosImp } from '../../services/services';
import { Funciones } from '../../classes/funciones';

import {
  IColumns
} from '../../models/grid.interfaces';


@Component({
  selector: 'incentivos-reporte-cuestionario',
  templateUrl: './reporte_cuestionario.component.html',
  styleUrls: ['./reporte_cuestionario.component.css']
})
export class ReporteCuestionarioComponent implements OnInit {

  /* *************** Parametros del Grid de datos ***************** */
  columns_rep_cuestionario: IColumns[] = [];

  nombre: any = sessionStorage.getItem('nombre');
  nomina: any = sessionStorage.getItem('nomina');
  datos: any = [];
  datos_prueba: any = [];
  empc: any[] = [];
  udnc: any[] = [];
  puestoc: any[] = [];
  ubicacionc: any[] = [];
  categoriac: any[] = [];
  quizz: any[] = [];

  direccion: any[] = [];
  subdireccion: any[] = [];

  idempresa: number = 0;
  idUDN: string = '0';
  idUbicacion: string = '0';
  idPuesto: string = '0';
  idColaborador: string = "";
  idCustionario: string = "";
  fechaIniCuestionario: any;
  fechaFinCuestionario: any;
  fechaiCuestionario: any;
  fechafCuestionario: any;
  fecha: string = "";
  nombreL: any;
  nominaL: any;
  principal: boolean = true;
  idquizz: number = 0;
  mostrar: boolean = false;

  idDireccion: string = '0';
  idSubdireccion: string = '0';

  constructor(
    private router: Router,
    public snackBar: MatSnackBar,
    public servicios: ServiciosImp,
    public funcion: Funciones
  ) {

    this.nombreL = this.nombre;
    this.nominaL = this.nomina;

    /* *************** Parametros del Grid de datos ***************** */
    this.columns_rep_cuestionario = [ // Columnas
      {
        caption: 'Nombre de cuestionario',
        dataField: 'titulo_sondeo',
      },
      {
        caption: 'Fecha publicación',
        dataField: 'fecha_inicial',
      },
      {
        caption: 'Fecha vencimiento',
        dataField: 'fecha_final',
      }
    ];

  }


  ngOnInit(): void {
    this.fecha = this.funcion.formatDate();
    this.cargaUDNCuestionario();
    this.cargaPuestosCuestionario();
    this.cargaUbicacionCuestionario();
    this.cargaCatalogoEmpresaCuestionario();
    this.cargaDireccionCuestionario();
    this.cargaSubdireccionCuestionario();
  }

  cargaUDNCuestionario() {
    this.servicios.getCatalogoUDN().subscribe(datocues1 => {
      if (!datocues1.error) {
        this.udnc = datocues1.data;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }

  cargaPuestosCuestionario() {
    this.servicios.getCatalogoPuestos().subscribe(datocues2 => {
      if (!datocues2.error) {
        this.puestoc = datocues2.data;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }

  cargaDireccionCuestionario() {
    this.servicios.getCatalogoDireccion().subscribe(dircuest => {
      if (!dircuest.error) {
        this.direccion = dircuest.data;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }
  cargaSubdireccionCuestionario() {
    this.servicios.getCatalogoSubdireccion().subscribe(subdircuest => {
      if (!subdircuest.error) {
        this.subdireccion = subdircuest.data;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }

  cargaUbicacionCuestionario() {
    this.servicios.getCatalogoUbicacion().subscribe(datocues3 => {
      if (!datocues3.error) {
        this.ubicacionc = datocues3.data;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }

  cargaCatalogoEmpresaCuestionario() {
    this.servicios.getCatalogoEmpresaPerfil().subscribe(datocues4 => {
      if (!datocues4.error) {
        this.empc = datocues4.data;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }

  arraySel(val: any) {
    this.idquizz = val
  }

  onClickDashboardCuestionario() {
    if (this.mostrar) {
      if (this.idquizz !== 0) {
        let param = {
          "id_quizz": this.idquizz
        }
        this.servicios.postService('analitica/quizz/', param).toPromise().then(
          (res: any) => {
            if (res.status == 401) {
              this.router.navigate(['login']);
            }
            this.datos = res.data
            this.fechaiCuestionario = this.datos.fecha_inicio;
            this.fechafCuestionario = this.datos.fecha_final;
            this.principal = false;
            this.funcion.hide();
          },
          (error: any) => {
            this.msgError(error);
          }
        )
      } else {
        this.snackBar.open("Debe seleccionar un cuestionario de la tabla", 'close', {
          duration: 2500,
        });
      }
    } else {
      this.snackBar.open("Debe buscar un cuestionario antes de consultar una gráfica del Dashboard", 'close', {
        duration: 2500,
      });
    }
  }

  msgError(error: any) {
    this.snackBar.open(error.message, 'close', { duration: 2500 });
    this.funcion.hide();
  }

  onClickExcelCuestionario() {
    if (this.mostrar) {
      if (this.idquizz !== 0) {
        let paramcues = {
          "id_quizz": this.idquizz
        }
        this.servicios.downExcel(paramcues, 'quizz').subscribe(datoexcelcuestionario => {
          if (!datoexcelcuestionario.error) {
            this.funcion.downloadExcel(datoexcelcuestionario, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'reporte_cuestionario.xlsx');
            this.funcion.hide();
          } else {
            this.router.navigate(['login']);
            this.funcion.hide();
          }
        });
      } else {
        this.snackBar.open("Debe seleccionar un cuestionario de la tabla", 'close', {
          duration: 2500,
        });
      }
    } else {
      this.snackBar.open("Debe buscar un cuestionario antes de descargar el archivo", 'close', {
        duration: 2500,
      });
    }
  }
  objCuestionario(){
    return {
      "fecha_inicio": this.fechaIniCuestionario,
      "fecha_fin": this.fechaFinCuestionario,
      "id_empresa": Number(this.idempresa) == 0 ? 13 : Number(this.idempresa),
      "id_udn": this.idUDN == '0' ? '': this.idUDN,
      "id_puesto": this.idPuesto == '0' ? '': this.idPuesto,
      "id_ubicacion": this.idUbicacion == '0' ? '': this.idUbicacion,
      "id_direccion": this.idDireccion == '0' ? '': this.idDireccion,
      "id_subdireccion": this.idSubdireccion == '0' ? '': this.idSubdireccion,
      "nomina": Number(this.idColaborador)
    };

  }

  onClickConsultaCuestionario() {
    if (this.fechaIniCuestionario !== undefined && this.fechaIniCuestionario !== "" && this.fechaFinCuestionario !== undefined && this.fechaFinCuestionario !== "") {
      this.mostrar = true;
      let body = this.objCuestionario();
      this.servicios.postService('quizz/audiencia/', body).toPromise().then(
        (res: any) => {
          if (res.status == 401) {
            this.router.navigate(['login']);
          }
          this.quizz = res.data;
          this.funcion.hide();
        },
        (error: any) => {
          this.msgError(error);
        }
      )
    } else {
      this.snackBar.open("Error en las fechas seleccionadas", 'close', {
        duration: 2500,
      });
    }
  }


  return() {
    this.principal = true;
  }




}
