import { Component, OnInit } from '@angular/core';
import { ServiciosImp } from '../../services/services';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  IColumns,
  TiposdeDato
} from '../../models/grid.interfaces';
import { Funciones } from '../../classes/funciones';

@Component({
  selector: 'incentivos-configuracion-tour',
  templateUrl: './configuracion_tour.component.html',
  styleUrls: ['./configuracion_tour.component.css']
})
export class ConfiguracionTourComponent implements OnInit {

  /* *************** Parametros del Grid de datos ***************** */
  columns_tours: IColumns[] = [];

  /* *************** Otros ***************** */
  wait = false;
  nombre: any = sessionStorage.getItem('nombre');
  nomina: any = sessionStorage.getItem('nomina');
  nombreL: any;
  usuarioL: any;
  nominaL: any;
  tours: any[] = [];
  status: any[] = [];
  emp: any[] = [];
  perfiles: any[] = [];
  tp: any[] = [];

  public estatus: string = '';
  public fecha_pub: string = '';
  public empresa: string = '';
  public rol: string = '';
  public tipo: string = '';
  url_tour: string = "/nuevo_tour/";


  constructor(
    public servicios: ServiciosImp,
    private router: Router,
    public snackBar: MatSnackBar,
    public funcion: Funciones
    ) {
    this.nombreL = this.nombre;
    this.nominaL = this.nomina;

    /* *************** Parametros del Grid de datos ***************** */
    this.columns_tours = [ // Columnas
      {
        caption: 'Empresa',
        dataField: 'empresa_desc',
      },
      {
        caption: 'Estatus',
        dataField: 'status',
      },
      {
        caption: 'Rol',
        dataField: 'rol',
      },
      {
        caption: 'Tipo',
        dataField: 'tipo',
        type: TiposdeDato.datetime
      },
      {
        caption: 'Fecha publicación',
        dataField: 'fecha_publicacion',
        type: TiposdeDato.datetime,
      },
    ];

  }

  ngOnInit(): void {

    this.cargaCatalogoEmpresa();
    this.catalogoPerfiles();

    this.wait = true;
    this.servicios.getService('tour/').toPromise().then(
      (res: any) => {
        if (res.status == 401) {
          this.router.navigate(['login']);
        }
        this.tours = res.data;
        this.wait = false;
        this.funcion.hide();
      },
      (error: any) => {
        this.msgError(error);
      }
    )
  }

  msgError(error: any) {
    this.wait = false;
    this.snackBar.open(error.message, 'close', { duration: 2500 });
    this.funcion.hide();
  }

  newTour() {
    this.router.navigate([`/nuevo_tour/${0}`]);
  }


  /*Consulta en el grid por filtro*/
  consulta() {
    let r = this.estatus.toLowerCase() == 'true' ? 1 : 0;
    let filtro1 = this.estatus != '' ? r : '';
    let filtro2 = this.empresa != '' ? this.empresa : '';
    let filtro3 = this.fecha_pub != '' ? this.fecha_pub : '';
    let filtro4 = this.rol != '' ? this.rol : '';
    let filtro5 = this.tipo != '' ? this.tipo : '';
    this.wait = true;
    this.servicios.getService(`tour/filtros/?limit=500&offset=0&status=` + filtro1 + `&empresa_id=` + filtro2 + `&fecha_publicacion=` + filtro3 + `&rol=` + filtro4 + `&tipo=` + filtro5 + ``).toPromise().then(
      (res: any) => {
        if (res.status == 401) {
          this.router.navigate(['login']);
        }
        this.tours = res.data.tour;
        this.wait = false;
        this.funcion.hide();
      },
      (error: any) => {
        this.msgError(error);
      }
    )
  }

  dataDef() {
    this.estatus = '';
    this.fecha_pub = '';
    this.empresa = '';
    this.rol = '';
    this.tipo = '';
  }

  cargaCatalogoEmpresa() {
    this.servicios.getService('empresa/').toPromise().then(
      (res: any) => {
        if (res.status == 401) {
          this.router.navigate(['login']);
        }
        this.emp = res.data;
        this.funcion.hide();
      }
    ).catch((error) => {
      this.msgError(error);
    });
  }

  catalogoPerfiles() {
    this.servicios.getService('servicios/roles/').toPromise().then(
      (res: any) => {
        this.perfiles = res.data;
      }, (error: any) => {
        //console.log(error)
        this.funcion.hide();
      }
    )
  }


}
