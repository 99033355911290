import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { getMessaging, onMessage } from "firebase/messaging";
import { ToastService } from './toast-service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  message: any = null;

  constructor(private titulo: Title,
    public toastService: ToastService

  ) {
    this.titulo.setTitle('Incentivos')
  }
  ngOnInit(): void {
    this.listen();
  }

  ngOnDestroy(): void {
    this.toastService.clear();
  }

  listen() {
    try {
      const messaging = getMessaging();
      onMessage(messaging, (payload: any) => {
        this.message = payload;
        console.log('Obtiene notificacion',this.message.data)
        let not = {
          title: this.message.data.title,
          body: this.message.data.body,
          image: this.message.data.image
        }
        this.downloadReportEx(this.message.data.body, "Reporte de clicks.xlsx");
        this.toastService.show(not);
      });
    } catch (e) {

    }
  }

  downloadReportEx(blob: any, filename: string) {
    const url = blob;
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.href = url;
    a.download = filename;
    a.click();
  }


}
