import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  IColumns,
  TiposdeDato
} from '../../models/grid.interfaces';
import { Funciones } from '../../classes/funciones';
import { ServiciosImp } from '../../services/services';



@Component({
  selector: 'incentivos-configuracion-inicio',
  templateUrl: './configuracion_inicio.component.html',
  styleUrls: ['./configuracion_inicio.component.css']
})
export class ConfiguracionInicioComponent implements OnInit {

  /* *************** Parametros del Grid de datos ***************** */
  columns_inicio: IColumns[] = [];

  /* *************** Otros ***************** */
  wait = false;
  nombre: any = sessionStorage.getItem('nombre');
  nomina: any = sessionStorage.getItem('nomina');
  nombreL: any;
  usuarioL: any;
  nominaL: any;
  logo_msg: any[] = [];
  url_inicio: string = "/registro_logo_mensaje/";


  constructor(
    private router: Router,
    public snackBar: MatSnackBar,
    public funcion: Funciones,
    public servicios: ServiciosImp
    )
  {
    this.nombreL = this.nombre;
    this.nominaL = this.nomina;

    /* *************** Parametros del Grid de datos ***************** */

    this.columns_inicio = [ // Columnas
      {
        caption: 'Id',
        dataField: 'id',
      },
      {
        caption: 'Mensaje Clara',
        dataField: 'mensaje_clara',
      },
      {
        caption: 'Fecha inicio',
        dataField: 'fecha_inicio',
        type: TiposdeDato.datetime,
      },
      {
        caption: 'Fecha fin',
        dataField: 'fecha_final',
        type: TiposdeDato.datetime,
      },

    ];

    /* *************** FIN Parametros del Grid de datos ***************** */
  }

  ngOnInit(): void {
    this.inicio();
  }

  public inicio(){
    this.wait = true;
    this.servicios.getService('logo/').toPromise().then(
      (res: any) => {
        if(res.status == 401){
          this.router.navigate(['login']);
        }
        this.logo_msg = res.data;
        this.wait = false;
        this.funcion.hide();
      },
      (error: any) => {
        this.wait = false;
        this.snackBar.open(error.message, 'close', {
          duration: 2500,
       });
       this.funcion.hide();
      }
    )
  }

  newRegistro() {
    this.router.navigate([`/registro_logo_mensaje/${0}`]);
  }

  public datosInicioDelete(senddata: any){
    this.servicios.deleteConfInicio(senddata).subscribe(datoinicio => {
      if (!datoinicio.error) {
        this.snackBar.open('Datos eliminados correctamente', 'close');
        this.funcion.hide();
        this.inicio();
      }else{
        this.snackBar.open('Error al eliminar los registros', 'close', {
            duration: 2500,
        });
        this.funcion.hide();
      }
    });
  }


}
