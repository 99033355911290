import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServiciosImp } from '../../services/services';
import { Funciones } from '../../classes/funciones'



@Component({
  selector: 'incentivos_reporte_nps',
  templateUrl: './reporte_nps.component.html',
  styleUrls: ['./reporte_nps.component.css']
})
export class ReporteNpsComponent implements OnInit {

  nombre: any = sessionStorage.getItem('nombre');
  nomina: any = sessionStorage.getItem('nomina');
  datos: any = [];
  datos_prueba: any = [];
  empn: any[] = [];
  udnn: any[] = [];
  pueston: any[] = [];
  ubicacionn: any[] = [];
  categorian: any[] = [];

  direccion: any[] = [];
  subdireccion: any[] = [];

  idempresa: number = 0;
  idUDN: string = '0';
  idUbicacion: string = '0';
  idPuesto: string = '0';
  idColaboradorNPS: string = "";
  fechaIniNPS: any;
  fechaFinNPS: any;
  fechaiNPS: any;
  fechafNPS: any;
  fecha: string = "";
  nombreL: any;
  nominaL: any;

  idDireccion: string = '0';
  idSubdireccion: string = '0';

  principal: boolean = true;

  constructor(
    private router: Router,
    public snackBar: MatSnackBar,
    public servicios: ServiciosImp,
    public funcion: Funciones
  ) {
    this.nombreL = this.nombre;
    this.nominaL = this.nomina;
  }

  ngOnInit(): void {
    this.fecha = this.funcion.formatDate();
    this.cargaUDNNPS();
    this.cargaPuestosNPS();
    this.cargaUbicacionNPS();
    this.cargaCatalogoEmpresaNPS();
    this.cargaDireccionNps();
    this.cargaSubdireccionNps();
  }

  cargaUDNNPS() {
    this.servicios.getCatalogoUDN().subscribe(datonps1 => {
      if (!datonps1.error) {
        this.udnn = datonps1.data;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }

  cargaPuestosNPS() {
    this.servicios.getCatalogoPuestos().subscribe(datonps2 => {
      if (!datonps2.error) {
        this.pueston = datonps2.data;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }

  cargaDireccionNps() {
    this.servicios.getCatalogoDireccion().subscribe(direcnps => {
      if (!direcnps.error) {
        this.direccion = direcnps.data;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }
  cargaSubdireccionNps() {
    this.servicios.getCatalogoSubdireccion().subscribe(subdirecnps => {
      if (!subdirecnps.error) {
        this.subdireccion = subdirecnps.data;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }

  cargaUbicacionNPS() {
    this.servicios.getCatalogoUbicacion().subscribe(datonps3 => {
      if (!datonps3.error) {
        this.ubicacionn = datonps3.data;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }

  cargaCatalogoEmpresaNPS() {
    this.servicios.getCatalogoEmpresaPerfil().subscribe(datonps4 => {
      if (!datonps4.error) {
        this.empn = datonps4.data;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }

  onClickExcelUsabilidad() {
    if (this.fechaIniNPS !== undefined && this.fechaIniNPS !== "" && this.fechaFinNPS !== undefined && this.fechaFinNPS !== "") {
      let paramusab = this.objNPS();
      this.servicios.downExcel(paramusab, 'nps').subscribe(datoexcelusabilidad => {
        if (!datoexcelusabilidad.error) {
          this.funcion.downloadExcel(datoexcelusabilidad, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'reporte_NPS.xlsx');
          this.funcion.hide();
        } else {
          this.router.navigate(['login']);
          this.funcion.hide();
        }
      });
    } else {
      this.snackBar.open("Debes llenar los campos obligatorios", 'close', {
        duration: 2500,
      });
    }
  }
  objNPS(){
    return {
      "fecha_inicio": this.fechaIniNPS,
      "fecha_fin": this.fechaFinNPS,
      "id_empresa": Number(this.idempresa),
      "id_udn": this.idUDN == '0' ? '': this.idUDN,
      "id_puesto": this.idPuesto == '0' ? '': this.idPuesto,
      "id_ubicacion": this.idUbicacion == '0' ? '': this.idUbicacion,
      "id_direccion": this.idDireccion == '0' ? '': this.idDireccion,
      "id_subdireccion": this.idSubdireccion == '0' ? '': this.idSubdireccion,
      "nomina": Number(this.idColaboradorNPS)
    };

  }
  onClickDashboardNPS() {
    if (this.fechaIniNPS !== undefined && this.fechaIniNPS !== "" && this.fechaFinNPS !== undefined && this.fechaFinNPS !== "") {
      let bodyNPS = this.objNPS();
      this.servicios.postService('analitica/nps/', bodyNPS).toPromise().then(
        (res: any) => {
          if (res.status == 401) {
            this.router.navigate(['login']);
          }

          this.fechaiNPS = this.fechaIniNPS;
          this.fechafNPS = this.fechaFinNPS;
          this.datos = res.data
          this.principal = false;
          this.funcion.hide();
        },
        (error: any) => {
          this.snackBar.open(error.message, 'close', {
            duration: 2500,
          });
          this.funcion.hide();
        }
      )
    } else {
      this.snackBar.open("Debes llenar los campos obligatorios", 'close', {
        duration: 2500,
      });
    }
  }

  return() {
    this.principal = true;
  }

}
