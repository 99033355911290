import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServiciosImp } from '../../services/services';
import { Funciones } from '../../classes/funciones';


@Component({
  selector: 'incentivos-registro-logo-msg',
  templateUrl: './registro_logo_msg.component.html',
  styleUrls: ['./registro_logo_msg.component.css']
})
export class RegistroLogoMsgComponent implements OnInit {

  nombre: any = sessionStorage.getItem('nombre');
  nomina: any = sessionStorage.getItem('nomina');
  nombreL: any;
  nominaL: any;
  mensaje_clara: string = '';
  logo_inicio: any;
  public img_inicio: any;
  public fecha_inicio: any;
  public fecha_fin: any;
  titulo: string = "Nuevo";
  public id: number = 0;
  filePrev: any;
  public default: boolean = false;

  constructor(
    private router: Router,
    public snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    public servicios: ServiciosImp,
    public funcion: Funciones
  ) {
    this.nombreL = this.nombre;
    this.nominaL = this.nomina;
  }

  ngOnInit(): void {
    this.inicio();
  }

  public inicio() {
    this.activatedRoute.params.subscribe(parametros => {
      this.id = parametros.id;
      if (this.id != 0) {
        this.titulo = 'Edita'
        let url = 'logo/' + this.id + '/';
        this.servicios.getService(url).toPromise().then(
          (res: any) => {
            console.log(res);
            if (res.status == 401) {
              this.router.navigate(['login']);
            }
            this.mensaje_clara = res.data.mensaje_clara;
            this.img_inicio = res.data.logo;
            this.logo_inicio = res.data.logo;
            this.filePrev = res.data.logo;
            this.fecha_inicio = res.data.fecha_inicio;
            this.fecha_fin = res.data.fecha_final;
            this.funcion.hide();
          },
          (error: any) => {
            this.snackBar.open(error.message, 'close', {
              duration: 2500,
            });
            this.funcion.hide();
          }
        )
      }
    });
  }


  public dataDef() {
    this.mensaje_clara = "";
    this.filePrev = "";
    this.img_inicio = "";
    this.fecha_inicio = "";
    this.fecha_fin = "";
    this.default = false;
  }

  public guardar() {
    if (this.mensaje_clara !== '' && this.fecha_inicio !== '' && this.fecha_fin !== '') {
      let body = {
        "mensaje_clara": this.mensaje_clara,
        "logo": this.img_inicio,
        "fecha_inicio": this.fecha_inicio,
        "fecha_final": this.fecha_fin,
        "default": this.default
      }
      if (this.id == 0) {
        this.nuevoLogo(body);
      } else {
        this.actualizarLogo(body);
      }
    } else {
      this.snackBar.open('Por favor, ingrese todos los campos', 'close', {
        duration: 2500,
      });
    }
  }

  nuevoLogo(body: any) {
    this.servicios.regLogo(body).subscribe(resplogo1 => {
      if (!resplogo1.error) {
        this.snackBar.open('Datos guardados correctamente', 'close', {
          duration: 2500,
        });
        this.funcion.hide();
        this.router.navigate(['registro_logo_mensaje/' + resplogo1.data.id]);
      } else {
        this.snackBar.open('Error al guardar los datos', 'close', {
          duration: 2500,
        });
        this.funcion.hide();
      }
    });
  }

  actualizarLogo(body: any) {
    this.servicios.actLogo(body, this.id).subscribe(resplogo2 => {
      if (!resplogo2.error) {
        this.snackBar.open('Datos actualizados correctamente', 'close', {
          duration: 2500,
        });
        this.funcion.hide();
        this.router.navigate(['registro_logo_mensaje/' + this.id]);
      } else {
        this.snackBar.open('Error al actualizar los datos', 'close', {
          duration: 2500,
        });
        this.funcion.hide();
      }
    });
  }

  logoInicio($event: any) {
    if(this.funcion.extensionValida($event)){
    let file: File = $event.target.files[0];
    let myReader: FileReader = new FileReader();
    myReader.onloadend = () => {
      this.filePrev = myReader.result;
      this.img_inicio = this.filePrev;
    }
    myReader.readAsBinaryString(file);
    }else{
      this.logo_inicio = '';
    }
  }

  onClickConfsi() {
    this.router.navigate(['resumen_post']);
  }

  onClickConfInicio() {
    this.router.navigate(['configuracion_inicio']);
  }

}
