import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators'
import { Funciones } from '../classes/funciones';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService {

  constructor( public funcion: Funciones) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      this.funcion.show();

      return next.handle(req)
          .pipe(tap((event: HttpEvent<any>) => {
                  if (event instanceof HttpResponse) {
                    /*** Se comento para aumentar el tiempo de carga de archivos del post, afectando el flujo de todos los spinners de carga del proyecto (se cierran manualmente) */
                  }
              }, (error) => {
                  this.funcion.hide();
              }));
  }

}
