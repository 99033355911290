import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Funciones } from '../../classes/funciones';
import { IColumns } from '../../models/grid.interfaces';
import { Router } from '@angular/router';
import { ServiciosImp } from '../../services/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'carga_calculo_manual',
  templateUrl: './carga_calculo_manual.component.html',
  styleUrls: ['./carga_calculo_manual.component.css']
})
export class CargaCalculoManualComponent implements OnInit {
  @ViewChild('modalpermiso') modal: ElementRef | undefined;
  url_tipo: any;
  nombre: any = sessionStorage.getItem('nombre');
  nomina: any = sessionStorage.getItem('nomina');
  nombreL: any;
  nominaL: any;
  empresa_id: number = 0;
  columns_sondeo: IColumns[] = [];
  wait = false;
  excel: string = 'Sin archivos seleccionados';
  bitCalculoManual: any[] = [];
  empsondeo: any[] = [];
  public empresa: string = '';
  public estatus: string = '';
  public titulo_sondeo: string = '';
  public selectedDate: Date = new Date();
  public nombreExcelCompleto: string = '';
  public fechacarga: string = '';

  datos: any[] = [];
  datos_excel: any[] = [];
  nombre_empresa: string = "";
  aux: any;

  incentivoCat: any[] = [];
  idIncentivo: string = '0';
  desIncentivo: string = '';
  empex: any[] = [];
  puestoex: any[] = [];
  ubicacionex: any[] = [];
  categoriaex: any[] = [];
  ruta_archivo: string = '';
  public senddata: any = [];

  constructor(
    public snackBar: MatSnackBar,
    public funcion: Funciones,
    public router: Router,
    public servicios: ServiciosImp,
    public modalService: NgbModal


  ) {
    this.nombreL = this.nombre;
    this.nominaL = this.nomina;



    this.columns_sondeo = [ // Columnas
      {
        caption: 'ID',
        dataField: 'id',
      },
      {
        caption: 'Fecha',
        dataField: 'fecha_carga',
      },
      {
        caption: 'Usuario',
        dataField: 'usuario',
      },
      {
        caption: 'Archivo',
        dataField: 'nombre_archivo',
      },
      {
        caption: 'Estatus',
        dataField: 'estatus',
      }

    ];


  }

  ngOnInit(): void {
    this.wait = true;
    this.inicio();
    this.cargaCatalogoEmpresaSondeo();
    this.cargaCatalogoIncentivo();
  }

  funcionHide() {
    this.funcion.hide();
  }



  msgError(error: any) {
    this.wait = false;
    this.snackBar.open(error.message, 'close', { duration: 2500 });
    this.funcionHide();
  }

  cargaCatalogoIncentivo() {
    this.servicios.getCatalogoIncentivos().subscribe(datosus1 => {
      if (!datosus1.error) {
        this.incentivoCat = datosus1.data.archivo;
        this.funcionHide();
      } else {
        this.funcionHide();
        this.enrutarInicio();
      }
    });
  }
  obtieneDesc() {
    this.incentivoCat.forEach((e: any) => {
      if (e.id == this.idIncentivo) {
        this.desIncentivo = e.descripcion;
      }
    });
  }

  descargaPlantillaCalculo() {
    if (this.idIncentivo !== '0') {
      let param = {
      }
      this.servicios.getObtienePlanCalculoManual(param, Number(this.idIncentivo)).subscribe(res => {

        this.funcion.downloadExcel(res, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', this.desIncentivo + '.xlsx');
        this.funcionHide();
        this.wait = false;
      }
      )
    } else {
      this.snackBar.open("Debes seleccionar un Incentivo", 'close', {

        duration: 2500,
      });
    }
  }

  cargaCatalogoEmpresaSondeo() {
    this.servicios.getCatalogoEmpresa().subscribe(datosondeo => {
      if (!datosondeo.error) {
        this.empsondeo = datosondeo.data;
        this.funcionHide();
      } else {
        this.funcionHide();
        this.enrutarInicio();
      }
    });
  }

  abrirArchivo() {
    let archivo: File;
    let input = document.createElement("input");
    input.type = 'File';
    input.accept = 'xls';
    input.click();
    input.onchange = () => {
      let imagenInput = input.files;
      this.excel = input.files![0].name;
      this.nombreExcelCompleto = this.excel;
      this.excel = this.excel.substring(0, 36);
      let ext = this.nombreExcelCompleto.split('.');
      let extName = ext[1].toLowerCase();
      if (extName !== 'xlsx' && extName !== 'xls' && extName !== 'cvs') {
        this.excel = 'Sin archivos seleccionados'
        this.snackBar.open('El archivo cargado no tiene una extensión correcta', 'close', {
          duration: 2500,
        });
        this.funcionHide();
      } else {
        for (let item in Object.getOwnPropertyNames(imagenInput)) {

          archivo = imagenInput![item];

        }

        var formData: any = new FormData();
        formData.append('nombre_archivo', archivo);
        formData.append('carpeta', '/cargacalculomanual');
        formData.append('bk', 2);

        this.servicios.subeArchivoBuket(formData).subscribe(datoinicio => {
          if (!datoinicio.error) {
            this.ruta_archivo = datoinicio.data.ruta
            this.snackBar.open('Archivo agregado correctamente', 'close', {
              duration: 2500,
            });
            this.funcionHide();
          } else {
            this.snackBar.open('Error al agregar archivo', 'close', {
              duration: 2500,
            });
            this.funcionHide();
          }
        });
      }
    }

  }



  public get_valor(valor: any, array: any) {
    let filtro = array.filter((item: { nombre: any; }) => item.nombre == valor);
    return filtro[0].id;
  }

  subirExcel() {
    
    this.url_tipo = "mesa/calculo-manual/";
    if (this.idIncentivo !== '0' && this.excel !== "Sin archivos seleccionados" && this.fechacarga !== '') {

      this.aux = {
        "periodo": this.fechacarga,
        "puesto": this.desIncentivo,
        "id_puesto": Number(this.idIncentivo),
        "ruta_archivo": this.ruta_archivo,
        "nombre_archivo": this.nombreExcelCompleto
      }
      this.servicios.postService(this.url_tipo, this.aux).toPromise().then(
        () => {
          this.idIncentivo = '0';

          this.snackBar.open('Archivo cargado correctamente', 'close', {
            duration: 2500,
          });
          this.funcionHide();
          this.inicio();
        }
      ).catch((error) => {
        if (error.status == 400 || error.status == 500) {
          this.snackBar.open('Error al cargar archivo ' + error, 'close', {
            duration: 2500,
          });
          this.funcionHide();
        } else {
          this.snackBar.open("Error en la estructura del archivo, favor de corroborar las cabeceras y los datos del archivo", 'close', {
            duration: 2500,
          });
          this.funcionHide();
        }
      });
    } else {
        this.snackBar.open("Debes llenar los campos obligatorios", 'close', {

          duration: 2500,
        });
      
    }
  }

  onClickExcelPerfil(senddata: any) {
    this.funcion.downloadExcelArchivo(senddata.data[0].url_archivo, senddata.data[0].nombre_archivo);
    this.funcionHide();

  }

  abrirModal(modal: any) {

    this.modalService.open(modal, {
      centered: true,
      size: 'md',
      ariaLabelledBy: 'modal-basic-title',
      scrollable: true,
    });
  }

  cerrarModal() {
    this.modalService.dismissAll();
  }


  public datosInicioDelete(senddata: any) {
    this.senddata = senddata
    this.abrirModal(this.modal);
  }

  public datosInicioProcesar(senddata: any) {
    let ruta = 'mesa/calculo-incentivo-manual/';
    let procesar: any = [
      {
        id: senddata.data[0].id,
      }
    ]
    this.servicios.postService(ruta, procesar).toPromise().then(
      (resp: any) => {
        if(!resp.error){
        this.snackBar.open('Archivo procesado correctamente', 'close', {
          duration: 2500,
        });
        this.funcionHide();
        this.inicio();
      }else{
        this.snackBar.open('Error al procesar archivo', 'close', {
          duration: 3000,
        });
        this.funcionHide();
        this.inicio();
      }
    }
    ).catch((error) => {
      if (error.status == 400 || error.status == 500) {
        this.snackBar.open('Error al procesar archivo ' + error, 'close', {
          duration: 2500,
        });
        this.funcionHide();
      }
    });
  }

  public eliminarArchivo() {
    this.cerrarModal();
    let id = this.senddata.data[0].id;
    let data: any = {
      "empresa": "Compartamos",
      "udn": "",
      "periodo": this.senddata.data[0].periodo,
      "ruta_archivo": this.senddata.data[0].ruta_archivo,
      "nombre_archivo": this.senddata.data[0].empresa
    }
    this.servicios.deleteCargaManual(data, id).subscribe(datoinicio => {
      if (!datoinicio.error) {
        this.snackBar.open('Archivo eliminado correctamente', 'close', {
          duration: 2500,
        });
        this.funcionHide();
        this.inicio();
      } else {
        this.snackBar.open('Error al eliminar el archivo', 'close', {
          duration: 2500,
        });
        this.funcionHide();
      }
    });
  }

  public enrutarInicio() {
    this.router.navigate(['login']);
  }

  public inicio() {
    this.wait = true;
    this.servicios.getService('mesa/calculo-manual/').toPromise().then(
      (respuesta: any) => {
        if (respuesta.status == 401) {
          this.enrutarInicio();
        }
        if(respuesta.data){
        this.bitCalculoManual = respuesta.data;
        }
        this.funcionHide();
        this.wait = false;
      },
      (error: any) => {
        this.msgError(error);
      }
    )
  }
}
