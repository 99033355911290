import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Funciones } from '../../classes/funciones';
import { ServiciosImp } from '../../services/services'

import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'incentivos_nueva-notificacion',
  templateUrl: './nueva_notificacion.component.html',
  styleUrls: ['./nueva_notificacion.component.css']
})
export class NuevaNotificacionComponent implements OnInit {
  @ViewChild('modalnominas') modal: ElementRef | undefined;
  nombre: any = sessionStorage.getItem('nombre');
  nomina: any = sessionStorage.getItem('nomina');
  nombreL: any;
  nominaL: any;



  public titulonot = 'Nueva';
  public titulo: string = '';
  public id_notificacion: number = 0;
  public texto_notificacion: string = '';
  notificacion_audiencia: any[] = [];
  audiencia: any[] = [];
  ver: boolean = true;
  public arbol_empresa: any[] = [];
  datos_matriz: any[] = [];
  datos_matriz_mod: any[] = [];
  objnominas: any[] = [];

  cargaNominas: boolean = false;
  notificacionaudiencia: boolean = false;
  listaNominas: any = [];

  constructor
    (
      public snackBar: MatSnackBar,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      public funcion: Funciones,
      public servicios: ServiciosImp,
      public modalService: NgbModal
    ) {
    this.nombreL = this.nombre;
    this.nominaL = this.nomina;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(parametros => {
      this.id_notificacion = parametros.id;
      if (this.id_notificacion != 0) {
        this.titulonot = 'Detalle';
        let url = 'notificacion/' + this.id_notificacion;
        this.servicios.getService(url).toPromise().then(
          (res: any) => {
            if (res.status == 401) {
              this.router.navigate(['login']);
            }
            this.ver = false;
            this.titulo = res.data.titulo;
            this.texto_notificacion = res.data.texto;
            this.funcion.hide();
          },
          (error: any) => {
            this.snackBar.open(error.message, 'close', {
              duration: 2500,
            });
            this.funcion.hide();
          }
        )
      }
    });

    this.servicios.getAudiencia().subscribe(datosaudiencia => {
      if (!datosaudiencia.error) {
        this.arbol_empresa = datosaudiencia.data;
        this.datos_matriz = this.arbol_empresa.map((item: any) => {
          item.id = item.id === "0" ? null : item.id;
          return item;
        });
        for (var val of this.datos_matriz) {
          let array = {
            "id": val.id,
            "idPadre": val.id_padre,
            "descripcion": val.descripcion,
            "descripcion_nivel": val.descripcion_nivel,
            "nomina": val.nomina,
            "id_empresa": val.id_empresa,
            "id_puesto": val.id_puesto,
            "id_ubicacion": val.id_ubicacion,
            "id_direccion": val.id_direccion,
            "id_subdireccion": val.id_subdireccion,
            "id_udn": val.id_udn,
            "tipo": val.tipo_audiencia,
            "nombre": val.nombre,
            "puesto": val.puesto,
            "ubicacion": val.ubicacion,
            "udn": val.udn
          }
          this.datos_matriz_mod.push(array);
        }
        this.funcion.hide();
      } else {
        this.snackBar.open("Error al consultar", 'close', {
          duration: 2500,
        });
        this.funcion.hide();
      }
    });
  }

  public mapeoAudienciaOrigin() {
    for (let datos of this.notificacion_audiencia) {
      let body_audiciencia = {
        "id": datos.idnot,
        "padre": datos.idPadre,
        "descripcion": datos.descripcion,
        "descripcion_nivel": datos.descripcion_nivel,
        "tipo_audiencia": datos.tipo,
        "nomina": datos.nomina,
        "id_empresa": datos.id_empresa,
        "id_puesto": datos.id_puesto,
        "id_ubicacion": datos.id_ubicacion,
        "id_direccion": datos.id_direccion,
        "id_subdireccion": datos.id_subdireccion,
        "id_udn": datos.id_udn,
        "empresa": datos.empresa,
        "hijo": datos.id,
        "nombre": datos.nombre,
        "puesto": datos.puesto,
        "ubicacion": datos.ubicacion,
        "udn": datos.udn
      }
      this.audiencia.push(body_audiciencia);
    }
  }

  public mapeoAudiencia() {
    for (let datos of this.notificacion_audiencia) {
      let body_audiciencia = {
        "id": datos[0].idnot,
        "padre": datos[0].idPadre,
        "descripcion": datos[0].descripcion,
        "descripcion_nivel": datos[0].descripcion_nivel,
        "tipo_audiencia": datos[0].tipo,
        "nomina": datos[0].nomina,
        "id_empresa": datos[0].id_empresa,
        "id_puesto": datos[0].id_puesto,
        "id_ubicacion": datos[0].id_ubicacion,
        "id_direccion": datos[0].id_direccion,
        "id_subdireccion": datos[0].id_subdireccion,
        "id_udn": datos[0].id_udn,
        "empresa": datos[0].empresa,
        "hijo": datos[0].id,
        "nombre": datos[0].nombre,
        "puesto": datos[0].puesto,
        "ubicacion": datos[0].ubicacion,
        "udn": datos[0].udn
      }
      this.audiencia.push(body_audiciencia);
    }
  }
  public validaNomina(){
    if (this.cargaNominas) {
      this.mapeoAudiencia();
    } else {
      this.mapeoAudienciaOrigin();
    }
  }
  public publicar() {

    if (this.titulo !== '' && this.texto_notificacion !== '') {
      if (this.notificacion_audiencia.length > 0) {
        this.audiencia.length = 0;
        this.validaNomina();
        let body = {
          "titulo": this.titulo,
          "texto": this.texto_notificacion,
          "audiencia": this.audiencia
        }
        this.servicios.postService('notificacion/', body).toPromise().then(
          (res: any) => {
            this.snackBar.open('Se han enviado las notificaciones correctamente', 'close', {
              duration: 2500,
            });
            this.funcion.hide();
            this.router.navigate(['notificaciones']);
          }
        ).catch((error) => {
          if (error.status == 400 || error.status == 500) {
            this.snackBar.open('Error al guardar los datos', 'close', {
              duration: 2500,
            });
            this.funcion.hide();
          } else {
            this.snackBar.open(error.message, 'close', {
              duration: 2500,
            });
            this.funcion.hide();
          }
        });
      } else {
        this.snackBar.open('Seleccione una audiencia', 'close', {
          duration: 2500,
        });
      }
    } else {
      this.snackBar.open('Campos incompletos, favor de ingresar registros', 'close', {
        duration: 2500,
      });
    }
  }

  public getSelection(e: any) {

    if (e.currentSelectedRowKeys.length == 1) {
      e.component.byKey(e.currentSelectedRowKeys[0]).done((datos: any) => {
        if (datos) {
          this.notificacion_audiencia.push(datos);
          this.valNotificacion();
        }
      });
    } else {
      e.component.byKey(e.currentDeselectedRowKeys[0]).done((datos: any) => {
        if (datos) {
          this.notificacion_audiencia = this.notificacion_audiencia.filter(item => item.id !== e.currentDeselectedRowKeys[0]);
          this.valNotificacion();
        }
      });
    }
  }
  valNotificacion(){
    if (this.notificacion_audiencia.length > 0) {
      this.notificacionaudiencia = true;
    } else {
      this.notificacionaudiencia = false;
    }
  }

  onClickCatNot() {
    this.router.navigate(['notificaciones']);
  }

  public dataDef() {
    this.titulo = '';
    this.texto_notificacion = ''
  }

  audiencia_notificacion = this.datos_matriz_mod
  plain = [
    {
      "descripcion": "Audiencia",
      "orden": 1,
      "tipo": 1,
      "activo": true,
      "ultimoNivel": 1
    }
  ]
  descripcion = "Error"

  isExpanded = [];
  searchPanel = { visible: true, width: 200, placeholder: 'Buscar...', filterRow: true };

  /** Abrir modal */
  abrirModal(modal: any) {

    this.modalService.open(modal, {
      centered: true,
      size: 'lg',
      ariaLabelledBy: 'modal-basic-title',
      scrollable: true,
    });
  }

  cerrarModal() {
    this.modalService.dismissAll();
    this.listaNominas = '';
  }

  guardarNominas() {

    this.cargaNominas = true;
    this.modalService.dismissAll();

    let nominas: any = this.listaNominas.split('\n');

    this.objnominas = [];
    this.notificacion_audiencia = [];
    nominas.forEach((nom: any) => {
      this.objnominas.push(this.datos_matriz_mod.filter(x => x.nomina == Number(nom)));
    });
    this.notificacion_audiencia = this.objnominas;
    this.listaNominas = '';

  }
}
