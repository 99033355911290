import { Component, OnInit } from '@angular/core';
import { ServiciosImp } from '../../services/services';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import {
  IColumns,
  TiposdeDato
} from '../../models/grid.interfaces';
import { Funciones } from '../../classes/funciones';

@Component({
  selector: 'incentivos-empresas',
  templateUrl: './catalogo_empresa.component.html',
  styleUrls: ['./catalogo_empresa.component.css']
})
export class EmpresasComponent implements OnInit {
  /* *************** Parametros del Grid de datos ***************** */
  columns_catalogo_empresa: IColumns[] = [];
  /* *************** Otros ***************** */
  wait = false;
  nombre: any = sessionStorage.getItem('nombre');
  nomina: any = sessionStorage.getItem('nomina');
  nombreL: any;
  usuarioL: any;
  nominaL: any;
  empresa: any[] = [];
  url_empresa: string = "/nueva_empresa/";

  constructor(
    public servicios: ServiciosImp,
    public router: Router,
    public snackBar: MatSnackBar,
    public funcion: Funciones
    ) {
    this.nombreL = this.nombre;
    this.nominaL = this.nomina;

    /* *************** Parametros del Grid de datos ***************** */
    this.columns_catalogo_empresa = [ // Columnas
      {
        caption: 'Estatus',
        dataField: 'status_desc',
      },
      {
        caption: 'Nombre de la empresa',
        dataField: 'nombre',
      },
      {
        caption: 'Descripción',
        dataField: 'descripcion',
      },
      {
        caption: 'Fecha creación',
        dataField: 'fecha_creacion',
        type: TiposdeDato.datetime
      },

    ];

  }

  ngOnInit(): void {
    this.wait = true;
    this.servicios.getService('empresa/').toPromise().then(
      (res: any) => {
        if(res.status == 401){
          this.router.navigate(['login']);
        }
        this.empresa = res.data;
        this.wait = false;
        this.funcion.hide();
      },
      (error: any) => {
        this.wait = false;
       this.snackBar.open(error.message, 'close', {
          duration: 2500,
       });
       this.funcion.hide();
      }
    )
  }

  newEmpresa() {
    this.router.navigate([`/nueva_empresa/${0}`]);
  }


}
