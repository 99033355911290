export interface IColumns {
    dataField?: string;
    caption?: string;
    dataType?: TiposdeDato;
    format?: TiposdeFormato;
    width?: number;
    fixed?: boolean;
    type?: string;
    name?: string;
    groupIndex?: string;
    cellTemplate?: string;
    hiddingPriority?: string;
    allowEditing?: boolean;
    validationRules?: any;
  }

  export enum TiposdeDato {
    string = 'string',
    number = 'number',
    date = 'date',
    boolean = 'boolean',
    object = 'object',
    datetime = 'datetime'
  }

  export enum TiposdeFormato {
    billions = 'billions',
    currency = 'currency',
    day = 'day',
    decimal = 'decimal',
    exponential = 'exponential',
    fixedPoint = 'fixedPoint',
    largeNumber = 'largerNumber',
    longDate = 'longDate',
    longTime = 'longTime',
    millions = 'millions',
    millisecond = 'millisecond',
    month = 'month',
    monthAndDay = 'monthAndDay',
    monthAndYear = 'monthAndYear',
    percent = 'percent',
    quarter = 'quarter',
    quarterAndYear = 'quarterAndYear',
    shortDate = 'shortDate',
    shortTime = 'shortTime',
    thousands = 'thousands',
    trillions = 'trillions',
    year = 'year',
    dayOfWeek = 'dayOfWeek',
    hour = 'hour',
    longDateLongTime = 'longDateLongTime',
    minute = 'minute',
    second = 'second',
    shortDateShortTime = 'shortDateShortTime',
    dmy = 'dd/MM/yyyy',
    dmyt = 'dd/MM/yyyy HH:mm:ss',
    moneda = '$#,##0.00'
  }

  export interface IGridOptions {
    paginacion: number;
    pageSize: any;
  }

  export interface IExportExcel {
    enabled: boolean;
    fileName: string;
  }

  export interface ISearchPanel {
    visible: boolean;
    width: number;
    placeholder: string;
    filterRow: boolean;
    filterHeader: boolean;
  }

  
export interface IScroll {
  mode: string;
}

export interface Toolbar {
  location: string;
  widget: string;
  locateInMenu: string;
  options?: object;
  visible: boolean;
  name?: string;
  name2?: string;
  selected?: object;
}

export interface IColumnHiding {
  hide: boolean;
}

export interface ICheckbox {
  checkboxmode: string;
}

export interface IEditing {
  allowupdate: boolean;
  mode: string;
}

export interface IColumnchooser {
  columnchooser: boolean;
}

export interface IDetail {
  detail: boolean;
}

export interface Color {
  filas: boolean;
  columnas: boolean;
  alternar: boolean;
  color: string;
} 