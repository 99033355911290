<incentivos-navbar [nombre]="nombreL" [nomina]="nominaL"></incentivos-navbar>
<!-- Filtro sondeo -->

<div class="loading-indicator" *ngIf="funcion.visibility | async">
    <img src="/assets/img/spinner.gif" alt="">
</div>

<div class="d-flex justify-content-center align-box2">
    <div class="box2">
        <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' %3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
            aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item b1" href="javascript:;">Configuración SI+</li>
                <li class="breadcrumb-item b2">Carga sustitución cálculo periodo</li>
            </ol>
        </nav>
    </div>
</div>
<div class="d-flex justify-content-center align-box2">
    <div class="box">
        <p class="f-t1">Carga sustitución cálculo periodo</p>
        <p class="f-t2">*Campos Obligatorios</p>
        <div class="container cuerpo">
            <form>
                <div class="row mb-5">
                    <div class="col-md-4">
                        <label for="input6" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                            <span class="h6 small bg-white text-muted px-1">Incentivo *</span>
                        </label>
                        <select class="form-select" id="idIncentivo" [(ngModel)]="idIncentivo"  (click)="obtieneDesc()"
                            name="idIncentivo">
                            <option [value]="0">Selecciona</option>
                            <option *ngFor="let inc of incentivoCat" [value]="inc.id">{{inc.descripcion}}</option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <div class="col d-grid gap-5 d-md-flex justify-content-md-end">
                            <button type="button" class="btn  btn-primary boton3"
                                (click)="descargaPlantillaCalculo()">Descarga
                                layout <img src="/assets/images/descarga.png" alt="" style="width: 18px;
                                margin-top: -5px;
                                margin-left: 4px;"></button>
                        </div>
                    </div>

                </div>
                <div class="row mb-5">
                    <div class="col-md-4"></div>
                    <div class="col-md-8">
                        <div class="row">
                            <span class="ayuda bg-white text-muted px-1">El Excel a cargar debe de respetar las columnas
                                como encabezado de los datos. Los campos deben llamarse</span>
                        </div>
                        <div class="row">
                            <span class="ayuda bg-white text-muted px-1">exactamente como el ID del componente, para
                                obtener el Template a utilizar da clic en Descarga layout.</span>
                        </div>
                    </div>

                </div>
                <div class="row mb-5">
                    <div class="col-md-4">
                        <label for="input6" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                            <span class="h6 small bg-white text-muted px-1">Archivo *</span>
                        </label>
                        <span class="form-control" id="filec1" (click)="abrirArchivo()">{{excel}}</span>
                    </div>
                    <div class="col-md-4">
                        <label for="fechacarga" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                            <span class="h6 small bg-white text-muted px-1">Fecha de carga *</span>
                        </label>
                        <input id="fechacarga" type="text" class="form-control" placeholder="Selecciona"
                            onfocus="(this.type='date')" onkeydown="return false" onblur="if(this.value==''){this.type='text'}" required
                            [(ngModel)]="fechacarga" name="fechacarga">
                    </div>
                    <div class="col-md-4">
                        <div class="col d-grid gap-5 d-md-flex justify-content-md-end">
                            <button type="button" class="btn  btn-primary boton3" (click)="subirExcel()">Cargar
                                archivo</button>
                        </div>
                    </div>

                </div>
            </form>
        </div>
        <div class="row">
            <p *ngIf="bitCalculoManual.length > 1" class="f-t2">Bitacora de Cargas</p>
            <incentivos-tabla-registros [datos]="bitCalculoManual" [wait]="wait" [columns]="columns_sondeo"
                [eliminar]="true" [descargar]="true" [procesar]="true" (opcdelete)="datosInicioDelete($event)"
                (opcdescarga)="onClickExcelPerfil($event)"
                (opcprocesar)="datosInicioProcesar($event)"></incentivos-tabla-registros>
        </div>
        <br><br>
    </div>
</div>
<!-- Modal de incentivos-->
<ng-template #modalpermiso let-modal>
    <div class="modal-content">
        <div class="modal-title" id="exampleModalLabel">
            <div>
                <p class="fomratEliminar" style="margin-top: 45px;">El archivo se eliminará</p>
                <p class="fomratEliminar"> ¿Estás seguro de querer hacerlo?</p>
            </div>
        </div>
        <br><br>
        <div class="modal-footer" style="border-top: 0;">
            <div class="col d-grid gap-5 d-md-flex justify-content-md-end">
                <button class="btn mt-2 boton2" type="button" (click)="cerrarModal()">&nbsp;&nbsp;Cerrar</button>
                <button class="btn mt-2 boton4" type="button" (click)="eliminarArchivo()">&nbsp;&nbsp;Eliminar</button>


            </div>
        </div>
    </div>
</ng-template>