import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Funciones } from '../../classes/funciones';

import { ChartComponent,ApexChart, ApexXAxis, ApexDataLabels, ApexStroke, ApexMarkers, ApexYAxis, ApexGrid, ApexLegend, ApexNonAxisChartSeries, ApexPlotOptions } from "ng-apexcharts";

export type ChartOptions = {
    series: ApexNonAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    stroke: ApexStroke;
    dataLabels: ApexDataLabels;
    markers: ApexMarkers;
    colors: string[];
    yaxis: ApexYAxis;
    grid: ApexGrid;
    legend: ApexLegend;
    labels: string[];
    plotOptions: ApexPlotOptions;

};

@Component({
    selector: 'incentivos_dashboard_cuestionario',
    templateUrl: './dashboard_cuestionario.component.html',
    styleUrls: ['./dashboard_cuestionario.component.css']
})
export class DashboardCuestionarioComponent implements OnInit {
    @ViewChild("chart") chart: ChartComponent = {} as ChartComponent;
    public char_radial_par: ChartOptions = {} as ChartOptions;


    @Input() fechaIC: any;
    @Input() fechaFC: any;
    @Input() inputDataCuestionario: any = [];

    periodo_cuestionario: string = "";
    titulo_cuestionario: string = "";

    public filtroselected_cuestionario:number = 0;
    public filtroidIni_cuestionario:number = 0;
    public esPrimeraVez_cuestionario: boolean= true;
    public filtros_cuestionario = [
        { "id": 0, "value": "Dirección"},
        { "id": 1, "value": "Puesto"},
        { "id": 2, "value": "Oficina"},
        { "id": 3, "value": "Colaborador"},
      ];
    public filtroCuestionario:boolean=false;

    constructor(
        public snackBar: MatSnackBar,
        public funcion: Funciones
    ) {
    }

    ngOnInit(): void {
        this.filtroidIni_cuestionario = this.filtroselected_cuestionario;
        this.periodo_cuestionario = this.funcion.getFechaFormat2(this.fechaIC) + " - " + this.funcion.getFechaFormat2(this.fechaFC);
        this.titulo_cuestionario = this.inputDataCuestionario.titulo;
        this.dataDonutPerfil();
        this.formatData();
    }

    public dataDonutPerfil() {
      if (this.inputDataCuestionario) {
        this.char_radial_par = {
          series: [this.inputDataCuestionario.procentaje_participacion],
          chart: {
            height: 270,
            type: "radialBar"
          },
          plotOptions: {
            radialBar: {
              hollow: {
                margin: 20,
                size: "82%",
              },

              dataLabels: {
                name: {
                  show: true,
                  offsetY: 30,
                },
                value: {
                  color: "#FFFFFF",
                  fontSize: "50px",
                  fontFamily: 'BreeRegular',
                  fontWeight: 400,
                  offsetY: -23,
                  show: true
                },
                total:{
                  show: true,
                  label: 'Participación',
                  fontSize: '15px',
                  fontFamily: 'BreeRegular',
                  fontWeight: 400,
                  color: '#FFFFFF',
                }

              }

            }
          },
          labels: [" "],
          colors: ["#EBA875"],
          dataLabels: {
            enabled: false
          },
          markers: {
          },
          legend: {
          },
          xaxis: {
          },
          yaxis: {
          },
          grid: {
          },
          stroke: {
          },
        }
      }
    }
    public activaFiltroCuestionario(){
        if(!this.filtroCuestionario){
            this.filtroCuestionario = true;
        }else{
            this.filtroCuestionario = false;
        }

    }

    public formatData(){
      let contador = 0;
      if(this.inputDataCuestionario.porcentaje_aprobados){
        this.inputDataCuestionario.porcentaje_aprobados = Math.trunc(this.inputDataCuestionario.porcentaje_aprobados);
      }
      if(this.inputDataCuestionario.porcentaje_reprobados){
        this.inputDataCuestionario.porcentaje_reprobados = Math.trunc(this.inputDataCuestionario.porcentaje_reprobados);
      }
      if(this.inputDataCuestionario.calificacion_promedio_aprobados){
        this.inputDataCuestionario.calificacion_promedio_aprobados = (Number(this.inputDataCuestionario.calificacion_promedio_aprobados)* .10).toFixed(2).replace('.00','');
      }
      if(this.inputDataCuestionario.calificacion_promedio_reprobados){
        this.inputDataCuestionario.calificacion_promedio_reprobados = (Number(this.inputDataCuestionario.calificacion_promedio_reprobados)* .10).toFixed(2).replace('.00','');
      }
      this.inputDataCuestionario.respuestas.forEach((_resp: any) => {
        if(_resp){
          contador = contador+1;
          _resp.id = contador;
        }

      });
    }
}
