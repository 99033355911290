<div *ngFor="let tour of item;">
    <div class="row mb-5">
        <div class="col-md-2">
            <label for="input" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                <span class="h6 small bg-white text-muted px-1">Tipo de contenido</span>
            </label>
            <select class="form-control form-select mt-2" id="input" required (change)="seleccionTipo($event,tour)"
                name="tipo_archivo" [(ngModel)]="tour.tipo">
                <option value="">Selecciona</option>
                <option value="1">Video</option>
                <option value="2">Imagen</option>
            </select>
        </div>
        <div class="col-md-3">
            <label for="input5" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                <span class="h6 small bg-white text-muted px-1">Contenido{{tour.id}}</span>
            </label>
            <input class="form-control mt-2" type="file" (change)="change_archivo($event,tour)" disabled="true"
                id="archivo{{tour.id}}">
        </div>
        <div class="col-md-1">
            <button class="button mt-2 boton3" id="btnc" type="button" (click)="abrirModal(this.modal,tour)"><em
                    class="bi bi-eye-fill" style="pointer-events: none;"></em></button>
        </div>

        <div class="col-md-2">
            <label for="input5" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                <span class="h6 small bg-white text-muted px-1">Temporizador (minutos)</span>
            </label>
            <input class="form-control mt-2" type="number" step=1 min="0" [(ngModel)]="tour.tiempo"
                [value]="tour.tiempo" id="temporizador{{tour.id}}" disabled="true">
        </div>
        <div class="col-md-4">
            <input type="text" placeholder="Ingresa texto (Máximo 280 carácteres)" maxlength="280"
                [(ngModel)]="tour.texto" class="form-control mt-2" required name="video_contenido">
        </div>
    </div>

    <ng-template #modal let-modal>
        <div class="modal-content">
            <div class="modal-title" id="exampleModalLabel">
                <p class="f-t3">Validación de contenido</p>
                <p class="f-t4">Verifica el contenido que está subiendo</p>
            </div>
            <div class="modal-body mt-3" style="text-align: center;">
                <div *ngIf="tourPrev.tipo==1" class="video">
                    <vg-player>
                        <video #media id="singleVideo" preload="auto" autoplay>
                            <source [src]="tourPrev.src" type="video/mp4">
                            <track label="Español" kind="captions" src="" default>
                        </video>
                    </vg-player>
                </div>
                <img *ngIf="tourPrev.tipo == 2" alt="" src="{{tourPrev.src}}" style="width: 330px;height: 615px;">
            </div>

            <div class="modal-footer" style="border-top: 0;">
                <div class="col d-grid gap-5 d-md-flex justify-content-md-end">
                    <button class="button mt-2 boton" (click)="cerrarModal()" type="button">&nbsp;&nbsp;Cerrar</button>
                </div>
            </div>
        </div>
    </ng-template>


</div>
