// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
  serviceUrl: 'https://incentivos-dev.gc.gentera.com.mx/back/',
  serviceComparnet: "https://comparnet-prd.gc.gentera.com.mx",
  //serviceUploadFile:"https://etmanuel.ddns.net:5001/"
  firebase: {
    apiKey: "AIzaSyBRYzv9oghFJWX85a1-nb35ZwmYsl-qWkQ",
    authDomain: "ent-dev-incentivos.firebaseapp.com",
    projectId: "ent-dev-incentivos",
    storageBucket: "ent-dev-incentivos.appspot.com",
    messagingSenderId: "862447291008",
    appId: "1:862447291008:web:cfed4525369fddef13b465",
    measurementId: "G-2R488QF3ZS",
    vapidKey: "BC9WRlokUuec00OkxFjqHeJ-vhNRUPAHQP7XHZU22P0AxsZfbQvKa-69yAEHqqmTzAc43Vv3DPNb5o1ZHWLiWCU"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */

