import { ApexAxisChartSeries, ApexChart, ApexXAxis, ApexDataLabels,
  ApexStroke, ApexMarkers, ApexYAxis, ApexGrid, ApexTitleSubtitle, ApexLegend, ApexFill, ApexNonAxisChartSeries,
   ApexPlotOptions, } from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  seriesPri: ApexNonAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  colors: string[];
  yaxis: ApexYAxis;
  grid: ApexGrid;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  labels: string[];
  plotOptions: ApexPlotOptions;
};

export class Graficas{

  public char: ChartOptions = {} as ChartOptions;
  public char_radial: ChartOptions = {} as ChartOptions;


  public charOptions(data_series:any, data_categorias:any){
    this.char = {
      plotOptions: {
      },
      labels: [" "],
      seriesPri: [],
      series: data_series,
      chart: {
        height: 240,
        type: "line",
        toolbar: {
          show: false
        }
      },
      fill: {
      },
      colors: ["#90ADC0", "#94CDC5", "#A992BC", "#FED9BC", "#6792AD", "#64257E", "#EBA875", "#8C6CA6", "#4DAB9E", "#DB699C", "#A0CFC9", "#CA97E9"],
      dataLabels: {
        enabled: true
      },
      stroke: {
        curve: "smooth"
      },
      title: {
        text: "sesiones",
        align: "left",
        style: {
          color: "#FFFFFF"
        }
      },
      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5
        }
      },
      markers: {
        size: 1
      },
      xaxis: {
        categories: data_categorias,
        title: {
          text: "Mes",
          style: {
            color: 'rgba(44, 103, 141, 0.7)',
            fontSize: '12px',
            fontFamily: 'BreeRegular'
          }
        }, labels: {
          formatter: function (val) {
            if (val !== undefined) {
              let s: string[] = String(val).split('-');
              let m: number = parseInt(s[1]);
              return s[2] + " " + getMes(m.toString());
            }
            return val;
          }
        }
      },
      yaxis: {
        title: {
          text: "Sesiones",
          style: {
            color: 'rgba(44, 103, 141, 0.7)',
            fontSize: '12px',
            fontFamily: 'BreeRegular'
          }
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "center",
        floating: false,
        fontSize: '7px',
        offsetY: -25,
        offsetX: -5
      }
    };
    return this.char;
  }

  public charOptionsRadial(data:any,color:any,height:any){
    this.char_radial = {
      series: [],
      seriesPri: [data],
      chart: {
        height: height,
        type: "radialBar"
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "60%",
          },

          dataLabels: {
            value: {
              color: color,
              fontSize: "20px",
              offsetY: -8,
              show: true
            }
          }
        }
      },
      labels: [" "],
      colors: [color],
      dataLabels: {
        enabled: false
      },
      markers: {
      },
      fill: {
      },
      legend: {
      },
      xaxis: {
      },
      yaxis: {
      },
      grid: {
      },
      stroke: {
      },
      title: {
      }
    };
    return this.char_radial;
  }
}

function getMes(mes: string) {
  switch (mes) {
    case "1":
      return "ene";
    case "2":
      return "feb";
    case "3":
      return "mar";
    case "4":
      return "abr";
    case "5":
      return "may";
    case "6":
      return "jun";
    case "7":
      return "jul";
    case "8":
      return "ago";
    case "9":
      return "sep";
    case "10":
      return "oct";
    case "11":
      return "nov";
    case "12":
      return "dic";
    default:
      return "";
  }
}

