<incentivos-navbar [nombre]="nombreL" [nomina]="nominaL"></incentivos-navbar>

<!-- Crear una nueva empresa -->
<div class="loading-indicator" *ngIf="funcion.visibility | async">
    <img src="/assets/img/spinner.gif" alt="">
</div>

<div class="d-flex justify-content-center align-box2">
    <div class="box2">
        <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
            aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item b1" href="javascript:;" (click)="onClickConfsi()">Configuración SI+</li>
                <li class="breadcrumb-item b1" href="javascript:;" (click)="onClickRegPlanCamp()">Configuración de planes y campañas</li>
                <li class="breadcrumb-item b2">{{titulo}} plan - campaña</li>
            </ol>
        </nav>
    </div>
</div>
<div class="d-flex justify-content-center align-box">
    <div class="box">
        <p class="f-t1">{{titulo}} Plan - Campaña</p>
        <p class="f-t2">*Campos Obligatorios</p>
        <div class="container cuerpo_post">
                <div class="row mb-5">
                    <div class="col-md-6">
                        <input type="text" placeholder="Nombre del plan o campaña*" class="form-control mt-2" required [(ngModel)]="nombrePC">
                    </div>
                    <div class="col-md-3">
                        <label for="input2" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                            <span class="h6 small bg-white text-muted px-1">Empresa*</span>
                        </label>
                        <select class="form-control form-select mt-2" id="input2" required [(ngModel)]="empresa" name="empresa">
                            <option *ngFor="let emp of empresaPC" [value]="emp.id">{{emp.nombre}}</option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label for="input2" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                            <span class="h6 small bg-white text-muted px-1">Tipo*</span>
                        </label>
                        <select class="form-control form-select mt-2" id="input2" required [(ngModel)]="tipo" name="tipo">
                            <option *ngFor="let tip of tipoPC" [value]="tip.id">{{tip.nombre}}</option>
                        </select>
                    </div>
                </div>
                <div class="row mb-5">
                    <div class="col-md-6">
                        <label for="input5" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                            <span class="h6 small bg-white text-muted px-1">Fecha inicio*</span>
                        </label>
                        <input id="fechai" type="text" class="mt-2 form-control" min="{{fecha}}" onfocus="(this.type='date')"
                        onblur="if(this.value==''){this.type='text'}" (change)="changeFechaInicio()" onkeydown="return false"
                                            required [(ngModel)]="fecha_inicio" name="fecha_inicio">
                    </div>
                    <div class="col-md-6">
                        <label for="input6" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                            <span class="h6 small bg-white text-muted px-1">Fecha fin*</span>
                        </label>
                        <input id="fechaf" type="text" required class="mt-2 form-control" min="{{fecha_inicio}}" onfocus="(this.type='date')"
                        onblur="if(this.value==''){this.type='text'}" onkeydown="return false"
                                           required [(ngModel)]="fecha_fin" name="fecha_vigencia">
                    </div>
                </div>
                <br>
                <div class="col d-grid gap-5 pe-4 py-5 d-md-flex justify-content-md-end">
                    <button class="button mt-2 boton" type="button" (click)="dataDef()">&nbsp;&nbsp;Limpiar</button>
                    <button class="button mt-2 boton2" type="button" (click)="guardaPC()">&nbsp;&nbsp;Guardar</button>
                </div>
        </div>
    </div>
</div>




