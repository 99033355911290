import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Funciones } from '../../classes/funciones';
import { ServiciosImp } from '../../services/services';

import {
  IColumns
} from '../../models/grid.interfaces';

@Component({
  selector: 'incentivos-modulo-sondeo',
  templateUrl: './modulo_sondeo.component.html',
  styleUrls: ['./modulo_sondeo.component.css']
})
export class ModuloSondeoComponent implements OnInit {
  /* *************** Parametros del Grid de datos ***************** */
  columns_sondeo: IColumns[] = [];

  /* *************** Otros ***************** */
  public estatus: string = '';
  public titulo_sondeo: string = '';
  public fecha_pubsondeo: string = '';
  public empresa: string = '';
  url_sondeo: string = "/nuevo_sondeo/";

  wait = false;
  nombre: any = sessionStorage.getItem('nombre');
  nomina: any = sessionStorage.getItem('nomina');
  nombreL: any;
  usuarioL: any;
  nominaL: any;
  quizz: any[] = [];
  modulos: any[] = [];
  status: any[] = [];
  empsondeo: any[] = [];
  tp: any[] = [];

  constructor(
    private router: Router,
    public snackBar: MatSnackBar,
    public funcion: Funciones,
    public servicios: ServiciosImp) {
    this.nombreL = this.nombre;
    this.nominaL = this.nomina;

    /* *************** Parametros del Grid de datos ***************** */
    this.columns_sondeo = [ // Columnas
      {
        caption: 'Empresa',
        dataField: 'empresa_dec',
      },
      {
        caption: 'Estatus',
        dataField: 'estatus_dec',
      },
      {
        caption: 'Fecha Publicación',
        dataField: 'fecha_inicial',
      },
      {
        caption: 'Título',
        dataField: 'titulo_sondeo',
      }
    ];

    /* *************** FIN Parametros del Grid de datos ***************** */
  }


  ngOnInit(): void {
    this.wait = true;
    this.servicios.getService('quizz/').toPromise().then(
      (res: any) => {
        if (res.status == 401) {
          this.router.navigate(['login']);
        }
        this.quizz = res.data;
        this.funcion.hide();
        this.wait = false;
      },
      (error: any) => {
        this.msgError(error);
      }
    )

    /*** Llamada a otros servicios */
    this.cargaCatalogoEmpresaSondeo();
  }


  newSondeo() {
    this.router.navigate([`nuevo_sondeo/${0}`]);
    localStorage.setItem('editar', 'false');
  }

  /*** Otros servicios */


  cargaCatalogoEmpresaSondeo() {
    this.servicios.getCatalogoEmpresa().subscribe(datosondeo => {
      if (!datosondeo.error) {
        this.empsondeo = datosondeo.data;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }

  /*Consulta en el grid por filtro*/
  consulta() {
    let filtro1 = this.estatus != '' ? this.estatus : '';
    let filtro2 = this.titulo_sondeo != '' ? this.titulo_sondeo : '';
    let filtro3 = this.fecha_pubsondeo != '' ? this.fecha_pubsondeo : '';
    let filtro4 = this.empresa != '' ? this.empresa : '';
    this.wait = true;
    this.servicios.getService(`quizz/?status=` + filtro1 + `&titulo=` + filtro2 + `&fecha=` + filtro3 + `&empresa_id=` + filtro4 + ``).toPromise().then(
      (res: any) => {
        if (res.status == 401) {
          this.router.navigate(['login']);
        }
        console.log(res.data);
        this.quizz = res.data;
        this.wait = false;
        this.funcion.hide();
      },
      (error: any) => {
        this.msgError(error);
      }
    )
  }

  msgError(error: any) {
    this.wait = false;
    this.snackBar.open(error.message, 'close', { duration: 2500 });
    this.funcion.hide();
  }

  dataDef() {
    this.estatus = '';
    this.titulo_sondeo = '';
    this.fecha_pubsondeo = '';
    this.empresa = '';
  }

}
