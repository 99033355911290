import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiciosImp } from '../../../services/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Funciones } from '../../../classes/funciones';
import {
  IColumns,
  TiposdeDato

} from '../../../models/grid.interfaces';

@Component({
  selector: 'incentivos-configuracion-plan-campania',
  templateUrl: './configuracion_plan_campania.component.html',
  styleUrls: ['./configuracion_plan_campania.component.css']
})
export class ConfiguracionPlanCampaniaComponent implements OnInit {

 columns_planes_campanias: IColumns[] = [];
 /* *************** Otros ***************** */
 wait = false;
 nombre: any = sessionStorage.getItem('nombre');
 nomina: any = sessionStorage.getItem('nomina');
 nombreL: any;
 usuarioL: any;
 nominaL: any;
 plan_campania: any[] = [];
 url_plan_campania: string = "/registro_plan_campania/";



 constructor(
   public servicios: ServiciosImp,
   private router: Router,
   public snackBar: MatSnackBar,
   public funcion: Funciones,
   ) {
   this.nombreL = this.nombre;
   this.nominaL = this.nomina;

   this.columns_planes_campanias = [ // Columnas
   {
     caption: 'Tipo',
     dataField: 'tipo_desc',
   },
   {
     caption: 'Nombre',
     dataField: 'nombre',
   },
   {
     caption: 'Fecha inicio',
     dataField: 'fecha_inicio',
     type: TiposdeDato.datetime,
   },
   {
     caption: 'Fecha fin',
     dataField: 'fecha_final',
     type: TiposdeDato.datetime,
   },

 ];
 }

 ngOnInit(): void {
   this.wait = true;
   this.servicios.getService('campania/').toPromise().then(
     (res: any) => {
       if(res.status == 401){
         this.router.navigate(['login']);
       }
       this.plan_campania = res.data;
       this.wait = false;
       this.funcion.hide();
     },
     (error: any) => {
       this.wait = false;
       this.snackBar.open(error.message, 'close', {
         duration: 2500,
      });
      this.funcion.hide();
     }
   )
 }

 newRegistro() {
   this.router.navigate([`/registro_plan_campania/${0}`]);
 }


}
