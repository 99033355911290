import { Component, ElementRef, OnInit, Pipe, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Funciones } from '../../classes/funciones';
import { ServiciosImp } from '../../services/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'incentivos-new-post',
  templateUrl: './nuevo_post.component.html',
  styleUrls: ['./nuevo_post.component.css']
})

export class NewPostComponent implements OnInit {

  @Pipe({ name: 'safe' })
  @ViewChild('modalnominas') modal: ElementRef | undefined;

  datos_audiencia: any[] = [];
  datos_audienciaOne: any[] = [];
  listaNominas: any = [];
  wait = false;
  nombre: any = sessionStorage.getItem('nombre');
  nomina: any = sessionStorage.getItem('nomina');
  nombreL: any;
  nominaL: any;
  modulosnpost: any[] = [];
  statusnpost: any[] = [];
  empnpost: any[] = [];
  mcnpost: any[] = [];
  tcnpost: any[] = [];
  audiencia: any[] = [];
  mostrar: string = "";
  arbol_notifica: any[] = [];
  datos_notifica: any[] = [];
  datos_arch1: any[] = [];
  datos_arch2: any[] = [];
  datos_arch3: any[] = [];
  datos_arch: any[] = [];
  archivo_1: any;
  archivo_2: any;
  archivo_3: any;
  datos_archivos1 = new FormData();
  datos_archivos2 = new FormData();
  datos_archivos3 = new FormData();
  aux2: number = 0;
  archivo_anexo: any;
  botonStatus: boolean = true;
  cargaNominas: boolean = false;
  datosaudiencia: boolean = false;

  public colorInactiva: string = '';
  public titulop = 'Nuevo';
  public titulo: string = '';
  public texto_corto: string = '';
  public modulo: number = 0;
  public empresa: number = 0;
  public estatus: number = 0;
  public marca_act: number = 0;
  public tipo_cont: number = 0;
  public tipo_cont_sel: any;
  public contenido: any;
  public url_accion: string = '';
  public msj_accion: string = '';
  public miniatura_post: string = '';
  public miniatura_banner: string = '';
  public fecha_publicacion: any;
  public fecha_vigencia: any;
  public nombre_archivo: string = '';
  public banner: boolean = false;
  public notificacion: boolean = false;
  public id_post: number = 0;
  public min_post: string = '';
  public m_post: any;
  public min_banner: string = '';
  public m_banner: any;
  public fecha_actual: any;
  publica: boolean = false;
  val1: number = 0;
  val2: number = 0;
  archivo: any;
  filePrev: any;
  filePrevMinPost: any;
  filePrevMinBanner: any;
  selectedStates: any;
  file: any;
  tipo_archivo1: any;
  tipo_archivo2: any;
  tipo_archivo3: any;
  fecha: string = "";

  uri_clara: string = "";
  cargarClara: boolean = false;

  constructor(
    public snackBar: MatSnackBar,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public funcion: Funciones,
    public servicios: ServiciosImp,
    public modalService: NgbModal
  ) {
    this.nombreL = this.nombre;
    this.nominaL = this.nomina;
  }

  ngOnInit(): void {
    this.fecha = this.funcion.formatDate();
    this.activatedRoute.params.subscribe(parametros => {
      this.id_post = parametros.id;
      if (this.id_post != 0) {
        this.publica = true;
        this.titulop = 'Edita';
        let url = 'post/' + this.id_post;
        this.servicios.getService(url).toPromise().then(
          (res: any) => {
            if (res.status == 401) {
              this.router.navigate(['login']);
            }
            this.mostrar = String(res.data.tipo_contenido_id);
            this.titulo = res.data.nombre;
            this.texto_corto = res.data.text_corto;
            this.modulo = res.data.modulo_id;
            this.empresa = res.data.empresa_id;
            this.estatus = res.data.status;
            this.marca_act = res.data.marca_actual;
            this.tipo_cont = res.data.tipo_contenido_id;
            this.url_accion = res.data.accion;
            this.msj_accion = res.data.mensaje_accion;
            this.fecha_publicacion = res.data.fecha_publicacion;
            this.fecha_vigencia = res.data.fecha_vencimieto;
            this.banner = res.data.banner;
            this.notificacion = res.data.notificacion;
            if (this.notificacion) {
              this.colorInactiva = '#E6863E';
            } else {
              this.colorInactiva = 'rgba(0,0,0,.12)';
            }
            this.wait = true;
            this.val1 = res.data.banner ? 1 : 0;
            this.val2 = res.data.notificacion ? 1 : 0;
            /*Archivos del buket*/
            this.tipo_cont_sel = res.data.nombre_archivo;
            this.filePrev = res.data.nombre_archivo;
            this.miniatura_post = res.data.miniatura;
            this.filePrevMinPost = res.data.miniatura;
            this.miniatura_banner = res.data.miniatura_banner;
            this.filePrevMinBanner = res.data.miniatura_banner;
            this.cargarClara = res.data.clara;
            this.uri_clara = String(res.data.gsuri).replace('gs://compensations/', '');
            this.funcion.hide();
          },
          (error: any) => {
            this.wait = false;
            this.snackBar.open(error.message, 'close', {
              duration: 2500,
            });
            this.funcion.hide();
          }
        )
      }
    });

    this.cargaCatalogoModuloNPost();
    this.cargaCatalogoEmpresaNPost();
    this.cargaCatalogoEstatusNPost();
    this.cargaMarcaActualizacionNPost();
    this.cargaTipoContenidoNPost();

  }


  cargaCatalogoModuloNPost() {
    this.servicios.getCatalogoModulo().subscribe(datonpost1 => {
      if (!datonpost1.error) {
        this.modulosnpost = datonpost1.data;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }

  cargaCatalogoEmpresaNPost() {
    this.servicios.getCatalogoEmpresa().subscribe(datonpost2 => {
      if (!datonpost2.error) {
        this.empnpost = datonpost2.data;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }

  cargaCatalogoEstatusNPost() {
    this.servicios.getCatalogoEstatus().subscribe(datonpost3 => {
      if (!datonpost3.error) {
        this.statusnpost = datonpost3.data;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }

  cargaMarcaActualizacionNPost() {
    this.servicios.getCatalogoMarcaAct().subscribe(datonpost4 => {
      if (!datonpost4.error) {
        this.mcnpost = datonpost4.data;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }

  cargaTipoContenidoNPost() {
    this.servicios.getCatalogoTipoCont().subscribe(datonpost5 => {
      if (!datonpost5.error) {
        this.tcnpost = datonpost5.data;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }

  /** Abrir modal */
  abrirModal(modal: any) {

    this.modalService.open(modal, {
      centered: true,
      size: 'lg',
      ariaLabelledBy: 'modal-basic-title',
      scrollable: true,
    });
  }

  array(val: any) {
    
    this.datos_audiencia = val.array;
    if (this.datos_audiencia.length == 0) {
      this.datosaudiencia = false;
    } else {
      this.datosaudiencia = true;
    }
    console.log(this.datos_audiencia)
  }

  arrayOne(val: any) {
    this.datos_audienciaOne = val.arrayOne;
  }

  guardarNominas() {
    debugger;
    let nominas: any = this.listaNominas.split('\n');
    let nomFiltro:any = [];
    nominas.forEach((listNom: any) => {
      
      if(listNom !== ''){
        nomFiltro.push(Number(listNom));
      } 
    });

    let objnominas: any = [];
    this.datos_audiencia = [];
    
    nomFiltro.forEach((nom: any) => {
      this.datos_audienciaOne.forEach((nom2: any) => {
        if(nom2.nomina == nom){
          objnominas.push(nom2);
        }
      }); 
      
    });
    


    this.datos_audiencia = objnominas;
    this.listaNominas = '';

    this.cargaNominas = true;
    this.modalService.dismissAll();

  }

  /** Cerrar Modal */
  cerrarModal() {
    this.modalService.dismissAll();
    this.listaNominas = '';
  }

  public guardar() {
    debugger;

    if (this.mostrar === '3') {
      let valida_url = this.validaURL(this.url_accion);
      if (valida_url === true) {
        if (this.titulo !== '' && this.texto_corto !== '' && this.modulo !== 0 && this.empresa !== 0 && this.estatus !== 0
          && this.fecha_publicacion !== undefined && this.fecha_vigencia !== undefined && this.marca_act !== 0 && this.tipo_cont !== 0) {
          this.guardarDatosPost()
        } else {
          this.snackBar.open('Complete todos los campos obligatorios', 'close', {
            duration: 2500,
          });
        }
      } else {
        this.snackBar.open('Formato de URL incorrecto, favor de verificar el campo.', 'close', {
          duration: 2500,
        });
      }
    } else {
      if (this.titulo !== '' && this.texto_corto !== '' && this.modulo !== 0 && this.empresa !== 0 && this.estatus !== 0
        && this.fecha_publicacion !== undefined && this.fecha_vigencia !== undefined && this.marca_act !== 0 && this.tipo_cont !== 0) {
        this.guardarDatosPost()
      } else {
        this.snackBar.open('Complete todos los campos obligatorios', 'close', {
          duration: 2500,
        });
      }
    }
  }

  public guardarDatosPost() {
    
    if (this.datos_audiencia.length > 0) {
      let body = {
        "nombre": this.titulo,
        "text_corto": this.texto_corto,
        "modulo_id": this.modulo,
        "empresa_id": this.empresa,
        "status": this.estatus,
        "marca_actual": this.marca_act,
        "tipo_contenido_id": this.tipo_cont,
        "accion": this.url_accion,
        "mensaje_accion": this.msj_accion,
        "fecha_publicacion": this.fecha_publicacion,
        "fecha_vencimieto": this.fecha_vigencia,
        "banner": this.val1,
        "notificacion": this.val2,
        "clara": this.cargarClara
      }
      if (this.id_post == 0) {
        this.validacionesGuardado(body);
      } else {
        this.actualizaPost(body);
      }
    } else {
      this.snackBar.open('Seleccione una audiencia', 'close', { duration: 2500 });
    }
  }

  validacionesGuardado(body: any) {
    if (this.miniatura_post !== '') {
      if (this.tipo_cont == 2) {
        if (this.miniatura_banner != '') {
          this.registraPost(body);
        } else {
          this.snackBar.open('Complete todos los campos obligatorios', 'close', { duration: 2500 });
        }
      } else {
        this.registraPost(body);
      }
    } else {
      this.snackBar.open('Complete todos los campos obligatorios', 'close', { duration: 2500 });
    }
  }

  registraPost(body: any) {
    this.servicios.regPost(body).subscribe(datospostreg1 => {
      if (!datospostreg1.error) {
        this.id_post = datospostreg1.data.id;
        this.insertaAudiencia(this.id_post, this.datos_audiencia, false);
      } else {
        this.wait = false;
        this.snackBar.open('Error al guardar los datos', 'close', {
          duration: 2500,
        });
        this.funcion.hide();
      }
    });
  }

  actualizaPost(body: any) {
    this.servicios.actPost(body, this.id_post).subscribe(datospostact => {
      if (!datospostact.error) {
        this.funcion.hide();
        this.insertaAudiencia(this.id_post, this.datos_audiencia, true);
      } else {
        this.wait = false;
        this.snackBar.open('Error al actualizar los datos', 'close', {
          duration: 2500,
        });
        this.funcion.hide();
      }
    });
  }

  mapeaAudiencia(audiencia: any) {
    for (let datos of audiencia) {
      let body_audiciencia = {
        "id_empresa": datos.id_empresa === '' ? "" : datos.id_empresa,
        "id_puesto": datos.id_puesto === '' ? "" : datos.id_puesto,
        "id_ubicacion": datos.id_ubicacion === '' ? "" : datos.id_ubicacion,
        "id_udn": datos.id_udn === '' ? "" : datos.id_udn,
        "id_direccion": datos.id_direccion === '' ? "" : datos.id_direccion,
        "id_subdireccion": datos.id_subdireccion === '' ? "" : datos.id_subdireccion,
        "nomina": datos.nomina
      }
      this.audiencia.push(body_audiciencia);
    }
    return audiencia;
  }

  mapeaAudienciaSinNom(audiencia: any) {
    for (let datos of audiencia) {
      let body_audiciencia = {
        "id_empresa": datos.id_empresa === null ? 13 : datos.id_empresa,
        "id_puesto": datos.id_puesto === null ? "" : datos.id_puesto,
        "id_ubicacion": datos.id_ubicacion === null ? "" : datos.id_ubicacion,
        "id_udn": datos.id_udn === null ? "" : datos.id_udn,
        "id_direccion": datos.id_direccion === null ? "" : datos.id_direccion,
        "id_subdireccion": datos.id_subdireccion === null ? "" : datos.id_subdireccion,
        "nomina": datos.nomina === '' ? 0 : datos.nomina
      }
      this.audiencia.push(body_audiciencia);
    }
    return audiencia;
  }

  public insertaAudiencia(id: any, audiencia: any, ac: any) {
    
    ////////// INSERTA AUDIENCIA /////////////////////////
    this.audiencia = [];
    if (this.cargaNominas) {
      this.audiencia = this.mapeaAudiencia(audiencia);
    } else {
      this.audiencia = this.mapeaAudienciaSinNom(audiencia);

    }
    let aux = { "audiencia": this.audiencia }
    this.servicios.regAudiencia(aux, id).subscribe(datosaudiencia => {
      if (!datosaudiencia.error) {
        this.funcion.hide();
        this.guardaArchivo2(this.id_post, ac);
        this.guardaArchivo3(this.id_post, ac);
        this.guardaArchivo1(this.id_post, ac);
      } else {
        this.wait = false;
        this.snackBar.open('Error al guardar los datos', 'close', {
          duration: 2500,
        });
        this.funcion.hide();
      }
    });
  }

  public publicar() {
    if (this.publica !== false) {
      this.servicios.publicarPost(this.id_post).subscribe(datospostpub => {
        if (!datospostpub.error) {
          this.snackBar.open('Se ha publicado el post', 'close', {
            duration: 2500,
          });
          this.funcion.hide();
        } else {
          this.wait = false;
          this.snackBar.open('Error al publicar el post', 'close', {
            duration: 2500,
          });
          this.funcion.hide();
        }
      });
    } else {
      this.snackBar.open('Guarde el nuevo post antes de realizar una publicación', 'close', {
        duration: 2500,
      });
    }
  }

  public onChangeBanner(e: any) {
    this.banner = e.checked;
    if (!this.banner) {
      this.val1 = 0;

    } else {
      this.val1 = 1;

    }
  }

  public onChangeNot(e: any) {
    if (!this.notificacion) {
      this.val2 = 0;
      this.colorInactiva = 'rgba(0,0,0,.12)';
    } else {
      this.val2 = 1;
      this.colorInactiva = '#E6863E';
    }
  }

  public upload(e: any): void {
    
    this.archivo_anexo = e.target.value;
    // Extensión del archivo
    let extension = this.archivo_anexo.substring(this.archivo_anexo.lastIndexOf('.'), this.archivo_anexo.length);
    // Validar que el archivo seleccionado sea correcto dependiendo del tipo de contenido seleccionado
    let archivo_correcto = this.getArchivoCorrecto(extension);
    if (this.validaciones1(extension)) {
      this.botonStatus = false;
    } else {
      this.botonStatus = true;
    }
    if (archivo_correcto == 1) {
      console.log("archivo correcto");
      this.configArchivo(e);
    } else {
      this.tipo_cont_sel = '';
      this.snackBar.open('Archivo inválido. No se permite cargar el archivo de acuerdo a la selección del tipo de contenido', 'close', {
        duration: 2500,
      });
    }
  }

  validaciones1(extension: string) {
    extension = extension.toUpperCase();
    if (this.mostrar === '5' && (extension === '.PPT' || extension === '.TXT' ||
      extension === '.DOCX' || extension === '.XLSX' || extension === '.PPTX' ||
      extension === '.DOC' || extension === '.XLS'))
      return true;
    else
      return false;
  }

  getArchivoCorrecto(extension: string) {
    extension = extension.toUpperCase();
    return (this.mostrar === '1' && (extension === '.JPEG' || extension === '.JPG' || extension === '.PNG'))
      || (this.mostrar === '2' && extension === '.MP4')
      || (this.mostrar === '5' && (extension === '.PDF' || extension === '.TXT' || extension === '.DOCX'
        || extension === '.XLSX' || extension === '.PPTX' || extension === '.DOC' || extension === '.XLS'
        || extension === '.PPT' || extension === '.JPEG')
      ) ? 1 : 0;
  }


  configArchivo(e: any) {
    this.tipo_archivo1 = '';
    this.datos_archivos1.delete('nombre_archivo');
    const index = this.datos_arch1.indexOf(this.datos_archivos1);
    if (index > -1) {
      this.datos_arch1.splice(index, 1);
    }
    this.archivo_1 = e.target.files[0];
    this.datos_archivos1.append('nombre_archivo', this.archivo_1);
    this.datos_archivos1.append('id_empresa', String(this.empresa));
    this.datos_archivos1.append('tipo', String(1));
    this.datos_archivos1.append('nomina', this.nomina);
    this.datos_archivos1.append('clara', String(this.cargarClara))
    this.datos_arch1.push(this.datos_archivos1);
    if (this.tipo_cont == 1 || this.tipo_cont == 5) {
      let archivo: File = e.target.files[0];
      let myReader: FileReader = new FileReader();
      myReader.readAsDataURL(archivo);
      myReader.onloadend = () => {
        this.filePrev = this.sanitizer.bypassSecurityTrustUrl(String(myReader.result));
      }
    } else {
      const files = e.target.files;
      if (files && files[0]) {
        this.filePrev = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(files[0]));
      }
    }
  }


  public changeMinPost(e: any): void {
    if (this.funcion.extensionValida(e)) {
      this.tipo_archivo2 = '';
      this.datos_archivos2.delete('nombre_archivo');
      const index = this.datos_arch2.indexOf(this.datos_archivos2);
      if (index > -1) {
        this.datos_arch2.splice(index, 1);
      }
      this.archivo_2 = e.target.files[0];
      this.datos_archivos2.append('nombre_archivo', this.archivo_2);
      this.datos_archivos2.append('id_empresa', String(this.empresa));
      this.datos_archivos2.append('tipo', String(2));
      this.datos_archivos2.append('nomina', this.nomina);
      this.datos_arch2.push(this.datos_archivos2);
      let file: File = e.target.files[0];
      let myReader: FileReader = new FileReader();
      myReader.readAsDataURL(file);
      myReader.onloadend = () => {
        this.filePrevMinPost = this.sanitizer.bypassSecurityTrustUrl(String(myReader.result));
      }
    } else {
      this.miniatura_post = '';
    }
  }

  public changeMinBanner(e: any): void {
    if (this.funcion.extensionValida(e)) {
      this.tipo_archivo3 = '';
      this.datos_archivos3.delete('nombre_archivo');
      this.archivo_3 = e.target.files[0];
      this.datos_archivos3.append('nombre_archivo', this.archivo_3);
      this.datos_archivos3.append('id_empresa', String(this.empresa));
      this.datos_archivos3.append('tipo', String(3));
      this.datos_archivos3.append('nomina', this.nomina);
      this.datos_arch3.push(this.datos_archivos3);
      let file: File = e.target.files[0];
      let myReader: FileReader = new FileReader();
      myReader.readAsDataURL(file);
      myReader.onloadend = () => {
        this.filePrevMinBanner = this.sanitizer.bypassSecurityTrustUrl(String(myReader.result));
      }
    } else {
      this.miniatura_banner = '';
    }
  }


  public dataDef() {
    this.titulo = '';
    this.texto_corto = '';
    this.modulo = 0;
    this.empresa = 0;
    this.estatus = 0;
    this.marca_act = 0;
    this.tipo_cont = 0;
    this.url_accion = '';
    this.msj_accion = '';
    this.miniatura_post = '';
    this.miniatura_banner = '';
    this.fecha_publicacion = '';
    this.fecha_vigencia = '';
    this.banner = false;
    this.notificacion = false;
    this.val1 = 0;
  }

  onClickPubcont() {
    this.router.navigate(['resumen_post']);
  }


  onClickResumenp() {
    this.router.navigate(['resumen_post']);
  }

  public selecType(event: any) {
    this.cargarClara = false;
    this.uri_clara = "";
    this.mostrar = event.target.value;
    if (this.id_post == 0) {
      if (this.mostrar == '2') {
        this.banner = true;
        this.val1 = 1;
      } else {
        this.banner = false;
        this.val1 = 0;
      }
    }
  }

  /** Metodos CallBack Temporal para archivos */

  public guardaArchivo1(id_post: any, accion: any) {
    if (this.datos_arch1.length > 0) {
      this.datos_archivos1.set('id_post', String(id_post));
      this.srvGuardaArchivo(accion, id_post);
    } else {
      this.msgAccion(accion, id_post);
    }
  }

  msgAccion(acc: any, idp: any) {
    if (!acc) {
      this.router.navigate(['nuevo_post/' + idp]);
      this.msgSave('Datos registrados correctamente');
    } else {
      this.router.navigate(['nuevo_post/' + idp]);
      this.msgSave('Datos actualizados correctamente');
    }
  }

  srvGuardaArchivo(accion: boolean, id_post: any) {
    this.servicios.guardarArchivo(this.datos_archivos1).subscribe(
      (data: any) => {
        this.uri_clara = String(data.data.gsuri).replace('gs://compensations/', '');
        if (!accion) {
          this.msgSave('Datos registrados correctamente');
          this.router.navigate(['nuevo_post/' + id_post]);
        } else {
          this.msgSave('Datos actualizados correctamente');
          this.router.navigate(['nuevo_post/' + id_post]);
        }
        this.funcion.hide();
      }, (error: any) => {
        this.funcion.hide();
      });
  }

  msgSave(msg: string) {
    this.snackBar.open(msg, 'close', { duration: 2500 });
    this.funcion.hide();
  }

  public guardaArchivo2(id_post: any, accion: any) {
    this.datos_archivos2.set('id_post', String(id_post));
    if (this.datos_arch2.length > 0) {
      this.servicios.guardarArchivo(this.datos_archivos2).subscribe(
        (data: any) => {
          if (!accion) {
            console.log("Carga completa ==> archivo 2");
          } else {
            console.log("Actualización completa ==> archivo 2");
          }
          this.funcion.hide();
        }, (error: any) => {
          console.log(error)
          this.funcion.hide();
        });
    }
    if (accion) {
      this.funcion.hide();
    }
  }

  public guardaArchivo3(id_post: any, accion: any) {
    this.datos_archivos3.set('id_post', String(id_post));
    if (this.datos_arch3.length > 0) {
      this.servicios.guardarArchivo(this.datos_archivos3).subscribe(
        (data: any) => {
          if (!accion) {
            console.log("Carga completa ==> archivo 3");
          } else {
            console.log("Actualización completa ==> archivo 3");
          }
          this.funcion.hide();
        }, (error: any) => {
          console.log(error)
          this.funcion.hide();
        }
      )
    }
    if (accion) {
      this.funcion.hide();
    }
  }

  validaURL(cadena: string) {
    let res = cadena.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null);
  }


  changeFechaPublicacion() {
    this.fecha_vigencia = "";
  }

  onChangeClara() {
    if (this.datos_arch1.length > 0) {
      this.datos_arch1[0].set('clara', String(this.cargarClara));
    }
  }

}
