<div class="loading-indicator" *ngIf="funcion.visibility | async">
  <img src="/assets/img/spinner.gif" alt="">
</div>
<div class="row mt-4">
  <dx-tree-list id="audiencia" [dataSource]="matriz_audiencia"
      parentIdExpr="id_padre" keyExpr="id" (onSelectionChanged)="getSelection($event)"
      [showRowLines]="true" [showBorders]="true"
      [columnAutoWidth]="true">
      <dxo-search-panel [visible]="true" [width]="searchPanel.width"
          [placeholder]="searchPanel.placeholder">
      </dxo-search-panel>
      <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
      <dxo-selection  mode="multiple"></dxo-selection>
      <dxo-filter-row [visible]="searchPanel.filterRow"></dxo-filter-row>
      <dxi-column dataField="descripcion" caption="Audiencia"></dxi-column>
      <dxi-column [visible]="false" dataField="id" caption="Id"></dxi-column>
      <dxi-column dataField="nomina" caption='Nómina'></dxi-column>
      <dxi-column dataField="descripcion_nivel" caption='Descripción Nivel'></dxi-column>
      <dxi-column [visible]="false" dataField="id_empresa"></dxi-column>
      <dxi-column [visible]="false" dataField="id_udn"></dxi-column>
      <dxi-column [visible]="true" dataField="id_direccion"></dxi-column>
      <dxi-column [visible]="true" dataField="id_subdireccion"></dxi-column>
      <dxi-column [visible]="true" dataField="id_ubicacion"></dxi-column> 
      <dxi-column [visible]="true" dataField="id_puesto"></dxi-column>    
  </dx-tree-list>
</div>

