<incentivos-navbar [nombre]="nombreL" [nomina]="nominaL"></incentivos-navbar>

<!-- SI Consola Empresa -->
<div class="loading-indicator" *ngIf="funcion.visibility | async">
  <img src="/assets/img/spinner.gif" alt="">
</div>

<div class="d-flex justify-content-center align-box">
  <div class="box2">
    <nav
      style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
      aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item b1" href="javascript:;">Configuración SI+</li>
        <li class="breadcrumb-item b1" href="javascript:;">Configuración del Tour</li>
        <li class="breadcrumb-item b2">Filtro / Historial</li>
      </ol>
    </nav>
  </div>
</div>

<div class="d-flex justify-content-center align-box">
  <div class="box">
    <div class="d-flex align-items-center py-4">
      <div class="container">
        <form>
          <div class="row mb-5">
            <div class="col-md-4">
              <label for="input1" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                <span class="h6 small bg-white text-muted px-1">Estatus</span>
              </label>
              <select class="form-control form-select mt-2" id="input1" required [(ngModel)]="estatus" name="estatus">
                <option value=true>Activo</option>
                <option value=false>Inactivo</option>
              </select>
            </div>
            <div class="col-md-4">
              <label for="input2" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                <span class="h6 small bg-white text-muted px-1">Empresa</span>
              </label>
              <select class="form-select" aria-label="Default select example" required [(ngModel)]="empresa"
                name="empresa">
                <option *ngFor="let empr of emp" [value]="empr.id">{{empr.nombre}}</option>
              </select>
            </div>
            <div class="col-md-4">
              <label for="input3" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                <span class="h6 small bg-white text-muted px-1">Fecha de publicación</span>
              </label>
              <input id="fecha" type="text" required class="form-control" onfocus="(this.type='date')"
                onblur="if(this.value==''){this.type='text'}" [(ngModel)]="fecha_pub" name="fecha_pub">
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-md-4">
              <label for="input4" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                <span class="h6 small bg-white text-muted px-1">Rol</span>
              </label>
              <select class="form-control form-select mt-2" id="input4" required [(ngModel)]="rol" name="rol">
                <option *ngFor="let rolp of perfiles" [value]="rolp.nombrePerfil">{{rolp.nombrePerfil}}</option>
              </select>
            </div>
            <div class="col-md-4">
              <label for="input5" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                <span class="h6 small bg-white text-muted px-1">Tipo</span>
              </label>
              <select class="form-control form-select mt-2" id="input5" required [(ngModel)]="tipo" name="tipo">
                <option value="welcome">Welcome</option>
                <option value="release">Release</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col d-grid gap-5 d-md-flex justify-content-md-end">
              <button type="button" class="btn mt-2 btn-lg boton" (click)="consulta()">Consultar</button>
              <button type="button" class="btn mt-2 btn-lg boton2" (click)="dataDef()">Limpiar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="d-grid gap-2 d-md-flex justify-content-md-end align-btn">
      <button type="button" class="btn btn-primary boton3" (click)="newTour()">+ Nuevo Tour</button>
    </div>
    <br><br>
    <div class="row mt-4">
      <incentivos-tabla-registros [datos]="tours" [wait]="wait" [columns]="columns_tours" [url]="url_tour" [ver]="true"></incentivos-tabla-registros>
    </div>
    <br><br>
  </div>
</div>
