import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ServiciosImp } from '../../services/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Funciones } from '../../classes/funciones';
import { FormControl } from "@angular/forms";


@Component({
  selector: 'sondeo_pregunta',
  templateUrl: './sondeo_pregunta.component.html',
  styleUrls: ['./sondeo_pregunta.component.css']
})
export class SondeoPreguntaComponent implements OnInit {

  @Input() idsondeo: number = 0;
  @Input() tipo: number = 0;
  public categoriaSelected = new FormControl('');
  public objBorrar: any = [];
  public arregloSimbolo: any = [];
  private arregloInicial: any = [];
  public nivelSelected: number = 0;
  public simboloSelected: number = 0;
  public Selecciona: string = 'Selecciona';
  public premio_insignia: any;
  public activRami: boolean = false;
  public vistaPrevia: boolean = false;
  public vistaRamificacion: boolean = false;
  public configurarespuesta: boolean = false;
  public idValIniRadio: number = 0;

  public arregloPre: any = {
    preguntas: [
      {
        orden: 1,
        tipo: 1,
        retro: false,
        multiple: false,
        obligatorio: false,
        apartadootra: true,
        pregunta: "",
        simbolo: 0,
        niveles: 0,
        respuestas: {
          radios: [
            { idradio: 1 }, { idradio: 2 }
          ],
          filas: [
            { idfila: 1 }, { idfila: 2 }
          ],
          columnas: [
            { idcolumna: 1 }, { idcolumna: 2 }
          ],
        },
      },


    ]
  }

  constructor(
    private router: Router,
    public snackBar: MatSnackBar,
    public servicios: ServiciosImp,
    public funcion: Funciones
  ) {

  }

  ngOnInit(): void {

    //
    this.validaTipoPregunta();
    if (localStorage.getItem('editar') == 'true') {

      let url = 'quizz/' + this.idsondeo + '/pregunta/';
      this.servicios.getService(url).toPromise().then(
        (res: any) => {
          if (res.status == 401) {
            this.router.navigate(['login']);
          }
          if (res.data.preguntas.length > 0) {
            this.arregloPre.preguntas = res.data.preguntas;
            console.log(this.arregloPre.preguntas);
            this.arregloPre.preguntas.forEach((_pre: any) => {
              if (_pre.respuestas) {
                _pre.respuestas = JSON.parse(String(_pre.respuestas));
              }

            });
            let consecutivo = 0;
            this.arregloPre.preguntas.forEach((_orden: any) => {
              consecutivo = consecutivo + 1;
              _orden.orden = consecutivo;
            });
            this.validaTipoPreguntaEditar();
            this.funcion.hide();
          } else {
            this.validaTipoPregunta();
          }
        },
        (error: any) => {
          this.snackBar.open(error.message, 'close', {
            duration: 2500,
          });
          this.funcion.hide();
        }
      )
    }
  }


  public selectorOption(sim: any, item: any) {
    this.arregloPre.preguntas.forEach((_v: any) => {
      let simboloele: any = [];
      if (_v.orden == item.orden) {
        _v.simbolos.forEach((_selSimbol: any) => {
          if (_selSimbol.id == sim.id) {
            simboloele = _v.simbolos[sim.id - 1];
          }
        });
        if (_v.simboloSele) {
          _v.simbolosIni = [simboloele];
        }
        _v.simboloSele = false;
        this.validaSimbolo(sim, item);
      }
    });
  }

  public selectorOptionNivel(niv: any, item: any) {
    //
    this.arregloPre.preguntas.forEach((_v: any) => {
      let nivelele: any = [];
      if (_v.orden == item.orden) {
        _v.nivelesobj.forEach((_selNivel: any) => {
          if (_selNivel.id == niv.id) {
            nivelele = _v.nivelesobj[niv.id - 1];
          }
        });
        if (_v.nivelSele) {
          _v.nivelesIni = [nivelele];
        }
        _v.nivelSele = false;
        this.validaNiveles(niv, item)
      }
    });
  }

  public selectorSimb(item: any) {

    this.arregloPre.preguntas.forEach((_v: any) => {
      if (_v.orden == item.orden) {
        if (_v.simboloSele) {
          _v.simboloSele = false;
        } else {
          _v.simboloSele = true;
        }
      }
    });

  }

  public selectorNivel(item: any) {

    this.arregloPre.preguntas.forEach((_v: any) => {
      if (_v.orden == item.orden) {
        if (_v.nivelSele) {
          _v.nivelSele = false;
        } else {
          _v.nivelSele = true;
        }
      }
    });

  }
  public validaTipoPregunta() {

    if (this.arregloPre.preguntas) {
      this.arregloPre.preguntas.forEach((pregunta: any) => {
        pregunta.simboloSele = false;
        pregunta.nivelSele = false;
        pregunta.simbolo = 0;
        pregunta.niveles = 0;
        pregunta.respuestacorrecta = false;
        pregunta.variasrespuestas = false;
        pregunta.nivelesIni = [
          { "descripcion": "Selecciona", "id": 0 },
        ];
        pregunta.nivelesobj = [
          { "descripcion": "1", "id": 1 },
          { "descripcion": "2", "id": 2 },
          { "descripcion": "3", "id": 3 },
          { "descripcion": "4", "id": 4 },
          { "descripcion": "5", "id": 5 },
          { "descripcion": "6", "id": 6 },
          { "descripcion": "7", "id": 7 },
          { "descripcion": "8", "id": 8 },
          { "descripcion": "9", "id": 9 },
          { "descripcion": "10", "id": 10 }
        ]
        pregunta.simbolos = [
          { "descripcion": "Estrella", "id": 1, "imagen": '/assets/images/Estrella.png' },
          { "descripcion": "Número", "id": 2, "imagen": '/assets/images/num.png' },
          { "descripcion": "Cara sonriente", "id": 3, "imagen": '/assets/images/cara.png' },
          { "descripcion": "Corazón", "id": 4, "imagen": '/assets/images/corazon.png' }
        ]
        pregunta.simbolosIni = [
          { "descripcion": "Selecciona", "id": 0 },
        ];
        pregunta.tipoPregunta = [
          {
            idPegunta: 1,
            nombre: 'Opción múltiple'
          },
          {
            idPegunta: 2,
            nombre: 'Calificación'
          },
          {
            idPegunta: 3,
            nombre: 'Likert'
          },
          {
            idPegunta: 4,
            nombre: 'Texto'
          },
        ]
        this.configuraTipoPregunta(pregunta);
        pregunta.respuestas.filas = [
          { idfila: 1 },
          { idfila: 2 }
        ];

        pregunta.respuestas.columnas = [
          { idcolumna: 1 },
          { idcolumna: 2 }
        ];

      });

      localStorage.setItem('sondeoPreguntas', JSON.stringify(this.arregloPre));
    }

  }
  public validaTipoPreguntaEditar() {
    if (this.arregloPre.preguntas) {
      this.arregloPre.preguntas.forEach((pregunta: any) => {
        pregunta.simboloSele = false;
        pregunta.nivelSele = false;
        pregunta.respuestacorrecta = false;
        pregunta.variasrespuestas = false;
        pregunta.nivelesIni = [{ "descripcion": "Selecciona", "id": 0 }];
        pregunta.nivelesobj = [
          { "descripcion": "1", "id": 1 },
          { "descripcion": "2", "id": 2 },
          { "descripcion": "3", "id": 3 },
          { "descripcion": "4", "id": 4 },
          { "descripcion": "5", "id": 5 },
          { "descripcion": "6", "id": 6 },
          { "descripcion": "7", "id": 7 },
          { "descripcion": "8", "id": 8 },
          { "descripcion": "9", "id": 9 },
          { "descripcion": "10", "id": 10 }
        ];
        pregunta.simbolos = [
          { "descripcion": "Estrella", "id": 1, "imagen": '/assets/images/Estrella.png' },
          { "descripcion": "Número", "id": 2, "imagen": '/assets/images/num.png' },
          { "descripcion": "Cara sonriente", "id": 3, "imagen": '/assets/images/cara.png' },
          { "descripcion": "Corazón", "id": 4, "imagen": '/assets/images/corazon.png' }
        ];
        pregunta.simbolosIni = [
          { "descripcion": "Selecciona", "id": 0 },
        ];
        pregunta.tipoPregunta = [
          {
            idPegunta: 1,
            nombre: 'Opción múltiple'
          },
          {
            idPegunta: 2,
            nombre: 'Calificación'
          },
          {
            idPegunta: 3,
            nombre: 'Likert'
          },
          {
            idPegunta: 4,
            nombre: 'Texto'
          },
        ];
        if (pregunta.respuestas.radios) {
          this.configuraPregunta(pregunta);
        }
        this.configuraTipoPregunta(pregunta);
        if (pregunta.niveles !== 0 && pregunta.simbolo !== 0) {
          this.validaSimbolos(pregunta);
        }
      });
    }
  }

  configuraTipoPregunta(pregunta: any) {
    pregunta.tipoPregunta.forEach((_tipo: any) => {
      _tipo.fondo = _tipo.idPegunta == pregunta.tipo ? '#E6863E' : '#ECECEC';
      _tipo.color = _tipo.idPegunta == pregunta.tipo ? '#FFFFFF' : '#49454F';
    });
  }

  configuraPregunta(pregunta: any,) {
    let contador = 0;
    pregunta.respuestas.radios.forEach((_idradio: any) => {
      pregunta.apartadootra = _idradio.idradio == -1 ? false : true;
      _idradio.fondorad = _idradio.status ? '#E6863E' : '#FFFFFF';
      contador = _idradio.status ? (contador + 1) : contador;
      pregunta.respuestacorrecta = _idradio.status ? true : false;
      pregunta.variasrespuestas = contador > 1 ? true : false;
      pregunta.configurarespuesta = contador > 1 ? true : false;
    });
  }

  public validaSimbolos(item: any) {
    this.arregloPre.preguntas.forEach((nivel: any) => {
      this.arregloSimbolo = [];
      if (nivel.orden == item.orden) {
        nivel.objimagen = [];
        let objimagen: any = [];
        for (let i = 1; i <= nivel.niveles; i++) {
          if (nivel.simbolo == 1) {
            objimagen = {
              "imagen": '/assets/images/Estrella.png'
            }
          }
          else if (nivel.simbolo == 2) {
            objimagen = {
              "imagen": '/assets/images/num.png'
            }
          }
          else if (nivel.simbolo == 3) {
            objimagen = {
              "imagen": '/assets/images/cara.png'
            }
          }
          else if (nivel.simbolo == 4) {
            objimagen = {
              "imagen": '/assets/images/corazon.png'
            }
          }

          this.arregloSimbolo.push(objimagen);

        }
        nivel.objimagen = this.arregloSimbolo;

        let nivelele: any = [];
        nivel.nivelesobj.forEach((_selNivel: any) => {
          if (_selNivel.id == nivel.niveles) {
            nivelele = nivel.nivelesobj[nivel.niveles - 1];
          }
        });
        nivel.nivelesIni = [nivelele];

        let simboloele: any = [];
        nivel.simbolos.forEach((_selSimbol: any) => {
          if (_selSimbol.id == nivel.simbolo) {
            simboloele = nivel.simbolos[nivel.simbolo - 1];
          }
        });
        nivel.simbolosIni = [simboloele];
      }

    });
  }

  public validaNiveles(niveles: any, item: any) {
    this.arregloPre.preguntas.forEach((nivel: any) => {
      this.arregloSimbolo = [];
      if (nivel.orden == item.orden) {
        nivel.niveles = niveles.id;
        nivel.objimagen = [];
        if (nivel.niveles !== 0 && nivel.simbolo !== 0) {
          let objimagen: any = [];
          for (let i = 1; i <= nivel.niveles; i++) {
            if (nivel.simbolo == 1) {
              objimagen = {
                "imagen": '/assets/images/Estrella.png'
              }
            }
            else if (nivel.simbolo == 2) {
              objimagen = {
                "imagen": '/assets/images/num.png'
              }
            }
            else if (nivel.simbolo == 3) {
              objimagen = {
                "imagen": '/assets/images/cara.png'
              }
            }
            else if (nivel.simbolo == 4) {
              objimagen = {
                "imagen": '/assets/images/corazon.png'
              }
            }

            this.arregloSimbolo.push(objimagen);

          }
          nivel.objimagen = this.arregloSimbolo;
        }

      }

    });
  }

  public validaSimbolo(obj: any, item: any) {
    this.arregloPre.preguntas.forEach((simbol: any) => {
      this.arregloSimbolo = [];
      if (simbol.orden == item.orden) {
        simbol.simbolo = obj.id;
        simbol.objimagen = [];

        if (simbol.niveles !== 0 && simbol.simbolo !== 0) {
          let objimagen: any = [];

          for (let i = 1; i <= simbol.niveles; i++) {
            if (simbol.simbolo == 1) {
              objimagen = {
                "imagen": '/assets/images/Estrella.png'
              }
            }
            else if (simbol.simbolo == 2) {
              objimagen = {
                "imagen": '/assets/images/num.png'
              }
            }
            else if (simbol.simbolo == 3) {
              objimagen = {
                "imagen": '/assets/images/cara.png'
              }
            }
            else if (simbol.simbolo == 4) {
              objimagen = {
                "imagen": '/assets/images/corazon.png'
              }
            }

            this.arregloSimbolo.push(objimagen);

          }
          simbol.objimagen = this.arregloSimbolo;
        }

      }

    });
  }

  public agregaPregunta() {
    this.categoriaSelected = new FormControl('');
    if (this.arregloPre.preguntas) {
      let obj: any = [];
      this.arregloInicial = JSON.parse(String(localStorage.getItem("sondeoPreguntas")));
      obj = this.arregloInicial.preguntas[0];
      obj = {
        ...obj,
        orden: Number(this.arregloPre.preguntas.length) + 1,
      }
      this.arregloPre.preguntas.push(obj);
    }
  }

  public agregaRamificacion(item: any, radio: any, e: any) {
    if (this.arregloPre.preguntas) {
      let obj: any = [];
      this.arregloInicial = JSON.parse(String(localStorage.getItem("sondeoPreguntas")));
      obj = this.arregloInicial.preguntas[0];
      if (e == 1) {
        this.arregloPre.preguntas.forEach((_ram: any) => {
          if (_ram.orden == item.orden) {
            this.configResp(_ram, obj, radio, e, item);

          }
        });
      }
    }
  }

  configResp(_ram: any, obj: any, radio: any, e: any, item: any) {
    _ram.respuestas.radios.forEach((_idradio: any) => {
      if (_idradio.idradio == radio.idradio) {
        _idradio.ramificacion = false;
        obj = {
          ...obj,
          orden: Number(this.arregloPre.preguntas.length) + 1,
        }
        _idradio.pregunta = obj;
        _ram.ramificacion = true;
      }
      else if (e == 2) {
        this.arregloPre.preguntas.forEach((_ram2: any) => {
          if (_ram2.orden == item.orden) {
            _ram2.respuestas.radios.forEach((_idradio2: any) => {
              if (_idradio2.idradio == radio.idradio) {
                _idradio2.ramificacion = false;
              }
            });
            _ram2.ramificacion = false;

          }
        });
      }

    });
  }

  public borrarPregunta(obj: any) {
    this.objBorrar = [];
    if (this.arregloPre.preguntas) {
      let consecutivo = 0;
      this.arregloPre.preguntas.forEach((_v: any) => {
        if (_v.orden !== obj.orden) {
          this.objBorrar.push(_v);
          consecutivo = consecutivo + 1;
          _v.orden = consecutivo;
        }
      });

      this.arregloPre.preguntas = this.objBorrar;
    }

  }

  public agregaRadios(obj: any) {
    //
    if (this.arregloPre.preguntas) {
      this.arregloPre.preguntas.forEach((_v: any) => {
        if (_v.respuestas.radios) {
          let num = Number(_v.respuestas.radios.length) + 1;
          if (_v.orden == obj.orden) {
            _v.respuestas.radios = [
              ..._v.respuestas.radios,
              {
                idradio: num
              }
            ]
          }
        }
      });
    }
  }

  public agregaFila(obj: any) {
    //
    if (this.arregloPre.preguntas) {
      this.arregloPre.preguntas.forEach((_v1: any) => {
        if (_v1.respuestas.filas) {
          let num1 = Number(_v1.respuestas.filas.length) + 1;
          if (_v1.orden == obj.orden) {
            _v1.respuestas.filas = [
              ..._v1.respuestas.filas,
              {
                idfila: num1
              }
            ]
          }
        }
      });
    }
  }



  public agregaColumna(obj: any) {
    if (this.arregloPre.preguntas) {
      this.arregloPre.preguntas.forEach((_v2: any) => {
        if (_v2.respuestas.columnas) {
          let num2 = Number(_v2.respuestas.columnas.length) + 1;
          if (_v2.orden == obj.orden) {
            _v2.respuestas.columnas = [
              ..._v2.respuestas.columnas,
              {
                idcolumna: num2
              }
            ]
          }
        }
      });
    }
  }



  public agregaRadioOtra(otro: any) {
    if (this.arregloPre.preguntas) {
      this.arregloPre.preguntas.forEach((_v: any) => {
        if (_v.orden == otro.orden) {
          _v.respuestas.radios = [
            ..._v.respuestas.radios,
            {
              idradio: -1,
              nombreradio: 'Otro'
            }
          ];
          _v.apartadootra = false;
        }
      });
    }
  }

  public validatipPregunta(valor: any, item: any) {
    if (this.arregloPre.preguntas) {
      this.arregloPre.preguntas.forEach((_v: any) => {
        if (_v.orden == item.orden) {
          _v.tipoPregunta.forEach((_tipo: any) => {
            if (_tipo.idPegunta == valor.idPegunta) {
              _tipo.fondo = '#E6863E';
              _tipo.color = '#FFFFFF';

            } else {
              _tipo.fondo = '#ECECEC';
              _tipo.color = '#49454F';
            }
          });
          _v.tipo = valor.idPegunta;
        }

      });

    }

  }

  public valorObligatorio(e: any, item: any) {
    if (this.arregloPre.preguntas) {
      this.arregloPre.preguntas.forEach((_v: any) => {
        if (_v.orden == item.orden) {
          _v.obligatorio = e.checked;
        }
      });
    }

  }
  public respuesta_larga(e: any, item: any) {
    if (this.arregloPre.preguntas) {
      this.arregloPre.preguntas.forEach((_v: any) => {
        if (_v.orden == item.orden) {
          _v.respuesta_larga = e.checked;
        }
      });
    }

  }

  public valorPregunta(item: any, e: any) {
    if (this.arregloPre.preguntas) {
      this.arregloPre.preguntas.forEach((_v: any) => {
        if (_v.orden == item.orden) {
          _v.pregunta = e;
        }
      });
    }
  }


  public valorPreguntaFila(item: any, e: any, filas: any) {
    if (this.arregloPre.preguntas) {
      this.arregloPre.preguntas.forEach((_v: any) => {
        if (_v.orden == item.orden) {
          _v.respuestas.filas.forEach((_v1: any) => {
            if (_v1.idfila == filas.idfila) {
              _v1.filasnombre = e;
            }
          });
        }
      });
    }
  }

  public valorPreguntaColumna(item: any, e: any, columnas: any) {
    if (this.arregloPre.preguntas) {
      this.arregloPre.preguntas.forEach((_v: any) => {
        if (_v.orden == item.orden) {
          _v.respuestas.columnas.forEach((_v1: any) => {
            if (_v1.idcolumna == columnas.idcolumna) {
              _v1.columnasnombre = e;
            }
          });
        }
      });
    }
  }


  public valorResp(item: any, e: any) {
    if (this.arregloPre.preguntas) {
      this.arregloPre.preguntas.forEach((_v: any) => {
        if (_v.orden == item.orden) {
          _v.respuesta = e;
        }
      });
    }

  }
  public valorRetro(item: any, e: any) {
    if (this.arregloPre.preguntas) {
      this.arregloPre.preguntas.forEach((_v: any) => {
        if (_v.orden == item.orden) {
          _v.retroalimentacion = e;
          _v.retro = true;
        }
      });
    }

  }
  public valorRadio(obj: any, e: any, radios: any) {
    if (this.arregloPre.preguntas) {
      this.arregloPre.preguntas.forEach((_rad: any) => {
        if (_rad.orden == obj.orden) {
          _rad.respuestas.radios.forEach((_idradio: any) => {
            if (_idradio.idradio == radios.idradio) {
              _idradio.nombreradio = e;
            }
            if (_rad.ramificacion) {
              _idradio.ramificacion = true;
            }
          });
        }
      });
    }
  }
  public validaRespuesta(obj: any, radios: any) {
    if (this.arregloPre.preguntas) {
      this.arregloPre.preguntas.forEach((_rad: any) => {
        if (_rad.orden == obj.orden) {
          _rad.respuestas.radios.forEach((_idradio: any) => {
            this.validaMultiples(_rad, _idradio, radios);
          });
        }
      });
    }
  }

  validaMultiples(_rad: any, _idradio: any, radios: any) {
    if (!_rad.multiple) {
      _idradio.status = _idradio.idradio == radios.idradio ? true : false;
      _idradio.fondorad = _idradio.idradio == radios.idradio ? '#E6863E' : '#FFFFFF';
    } else if (_rad.multiple) {
      if (_idradio.idradio == radios.idradio) {
        _idradio.status = !_idradio.status ? true : false;
        _idradio.fondorad = !_idradio.status ? '#E6863E' : '#FFFFFF';
      }
    }
  }

  public activaVistaPrevia() {
    this.vistaPrevia = true;
    if (!this.activRami) {
      this.activRami = true;
    } else {
      this.activRami = false;
    }

  }


  public activaRam() {
    if (!this.activRami) {
      this.activRami = true;
    } else {
      this.activRami = false;
    }
    this.vistaPrevia = false;

  }
  public validaRespuestas(): boolean {
    let respuesta: boolean = true;
    this.arregloPre.preguntas.forEach((_gua: any) => {
      respuesta = _gua.pregunta == '' ? false : true;
      if (_gua.tipo == 1) {
        _gua.respuestas.radios.forEach((_rad: any) => {
          respuesta = !_rad.nombreradio ? false : true;
        });
      } else if (_gua.tipo == 2 && _gua.pregunta == '' && _gua.niveles == 0 && _gua.simbolo == 0) {
        respuesta = false;
      } else if (_gua.tipo == 3) {
        _gua.respuestas.columnas.forEach((_col: any) => {
          respuesta = !_col.columnasnombre ? false : true;
        });
        _gua.respuestas.filas.forEach((_fil: any) => {
          respuesta = !_fil.filasnombre ? false : true;
        });
      }
    });
    return respuesta;
  }

  public guardarPreguntas() {
    localStorage.setItem('arregloFinal', JSON.stringify(this.arregloPre));
    if (!this.validaRespuestas()) {
      this.snackBar.open('Debe capturar todas las preguntas!', 'close', {
        duration: 2500,
      });
      this.funcion.hide();
      return;
    }
    if (this.arregloPre.preguntas) {
      this.arregloPre.preguntas.forEach((_gua: any) => {
        delete _gua.nivelesobj;
        delete _gua.apartadootra;
        delete _gua.tipoPregunta;
        delete _gua.simbolos;
        delete _gua.id;
        delete _gua.simbolosIni;
        delete _gua.configurarespuesta;
        delete _gua.respuestacorrecta;
        delete _gua.variasrespuestas;
        delete _gua.simboloSele;
        delete _gua.nivelSele;
        delete _gua.objimagen;
        delete _gua.nivelesIni;

        if (_gua.tipo == 1) {
          delete _gua.respuestas.filas;
          delete _gua.respuestas.columnas;
        } else if (_gua.tipo == 2 || _gua.tipo == 4) {
          delete _gua.respuestas.filas;
          delete _gua.respuestas.columnas;
          delete _gua.respuestas.radios;
        } else if (_gua.tipo == 3) {
          delete _gua.respuestas.radios;
        }
      });
      this.guardarSrv();

    }
  }

  guardarSrv() {
    this.servicios.postService('quizz/' + Number(this.idsondeo) + '/pregunta/', this.arregloPre).toPromise().then(
      (res: any) => {
        if (res.status == 401) {
          this.router.navigate(['login']);
        }
        this.arregloPre = JSON.parse(String(localStorage.getItem("arregloFinal")));
        this.vistaPrevia = this.vistaPrevia ? false : true;
        this.snackBar.open('Pregunta guardada correctamente!', 'close', { duration: 2500 });
        this.funcion.hide();
      }
    ).catch((error) => {
      if (error.status == 400 || error.status == 500) {
        this.snackBar.open('Error al guardar la pregunta del cuestionario', 'close', { duration: 2500 });
        this.funcion.hide();
      } else {
        this.snackBar.open(error.message, 'close', { duration: 2500 });
        this.funcion.hide();
      }
    });
  }

  public respuestaCorrecta(e: any, item: any) {
    if (this.arregloPre.preguntas) {
      this.arregloPre.preguntas.forEach((_correc: any) => {
        if (_correc.orden == item.orden) {
          if (e.checked) {
            _correc.configurarespuesta = true;
          } else {
            _correc.configurarespuesta = false;
          }
        }
      });
    }


  }
  public varias_respuestas(e: any, item: any) {
    if (this.arregloPre.preguntas) {
      this.arregloPre.preguntas.forEach((_var: any) => {
        if (_var.orden == item.orden) {
          if (e.checked) {
            _var.multiple = true;
          } else {
            _var.multiple = false;
          }
          _var.respuestas.radios.forEach((_radioLi: any) => {
            _radioLi.status = false;
            _radioLi.fondorad = "#FFFFFF";
          });
        }
      });
    }


  }

  public ordenarArribaAbajo(item: any, idOrd: number) {
    let idice = item.orden - 1;
    if (idOrd == 1) {
      let arre2: any = this.arregloPre.preguntas[idice - 1];
      this.arregloPre.preguntas[idice - 1] = this.arregloPre.preguntas[idice];
      this.arregloPre.preguntas[idice] = arre2;

      let consecutivo = 0;
      this.arregloPre.preguntas.forEach((_orden: any) => {
        consecutivo = consecutivo + 1;
        _orden.orden = consecutivo;
      });

    } else if (idOrd == 2) {
      let arre2: any = this.arregloPre.preguntas[idice + 1];
      this.arregloPre.preguntas[idice + 1] = this.arregloPre.preguntas[idice];
      this.arregloPre.preguntas[idice] = arre2;

      let consecutivo = 0;
      this.arregloPre.preguntas.forEach((_orden: any) => {
        consecutivo = consecutivo + 1;
        _orden.orden = consecutivo;
      });
    }
  }
}

