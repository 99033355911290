<incentivos-navbar [nombre]="nombreL" [nomina]="nominaL"></incentivos-navbar>

<!-- Crear una nueva empresa -->
<div class="loading-indicator" *ngIf="funcion.visibility | async">
    <img src="/assets/img/spinner.gif" alt="">
</div>

<div class="d-flex justify-content-center align-box2">
    <div class="box2">
        <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
            aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item b1" href="javascript:;" (click)="onClickConfsi()">Configuración SI+</li>
                <li class="breadcrumb-item b1" href="javascript:;" (click)="onClickConfcat()">Configuración de catálogos</li>
                <li class="breadcrumb-item b1" href="javascript:;" (click)="onClickCatemp()">Catálogo de empresas</li>
                <li class="breadcrumb-item b2">{{titulo}} empresa</li>
            </ol>
        </nav>
    </div>
</div>
<div class="d-flex justify-content-center align-box">
    <div class="box">
        <p class="f-t1">{{titulo}} Empresa</p>
        <p class="f-t2">*Campos Obligatorios</p>
        <div class="container cuerpo_post">
                <div class="row mb-5">
                    <div class="col-md-3">
                        <input type="text" placeholder="Nombre empresa*" class="form-control f-size-1 mt-2" required [(ngModel)]="nombreEmpresa">
                    </div>
                    <div class="col-md-6">
                        <input type="text" placeholder="Descripción*" class="form-control f-size-2 mt-2" required [(ngModel)]="descripcion">
                    </div>
                    <div class="col-md-3">
                        <label for="input1" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                            <span class="h6 small bg-white text-muted px-1">Estatus*</span>
                        </label>
                        <select class="form-control form-select f-size-1 mt-2" id="input1" required [(ngModel)]="statusEmpresa">
                            <option *ngFor="let sE of status_empresa" [value]="sE.id">{{sE.nombre}}</option>
                        </select>
                    </div>
                </div>
                <div class="row mb-5">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-12">
                                <label>Configuración de ícono SI+*:</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <input class="form-control f-size-1 mt-2" type="file" id="filec1"
                                    (change)="changeSI($event)" [(ngModel)]="si" required accept="image/*">
                            </div>
                            <div class="col-md-6">
                                <button class="button mt-2 boton3" type="button" data-bs-toggle="modal"
                                    data-bs-target="#openModalE1" [disabled]="!si">
                                    <em class="bi bi-eye-fill" style="pointer-events: none;"></em>&nbsp;&nbsp;Previsualizar</button>
                            </div>
                            <img  *ngIf="img_si" alt="img_si" hidden [src]="img_si">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-12">
                                <label >Logo de ayuda (Clara)*:</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <input class="form-control f-size-1 mt-2" type="file" id="filec2"
                                (change)="changeClara($event)" [(ngModel)]="clara" required accept="image/*">
                            </div>
                            <div class="col-md-6">
                                <button class="button mt-2 boton3" type="button" data-bs-toggle="modal" data-bs-target="#openModalE2" [disabled]="!clara">
                                    <em class="bi bi-eye-fill" style="pointer-events: none;"></em>&nbsp;&nbsp;Previsualizar</button>
                            </div>
                            <img  *ngIf="img_clara" alt="img_clara" hidden [src]="img_clara">
                        </div>
                    </div>
                </div>
                <div class="row mb-5">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-12">
                                <label>Configuración de banner del home*:</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <input class="form-control f-size-1 mt-2" type="file" id="filec3"
                                (change)="changeBanner($event)" [(ngModel)]="home" required accept="image/*">
                            </div>
                            <div class="col-md-6">
                                <button class="button mt-2 boton3" type="button" data-bs-toggle="modal" data-bs-target="#openModalE3" [disabled]="!home">
                                    <em class="bi bi-eye-fill" style="pointer-events: none;"></em>&nbsp;&nbsp;Previsualizar</button>
                            </div>
                            <img  *ngIf="img_banner" alt="img_banner" hidden [src]="img_banner">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-12">
                                <label>Configuración de pleca del home*:</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <input class="form-control f-size-1 mt-2" type="file" id="filec4"
                                (change)="changePleca($event)" [(ngModel)]="pleca" required accept="image/*">
                            </div>
                            <div class="col-md-6">
                                <button class="button mt-2 boton3" type="button" data-bs-toggle="modal" data-bs-target="#openModalE4" [disabled]="!pleca">
                                    <em class="bi bi-eye-fill" style="pointer-events: none;"></em>&nbsp;&nbsp;Previsualizar</button>
                            </div>
                            <img  *ngIf="img_pleca" alt="img_pleca" hidden [src]="img_pleca">
                        </div>
                    </div>
                </div>
                <div class="row mb-5">
                    <div class="col-md-3">
                        <label for="input4" class="ms-2 position-absolute"
                        style="margin-top: -0.25rem !important">
                        <span class="h6 small bg-white text-muted px-1">Color letras Home*</span>
                         </label>
                    <input type="color" placeholder="Selecciona" id="color1"
                        class="form-control mt-2"  required >
                    </div>
                    <div class="col-md-3">
                        <label for="input5" class="ms-2 position-absolute"
                        style="margin-top: -0.25rem !important">
                        <span class="h6 small bg-white text-muted px-1">Color primario*</span>
                    </label>
                    <input type="color" placeholder="Selecciona" id="color2"
                        class="form-control mt-2" required >
                    </div>
                    <div class="col-md-3">
                        <label for="input6" class="ms-2 position-absolute"
                                    style="margin-top: -0.25rem !important">
                                    <span class="h6 small bg-white text-muted px-1">Color secundario*</span>
                        </label>
                        <input type="color" placeholder="Selecciona" id="color3"
                        class="form-control mt-2" required >
                    </div>
                    <div class="col-md-3">
                        <label for="input6" class="ms-2 position-absolute"
                                    style="margin-top: -0.25rem !important">
                                    <span class="h6 small bg-white text-muted px-1">Color íconos*</span>
                        </label>
                        <input type="color" placeholder="Selecciona" id="color4"
                        class="form-control mt-2" required >
                    </div>
                </div>
                <div class="row mb-5">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-12">
                                <label>Configuración de logo para gráfica*:</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <input class="form-control f-size-1 mt-2" type="file" id="filec4"
                                (change)="changeGrafica($event)" [(ngModel)]="grafica" required accept="image/*">
                            </div>
                            <div class="col-md-6">
                                <button class="button mt-2 boton3" type="button" data-bs-toggle="modal" data-bs-target="#openModalE5" [disabled]="!grafica">
                                    <em class="bi bi-eye-fill" style="pointer-events: none;"></em>&nbsp;&nbsp;Previsualizar</button>
                            </div>
                            <img  *ngIf="img_grafica" alt="img_grafica" hidden [src]="img_grafica">
                        </div>
                    </div>
                </div>
                <div class="row mb-5">
                    <div class="col-md-4">
                        <label for="input7" class="ms-2 position-absolute"
                                    style="margin-top: -0.25rem !important">
                                    <span class="h6 small bg-white text-muted px-1">Color avance gráfica*</span>
                        </label>
                        <input type="color" placeholder="Selecciona" id="color5"
                        class="form-control mt-2" required >
                    </div>
                    <div class="col-md-4">
                        <label for="input8" class="ms-2 position-absolute"
                                    style="margin-top: -0.25rem !important">
                                    <span class="h6 small bg-white text-muted px-1">Color compromiso gráfica*</span>
                        </label>
                        <input type="color" placeholder="Selecciona" id="color6"
                        class="form-control mt-2" required  >
                    </div>
                    <div class="col-md-4">
                        <label for="input9" class="ms-2 position-absolute"
                                    style="margin-top: -0.25rem !important">
                                    <span class="h6 small bg-white text-muted px-1">Color proyectado gráfica*</span>
                        </label>
                        <input type="color" placeholder="Selecciona" id="color7"
                        class="form-control mt-2" equired >
                    </div>
                </div>
                <div *ngIf="idEmpresa != 0" class="d-grid gap-2 d-md-flex justify-content-md-end align-btn">
                    <button type="button" (click)="abrirModal(this.modal)" class="btn btn-primary boton3">+ Nuevo Nivel</button>
                </div>
                <br><br>
                 <div class="row mt-4">
                  <incentivos-tabla-registros [datos]="empresa" [wait]="wait" [columns]="columns_nueva_empresa" [actualiza]="true" [eliminar]="true" (opcupdate)="datosEmpresa($event)" (opcdelete)="datosEmpresaDelete($event)"></incentivos-tabla-registros>
                </div>
                <div class="col d-grid gap-5 pe-4 py-5 d-md-flex justify-content-md-end">
                    <button class="button mt-2 boton" type="button" (click)="dataDef()">&nbsp;&nbsp;Limpiar</button>
                    <button class="button mt-2 boton2" type="button" (click)="guardaEmpresa()">&nbsp;&nbsp;Guardar</button>
                </div>
        </div>
    </div>
</div>

<!-- Modal de previsualización Img 1-->
<div class="modal fade" id="openModalE1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-title" id="exampleModalLabel">
                <p class="f-t3">Validación de contenido</p>
                <p class="f-t4">Verifica el contenido que está subiendo</p>
            </div>
            <div class="modal-body mt-3">
                <iframe *ngIf="filePrev1" [attr.src]="filePrev1" title="Imagen 1" height="80%"
                    width="100%"></iframe>
            </div>
            <div class="modal-footer" style="border-top: 0;">
                <div class="col d-grid gap-5 d-md-flex justify-content-md-end">
                    <button class="button mt-2 boton" data-bs-dismiss="modal"
                        type="button">&nbsp;&nbsp;Cerrar</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal de previsualización Img 2-->
<div class="modal fade" id="openModalE2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-title" id="exampleModalLabel">
                <p class="f-t3">Validación de contenido</p>
                <p class="f-t4">Verifica el contenido que está subiendo</p>
            </div>
            <div class="modal-body mt-3">
                <iframe *ngIf="filePrev2" [attr.src]="filePrev2" title="Imagen 2"  height="80%"
                    width="100%"></iframe>
            </div>
            <div class="modal-footer" style="border-top: 0;">
                <div class="col d-grid gap-5 d-md-flex justify-content-md-end">
                    <button class="button mt-2 boton" data-bs-dismiss="modal"
                        type="button">&nbsp;&nbsp;Cerrar</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal de previsualización Img 3-->
<div class="modal fade" id="openModalE3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-title" id="exampleModalLabel">
                <p class="f-t3">Validación de contenido</p>
                <p class="f-t4">Verifica el contenido que está subiendo</p>
            </div>
            <div class="modal-body mb-3">
                <iframe *ngIf="filePrev3" [attr.src]="filePrev3" title="Imagen 3"  height="80%"
                    width="100%"></iframe>
            </div>
            <div class="modal-footer" style="border-top: 0;">
                <div class="col d-grid gap-5 d-md-flex justify-content-md-end">
                    <button class="button mt-2 boton" data-bs-dismiss="modal"
                        type="button">&nbsp;&nbsp;Cerrar</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal de previsualización Img 4 - Pleca -->
<div class="modal fade" id="openModalE4" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-title" id="exampleModalLabel">
                <p class="f-t3">Validación de contenido</p>
                <p class="f-t4">Verifica el contenido que está subiendo</p>
            </div>
            <div class="modal-body mb-3">
                <iframe *ngIf="filePrev4" [attr.src]="filePrev4" title="Imagen 4"  height="80%"
                      width="100%"></iframe>
            </div>
            <div class="modal-footer" style="border-top: 0;">
                <div class="col d-grid gap-5 d-md-flex justify-content-md-end">
                    <button class="button mt-2 boton" data-bs-dismiss="modal"
                        type="button">&nbsp;&nbsp;Cerrar</button>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Modal de previsualización Img 5 - Gráfica -->
<div class="modal fade" id="openModalE5" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-title" id="exampleModalLabel">
                <p class="f-t3">Validación de contenido</p>
                <p class="f-t4">Verifica el contenido que está subiendo</p>
            </div>
            <div class="modal-body mb-3">
                <iframe *ngIf="filePrev5" [attr.src]="filePrev5" title="Gráfica"  height="80%"
                      width="100%"></iframe>
            </div>
            <div class="modal-footer" style="border-top: 0;">
                <div class="col d-grid gap-5 d-md-flex justify-content-md-end">
                    <button class="button mt-2 boton" data-bs-dismiss="modal"
                        type="button">&nbsp;&nbsp;Cerrar</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal de incentivos-->
<ng-template #modalincentivos let-modal>
        <div class="modal-content">
            <div class="modal-title" id="exampleModalLabel">
                <p class="f-t3">Niveles de incentivos</p>
                <p class="f-t4">*Campos obligatorios</p>
            </div>
            <br><br><br>
            <div class="modal-body">
                <input type="hidden" [(ngModel)]="idEmpresa2">
                <div class="row mb-4 mt-5">
                    <div class="col-12">
                        <label for="input1" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                            <span class="h6 small bg-white text-muted px-1">Estatus*</span>
                        </label>
                        <select class="form-control form-select mt-2" id="input1" required  [(ngModel)]="statusEmpresaNivel">
                            <option *ngFor="let sE of status_empresa" [value]="sE.id">{{sE.nombre}}</option>
                        </select>
                    </div>
                </div>
                <div class="row mb-2 mt-4">
                    <div class="col-12">
                        <input type="text" placeholder="Nombre*" class="form-control" required [(ngModel)]="nombreEmpresaNivel">
                    </div>
                </div>
                <div class="row mb-2 mt-4">
                    <div class="col-12">
                        <input type="text" placeholder="Descripción*" class="form-control" required [(ngModel)]="descripcionEmpresaNivel">
                    </div>
                </div>
                <div class="row mb-2 mt-4">
                    <div class="col-md-12">
                        <label for="input6" class="ms-2 position-absolute"
                                    style="margin-top: -0.25rem !important">
                                    <span class="h6 small bg-white text-muted px-1">Color*</span>
                        </label>
                        <input type="color" placeholder="Selecciona" id="colorNv"
                        class="form-control mt-2" required >
                    </div>
                </div>
            </div>
            <div class="modal-footer" style="border-top: 0;">
                <div class="col d-grid gap-5 d-md-flex justify-content-md-end">
                    <button class="btn mt-2 boton" type="button"  (click)="cerrarModal()">&nbsp;&nbsp;Cerrar</button>
                    <button class="btn mt-2 boton2" type="button" (click)="guardaEmpresaNivel()">&nbsp;&nbsp;Guardar</button>
                </div>
            </div>
        </div>
</ng-template>

