<div class="loading-indicator" *ngIf="funcion.visibility | async">
    <img src="/assets/img/spinner.gif" alt="">
</div>


<div style="padding: 3.5em 4em 1em 5em;">
    <div class="overlay">
        <div class="row " style="padding-left: 0.5em;">
            <div class="row">
                <div class="col-6 titulo" style="text-align: left;">
                    <span>Reporte de Mi Perfil</span>
                </div>
                <div class="col-6 subtitulo" style="text-align: right;">
                    <span>Periodo: {{periodo_perfil}}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row m-1" style="padding: 1em 2em 1em 2em;">
    <div class="col-12" style="padding-top: 2em;">
        <div class="row segundaseccion" style="left: 10px;">
            <div class="row m-1" style="padding-left: 1em;">
                <div class="col-6" style="    padding-left: 0em;">
                    <div *ngIf="inputDataPerfil">
                        <apx-chart
                            [series]="chartDonautPerfil.seriesPri"
                            [chart]="chartDonautPerfil.chart"
                            [dataLabels]="chartDonautPerfil.dataLabels"
                            [plotOptions]="chartDonautPerfil.plotOptions"
                            [responsive]="chartDonautPerfil.responsive"
                            [colors]="chartDonautPerfil.colors"
                            [labels]="chartDonautPerfil.labels"
                            [legend]="chartDonautPerfil.legend"
                        ></apx-chart>
                    </div>
                </div>
                <div class="col-6" style="margin-top: 30px;padding-left: 0em;">
                    <div *ngIf="inputDataPerfil">
                        <div class="row">
                            <div class="col-2" style="border-bottom: 1px solid #D9D9D9;">
                                <div style="text-align: right; margin-left: -18px; ">
                                    <span class="labelgen" style="font-size: 18px;color: #4DAB9E;">{{inputDataPerfil.miperfil.completado |
                                        number:'1.0':'en-US'}}</span>
                                </div>
                            </div>
                            <div class="col-2" style="border-bottom: 1px solid #D9D9D9;"></div>
                            <div class="col-5" style="border-bottom: 1px solid #D9D9D9;">
                                <div style="text-align: left; margin-left: -18px; ">
                                    <span class="labelgen" style="font-size: 18px;color: #4DAB9E; ">Perfil completado</span>
                                </div>
                            </div>
                            <div class="col-3" style="border-bottom: 1px solid #D9D9D9;">
                                <div style="text-align: right; margin-left: -18px; ">
                                    <span class="labelgen" style="font-size: 18px;color: #4DAB9E; ">{{inputDataPerfil.miperfil.porcien_completado}}&nbsp;%</span>
                                </div>
                            </div>
                            <div class="col-2" style="border-bottom: 1px solid #D9D9D9;">
                                <div style="text-align: right; margin-left: -18px; ">
                                    <span class="labelgen" style="font-size: 18px;color: #8C6CA6;">{{inputDataPerfil.miperfil.foto |
                                        number:'1.0':'en-US'}}</span>
                                </div>
                            </div>
                            <div class="col-2" style="border-bottom: 1px solid #D9D9D9;"></div>
                            <div class="col-5" style="border-bottom: 1px solid #D9D9D9;">
                                <div style="text-align: left; margin-left: -18px; ">
                                    <span class="labelgen" style="font-size: 18px;color: #8C6CA6; ">Foto de Perfil</span>
                                </div>
                            </div>
                            <div class="col-3" style="border-bottom: 1px solid #D9D9D9;">
                                <div style="text-align: right; margin-left: -18px; ">
                                    <span class="labelgen" style="font-size: 18px;color: #8C6CA6; ">{{inputDataPerfil.miperfil.porcien_foto}}&nbsp;%</span>
                                </div>
                            </div>
                            <div class="col-2" style="border-bottom: 1px solid #D9D9D9;">
                                <div style="text-align: right; margin-left: -18px; ">
                                    <span class="labelgen" style="font-size: 18px;color: #DB699C;">{{inputDataPerfil.miperfil.portada |
                                        number:'1.0':'en-US'}}</span>
                                </div>
                            </div>
                            <div class="col-2" style="border-bottom: 1px solid #D9D9D9;"></div>
                            <div class="col-5" style="border-bottom: 1px solid #D9D9D9;">
                                <div style="text-align: left; margin-left: -18px; ">
                                    <span class="labelgen" style="font-size: 18px;color: #DB699C; ">Foto de Portada</span>
                                </div>
                            </div>
                            <div class="col-3" style="border-bottom: 1px solid #D9D9D9;">
                                <div style="text-align: right; margin-left: -18px; ">
                                    <span class="labelgen" style="font-size: 18px;color: #DB699C; ">{{inputDataPerfil.miperfil.porcien_portada}}&nbsp;%</span>
                                </div>
                            </div>
                            <div class="col-2" style="border-bottom: 1px solid #D9D9D9;">
                                <div style="text-align: right; margin-left: -18px; ">
                                    <span class="labelgen" style="font-size: 18px;color: #EBA875;">{{inputDataPerfil.miperfil.misueno |
                                        number:'1.0':'en-US'}}</span>
                                </div>
                            </div>
                            <div class="col-2" style="border-bottom: 1px solid #D9D9D9;"></div>
                            <div class="col-5" style="border-bottom: 1px solid #D9D9D9;">
                                <div style="text-align: left; margin-left: -18px; ">
                                    <span class="labelgen" style="font-size: 18px;color: #EBA875; ">Sueño</span>
                                </div>
                            </div>
                            <div class="col-3" style="border-bottom: 1px solid #D9D9D9;">
                                <div style="text-align: right; margin-left: -18px; ">
                                    <span class="labelgen" style="font-size: 18px;color: #EBA875; ">{{inputDataPerfil.miperfil.porcien_misueno}}&nbsp;%</span>
                                </div>
                            </div>
                            <div class="col-2" style="border-bottom: 1px solid #D9D9D9;">
                                <div style="text-align: right; margin-left: -18px; ">
                                    <span class="labelgen" style="font-size: 18px;color: #2C678D;">{{inputDataPerfil.miperfil.incompleto |
                                        number:'1.0':'en-US'}}</span>
                                </div>
                            </div>
                            <div class="col-2" style="border-bottom: 1px solid #D9D9D9;"></div>
                            <div class="col-5" style="border-bottom: 1px solid #D9D9D9;">
                                <div style="text-align: left; margin-left: -18px; ">
                                    <span class="labelgen" style="font-size: 18px;color: #2C678D; ">Colaboradores sin perfil completado</span>
                                </div>
                            </div>
                            <div class="col-3" style="border-bottom: 1px solid #D9D9D9;">
                                <div style="text-align: right; margin-left: -18px; ">
                                    <span class="labelgen" style="font-size: 18px;color: #2C678D; ">{{inputDataPerfil.miperfil.porcien_incompleto}}&nbsp;%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    <div style="margin: 25px;"></div>

    <div class="col-12">
        <div class="row segundaseccion" style="left: 10px;">
            <div class="row" style="padding-left: 1em;">
                <div class="col-2 filtroPerfil" (click)="activaFiltro()">
                    <img src="/assets/images/filtro.png" alt="" style="width: 15px;margin-left: -6px;margin-top: 3px;">
                </div>
                <div class="col-3 filtroSelect" *ngIf="filtroPerfil" >
                    <select matNativeControl [(ngModel)]="filtroselected_perfil" class="select"
                    (click)="dataColaboradoresCompleto()">
                    <option *ngFor="let f of filtros_perfil" [value]="f.id" style="font-size: 1.2em;">
                    {{ f.value }}
                    </option>
                    </select>
                </div>
                <div class="col-10" style="margin-top: 11px; margin-left: 55px;">
                    <span class="cardTitle">Colaboradores con perfil completado</span>
                </div>
                <div class="col-12">
                     <div *ngIf="inputDataPerfil">
                        <apx-chart [series]="chartPerfilCompleto.series" [chart]="chartPerfilCompleto.chart"
                            [xaxis]="chartPerfilCompleto.xaxis" [stroke]="chartPerfilCompleto.stroke"
                            [colors]="chartPerfilCompleto.colors"
                            [dataLabels]="chartPerfilCompleto.dataLabels"
                            [legend]="chartPerfilCompleto.legend" [markers]="chartPerfilCompleto.markers"
                            [grid]="chartPerfilCompleto.grid" [yaxis]="chartPerfilCompleto.yaxis"
                            ></apx-chart>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
