<!-- Grárficas del reporte -->
<div class="loading-indicator" *ngIf="funcion.visibility | async">
  <img src="/assets/img/spinner.gif" alt="" />
</div>

<div *ngIf="!vistaPrevia">
  <div class="overlay segundaseccion">
    <div class="row m-1" style="padding-left: 0.5em">
      <div *ngFor="let item of arregloPre.preguntas; let id = index">
        <div>
          <div class="row" *ngIf="item.tipo == 1">
            <div
              class="col-6 info"
              style="text-align: left; margin-top: 36px; cursor: pointer"
            >
              <span (click)="agregaPregunta()" style="cursor: pointer"
                >+ Pregunta</span
              >
            </div>
            <div class="col-6"></div>
            <div class="col-9"></div>
            <div class="col-3">
              <div class="row">
                <div class="col-6"></div>
                <div
                  class="col-2"
                  style="text-align: right"
                  *ngIf="arregloPre.preguntas.length > 1"
                >
                  <img
                    (click)="borrarPregunta(item)"
                    src="/assets/images/trashh.png"
                    alt=""
                    style="width: 15px; margin-top: -5px; cursor: pointer"
                  />
                </div>
                <div class="col-2" *ngIf="arregloPre.preguntas.length > 1">
                  <img
                    (click)="ordenarArribaAbajo(item, 1)"
                    src="/assets/images/flearriba.png"
                    alt=""
                    style="
                      width: 10px;
                      margin-top: -5px;
                      cursor: pointer;
                      margin-left: 22px;
                    "
                  />
                </div>
                <div class="col-2" *ngIf="arregloPre.preguntas.length > 1">
                  <img
                    (click)="ordenarArribaAbajo(item, 2)"
                    src="/assets/images/fleabajo.png"
                    alt=""
                    style="width: 10px; margin-top: -5px; cursor: pointer"
                  />
                </div>
              </div>
            </div>
            <div style="margin-top: 10px"></div>
            <div class="col-12">
              <table style="width: 102%; margin-left: -13px" #tabla1>
                <caption></caption>
                <tr>
                  <th
                    scope="col"
                    style="width: 20%"
                    class="seccionTipoPre"
                    [style.background-color]="tipopre.fondo + '!important'"
                    *ngFor="let tipopre of item.tipoPregunta; let id = index"
                  >
                    <div
                      (click)="validatipPregunta(tipopre, item)"
                      style="
                        text-align: center;
                        margin-top: 4px;
                        cursor: pointer;
                      "
                    >
                      <span
                        class="laberTipoPre"
                        [style.color]="tipopre.color + '!important'"
                        >{{ tipopre.nombre }}</span
                      >
                    </div>
                  </th>
                </tr>
              </table>
            </div>
            <div style="margin-top: 20px"></div>
            <div
              class="col-1 numeroPre"
              style="margin-top: 16px; margin-left: -10px"
            >
              {{ item.orden }} .
            </div>
            <div class="col-11">
              <input
                class="seccionPreResp labelPreResp"
                #pregunta
                (blur)="valorPregunta(item, pregunta.value)"
                value="{{ item.pregunta }}"
                type="text"
                placeholder="Pregunta"
              />
            </div>
            <div class="col-1" style="margin-left: -10px"></div>
            <div class="col-11">
              <input
                class="seccionPreResp labelPreResp"
                type="text"
                #retro
                (blur)="valorRetro(item, retro.value)"
                value="{{ item.retroalimentacion }}"
                placeholder="Retroalimentación"
              />
            </div>
            <div class="col-1"></div>
            <div *ngFor="let radios of item.respuestas.radios; let id = index">
              <div class="row col-11" *ngIf="radios.idradio !== -1">
                <div class="col-1" style="margin-top: 21px">
                  <img
                    src="/assets/images/elipse.png"
                    alt=""
                    style="width: 17px; margin-left: 26px"
                  />
                </div>
                <div class="col-11">
                  <input
                    class="seccionLabelradios labelPreResp"
                    #radio
                    (blur)="valorRadio(item, radio.value, radios)"
                    value="{{ radios.nombreradio }}"
                    type="text"
                    placeholder="Opcion {{ radios.idradio }}"
                  />
                </div>
              </div>
              <div class="row col-11" *ngIf="radios.idradio == -1">
                <div class="col-1" style="margin-top: 21px">
                  <img
                    src="/assets/images/elipse.png"
                    alt=""
                    style="width: 17px; margin-left: 26px"
                  />
                </div>
                <div class="col-11">
                  <label class="seccionLabelradios labelPreResp">Otras</label>
                </div>
              </div>
            </div>
            <div style="margin-top: 25px"></div>
            <div class="col-2">
              <span
                class="labelagregar"
                (click)="agregaRadios(item)"
                style="margin-left: 26px; cursor: pointer"
                >+ Agregar opción</span
              >
            </div>
            <div class="col-4" *ngIf="item.apartadootra">
              <span
                class="labelagregar"
                (click)="agregaRadioOtra(item)"
                style="cursor: pointer"
                >+Agregar la opción “Otras”</span
              >
            </div>
            <div style="margin-top: 10px"></div>

            <hr style="width: 99%" />
            <div style="margin-top: 10px"></div>
            <div class="col-3">
              <p class="labelPreResp" style="margin-left: 30px">
                Configuración Respueta Correcta
              </p>
            </div>
            <div class="col-3">
              <section>
                <mat-label ngClass="labelBeforeSlide" style="position: absolute"
                  >No</mat-label
                >
                <mat-slide-toggle
                  class="example-margin"
                  style="margin-left: 2.5em"
                  (change)="respuestaCorrecta($event, item)"
                  checked="{{ item.respuestacorrecta }}"
                >
                  <label
                    class="form-check-label"
                    for="flexSwitchCheckDefault"
                    class="labelPreResp"
                    style="margin-left: 12px"
                    >Si</label
                  >
                </mat-slide-toggle>
              </section>
            </div>
            <div class="col-3" *ngIf="!item.configurarespuesta"></div>
            <div class="col-3" *ngIf="item.configurarespuesta">
              <section>
                <mat-slide-toggle
                  class="example-margin"
                  (change)="varias_respuestas($event, item)"
                  checked="{{ item.variasrespuestas }}"
                >
                  <label
                    class="form-check-label"
                    for="flexSwitchCheckDefault"
                    class="labelPreResp"
                    style="margin-left: 12px"
                    >Varias respuestas</label
                  >
                </mat-slide-toggle>
              </section>
            </div>
            <div class="col-3" style="text-align: right">
              <section>
                <mat-slide-toggle
                  class="example-margin"
                  (change)="valorObligatorio($event, item)"
                  checked="{{ item.obligatorio }}"
                >
                  <label
                    class="form-check-label"
                    for="flexSwitchCheckDefault"
                    class="labelPreResp"
                    style="margin-left: 12px"
                    >Obligatoria</label
                  >
                </mat-slide-toggle>
              </section>
            </div>
            <div *ngIf="item.configurarespuesta">
              <div *ngIf="!item.multiple">
                <div
                  *ngFor="let radios of item.respuestas.radios; let id = index"
                >
                  <div class="row col-12" *ngIf="radios.idradio !== -1">
                    <div
                      class="col-1"
                      style="margin-top: 11px; margin-left: 30px"
                    >
                      <button
                        type="button"
                        class="radio"
                        [style.background-color]="
                          radios.fondorad + '!important'
                        "
                        (click)="validaRespuesta(item, radios)"
                      ></button>
                    </div>
                    <div class="col-6">
                      <label
                        class="seccionLabelradiosResp labelPreResp"
                        style="margin-top: 8px; margin-left: -45px"
                        type="text"
                        >{{ radios.nombreradio }}</label
                      >
                    </div>
                  </div>
                  <div class="row col-12" *ngIf="radios.idradio == -1">
                    <div
                      class="col-1"
                      style="margin-top: 11px; margin-left: 30px"
                    >
                      <button
                        type="button"
                        class="radio"
                        [style.background-color]="
                          radios.fondorad + '!important'
                        "
                        (click)="validaRespuesta(item, radios)"
                      ></button>
                    </div>
                    <div class="col-6" style="margin-top: 2px">
                      <label
                        class="seccionLabelradiosResp labelPreResp"
                        style="margin-top: 8px; margin-left: -45px"
                        type="text"
                        >Otras</label
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="item.multiple">
                <div
                  *ngFor="let radios of item.respuestas.radios; let id = index"
                >
                  <div class="row col-12" *ngIf="radios.idradio !== -1">
                    <div
                      class="col-1"
                      style="margin-top: 11px; margin-left: 30px"
                    >
                      <button
                        type="button"
                        class="radio"
                        [style.background-color]="
                          radios.fondorad + '!important'
                        "
                        (click)="validaRespuesta(item, radios)"
                      ></button>
                    </div>
                    <div class="col-6">
                      <label
                        class="seccionLabelradiosResp labelPreResp"
                        style="margin-top: 8px; margin-left: -45px"
                        type="text"
                        >{{ radios.nombreradio }}</label
                      >
                    </div>
                  </div>
                  <div class="row col-12" *ngIf="radios.idradio == -1">
                    <div
                      class="col-1"
                      style="margin-top: 11px; margin-left: 30px"
                    >
                      <button
                        type="button"
                        class="radio"
                        [style.background-color]="
                          radios.fondorad + '!important'
                        "
                        (click)="validaRespuesta(item, radios)"
                      ></button>
                    </div>
                    <div class="col-6" style="margin-top: 2px">
                      <label
                        class="seccionLabelradiosResp labelPreResp"
                        style="margin-top: 8px; margin-left: -45px"
                        type="text"
                        >Otras</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="item.tipo == 2">
            <div class="col-12 info" style="text-align: left; margin-top: 36px">
              <span (click)="agregaPregunta()">+ Pregunta</span>
            </div>
            <div class="col-9"></div>
            <div class="col-3">
              <div class="row">
                <div class="col-6"></div>
                <div
                  class="col-2"
                  style="text-align: right"
                  *ngIf="arregloPre.preguntas.length > 1"
                >
                  <img
                    (click)="borrarPregunta(item)"
                    src="/assets/images/trashh.png"
                    alt=""
                    style="width: 15px; margin-top: -5px; cursor: pointer"
                  />
                </div>
                <div class="col-2" *ngIf="arregloPre.preguntas.length > 1">
                  <img
                    (click)="ordenarArribaAbajo(item, 1)"
                    src="/assets/images/flearriba.png"
                    alt=""
                    style="
                      width: 10px;
                      margin-top: -5px;
                      cursor: pointer;
                      margin-left: 22px;
                    "
                  />
                </div>
                <div class="col-2" *ngIf="arregloPre.preguntas.length > 1">
                  <img
                    (click)="ordenarArribaAbajo(item, 2)"
                    src="/assets/images/fleabajo.png"
                    alt=""
                    style="width: 10px; margin-top: -5px; cursor: pointer"
                  />
                </div>
              </div>
            </div>
            <div style="margin-top: 10px"></div>
            <div class="col-12">
              <table style="width: 102%; margin-left: -13px" #tabla1>
                <caption></caption>
                <tr>
                  <th
                    scope="col"
                    style="width: 20%"
                    class="seccionTipoPre"
                    [style.background-color]="tipopre.fondo + '!important'"
                    *ngFor="let tipopre of item.tipoPregunta; let id = index"
                  >
                    <div
                      (click)="validatipPregunta(tipopre, item)"
                      style="text-align: center; margin-top: 4px"
                    >
                      <span
                        class="laberTipoPre"
                        [style.color]="tipopre.color + '!important'"
                        >{{ tipopre.nombre }}</span
                      >
                    </div>
                  </th>
                </tr>
              </table>
            </div>
            <div style="margin-top: 20px"></div>
            <div
              class="col-1 numeroPre"
              style="margin-top: 16px; margin-left: -10px"
            >
              {{ item.orden }} .
            </div>
            <div class="col-11">
              <input
                class="seccionPreResp labelPreResp"
                #pregunta
                (blur)="valorPregunta(item, pregunta.value)"
                type="text"
                placeholder="Pregunta"
                value="{{ item.pregunta }}"
              />
            </div>
            <div style="margin-top: 25px"></div>
            <div class="col-12" style="text-align: left; margin-left: 34px">
              <table style="width: 50%" #tabla1>
                <caption></caption>
                <tr>
                  <th
                    scope="col"
                    style="width: 5%"
                    *ngFor="let imgen of item.objimagen"
                  >
                    <div style="margin-top: 4px">
                      <img
                        src="{{ imgen.imagen }}"
                        style="width: 25px; height: 25px"
                        alt=""
                      />
                    </div>
                  </th>
                </tr>
              </table>
            </div>
            <div class="col-2" style="margin-top: 8px; margin-left: 36px">
              <span class="laberTipoPre">Niveles</span>
            </div>
            <div class="col-3" style="margin-top: 3px; margin-left: -68px">
              <div class="select" id="select" (click)="selectorNivel(item)">
                <div
                  id="sel"
                  class=""
                  style="display: flex !important"
                  *ngFor="let nivel of item.nivelesIni"
                >
                  <div style="margin-left: 6px; margin-top: 2px">
                    <span class="laberTipoPre">{{ nivel.descripcion }}</span>
                  </div>
                </div>
                <img
                  src="/assets/images/select.png"
                  style="width: 10px; height: 6px"
                  alt=""
                />
              </div>
              <div
                class="opciones"
                *ngIf="item.nivelSele"
                style="position: absolute; width: 21%"
              >
                <div class="opcion">
                  <div
                    id="ele"
                    (click)="selectorOptionNivel(niveles, item)"
                    class="contenido-opcion"
                    *ngFor="let niveles of item.nivelesobj"
                  >
                    <div>
                      <span class="laberTipoPre" style="margin-left: 10px">{{
                        niveles.descripcion
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-2" style="margin-top: 8px; margin-left: 36px">
              <span class="laberTipoPre" style="margin-left: 17px"
                >Símbolo</span
              >
            </div>
            <div class="col-3" style="margin-top: 3px; margin-left: -68px">
              <div class="select" id="select" (click)="selectorSimb(item)">
                <div
                  id="sel"
                  class=""
                  style="display: flex !important"
                  *ngFor="let simbolo of item.simbolosIni"
                >
                  <img
                    src="{{ simbolo.imagen }}"
                    *ngIf="simbolo.imagen"
                    style="
                      width: 19px;
                      height: 19px;
                      margin-left: 2px;
                      margin-top: 3px;
                    "
                    alt=""
                  />
                  <div style="margin-left: 6px; margin-top: 2px">
                    <span class="laberTipoPre">{{ simbolo.descripcion }}</span>
                  </div>
                </div>
                <img
                  src="/assets/images/select.png"
                  style="width: 10px; height: 6px"
                  alt=""
                />
              </div>
              <div
                class="opciones"
                *ngIf="item.simboloSele"
                style="position: absolute; width: 21%"
              >
                <div class="opcion">
                  <div
                    id="ele"
                    (click)="selectorOption(simbolo, item)"
                    class="contenido-opcion"
                    *ngFor="let simbolo of item.simbolos"
                  >
                    <img
                      src="{{ simbolo.imagen }}"
                      style="
                        width: 20px;
                        height: 20px;
                        margin-top: 1px;
                        margin-left: 2px;
                      "
                      alt=""
                    />
                    <div>
                      <span class="laberTipoPre" style="margin-left: 10px">{{
                        simbolo.descripcion
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-top: 25px"></div>
            <hr style="width: 99%" />
            <div style="margin-top: 10px"></div>
            <div class="col-9"></div>
            <div class="col-3" style="text-align: right">
              <section>
                <mat-slide-toggle
                  class="example-margin"
                  (change)="valorObligatorio($event, item)"
                  checked="{{ item.obligatorio }}"
                >
                  <label
                    class="form-check-label"
                    for="flexSwitchCheckDefault"
                    class="labelPreResp"
                    style="margin-left: 12px"
                    >Obligatoria</label
                  >
                </mat-slide-toggle>
              </section>
            </div>
          </div>
          <div class="row" *ngIf="item.tipo == 3">
            <div class="col-12 info" style="text-align: left; margin-top: 36px">
              <span (click)="agregaPregunta()">+ Pregunta</span>
            </div>
            <div class="col-9"></div>
            <div class="col-3">
              <div class="row">
                <div class="col-6"></div>
                <div
                  class="col-2"
                  style="text-align: right"
                  *ngIf="arregloPre.preguntas.length > 1"
                >
                  <img
                    (click)="borrarPregunta(item)"
                    src="/assets/images/trashh.png"
                    alt=""
                    style="width: 15px; margin-top: -5px; cursor: pointer"
                  />
                </div>
                <div class="col-2" *ngIf="arregloPre.preguntas.length > 1">
                  <img
                    (click)="ordenarArribaAbajo(item, 1)"
                    src="/assets/images/flearriba.png"
                    alt=""
                    style="
                      width: 10px;
                      margin-top: -5px;
                      cursor: pointer;
                      margin-left: 22px;
                    "
                  />
                </div>
                <div class="col-2" *ngIf="arregloPre.preguntas.length > 1">
                  <img
                    (click)="ordenarArribaAbajo(item, 2)"
                    src="/assets/images/fleabajo.png"
                    alt=""
                    style="width: 10px; margin-top: -5px; cursor: pointer"
                  />
                </div>
              </div>
            </div>
            <div style="margin-top: 10px"></div>
            <div class="col-12">
              <table style="width: 102%; margin-left: -13px" #tabla1>
                <caption></caption>
                <tr>
                  <th
                    scope="col"
                    style="width: 20%"
                    class="seccionTipoPre"
                    [style.background-color]="tipopre.fondo + '!important'"
                    *ngFor="let tipopre of item.tipoPregunta; let id = index"
                  >
                    <div
                      (click)="validatipPregunta(tipopre, item)"
                      style="
                        text-align: center;
                        margin-top: 4px;
                        cursor: pointer;
                      "
                    >
                      <span
                        class="laberTipoPre"
                        [style.color]="tipopre.color + '!important'"
                        >{{ tipopre.nombre }}</span
                      >
                    </div>
                  </th>
                </tr>
              </table>
            </div>
            <div style="margin-top: 20px"></div>
            <div
              class="col-1 numeroPre"
              style="margin-top: 16px; margin-left: -10px"
            >
              {{ item.orden }} .
            </div>
            <div class="col-11">
              <input
                class="seccionPreResp labelPreResp"
                #pregunta
                (blur)="valorPregunta(item, pregunta.value)"
                value="{{ item.pregunta }}"
                type="text"
                placeholder="Pregunta"
              />
            </div>

            <div class="col-1"></div>
            <div style="margin-top: 15px"></div>
            <div class="row">
              <div class="col-11" style="margin-left: 34px">
                <table class="table table-borderless">
                  <caption></caption>
                  <thead>
                    <tr>
                      <th scope="col"></th>

                      <th
                        scope="col"
                        *ngFor="
                          let filas of item.respuestas.filas;
                          let id = index
                        "
                      >
                        <input
                          class="seccionInputsOptions labelPreResp"
                          #fila
                          value="{{ filas.filasnombre }}"
                          (blur)="valorPreguntaFila(item, fila.value, filas)"
                          type="text"
                          placeholder="Opcion {{ filas.idfila }}"
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    *ngFor="
                      let columnas of item.respuestas.columnas;
                      let id = index
                    "
                  >
                    <tr style="background: rgba(236, 236, 236, 0.45)">
                      <th scope="row">
                        <input
                          class="seccionInputsOptions labelPreResp"
                          #columna
                          value="{{ columnas.columnasnombre }}"
                          (blur)="
                            valorPreguntaColumna(item, columna.value, columnas)
                          "
                          type="text"
                          placeholder="Instrucción {{ columnas.idcolumna }}"
                        />
                      </th>

                      <td
                        *ngFor="
                          let filas of item.respuestas.filas;
                          let id = index
                        "
                      >
                        <img
                          src="/assets/images/elipse.png"
                          alt=""
                          style="width: 17px; margin-left: 18px"
                        />
                      </td>
                    </tr>
                    <div style="margin-top: 8px"></div>
                  </tbody>
                </table>
              </div>
              <div class="col-1" style="margin-left: -40px; margin-top: 9px">
                <img
                  (click)="agregaFila(item)"
                  src="/assets/images/cross.png"
                  alt=""
                />
              </div>
            </div>
            <div style="margin-top: 10px"></div>
            <div class="col-2" style="margin-left: 24px">
              <span
                class="labelagregar"
                (click)="agregaColumna(item)"
                style="margin-left: 12px"
                >+ Agregar instrucción</span
              >
            </div>
            <div style="margin-top: 10px"></div>
            <hr style="width: 99%" />
            <div style="margin-top: 10px"></div>
            <div class="col-9"></div>
            <div class="col-3" style="text-align: right">
              <section>
                <mat-slide-toggle
                  class="example-margin"
                  (change)="valorObligatorio($event, item)"
                  checked="{{ item.obligatorio }}"
                >
                  <label
                    class="form-check-label"
                    for="flexSwitchCheckDefault"
                    class="labelPreResp"
                    style="margin-left: 12px"
                    >Obligatoria</label
                  >
                </mat-slide-toggle>
              </section>
            </div>
          </div>
          <div class="row" *ngIf="item.tipo == 4">
            <div
              class="col-6 info"
              style="text-align: left; margin-top: 36px; cursor: pointer"
            >
              <span (click)="agregaPregunta()" style="cursor: pointer"
                >+ Pregunta</span
              >
            </div>
            <div class="col-6"></div>
            <div class="col-9"></div>
            <div class="col-3">
              <div class="row">
                <div class="col-6"></div>
                <div
                  class="col-2"
                  style="text-align: right"
                  *ngIf="arregloPre.preguntas.length > 1"
                >
                  <img
                    (click)="borrarPregunta(item)"
                    src="/assets/images/trashh.png"
                    alt=""
                    style="width: 15px; margin-top: -5px; cursor: pointer"
                  />
                </div>
                <div class="col-2" *ngIf="arregloPre.preguntas.length > 1">
                  <img
                    (click)="ordenarArribaAbajo(item, 1)"
                    src="/assets/images/flearriba.png"
                    alt=""
                    style="
                      width: 10px;
                      margin-top: -5px;
                      cursor: pointer;
                      margin-left: 22px;
                    "
                  />
                </div>
                <div class="col-2" *ngIf="arregloPre.preguntas.length > 1">
                  <img
                    (click)="ordenarArribaAbajo(item, 2)"
                    src="/assets/images/fleabajo.png"
                    alt=""
                    style="width: 10px; margin-top: -5px; cursor: pointer"
                  />
                </div>
              </div>
            </div>
            <div style="margin-top: 10px"></div>
            <div class="col-12">
              <table style="width: 102%; margin-left: -13px" #tabla1>
                <caption></caption>
                <tr>
                  <th
                    scope="col"
                    style="width: 20%"
                    class="seccionTipoPre"
                    [style.background-color]="tipopre.fondo + '!important'"
                    *ngFor="let tipopre of item.tipoPregunta; let id = index"
                  >
                    <div
                      (click)="validatipPregunta(tipopre, item)"
                      style="
                        text-align: center;
                        margin-top: 4px;
                        cursor: pointer;
                      "
                    >
                      <span
                        class="laberTipoPre"
                        [style.color]="tipopre.color + '!important'"
                        >{{ tipopre.nombre }}</span
                      >
                    </div>
                  </th>
                </tr>
              </table>
            </div>
            <div style="margin-top: 20px"></div>
            <div
              class="col-1 numeroPre"
              style="margin-top: 16px; margin-left: -10px"
            >
              {{ item.orden }} .
            </div>
            <div class="col-11">
              <input
                class="seccionPreResp labelPreResp"
                #pregunta
                value="{{ item.pregunta }}"
                (blur)="valorPregunta(item, pregunta.value)"
                type="text"
                placeholder="Pregunta"
              />
            </div>
            <div class="col-1" style="margin-left: -10px"></div>
            <div class="col-11">
              <input
                class="seccionPreResp labelPreResp"
                type="text"
                #retro
                value="{{ item.retroalimentacion }}"
                (blur)="valorRetro(item, retro.value)"
                placeholder="Retroalimentación"
              />
            </div>
            <div style="margin-top: 25px"></div>

            <hr style="width: 99%" />
            <div style="margin-top: 10px"></div>
            <div class="col-9"></div>
            <div class="col-3" style="text-align: right">
              <section>
                <mat-slide-toggle
                  class="example-margin"
                  (change)="valorObligatorio($event, item)"
                  checked="{{ item.obligatorio }}"
                >
                  <label
                    class="form-check-label"
                    for="flexSwitchCheckDefault"
                    class="labelPreResp"
                    style="margin-left: 12px"
                    >Obligatoria</label
                  >
                </mat-slide-toggle>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 20px"></div>
    <div style="text-align: right; margin-right: 7px">
      <button
        type="button"
        (click)="activaVistaPrevia()"
        class="btn btn-lg me-md-2 boton"
      >
        <img
          src="/assets/images/vista.png"
          alt=""
          style="width: 15px; margin-left: -5px"
        />&nbsp;&nbsp;Vista Previa
      </button>
      <button
        type="button"
        (click)="guardarPreguntas()"
        class="btn btn-lg boton3"
      >
        Guardar
      </button>
    </div>
    <div style="margin-top: 20px"></div>
  </div>
</div>
<div *ngIf="vistaPrevia">
  <div class="overlay">
    <div class="row m-1" style="padding-left: 0.5em">
      <div
        class="col-10 info"
        *ngIf="!vistaPrevia"
        style="text-align: left; margin-top: 36px"
      >
        <span>Opciones de Ramificación</span>
      </div>
      <div
        class="col-10 info"
        *ngIf="vistaPrevia"
        style="text-align: left; margin-top: 36px"
      >
        <span>Vista previa</span>
      </div>
      <div
        class="col-2 infoAtras"
        (click)="activaRam()"
        style="text-align: right; margin-top: 36px; cursor: pointer"
      >
        <img
          src="/assets/images/fleatras.png"
          alt=""
          style="width: 10px; margin-top: -5px"
        />&nbsp;&nbsp;&nbsp;
        <span>Atrás</span>
      </div>
      <div style="margin-top: 10px"></div>
      <div class="col-12 seccionEncab"></div>
      <div style="margin-top: 15px"></div>
      <div class="cuadroRami">
        <div *ngFor="let item of arregloPre.preguntas; let id = index">
          <div class="row -1" *ngIf="item.tipo == 1">
            <div style="margin-top: 10px"></div>
            <div
              class="col-1 numeroPre"
              style="margin-top: 16px; margin-left: -10px"
            >
              {{ item.orden }} .
            </div>
            <div class="col-11" *ngIf="this.activRami">
              <label
                class="seccionLabelradiosResp labelPreResp"
                style="margin-left: -53px"
                type="text"
                >{{ item.pregunta }}</label
              >
            </div>
            <div class="col-1"></div>
            <div *ngFor="let radios of item.respuestas.radios; let id = index">
              <div class="row" *ngIf="radios.idradio !== -1">
                <div class="col-1" style="margin-top: 12px">
                  <img
                    src="/assets/images/elipse.png"
                    alt=""
                    style="width: 17px; margin-left: 26px"
                  />
                </div>
                <div class="col-6">
                  <label
                    class="seccionLabelradiosResp labelPreResp"
                    style="margin-top: 10px"
                    type="text"
                    >{{ radios.nombreradio }}</label
                  >
                </div>
                <div
                  class="col-1"
                  style="margin-top: 20px"
                  *ngIf="!vistaPrevia"
                >
                  <label class="labelPreResp">Ir a</label>
                </div>
                <div class="col-3" *ngIf="!vistaPrevia">
                  <select
                    matNativeControl
                    requerid
                    #idIra
                    (change)="agregaRamificacion(item, radios, idIra.value)"
                    class="seccionLabelradiosResp"
                  >
                    <option value="0" selected>Seleccionar</option>
                    <option value="1">Siguiente</option>
                    <option value="2">Fin de formulario</option>
                  </select>
                </div>
              </div>
              <div class="row" *ngIf="radios.idradio == -1">
                <div class="col-1" style="margin-top: 12px">
                  <img
                    src="/assets/images/elipse.png"
                    alt=""
                    style="width: 17px; margin-left: 26px"
                  />
                </div>
                <div class="col-6">
                  <label
                    class="seccionLabelradiosResp labelPreResp"
                    style="margin-top: 10px"
                    type="text"
                    >Otras</label
                  >
                </div>
                <div
                  class="col-1"
                  style="margin-top: 20px"
                  *ngIf="!vistaPrevia"
                >
                  <label class="labelPreResp">Ir a</label>
                </div>
                <div class="col-3" *ngIf="!vistaPrevia">
                  <select
                    matNativeControl
                    requerid
                    class="seccionLabelradiosResp"
                  >
                    <option value="0">Seleccionar</option>
                    <option value="1">Siguiente</option>
                    <option value="2">Fin de formulario</option>
                  </select>
                </div>
              </div>
            </div>

            <div style="margin-top: 10px"></div>
          </div>
          <div class="row" *ngIf="item.tipo == 2">
            <div style="margin-top: 10px"></div>
            <div
              class="col-1 numeroPre"
              style="margin-top: 16px; margin-left: -10px"
            >
              {{ item.orden }} .
            </div>
            <div class="col-11" *ngIf="this.activRami" style="margin-top: 10px">
              <label class="seccionResp labelPreResp" type="text">{{
                item.pregunta
              }}</label>
            </div>
            <div style="margin-top: 25px"></div>
            <div class="col-12" style="text-align: left; margin-left: 34px">
              <table style="width: 50%" #tabla1>
                <caption></caption>
                <tr>
                  <th
                    scope="col"
                    style="width: 5%"
                    *ngFor="let imgen of item.objimagen"
                  >
                    <div style="margin-top: 4px">
                      <img
                        src="{{ imgen.imagen }}"
                        style="width: 25px; height: 25px"
                        alt=""
                      />
                    </div>
                  </th>
                </tr>
              </table>
            </div>
            <div style="margin-top: 25px"></div>
          </div>

          <div class="row" *ngIf="item.tipo == 3">
            <div style="margin-top: 20px"></div>
            <div
              class="col-1 numeroPre"
              style="margin-top: 16px; margin-left: -10px"
            >
              {{ item.orden }} .
            </div>
            <div class="col-11 mt-2">
              <label
                class="labelPreResp"
                style="margin-top: 8px; margin-left: -52px"
                >{{ item.pregunta }}</label
              >
            </div>

            <div class="col-1"></div>
            <div style="margin-top: 15px"></div>
            <div class="row m-1">
              <div class="col-12">
                <table
                  class="table table-borderless"
                  style="width: 98%; margin-left: 12px"
                >
                  <caption></caption>
                  <thead>
                    <tr>
                      <th scope="col"></th>

                      <th
                        scope="col"
                        *ngFor="
                          let filas of item.respuestas.filas;
                          let id = index
                        "
                      >
                        <label class="labelPreResp">{{
                          filas.filasnombre
                        }}</label>
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    *ngFor="
                      let columnas of item.respuestas.columnas;
                      let id = index
                    "
                  >
                    <tr style="background: rgba(236, 236, 236, 0.45)">
                      <th scope="row">
                        <label class="labelPreResp">{{
                          columnas.columnasnombre
                        }}</label>
                      </th>

                      <td
                        *ngFor="
                          let filas of item.respuestas.filas;
                          let id = index
                        "
                      >
                        <img
                          src="/assets/images/elipse.png"
                          alt=""
                          style="width: 17px; margin-left: 18px"
                        />
                      </td>
                    </tr>
                    <div style="margin-top: 8px"></div>
                  </tbody>
                </table>
              </div>
            </div>
            <div style="margin-top: 25px"></div>
          </div>

          <div class="row" *ngIf="item.tipo == 4">
            <div style="margin-top: 10px"></div>
            <div
              class="col-1 numeroPre"
              style="margin-top: 16px; margin-left: -10px"
            >
              {{ item.orden }} .
            </div>
            <div class="col-11 mt-2">
              <label
                class="labelPreResp"
                style="margin-top: 8px; margin-left: -52px"
                >{{ item.pregunta }}</label
              >
            </div>

            <div
              class="col-12"
              style="text-align: left; margin-top: 30px; margin-left: 29px"
            >
              <span>
                <textarea
                  class="secciontextarea"
                  rows="5"
                  style="border: none; outline: none; width: 96%"
                ></textarea>
              </span>
            </div>
            <div style="margin-top: 25px"></div>
          </div>
        </div>
      </div>
      <div style="margin-top: 20px"></div>
    </div>
    <div style="margin-top: 20px"></div>
    <div style="text-align: right; margin-right: 7px">
      <button
        type="button"
        (click)="guardarPreguntas()"
        class="btn btn-lg boton3"
      >
        Guardar
      </button>
    </div>
  </div>
</div>
