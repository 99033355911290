import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ServiciosImp } from '../services/services';
import { Funciones } from '../classes/funciones';
import { FormGroup, FormBuilder } from '@angular/forms';
import { getMessaging, getToken } from "firebase/messaging";
import { environment } from "../../environments/environment";
import { ToastService } from '../toast-service';

@Component({
  selector: 'incentivos-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
 public myForm: FormGroup;
 errorStatus:boolean = false;
 errorMsj:any = "";
 logo_inicio:any;

  constructor(
    private router: Router,
    public service: ServiciosImp,
    public funcion: Funciones,
    public formBuilder: FormBuilder,
    public toastService: ToastService

     ) {
      let obj = {};
      this.myForm = this.createMyForm(obj);
  }

  public createMyForm(_obj: any) {
    return this.formBuilder.group({
      usn: [''],
      pss: ['']
    });
  }

  public onLogin(){
    let obj = this.myForm.value;
    let credenciales = {
      Nombre: obj.usn,//b64U,
      Password: obj.pss //b64P
    }
    this.service.authLogin(credenciales).subscribe(
      (datoslogg) => {
        if(!datoslogg.error){
          sessionStorage.setItem('nombre',datoslogg.data.Nombre);
          sessionStorage.setItem('nomina',datoslogg.data.Nomina);
          sessionStorage.setItem('sesion',datoslogg.data.token);
          this.requestPermission(datoslogg.data.Nomina);
          this.router.navigate(['resumen_post']);
        }else{
          this.funcion.hide();
          this.errorStatus = true;
          this.errorMsj = datoslogg.message;
        }
      }
    )
  }

  requestPermission(nomina: any) {
    const messaging = getMessaging();
    getToken(messaging,
      { vapidKey: environment.firebase.vapidKey }).then(
        (currentToken: any) => {
          if (currentToken) {
            let body = {
              "nomina": nomina,
              "empresa_id": 1,
              "token": currentToken
            }
            this.service.postSer('si/suscripcion/', body).subscribe((resp: any) => {
              console.log(resp);
              this.funcion.hide();
            })

          } else {
            console.log('No permisos.');
            let not = {
              title: 'No permisos.',
              body: currentToken
            }
            this.toastService.show(not);
          }
        }).catch((err: any) => {
          this.funcion.hide();
          console.log('Error: . ', err);
          let not = {
            title: 'Error',
            body: "No estan activas las notificaciones."
          }
          this.toastService.show(not);
        });
  }


}
