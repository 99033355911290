import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Funciones } from '../../classes/funciones';

@Component({
  selector: 'incentivos_importar-datos',
  templateUrl: './importar_datos.component.html',
  styleUrls: ['./importar_datos.component.css']
})
export class ImportarDatosComponent {

  nombre: any = sessionStorage.getItem('nombre');
  nomina: any = sessionStorage.getItem('nomina');
  nombreL: any;
  nominaL: any;

  constructor(
    public snackBar: MatSnackBar,
    public funcion: Funciones
  ) {
    this.nombreL = this.nombre;
    this.nominaL = this.nomina;
  }
}
