<div class="loading-indicator" *ngIf="funcion.visibility | async">
    <img src="/assets/img/spinner.gif" alt="">
</div>


<div style="padding: 3.5em 4em 1em 5em;">
    <div class="overlay">
        <div class="row " style="padding-left: 0.5em;">
            <div class="row">
                <div class="col-6 titulo" style="text-align: left;">
                    <span>Reporte del Cuestionario</span>
                </div>
            </div>
        </div>
      <div class="row " style="padding-left: 0.5em; padding-top: 3em;">
          <div class="row">
            <div class="col-6 subtitulo" style="text-align: left;">
             </div>
              <div class="col-6 subtitulo" style="text-align: right;">
                  <span>Periodo: {{periodo_cuestionario}}</span>
              </div>
              <br>
              <div class="col-12 subtitulo" style="text-align: right;">
                <span>"{{titulo_cuestionario}}"</span>
            </div>
          </div>
      </div>
  </div>
</div>

<div class="row m-1" style="padding: 1em 2em 1em 2em;">
    <div class="col-12" style="margin-left: 1em;">
        <div class="row">
            <div class="col-3">
                <div class="col-12 cardGeneral" style="background: #F2A53A;">
                    <div class="row">
                      <div class="col-12" style="margin-top: 18px;">
                        <apx-chart [series]="char_radial_par.series" [chart]="char_radial_par.chart"
                        [plotOptions]="char_radial_par.plotOptions" [labels]="char_radial_par.labels"
                        [colors]="char_radial_par.colors"></apx-chart>
                      </div>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="cardGeneral" style="background: #5E3084;">
                    <div class="row">
                        <div class="col-12 text-center">
                            <img class="formImg" src="/assets/images/colaborador.png" alt="">
                            <div class="formNum">{{inputDataCuestionario.colaboradores_asignados |
                              number:'1.0':'en-US'}}</div>
                            <span class="formLabel">Total de colaboradores<br>
                               asignados</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="col-12 cardGeneral" style="background: #4DAB9E;">
                    <div class="row">
                      <div class="col-12 text-center">
                        <img class="formImg2" src="/assets/images/colaborador_check.png" alt="">
                        <div class="formNum">{{inputDataCuestionario.colaboradores_cuestionario |
                          number:'1.0':'en-US'}}</div>
                        <span class="formLabel" style="text-align: center;">Total de colaboradores con cuestionario completado</span>
                    </div>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="col-12 cardGeneral" style="background: #2C678D;">
                    <div class="row">
                      <div class="col-12 text-center">
                        <img class="formImg" src="/assets/images/saldo.png" alt="">
                        <div class="formNum2">${{inputDataCuestionario.saldo_presupuesto |
                          currency:'':''}}</div>
                        <span class="formLabel2" style="margin-bottom: 1em;">Mi Saldo</span>
                        <br>
                        <span class="formLabel3" style="color: #FFFFFF;">Presupuesto</span>
                        <br><br>
                        <span class="formLabel3" style="color: #EBCA75; margin-right: 0.5em;">Valor del cuestionario</span>
                        <span class="formLabel3" style="color: #EBCA75; font-size: 22px; margin-left: 0.5em;">${{inputDataCuestionario.monto_qizz |
                          currency:'':''}}</span>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div style="margin: 15px;"></div>

    <div class="col-12" style="margin-left: 1em;">
      <div class="row">
          <div class="col-6">
              <div class="col-12 cardColaborador">
                  <div class="row">
                    <span class="formLabelColaboradores" style="margin-top: 2em; margin-left: 3em;">
                         <img src="/assets/images/aprobados.png" alt="">&nbsp;&nbsp;&nbsp;Colaboradores Aprobados</span>
                      <div class="col-1" style="margin-top: 3em;"></div>
                      <div class="col-4" style="margin-top: 3em; text-align: center;">
                        <span class="formLabelCantidades" >{{inputDataCuestionario.aprobados}}</span><span class="formLabelCantidades2" style="margin-left: 0.3em;">/{{inputDataCuestionario.colaboradores_cuestionario}}</span>
                        <br>
                        <span class="formLabelColaboradores2" >Colaboradores que aprobaron</span>
                      </div>
                      <div class="col-2" style="margin-top: 3em;">
                        <img class="ImgSeparador" style="margin-left: 2.3em;" src="/assets/images/separador.png" alt="">
                      </div>
                      <div class="col-4" style="margin-top: 3em;text-align: center;">
                        <span class="formLabelCantidades">{{inputDataCuestionario.calificacion_promedio_aprobados}}/10</span>
                        <br>
                        <span class="formLabelColaboradores2">Calificación promedio</span>
                      </div>
                      <div class="col-1" style="margin-top: 3em;"></div>
                      <div class="row" style="margin: 30px;">
                        <div class="col-9" style="margin-top: 1em;">
                          <mat-progress-bar mode="determinate" value="{{inputDataCuestionario.porcentaje_aprobados}}" class="matBarAprobado aprob-bar"></mat-progress-bar>
                        </div>
                        <div class="col-3">
                          <span class="formLabelProcentaje" style="margin-left: 1em;">{{inputDataCuestionario.porcentaje_aprobados}}%</span>
                        </div>
                      </div>
                      <div class="cardColaboradorSaldo">
                        <div class="col-12 text-center">
                          <div class="formNum3" style="margin-top: 0.3em;">${{inputDataCuestionario.saldo_abonado |
                            currency:'':''}}</div>
                          <div style="margin: 8px;"></div>
                          <span class="formLabel4">Mi Saldo</span>
                          <br>
                          <span class="formLabel5">Abonado Total</span>
                      </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-6">
              <div class="col-12 cardColaborador">
                  <div class="row">
                    <span class="formLabelColaboradores" style="margin-top: 2em; margin-left: 3em;">
                        <img src="/assets/images/reprobados.png" alt="">&nbsp;&nbsp;&nbsp;Colaboradores Reprobados</span>
                        <div class="col-1" style="margin-top: 3em;"></div>
                        <div class="col-4" style="margin-top: 3em; text-align: center;">
                          <span class="formLabelCantidades">{{inputDataCuestionario.reprobados}}</span><span class="formLabelCantidades2" style="margin-left: 0.3em;">/{{inputDataCuestionario.colaboradores_cuestionario}}</span>
                          <br>
                          <span class="formLabelColaboradores2">Colaboradores que reprobaron</span>
                        </div>
                        <div class="col-2" style="margin-top: 3em;">
                          <img class="ImgSeparador" style="margin-left: 2.3em;" src="/assets/images/separador.png" alt="">
                        </div>
                        <div class="col-4" style="margin-top: 3em; text-align: center;">
                          <span class="formLabelCantidades" >{{inputDataCuestionario.calificacion_promedio_reprobados}}/10</span>
                          <br>
                          <span class="formLabelColaboradores2">Calificación promedio</span>
                        </div>
                        <div class="col-1" style="margin-top: 3em;"></div>
                        <div class="row" style="margin: 30px;">
                          <div class="col-9" style="margin-top: 1em;">
                            <mat-progress-bar mode="determinate" value="{{inputDataCuestionario.porcentaje_reprobados}}" class="matBarAprobado reprob-bar"></mat-progress-bar>
                          </div>
                          <div class="col-3">
                            <span class="formLabelProcentaje" style="margin-left: 1em;">{{inputDataCuestionario.porcentaje_reprobados}}%</span>
                          </div>
                        </div>
                          <span class="formLabelColaboradoresReproTitulo" style="margin-left: 3em;">Pregunta con mayor error</span>
                          <span class="formLabelColaboradoresIntentos" style="margin-left: 4em;">Número de veces incorrectas en el último intento del colaborador</span>
                          <div class="cardColaboradorPreguntas">
                            <div class="col-12" style="margin-top: 10px;">
                              <div *ngFor="let item of inputDataCuestionario.respuestas;">
                                <div class="row">
                                  <div class="col-1 cuadroPregunta" style="margin-left: 10px;">
                                    <div class="fomatLabelPre">
                                      <span>{{item.id}}</span>
                                    </div>
                                  </div>
                                  <div class="col-11" style="text-align: left;">
                                    <span class="form-label-preguntas">{{item.pregunta}}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                  </div>
              </div>
          </div>
      </div>
  </div>


</div>
