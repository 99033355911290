import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SafePipeModule } from 'safe-pipe';

import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NavbarComponent } from './plantilla/navbar/navbar.component';
import { InterceptorService } from './services/interceptor.service';
import { ServiciosImp } from './services/services';
import { Funciones } from './classes/funciones';
import { Graficas } from './classes/graficas';
import { CommonModule, CurrencyPipe } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GridComponentComponent } from './components/grid-component/grid-component.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { MaterialModules } from './material-module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { environment } from '../environments/environment';

/** Video */
import {VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';
import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';

/**Graficas */
import { NgApexchartsModule } from "ng-apexcharts";
import { NgxPaginationModule } from 'ngx-pagination';



import {
  DevExtremeModule,
  DxDataGridModule,
  DxFileUploaderModule,
  DxCheckBoxModule,
  DxSelectBoxModule,
  DxButtonModule,
  DxDropDownBoxModule,
  DxAutocompleteModule,
  DxTemplateModule
} from 'devextreme-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AutocompleteOffDirective } from './autocomplete-off.directive';
import { NewEmpresaComponent } from './modulo_empresa/nueva_empresa/nueva_empresa.component';

// FIREBASE
import { initializeApp } from "firebase/app";
initializeApp(environment.firebase);


import { ToastsContainer } from './toasts-container.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    routingComponents,
    GridComponentComponent,
    AutocompleteOffDirective,
    ToastsContainer
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    HttpClientModule,
    ColorPickerModule,
    SafePipeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    CommonModule ,
    MatSlideToggleModule,
    MatSelectModule,
    NgxPaginationModule,

    /** Video */
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,

    // DevExtreme
    DevExtremeModule,
    DxDataGridModule,
    DxFileUploaderModule,
    DxCheckBoxModule,
    DxSelectBoxModule,
    DxButtonModule,
    DxDropDownBoxModule,
    DxAutocompleteModule,
    DxTemplateModule,
    MaterialModules,
    BrowserAnimationsModule,
    NgApexchartsModule,

  ],
  //providers: [DatePipe],
  providers: [
    CurrencyPipe,
    ServiciosImp,
    Funciones,
    Graficas,
    NewEmpresaComponent,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
