import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Funciones } from '../../classes/funciones';
import { ServiciosImp } from '../../services/services';

@Component({
  selector: 'incentivos-permisos',
  templateUrl: './permisos.component.html',
  styleUrls: ['./permisos.component.css']
})
export class PermisosComponent implements OnInit {

  @ViewChild('modalpermiso') modal: ElementRef | undefined;


  nombre: any = sessionStorage.getItem('nombre');
  nomina: any = sessionStorage.getItem('nomina');
  nombreL: any;
  nominaL: any;

  empresa: string = 'BANCO COMPARTAMOS';
  puesto: any;
  npermiso: any;
  empper: any[] = [];
  puestoper: any[] = [];
  listapuesto: any[] = [];
  listapermiso: any[] = [];
  lpuestoindex: any[] = [];
  permiso_check: any;
  activaSelect: boolean = false;
  selecPermiso: number = -1;
  permisoSelected: number = 0;
  objPermiso: any = [];
  indicadorPuesto: boolean = false;
  listaPuestoA: any = '';
  nombrePuesto: string = '';

  constructor(
    public funcion: Funciones,
    public servicios: ServiciosImp,
    private router: Router,
    public snackBar: MatSnackBar,
    public modalService: NgbModal
  ) {
    this.nombreL = this.nombre;
    this.nominaL = this.nomina;
  }

  ngOnInit(): void {
    this.cargaCatalogoEmpresaPermisos();
    this.cargaListaPuestos();
    this.cargaListaPermisos();
    this.consultaPermiso();
  }


  cargaCatalogoEmpresaPermisos() {
    this.servicios.getCatEmpresa().subscribe(datosemp => {
      if (!datosemp.error) {
        this.empper = datosemp.data;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }

  cargaListaPuestos() {
    this.servicios.getListaPuestos().subscribe(datoslistapuestos => {
      if (!datoslistapuestos.error) {
        this.carga(datoslistapuestos);
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }

  onChangeElige(obj: any, indicador: any, nomPuesto: any) {
    if (indicador == 'puesto') {
      this.listaPuestoA = '';
      this.nombrePuesto = nomPuesto;
      let nombrePuesto = {
        "puesto": this.nombrePuesto
      }
      this.servicios.postService('mesa/puestos/descripcion/', nombrePuesto).toPromise().then(
        (res: any) => {
          this.listaPuestoA = res.data.descripcion;
          this.funcion.hide();
        }
      );
      this.indicadorPuesto = true;
      this.abrirModal(obj);
      this.activaSelect = false;
    } else {
      this.abrirModal(obj);
      this.activaSelect = false;
      this.indicadorPuesto = false;
    }
  }

  validaSelect(permiso: any) {
    this.objPermiso = permiso;
    if (this.selecPermiso !== permiso.id) {
      if (this.activaSelect) {
        this.activaSelect = true;
        this.selecPermiso = permiso.id;
      } else {
        this.activaSelect = this.activaSelect ? false : true;
        this.selecPermiso = permiso.id;
      }
    } else {
      this.activaSelect = this.activaSelect ? false : true;
    }
  }

  /*Consulta en la tabla por filtro RJR*/
  public consultaPermiso() {

    let filtro1 = this.empresa != undefined ? this.empresa : '';
    let filtro2 = this.puesto != undefined ? this.puesto : '';
    this.servicios.getService(`mesa/puestos/?empresa=` + filtro1 + `&puesto=` + filtro2 + ``).toPromise().then(
      (res: any) => {
        if (res.status == 401) {
          this.router.navigate(['login']);
        }
        this.carga(res);
        this.funcion.hide();
      },
      (error: any) => {
        this.snackBar.open(error.message, 'close', {
          duration: 2500,
        });
        this.funcion.hide();
      }
    )
  }

  public carga(puestos: any) {
    this.listapuesto = puestos.data.puestos;
    if (this.listapuesto) {
      this.listapuesto.forEach((checker: any) => {
        if (checker.permisos) {
          checker.permisos.forEach((per: any) => {
            per.statuspermiso = per.valor == 1 ? true : false;
          });
        }
      });
    }
  }

  public valorPermiso(e: any, permiso: any, puesto: any) {
    if (this.listapuesto) {
      this.listapuesto.forEach((checker: any) => {
        if (checker.puesto == puesto.puesto) {
          checker.permisos.forEach((per: any) => {
            if (per.id == permiso.id) {
              per.statuspermiso = e.checked;
              per.valor = per.statuspermiso ? 1 : 0;
            }
          });
        }
      });
    }

  }

  public valorPermisoGen(evento: any, arg: any) {
    if (this.listapuesto) {
      this.listapuesto.forEach((checker: any) => {
        checker.permisos.forEach((per: any) => {
          if (per.id == arg.id) {
            per.statuspermiso = evento.checked;
            per.valor = per.statuspermiso ? 1 : 0;
          }
        });
      });
    }

  }

  cargaListaPermisos() {
    this.servicios.getListaPermisos().subscribe(datoslistaperm => {
      if (!datoslistaperm.error) {
        this.listapermiso = Object.values(datoslistaperm.data);
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }


  public guardarPermiso() {
    let objFin = this.listapuesto;
    if (objFin) {
      let array_com = { "empresa": this.empresa, "puestos": objFin }
      this.guardarSrv(array_com);
    }

  }

  guardarSrv(objarray: any) {
    this.servicios.postService('mesa/puestos/', objarray).toPromise().then(
      (res: any) => {
        if (res.status == 401) {
          this.router.navigate(['login']);
        }
        this.snackBar.open('Permisos actualizados correctamente!', 'close', { duration: 2500 });
        this.funcion.hide();
      }
    ).catch((error) => {
      if (error.status == 400 || error.status == 500) {
        this.snackBar.open('Error al guardar permisos', 'close', { duration: 2500 });
        this.funcion.hide();
      } else {
        this.snackBar.open(error.message, 'close', { duration: 2500 });
        this.funcion.hide();
      }
    });
  }

  guardarPermisos() {
    let body = {
      "nombre": this.npermiso
    }
    if (this.permisoSelected == 1) {
      this.servicios.actualizaPer(this.objPermiso.id, body).subscribe(datperact => {
        if (!datperact.error) {
          this.modalService.dismissAll();
          this.cargaListaPermisos();
          this.cargaListaPuestos();
          this.consultaPermiso();
        } else {
          this.snackBar.open('Error al guardar los datos', 'close', {
            duration: 2500,
          });
          this.funcion.hide();
        }
      });
      this.permisoSelected = 0;
    } else if (this.permisoSelected == 2) {
      this.servicios.deletePer(this.objPermiso.id).subscribe(datperdelete => {
        if (!datperdelete.error) {
          this.modalService.dismissAll();
          this.cargaListaPermisos();
          this.cargaListaPuestos();
          this.consultaPermiso();
        } else {
          this.snackBar.open('Error al guardar los datos', 'close', {
            duration: 2500,
          });
          this.funcion.hide();
        }
      });
      this.permisoSelected = 0;

    } else {
      this.servicios.regPer(body).subscribe(datospermiso => {
        if (!datospermiso.error) {
          this.modalService.dismissAll();
          this.cargaListaPermisos();
          this.cargaListaPuestos();
          this.consultaPermiso();
        } else {
          this.snackBar.open('Error al guardar los datos', 'close', {
            duration: 2500,
          });
          this.funcion.hide();
        }
      });
    }
  }

  guardarPuesto() {
    let body = {
      "descripcion": this.listaPuestoA,
      "puesto": this.nombrePuesto
    }
    this.servicios.putService('mesa/puestos/descripcion/', body).subscribe(datperact => {
      if (datperact) {
        this.modalService.dismissAll();
        this.consultaPermiso();
      } else {
        this.snackBar.open('Error al guardar los datos', 'close', {
          duration: 2500,
        });
        this.funcion.hide();
      }
    });

  }

  /** Abrir modal */
  abrirModal(modal: any) {

    this.modalService.open(modal, {
      centered: true,
      size: 'md',
      ariaLabelledBy: 'modal-basic-title',
      scrollable: true,
    });
  }

  cancelar() {
    this.consultaPermiso();
  }

  /** Cerrar Modal */
  cerrarModal() {
    this.modalService.dismissAll();
    this.permisoSelected = 0;
  }

}
