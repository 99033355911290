<incentivos-navbar [nombre]="nombreL" [nomina]="nominaL"></incentivos-navbar>
<!-- Filtro sondeo -->

<div class="loading-indicator" *ngIf="funcion.visibility | async">
    <img src="/assets/img/spinner.gif" alt="">
</div>

<div class="d-flex justify-content-center align-box2">
    <div class="box2">
        <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' %3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
            aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item b1" href="javascript:;">Configuración SI+</li>
                <li class="breadcrumb-item b2">Filtro Cuestionario</li>
            </ol>
        </nav>
    </div>
</div>
<br><br>
<div class="d-flex justify-content-center align-box">
    <div class="box">
        <div class="d-flex align-items-center py-4">
            <div class="container">
                <form>
                        <div class="row mb-5">
                            <div class="col-md-4">
                                <label for="input6" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                                    <span class="h6 small bg-white text-muted px-1">Estatus</span>
                                </label>
                                <select class="form-select" aria-label="Default select example" required [(ngModel)]="estatus" name="estatus">
                                    <option value=""></option>
                                    <option value="1">Activo</option>
                                    <option value="0">Inactivo</option>
                                </select>
                            </div>
                            <div class="col-md-4">
                                <label for="input6" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                                    <span class="h6 small bg-white text-muted px-1">Título del cuestionario</span>
                                </label>
                                <input type="text" class="form-control" [(ngModel)]="titulo_sondeo" name="titulo_sondeo">
                            </div>
                            <div class="col-md-4">
                                <label for="fecha_pubsondeo" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                                    <span class="h6 small bg-white text-muted px-1">Fecha de publicación</span>
                                </label>
                                <input id="fecha_pubsondeo" type="text" required class="form-control" onfocus="(this.type='date')" onblur="if(this.value==''){this.type='text'}" [(ngModel)]="fecha_pubsondeo" name="fecha_pubsondeo">
                            </div>
                        </div>
                        <div class="row mb-5">
                            <div class="col-md-4">
                                <label for="input6" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                                    <span class="h6 small bg-white text-muted px-1">Empresa</span>
                                </label>
                                <select class="form-select" aria-label="Default select example" required [(ngModel)]="empresa" name="empresa">
                                    <option *ngFor="let empr of empsondeo" [value]="empr.id">{{empr.nombre}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                          <div class="col d-grid gap-5 d-md-flex justify-content-md-end">
                            <button type="button" class="btn  btn-lg me-md-2 boton" (click)="dataDef()">Limpiar</button>
                            <button type="button" class="btn  btn-lg boton2" (click)="consulta()">Consultar</button>
                            <button type="button" class="btn  btn-primary boton3" (click)="newSondeo()">+ Nuevo Cuestionario</button>
                          </div>
                      </div>
                </form>
            </div>
        </div>
        <br><br>
        <div class="row mt-4">
        <incentivos-tabla-registros [datos]="quizz" [wait]="wait" [columns]="columns_sondeo" [url]="url_sondeo" [ver]="true"></incentivos-tabla-registros>
        </div>
        <br><br>
    </div>
</div>
