import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Funciones } from '../../classes/funciones';
import { ServiciosImp } from '../../services/services';



@Component({
  selector: 'incentivos-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {

  @Input() nombre: any;
  @Input() nomina: any;
  logo_inicio: any;
  message: any = null;


  constructor(
    private router: Router,
    public snackBar: MatSnackBar,
    public funcion: Funciones,
    public servicios: ServiciosImp
  ) { }

  onLogout() {
    this.servicios.getCatalogoEstatus().subscribe(datoslogout => {
      if (!datoslogout.error) {
        sessionStorage.removeItem('sesion');
        sessionStorage.removeItem('nombre');
        sessionStorage.removeItem('nomina');
        this.funcion.hide();
        this.router.navigate(['login']);
      }else{
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }

  onClickHome() {
    this.router.navigate(['home']);
  }

  onClickContenidos() {
    this.router.navigate(['resumen_post']);
  }

  onClickEmpresas() {
    this.router.navigate(['catalogo_empresa']);
  }

  onClickNotificacion() {
    this.router.navigate(['notificaciones']);
  }

  onClickImportar() {
    this.router.navigate(['importar_datos_perfil']);
  }

  onClickDescuentosRetroactivos() {
    this.router.navigate(['descuentos_retroactivos']);
  }

  onClickCargaCalculoManual() {
    this.router.navigate(['carga_manual_calculo']);
  }

  onClickUsabilidad() {
    this.router.navigate(['reporte_usabilidad']);
  }

  /** Dummie ver un video de una ruta */
  onClickVerVideo() {
    this.router.navigate(['video']);
  }

  onClickConfiguraTour() {
    this.router.navigate(['configuracion_tour']);
  }

  onClickCargaIncentivos() {
    this.router.navigate(['carga_incentivos']);
  }

  onClickCargaSondeo() {
    this.router.navigate(['configurar_sondeo']);
  }

  onClickConfiguraInicio() {
    this.router.navigate(['configuracion_inicio']);
  }

  onClickPermisosPuesto() {
    this.router.navigate(['permisos_puesto']);
  }

  onClickConfiguraPlanesCampanias() {
    this.router.navigate(['configuracion_planes_campanias']);
  }

  onClicReporteNps() {
    this.router.navigate(['reporte_nps']);
  }

  onClicReportePerfil() {
    this.router.navigate(['reporte_perfil']);
  }

  onClicCuestionario() {
    this.router.navigate(['reporte_cuestionario']);
  }


}
