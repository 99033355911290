import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServiciosImp } from '../../services/services';
import { Funciones } from '../../classes/funciones'


@Component({
  selector: 'incentivos-reporte-perfil',
  templateUrl: './reporte_perfil.component.html',
  styleUrls: ['./reporte_perfil.component.css']
})
export class ReportePerfilComponent implements OnInit {

  nombre: any = sessionStorage.getItem('nombre');
  nomina: any = sessionStorage.getItem('nomina');
  datos: any = [];
  datos_prueba: any = [];
  empp: any[] = [];
  udnp: any[] = [];
  puestop: any[] = [];
  ubicacionp: any[] = [];
  categoriap: any[] = [];

  direccion: any[] = [];
  subdireccion: any[] = [];

  idempresa: number = 0;
  idUDN: string = '0';
  idUbicacion: string = '0';
  idPuesto: string = '0';
  idColaborador: string = "";
  fechaIniPerfil: any;
  fechaFinPerfil: any;
  fechai:any;
  fechaf:any;
  fecha: string = "";
  nombreL: any;
  nominaL: any;
  principal: boolean = true;

  idDireccion: string = '0';
  idSubdireccion: string = '0';

  constructor(
      private router: Router,
      public snackBar: MatSnackBar,
      public servicios: ServiciosImp,
      public funcion: Funciones
  ) {
    this.nombreL = this.nombre;
    this.nominaL = this.nomina;
  }

  ngOnInit(): void {
      this.fecha = this.funcion.formatDate();
      this.cargaUDNPerfil();
      this.cargaPuestosPerfil();
      this.cargaUbicacionPerfil();
      this.cargaCatalogoEmpresaPerfil();
      this.cargaDireccionPerfil();
      this.cargaSubdireccionPerfil();
  }

  cargaUDNPerfil() {
    this.servicios.getCatalogoUDN().subscribe(datoper1 => {
      if (!datoper1.error) {
        this.udnp = datoper1.data;
        this.funcion.hide();
      }else{
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }

  cargaPuestosPerfil() {
    this.servicios.getCatalogoPuestos().subscribe(datoper2 => {
      if (!datoper2.error) {
        this.puestop = datoper2.data;
        this.funcion.hide();
      }else{
        this.funcionError();
      }
    });
  }

  funcionError(){
    this.funcion.hide();
    this.router.navigate(['login']);

  }

  cargaUbicacionPerfil() {
    this.servicios.getCatalogoUbicacion().subscribe(datoper3 => {
      if (!datoper3.error) {
        this.ubicacionp = datoper3.data;
        this.funcion.hide();
      }else{
        this.funcionError();
      }
    });
  }

  cargaDireccionPerfil() {
    this.servicios.getCatalogoDireccion().subscribe(direcperfil => {
      if (!direcperfil.error) {
        this.direccion = direcperfil.data;
        this.funcion.hide();
      } else {
        this.funcionError();
      }
    });
  }
  cargaSubdireccionPerfil() {
    this.servicios.getCatalogoSubdireccion().subscribe(subdirecperfil => {
      if (!subdirecperfil.error) {
        this.subdireccion = subdirecperfil.data;
        this.funcion.hide();
      } else {
        this.funcionError();
      }
    });
  }

  cargaCatalogoEmpresaPerfil() {
    this.servicios.getCatalogoEmpresaPerfil().subscribe(datoper4 => {
      if (!datoper4.error) {
        this.empp = datoper4.data;
        this.funcion.hide();
      }else{
        this.funcionError();
      }
    });
  }
  objPerfil(){
    return {
      "fecha_inicio": this.fechaIniPerfil,
      "fecha_fin": this.fechaFinPerfil,
      "id_empresa": Number(this.idempresa) == 0 ? 13 : Number(this.idempresa),
      "id_udn": this.idUDN == '0' ? '': this.idUDN,
      "id_puesto": this.idPuesto == '0' ? '': this.idPuesto,
      "id_ubicacion": this.idUbicacion == '0' ? '': this.idUbicacion,
      "id_direccion": this.idDireccion == '0' ? '': this.idDireccion,
      "id_subdireccion": this.idSubdireccion == '0' ? '': this.idSubdireccion,
      "nomina": Number(this.idColaborador)
    };

  }


  onClickDashboardPerfil() {
      if (this.fechaIniPerfil !== undefined && this.fechaIniPerfil !== "" && this.fechaFinPerfil !== undefined && this.fechaFinPerfil !== "") {
          let bodyPer = this.objPerfil();
          this.servicios.postService('analitica/perfiles/',bodyPer).toPromise().then(
            (res: any) => {
              if(res.status == 401){
                this.router.navigate(['login']);
              }

              this.fechai = this.fechaIniPerfil;
              this.fechaf = this.fechaFinPerfil;
              this.datos = res.data
              this.principal = false;
              this.funcion.hide();
            },
            (error: any) => {
              this.snackBar.open(error.message, 'close', {
                duration: 2500,
             });
             this.funcion.hide();
            }
          )
      } else {
          this.snackBar.open("Debes llenar los campos obligatorios", 'close', {
              duration: 2500,
          });
      }
  }

  onClickExcelPerfil(){
    if (this.fechaIniPerfil !== undefined && this.fechaIniPerfil !== "" && this.fechaFinPerfil !== undefined && this.fechaFinPerfil !== "") {
        let paramper = this.objPerfil();
        this.servicios.downExcel(paramper,'perfiles').subscribe(datoexcelperfil => {
          if (!datoexcelperfil.error) {
            this.funcion.downloadExcel(datoexcelperfil,'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'reporte_perfil.xlsx');
            this.funcion.hide();
          }else{
            this.funcionError();
          }
        });
      } else {
        this.snackBar.open("Debes llenar los campos obligatorios", 'close', {
            duration: 2500,
        });
    }

  }

  return() {
      this.principal = true;
  }



}
