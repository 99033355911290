import {
  Component, OnInit, Input, Output, EventEmitter, ElementRef, Renderer2, ViewChild, AfterViewInit,
  TemplateRef, HostListener
} from '@angular/core';
import {
  IGridOptions, IColumns, IExportExcel, ISearchPanel, IScroll, Toolbar, IColumnHiding,
  ICheckbox, IEditing, IColumnchooser, IDetail, Color
} from '../../models/grid.interfaces';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DxDataGridComponent } from 'devextreme-angular';
import { MatDialog } from '@angular/material/dialog';
import 'devextreme/integration/jquery';


@Component({
  selector: 'app-grid-component',
  templateUrl: './grid-component.component.html',
  styleUrls: ['./grid-component.component.scss'],
})

export class GridComponentComponent implements OnInit, AfterViewInit {

  @ViewChild('Gridlightbox', { static: false }) Gridlightbox: TemplateRef<any> = {} as TemplateRef<any>;
  @ViewChild('grid', { static: false }) dataGrid: DxDataGridComponent = {} as DxDataGridComponent;
  @ViewChild('griddetail', { static: false }) dataGriddetail: DxDataGridComponent = {} as DxDataGridComponent;
  @ViewChild('grid', { read: ElementRef, static: false }) grid: ElementRef = {} as ElementRef;
  @ViewChild('griddetail', { read: ElementRef, static: false }) griddetail: ElementRef = {} as ElementRef;
  // ******************SE RECIBEN PARAMETROS Y SE ENVIA RESPUESTA**************** */

  // Seleccion
  selectionMode = 'single'
  @Input() datos: any[] = [];
  @Input() datosdetail: [] = [];
  @Input() Detail: IDetail = { detail: false };
  @Input() gridOptions: IGridOptions = { paginacion: 10, pageSize: [10, 30, 50, 100] };
  @Input() columnHiding: IColumnHiding = { hide: false };
  @Input() Checkbox: ICheckbox = { checkboxmode: 'none' };
  @Input() Editing: IEditing = { allowupdate: false, mode: '' };
  @Input() CheckboxDetail: ICheckbox = { checkboxmode: 'none' };
  @Input() Columnchooser: IColumnchooser = { columnchooser: true };
  @Input() columns: IColumns[] = [];
  @Input() columnsdetail: IColumns[] = [];
  @Input() exportExcel: IExportExcel = { enabled: true, fileName: 'Exportación' };
  @Input() searchPanel: ISearchPanel = {
    visible: true,
    width: 200,
    placeholder: 'Buscar...',
    filterRow: true,
    filterHeader: false
  };

  @Input() scroll: IScroll = { mode: '' };
  @Input() toolbar: Toolbar[] = [];
  @Input() toolbardetail: Toolbar[] = [];
  @Input() Color: Color = {
    color: 'gris',
    columnas: true,
    alternar: true,
    filas: true
  }
  @Input() keyfather: string = '';
  @Input() keyfilter: string = '';
  @Input() keyDetalle: string = '';
  @Input() onExportingFun: Function = Function();
  @Input() onExportedFun: Function = Function();

  @Output() messageEvent = new EventEmitter<{ event: string, data: [] }>();
  remoteOperations: boolean = false;
  @Output() datosevent = new EventEmitter<{ data: [], event: any }>();
  @Output() datosdetailevent = new EventEmitter<{ data: [], event: any }>();
  @Output() editevent = new EventEmitter<any>();
  @Output() onInitialized = new EventEmitter<any>();
  @Output() onRowUpdated = new EventEmitter<any>();
  public contador = 0;
  public contadordetail = 0;
  public toole: any;
  public tooledetail: any;
  documentos: any[] = [];
  DataSourceStorage: any;
  public newInnerWidth: number = 0;
  justDeselected: any;
  constructor(config: NgbModalConfig, private modalService: NgbModal, private element: ElementRef, private renderer: Renderer2, public dialog: MatDialog) {
    config.backdrop = 'static';
    config.keyboard = false;
    this.DataSourceStorage = [];


  }

  ngAfterViewInit() {
    const div = this.grid.nativeElement.querySelector('.dx-datagrid-filter-panel');
    const parent = this.grid.nativeElement.querySelector('.dx-datagrid');
    const refChild = this.element.nativeElement.querySelector('.dx-datagrid-headers');
    this.renderer.insertBefore(parent, div, refChild);
  }

  open(content: any) {
    this.modalService.open(content);
  }

  // ******************PARAMETROS QUE SE AJUSTARAN SEGUN SE REAJUSTE LA RESOLUCION DEL DISPOSITIVO**************** */
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.newInnerWidth = event.target.innerWidth;
    if (this.newInnerWidth <= 768) {
      this.columnHiding = { hide: true };
      this.gridOptions = { paginacion: 10, pageSize: [10, 30, 50, 100] };
    } else if (this.newInnerWidth > 768) {
      this.columnHiding = { hide: false };
      if (this.gridOptions === undefined) {
        this.gridOptions = { paginacion: 10, pageSize: [10, 30, 50, 100] };
      }
    }
  }

  ngOnInit() {
    this.configDatos();
    this.setConfigDoctos();
    this.setEvents();
  }

  setConfigDoctos() {
    this.newInnerWidth = window.innerWidth;
    if (this.newInnerWidth <= 768) {
      this.columnHiding = { hide: true };
      this.gridOptions = { paginacion: 10, pageSize: [10, 30, 50, 100] };
    } else if (this.newInnerWidth > 768) {
      this.columnHiding = { hide: false };
      if (this.gridOptions === undefined) {
        this.gridOptions = { paginacion: 10, pageSize: [10, 30, 50, 100] };
      }
    }
  }

  setEvents() {
    // ******************PARAMETROS DE EXPORTACION**************** */
    if (!this.exportExcel) this.exportExcel = { enabled: true, fileName: 'Datos' };
    // ******************PARAMETROS DE PARA SELECCION DE COLUMNAS**************** */
    if (!this.Columnchooser) this.Columnchooser = { columnchooser: true };
    // ******************PARAMETROS DE SEARCH**************** */
    this.searchPanel = { visible: false, width: 150, placeholder: 'Buscar...', filterRow: true, filterHeader: false };
    // ******************PARAMETROS DE SCROLL**************** */
    this.scroll = { mode: 'standard' };
    // ******************PARAMETROS DE MAESTRO DETALLE**************** */
    if (this.Detail === undefined) {
      this.Detail = { detail: false };
    }
    // ******************PARAMETROS DE CONTROL DE COLORES**************** */
    if (this.Color.color === 'gris') {
      this.Color = { filas: false, columnas: false, alternar: true, color: 'gris' };
    } else {
      this.Color = { filas: true, columnas: true, alternar: false, color: '' };
    }
  }

  // ******************SE DEVUELVE EVENTO CLICK**************** */
  onclick(event: any, data: any) {
    this.messageEvent.emit({ event, data });
  }

  // ******************CONTADOR DE ITEMS SELECCINADOS Y DEVUELVE DATOS AL EMITER**************** */


  onSelectionChanged(e: any) {
    const disabledKeys = e.currentSelectedRowKeys.filter((i: any) => (i.disable));
    if (disabledKeys.length > 0) {
      if (this.justDeselected) {
        this.justDeselected = false;
        e.component.deselectAll();
      } else {
        this.justDeselected = true;
        e.component.deselectRows(disabledKeys);
      }
    }
    this.onselectionExtra(e);
    this.dataGrid.instance.refresh();
  }

  onselectionExtra(e: any) {
    const data = e.selectedRowsData;
    this.datosevent.emit({ data, event: e });
    const cont = [];
    cont.push(e.selectedRowKeys);
    this.contador = cont[0].length;
    for (let i = 0; i < this.toole.toolbarOptions.items.length - 1; i++) {
      if (this.toole.toolbarOptions.items[i].name) {
        if (cont[0].length >= 1 && this.toole.toolbarOptions.items[i].name === 'simple') {
          this.toole.toolbarOptions.items[i].visible = true;
          if (cont[0].length >= 2 && this.toole.toolbarOptions.items[i].name2 === 'multiple') {
            this.toole.toolbarOptions.items[i].visible = false;
          }
        } else if (cont[0].length <= 0 && this.toole.toolbarOptions.items[i].name === 'simple') {
          this.toole.toolbarOptions.items[i].visible = false;
        }
      }
    }
  }

  // ******************CONTADOR DE ITEMS SELECCINADOS Y DEVUELVE DATOS AL EMITER DE MAESTRO DETALLE**************** */
  onSelectionChangedDetail(e: any) {
    const disabledKeys = e.currentSelectedRowKeys.filter((i: any) => (i.disable));
    if (disabledKeys.length > 0) {
      if (this.justDeselected) {
        e.component.deselectAll();
      } else {
        e.component.deselectRows(disabledKeys);
      }
      this.justDeselected = !this.justDeselected;
    }
    const data = e.selectedRowsData;
    this.datosdetailevent.emit({ data, event: e });
    const dataGrid = e.component;
    const element = e.component._$element;
    const contadorHTML: Element = element[0];
    contadorHTML.getElementsByClassName('countcount')[0].innerHTML = dataGrid.getSelectedRowKeys().length;
    this.onselectionDetailExtra(dataGrid);
    this.dataGriddetail.instance.refresh();
  }

  onselectionDetailExtra(dataGrid: any) {
    for (let i = 0; i < this.tooledetail.toolbarOptions.items.length - 1; i++) {
      if (this.tooledetail.toolbarOptions.items[i].name) {
        if (dataGrid.getSelectedRowKeys().length >= 1 && this.tooledetail.toolbarOptions.items[i].name === 'simple') {
          this.tooledetail.toolbarOptions.items[i].visible = true;
          if (dataGrid.getSelectedRowKeys().length >= 2 && this.tooledetail.toolbarOptions.items[i].name2 === 'multiple') {
            this.tooledetail.toolbarOptions.items[i].visible = false;
          }
        } else if (dataGrid.getSelectedRowKeys().length <= 0 && this.tooledetail.toolbarOptions.items[i].name === 'simple') {
          this.tooledetail.toolbarOptions.items[i].visible = false;
        }
      }
    }
  }

  // ******************DEVUELVE LOS DATOS EDITADOS DEL GRID **************** */
  onRowUpdating(e: any) {
    const key = e.oldData;
    const newdata = e.newData;
    const editdata = {
      key,
      newData: newdata
    };
    this.editevent.emit({ editdata });
    this.dataGrid.instance.repaint();
  }

  // ******************DEVUELVE LOS DATOS EDITADOS DEL GRID MAESTRO DETALLE**************** */
  onRowUpdatingDetail(e: any) {
    const key = e.oldData;
    const newdata = e.newData;
    const editdata = {
      key,
      newData: newdata
    };
    this.editevent.emit({ editdata });
    this.dataGriddetail.instance.repaint();
  }

  // ******************CREACION DE TOOLBAR**************** */

  onToolbarPreparing(e: any) {
    this.toole = e;
    e.toolbarOptions.items.unshift({
      location: 'before',
      template: 'Totalderegistros'
    },
      {
        location: 'before',
        template: 'Contarseleccionados',
        visible: false,
        name: 'simple'
      },
      ...this.toolbar
    );
  }

  // ******************CREACION DE TOOLBAR MAESTRO DETALLE**************** */

  onToolbarPreparingDetail(e: any) {
    this.tooledetail = e;

    e.toolbarOptions.items.unshift({
      location: 'before',
      template: 'Totalderegistrosdetail'
    },
      {
        location: 'before',
        template: 'Contarseleccionadosdetail'
      },
      ...this.toolbardetail
    );
  }

  // ***************COLUMN CHOOSER EVENT****************** */
  onContentReady(e: any) {
    this.configDatos();
    if (this.Color.color === 'gris') {
      const cont = this.grid.nativeElement.querySelector('.dx-header-row');
      this.renderer.setStyle(cont, 'background', 'transparent');
      this.renderer.setStyle(cont, 'font-weight', 'bold');
    }
  }

  configDatos() {
    this.datos.forEach((data: any, index) => {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const element = data[key];
          if (key === this.keyDetalle) {
            this.datos[index].detalle = element;
            delete data[key];
          }
        }
      }
    });
  }

  // ***************COLUMN CHOOSER EVENT MAESTRO DETALLE****************** */
  onContentReadyDetail(e: any) {
    if (this.Color.color === 'gris') {
      const cont = this.griddetail.nativeElement.querySelector('.dx-header-row');
      this.renderer.setStyle(cont, 'background', 'transparent');
      this.renderer.setStyle(cont, 'font-weight', 'bold');
    }
  }

  onInitializedMaster($event: any) {
    this.onInitialized.emit(this.dataGrid);
  }

  onRowPrepared(e: any) {
    if (e.rowType === 'data') {
      e.rowElement.find('td').css('background', e.data.backgroundcolor);
    }
  }

  OnRowUpdated($event: any) {
    this.onRowUpdated.emit($event);
  }

  onExporting($event: any) {
    if (this.onExportingFun) this.onExportingFun();
  }

  onExported($event: any) {
    if (this.onExportedFun) this.onExportedFun();
  }



}
