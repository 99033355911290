<incentivos-navbar [nombre]="nombreL" [nomina]="nominaL"></incentivos-navbar>

<!-- Importar los datos para incentivos -->
<div class="loading-indicator" *ngIf="funcion.visibility | async">
    <img src="/assets/img/spinner.gif" alt="">
</div>

<div class="d-flex justify-content-center align-box2">
    <div class="box2">
      <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
         aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item b1" href="javascript:;">Configuración SI+</li>
                <li class="breadcrumb-item b2" href="javascript:;">Importar datos incentivos</li>
            </ol>
        </nav>
    </div>
</div>

<div>
  <incentivos-form-excel [Tipo]="1"></incentivos-form-excel>
</div>
