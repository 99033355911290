import { Component, } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Funciones } from '../classes/funciones';


@Component({
  selector: 'incentivos-carga-incentivo',
  templateUrl: './carga_incentivo.component.html',
  styleUrls: ['./carga_incentivo.component.css']})
export class CargaIncentivoComponent {

  nombre: any = sessionStorage.getItem('nombre');
  nomina: any = sessionStorage.getItem('nomina');
  nombreL: any;
  nominaL: any;

  public empresa: number = 0;


  constructor(
    public snackBar: MatSnackBar,
    public funcion: Funciones
  ) {
    this.nombreL = this.nombre;
    this.nominaL = this.nomina;
  }

}
