import { Component, OnInit, ViewChild, ElementRef, Pipe } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { PrevTourComponent } from '../../modulo_tour/prev_tour/prev_tour.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ServiciosImp } from '../../services/services';
import { Funciones } from '../../classes/funciones';


@Component({
  selector: 'incentivos-nuevo-tour',
  templateUrl: './nuevo-tour.component.html',
  styleUrls: ['./nuevo-tour.component.css']
})
export class NuevoTourComponent implements OnInit {

  @Pipe({ name: 'safe' })

  @ViewChild('modalincentivos') modal: ElementRef | undefined;
  nombre: any = sessionStorage.getItem('nombre');
  nomina: any = sessionStorage.getItem('nomina');
  nombreL: any;
  usuarioL: any;
  nominaL: any;
  status_empresa: any[] = [];
  datos_matriz_mod: any[] = [];
  arbol_empresa: any[] = [];
  datos_matriz: any[] = [];
  datos_audiencia: any[] = [];
  emptour: any[] = [];
  perfilestour: any[] = [];
  sum_carga: any[] = [];
  visualizador: any[] = [];
  files: any = [];
  // Para la imagen de la portada
  datos_archivo = new FormData();
  datos_arch: any[] = [];
  tours: any[] = [];
  valor: number = 0;
  filePrev: any;
  public texto_video: string = "";
  public tiempo_portada: any;
  archivo_portada!: File

  // Campos en el front
  idempresa: number = 0;
  estatus: any;
  tipo: any;
  rol: any;
  idt: any;
  completo: number = 0;
  arcargar: number = 0;
  public portada: any;
  public imagen_portada: any;
  public texto_portada: string = "";
  public fecha_publicacion: any;
  public fecha_vigencia: any;
  archivos: File[] = []
  wait: boolean = false;
  public idTour: number = 0;
  public orden: string = '';
  public titulo = 'Nuevo';
  videoDuration: number | undefined;
  public muestra_prev: boolean = false;
  public editar: boolean = false;
  archivoPortada!: File;
  fecha: string = "";


  constructor(
    private router: Router,
    public snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private matDialog: MatDialog,
    public funcion: Funciones,
    public servicios: ServiciosImp
  ) {
    this.nombreL = this.nombre;
    this.nominaL = this.nomina;
  }

  ngOnInit(): void {
    this.fecha = this.funcion.formatDate();
    this.inicio();
    this.cargaCatalogoEmpresa();
    this.catalogoPerfiles();

  }

  changePublicacion() {
    this.fecha_vigencia = "";
  }

  public inicio() {
    this.activatedRoute.params.subscribe(parametros => {
      this.idTour = parametros.id;
      if (this.idTour != 0) {
        this.wait = true;
        this.titulo = 'Edita'
        let url = 'tour/' + this.idTour + '/';
        this.servicios.getService(url).toPromise().then(
          async (res: any) => {
            if (res.status == 401) {
              this.router.navigate(['login']);
            }
            this.tours = [];
            this.muestra_prev = true;
            this.idempresa = res.data.empresa_id;
            this.estatus = res.data.status;
            this.tipo = res.data.tipo;
            this.rol = res.data.rol;
            this.tiempo_portada = res.data.tiempo_portada;
            this.texto_portada = res.data.texto_portada;

            let base64Image = "";
            this.toDataUrl(res.data.url_portada, function (x: any) {
              base64Image = x;
              let list = new DataTransfer();
              list.items.add(new File([base64Image], 'archivo cargado'));
              let myFileList = list.files;
              let archivo = <HTMLInputElement>document.getElementById('imgPortada');
              archivo.files = myFileList;
            });

            this.portada = res.data.url_portada;
            this.imagen_portada = res.data.url_portada;
            this.filePrev = res.data.url_portada;
            this.fecha_publicacion = res.data.fecha_publicacion;
            this.fecha_vigencia = res.data.fecha_vigencia;
            let t = res.data;
            this.editar = true
            this.tours.push({ "id": 1, "tipo": t.tipo_video1, "texto": t.texto_video1, "src": t.url_video1, "tiempo": t.tiempo_video1, "archivo": null });
            this.tours.push({ "id": 2, "tipo": t.tipo_video2, "texto": t.texto_video2, "src": t.url_video2, "tiempo": t.tiempo_video2, "archivo": null });
            this.tours.push({ "id": 3, "tipo": t.tipo_video3, "texto": t.texto_video3, "src": t.url_video3, "tiempo": t.tiempo_video3, "archivo": null });
            this.tours.push({ "id": 4, "tipo": t.tipo_video4, "texto": t.texto_video4, "src": t.url_video4, "tiempo": t.tiempo_video4, "archivo": null });
            this.tours.push({ "id": 5, "tipo": t.tipo_video5, "texto": t.texto_video5, "src": t.url_video5, "tiempo": t.tiempo_video5, "archivo": null });
            this.tours.push({ "id": 6, "tipo": t.tipo_video6, "texto": t.texto_video6, "src": t.url_video6, "tiempo": t.tiempo_video6, "archivo": null });
            console.log(this.tours);
            this.idt = res.data.id
            this.funcion.hide();
          },
          (error: any) => {
            this.snackBar.open(error.message, 'close', {
              duration: 2500,
            });
            this.funcion.hide();
          }
        )
      } else {
        this.muestra_prev = true;
        this.tours.push({ "id": 1, "tipo": '', "texto": '', "src": '', "tiempo": '', "archivo": '' });
        this.tours.push({ "id": 2, "tipo": '', "texto": '', "src": '', "tiempo": '', "archivo": '' });
        this.tours.push({ "id": 3, "tipo": '', "texto": '', "src": '', "tiempo": '', "archivo": '' });
        this.tours.push({ "id": 4, "tipo": '', "texto": '', "src": '', "tiempo": '', "archivo": '' });
        this.tours.push({ "id": 5, "tipo": '', "texto": '', "src": '', "tiempo": '', "archivo": '' });
        this.tours.push({ "id": 6, "tipo": '', "texto": '', "src": '', "tiempo": '', "archivo": '' });
        this.wait = false;
      }
    });
  }


  cargaCatalogoEmpresa() {
    this.servicios.getCatalogoEmpresa().subscribe(datotour1 => {
      if (!datotour1.error) {
        this.emptour = datotour1.data;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }

  catalogoPerfiles() {
    this.servicios.getCatalogoPerfiles().subscribe(datotour2 => {
      if (!datotour2.error) {
        this.perfilestour = datotour2.data;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }


  public dataDef() {
    this.router.navigate(['configuracion_tour']);
  }

  public guardaTour() {
    if (this.validaciones1() && this.validaciones2()) {
      if (this.idTour == 0) {
        this.saveTour(this.getBody());
      } else {
        this.updateTour(this.getBody());
      }
    } else {
      this.snackBar.open('Por favor, ingrese todos los campos', 'close', {
        duration: 2500,
      });
    }
  }

  validaciones1() {
    if (this.idempresa !== 0 && this.estatus !== 0 && this.tipo !== 0 && this.rol !== 0)
      return true;
    else
      return false;
  }

  validaciones2() {
    if (this.fecha_publicacion !== undefined && this.fecha_vigencia !== undefined
      && this.imagen_portada !== undefined && this.imagen_portada !== "" && this.tiempo_portada !== undefined)
      return true;
    else
      return false;
  }

  getBody() {
    return {
      "empresa_id": this.idempresa,
      "status": this.estatus,
      "tipo": this.tipo,
      "rol": this.rol,
      "texto_portada": this.texto_portada,
      "texto_video1": this.tours[0].texto,
      "texto_video2": this.tours[1].texto,
      "texto_video3": this.tours[2].texto,
      "texto_video4": this.tours[3].texto,
      "texto_video5": this.tours[4].texto,
      "texto_video6": this.tours[5].texto,
      "tipo_video1": this.tours[0].tipo,
      "tipo_video2": this.tours[1].tipo,
      "tipo_video3": this.tours[2].tipo,
      "tipo_video4": this.tours[3].tipo,
      "tipo_video5": this.tours[4].tipo,
      "tipo_video6": this.tours[5].tipo,
      "tiempo_portada": this.tiempo_portada == '' ? '0' : this.tiempo_portada,
      "tiempo_video1": this.tours[0].tiempo == '' ? '0' : this.tours[0].tiempo,
      "tiempo_video2": this.tours[1].tiempo == '' ? '0' : this.tours[1].tiempo,
      "tiempo_video3": this.tours[2].tiempo == '' ? '0' : this.tours[2].tiempo,
      "tiempo_video4": this.tours[3].tiempo == '' ? '0' : this.tours[3].tiempo,
      "tiempo_video5": this.tours[4].tiempo == '' ? '0' : this.tours[4].tiempo,
      "tiempo_video6": this.tours[5].tiempo == '' ? '0' : this.tours[5].tiempo,
      "fecha_publicacion": this.fecha_publicacion,
      "fecha_vigencia": this.fecha_vigencia
    }
  }

  saveTour(body: any) {
    this.servicios.regTour(body).subscribe(datotourreg => {
      if (!datotourreg.error) {
        this.titulo = 'Edita'
        this.guardaArchivoPortada(datotourreg.data.id);
        this.guardaArchivoVideo(datotourreg.data.id);
        this.funcion.hide();
      } else {
        this.snackBar.open('Error al guardar los datos', 'close', {
          duration: 2500,
        });
        this.funcion.hide();
      }
    });
  }

  updateTour(body: any) {
    this.servicios.actTour(body, this.idTour).subscribe(datotouract => {
      if (!datotouract.error) {
        this.guardaArchivoPortada(this.idTour);
        this.guardaArchivoVideo(this.idTour);
        this.funcion.hide();
      } else {
        this.snackBar.open('Error al actualizar los datos', 'close', {
          duration: 2500,
        });
        this.funcion.hide();
      }
    });
  }

  /*base64 de imagen cargadas y video*/
  public change_portada($event: any): void {
    if(this.funcion.extensionValida($event)){
    //Para carga
    if ($event.target.files.length > 0) {
      this.archivo_portada = $event.target.files[0];
      this.datos_archivo.delete('nombre_archivo');
      const index = this.datos_arch.indexOf(this.datos_archivo);
      if (index > -1) {
        this.datos_arch.splice(index, 1);
      }
      this.datos_archivo.append('nombre_archivo', this.archivo_portada);
      this.datos_archivo.append('id_empresa', String(this.idempresa));
      this.datos_archivo.append('tipo', "portada");
      this.datos_arch.push(this.datos_archivo);
      //Para visualizar
      let readerp: FileReader = new FileReader();
      readerp.onloadend = () => {
        this.filePrev = readerp.result;
      }
      readerp.readAsBinaryString(this.archivo_portada);
    } else {
      let list = new DataTransfer();
      list.items.add(this.archivo_portada);
      let myFileList = list.files;
      $event.target.files = myFileList;
    }
  }else{
    this.imagen_portada = '';
  }
  }


  public guardaArchivoPortada(idtour: any) {
    this.datos_archivo.set('id_tour', idtour);
    if (this.datos_arch.length != 0) {
      for (let i = 0; i < this.datos_arch.length; i++) {
        this.httpClient.post(environment.serviceUrl + 'servicios/archivos/tour/', this.datos_arch[i]).subscribe(
          (data: any) => {
            if (i == (this.datos_arch.length - 1)) {
              console.log("Carga completa ==> archivo");
              if (this.tours.length != 0) {
                this.funcion.hide();
                this.router.navigate(['nuevo_tour/' + idtour]);
              }
            }
          }, (error: any) => {
            console.log(error)
            this.funcion.hide();
          }
        )
      }
    } else {
      this.funcion.hide();
    }
  }

  public guardaArchivoVideo(idtour: any) {

    if (this.tours.length != 0) {
      for (let i = 0; i < this.tours.length; i++) {
        if (this.tours[i].archivo !== null) {
          this.arcargar = this.arcargar + (i + 1)
          console.log(this.arcargar);
          let datos = new FormData();
          datos.delete('nombre_archivo');
          const index = this.datos_arch.indexOf(datos);
          if (index > -1) {
            this.datos_arch.splice(index, 1);
          }
          datos.append('nombre_archivo', this.tours[i].archivo);
          datos.append('id_empresa', '13');
          datos.append('id_tour', idtour);
          let n = "video" + this.tours[i].id
          datos.append('tipo', n);
          this.datos_arch.push(datos);
          this.tiempoCarga(datos, i, idtour);
        } else {
          this.snackBar.open('Registro Correcto del Tour', 'close', {
            duration: 2500,
          });
          console.log("Sin archivos para carga");
        }
      }
    } else {
      this.funcion.hide();
    }
  }

  async tiempoCarga(datos: any, i: any, id_tour: any) {

    this.httpClient.post(environment.serviceUrl + 'servicios/archivos/tour/', datos).subscribe(
      (data: any) => {
        console.log("Carga completa ==> archivo " + (i + 1));
        this.completo = this.completo + (i + 1)
        if (this.completo == this.arcargar) {
          this.funcion.hide();
          this.snackBar.open('Registro Correcto del Tour', 'close', {
            duration: 2500,
          });
          this.router.navigate(['nuevo_tour/' + id_tour]);
        }
      }, (error: any) => {
        console.log(error);
        this.funcion.hide();
      }
    )

  }

  guardarArchivos(archivo: any) {
    let arch = this.tours.filter(x => x.id == archivo.id)
    arch[0].archivo = archivo.file
  }


  modalPrev(arr: any) {
    let portada = this.editar && !this.imagen_portada.includes('fakepath') ? this.imagen_portada : this.filePrev;
    let items = arr.filter((x: any) => x.tipo != "");
    if ((portada !== undefined || this.texto_portada != "") && items.length > 0) {
      this.matDialog.open(PrevTourComponent, {
        width: '412px',
        height: '914px',
        autoFocus: false,
        data: {
          data_videos: arr, portada: portada, textop: this.texto_portada
        }
      });
    }
  }

  onClickHistorial() {
    this.router.navigate(['configuracion_tour']);
  }

  matriz_audiencia = this.datos_matriz_mod
  plain = [
    {
      "descripcion": "Audiencia",
      "orden": 1,
      "activo": true,
      "ultimoNivel": 1
    }
  ]

  toDataUrl(url: any, callback: any) {
    let xhr = new XMLHttpRequest();
    xhr.onload = function () {
      let reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      }
      reader.readAsBinaryString(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }


  descripcion = "Error"
  searchPanel = { visible: true, width: 200, placeholder: 'Buscar...', filterRow: true };
}
