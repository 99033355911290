import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ServiciosImp {
  url: string;
  headers: HttpHeaders = new HttpHeaders;

  constructor(
    private http: HttpClient
  ) {
    this.url = environment.serviceUrl;
  }

  ///////////////////////////////////////////////////////////////////////////////////////
  ////////////////  Llamada a los servicios anterior ///////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////

  getService(ruta: string) {
    let token = !sessionStorage.getItem('sesion') ? '' : sessionStorage.getItem('sesion');
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    return this.http.get(this.url + ruta, { headers: this.headers });
  }

  postService(ruta: string, body?: any) {
    let token = !sessionStorage.getItem('sesion') ? '' : sessionStorage.getItem('sesion');
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    return this.http.post(this.url + ruta, body, { headers: this.headers });
  }

  postSer(ruta: string, body?: any) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(this.url + ruta, body, { headers: this.headers });
  }

  postServiceDownload(ruta: string, body?: any) {
    let token = !sessionStorage.getItem('sesion') ? '' : sessionStorage.getItem('sesion');
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    return this.http.post(this.url + ruta, body, { headers: this.headers, observe: 'body', responseType: 'blob' });
  }


  putService(ruta: string, body?: any) {
    let token = !sessionStorage.getItem('sesion') ? '' : sessionStorage.getItem('sesion');
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    return this.http.put(this.url + ruta, body, { headers: this.headers });
  }

  deleteDirectService(ruta: string) {
    let token = !sessionStorage.getItem('sesion') ? '' : sessionStorage.getItem('sesion');
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    return this.http.delete(this.url + ruta, { headers: this.headers });
  }

  /****************************************************************************************************************** */

  ///////////////////////////////////////////////////////////////////////////////////////
  ////////////////  Llamada a los servicios nueva ///////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////

  private cabecera() {
    const tokensession = sessionStorage.getItem('sesion') ? sessionStorage.getItem('sesion') : '';
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokensession}`
    });
  }

  public authLogin(obj: any): Observable<any> {
    return this.http.post<any>(
      environment.serviceUrl + 'auth/login/',
      obj,
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public regLogo(obj: any): Observable<any> {
    return this.http.post<any>(
      environment.serviceUrl + 'logo/',
      obj,
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public actLogo(obj: any, id: any): Observable<any> {
    return this.http.put<any>(
      environment.serviceUrl + 'logo/' + id + '/',
      obj,
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public regTour(obj: any): Observable<any> {
    return this.http.post<any>(
      environment.serviceUrl + 'tour/',
      obj,
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public actTour(obj: any, id: any): Observable<any> {
    return this.http.put<any>(
      environment.serviceUrl + 'tour/' + id + '/',
      obj,
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public regEmp(obj: any): Observable<any> {
    return this.http.post<any>(
      environment.serviceUrl + 'empresa/',
      obj,
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public actEmp(obj: any, id: any): Observable<any> {
    return this.http.put<any>(
      environment.serviceUrl + 'empresa/' + id,
      obj,
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public regnvlEmp(obj: any, id: any): Observable<any> {
    return this.http.post<any>(
      environment.serviceUrl + 'empresa/' + id + '/incentivo/',
      obj,
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public regPer(obj: any): Observable<any> {
    return this.http.post<any>(
      environment.serviceUrl + 'mesa/permisos/',
      obj,
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public actualizaPer(idPermiso: any, obj: any): Observable<any> {
    return this.http.put<any>(
      environment.serviceUrl + 'mesa/permisos/' + idPermiso + '/',
      obj,
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public deletePer(idPermiso: any): Observable<any> {
    return this.http.delete<any>(
      environment.serviceUrl + 'mesa/permisos/' + idPermiso + '/',
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }


  public actnvlEmp(obj: any, id1: any, id2: any): Observable<any> {
    return this.http.put<any>(
      environment.serviceUrl + `empresa/` + id1 + `/incentivo/` + id2,
      obj,
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public regSondeo(obj: any): Observable<any> {
    return this.http.post<any>(
      environment.serviceUrl + 'quizz/',
      obj,
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public actSondeo(obj: any, id: any): Observable<any> {
    return this.http.post<any>(
      environment.serviceUrl + 'quizz/' + id + '/',
      obj,
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public regPost(obj: any): Observable<any> {
    return this.http.post<any>(
      environment.serviceUrl + 'post/',
      obj,
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public actPost(obj: any, id: any): Observable<any> {
    return this.http.put<any>(
      environment.serviceUrl + 'post/' + id,
      obj,
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public regAudiencia(obj: any, id: any): Observable<any> {
    return this.http.post<any>(
      environment.serviceUrl + 'post/' + id + '/audiencia/',
      obj,
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public publicarPost(id: any): Observable<any> {
    return this.http.get<any>(
      environment.serviceUrl + 'post/' + id + '/publicar/',
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }



  public excelDescuentosRetro(fecha: any): Observable<any> {
    return this.http.get<any>(
      environment.serviceUrl + 'mesa/descuento-retroactivo/?fecha=' + fecha,
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public regCampania(obj: any): Observable<any> {
    return this.http.post<any>(
      environment.serviceUrl + 'campania/',
      obj,
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public guardarArchivo(obj: FormData): Observable<any> {
    return this.http.post<any>(
      environment.serviceUrl + 'servicios/archivos/', obj
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public downExcel(obj: any, tipo: any): Observable<any> {
    console.log('token', this.cabecera())
    return this.http.post<any>(
      environment.serviceUrl + 'analitica/' + tipo + '/?descarga=1',
      obj,
      {
        headers: this.cabecera(),
        observe: 'body',
        responseType: 'blob' as 'json'
      }

    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public downExcelClicks(obj: any, tipo: any): Observable<any> {
    console.log('token', this.cabecera())
    return this.http.post<any>(
      environment.serviceUrl + 'analitica/' + tipo + '/?bucket=1&click=1',
      obj,
      {
        headers: this.cabecera(),
        observe: 'body',
        responseType: 'blob' as 'json'
      }

    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public getObtienePlanCalculoManual(obj: any, id: any): Observable<any> {
    console.log('token', this.cabecera())
    return this.http.post<any>(environment.serviceUrl + 'mesa/calculo-manual/puesto/' + id + '/', obj, {
      headers: this.cabecera(), observe: 'body', responseType: 'blob' as 'json'
    })
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public actCampania(obj: any, id: any): Observable<any> {
    return this.http.put<any>(
      environment.serviceUrl + 'campania/' + id + '/',
      obj,
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public outLogin(): Observable<any> {
    return this.http.get<any>(
      environment.serviceUrl + 'auth/logout/',
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public getListaPermisos(): Observable<any> {
    return this.http.get<any>(
      environment.serviceUrl + 'mesa/permisos/',
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }


  public getListaPuestos(): Observable<any> {
    return this.http.get<any>(
      environment.serviceUrl + 'mesa/puestos/',
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public getCatalogoEmpresa(): Observable<any> {
    return this.http.get<any>(
      environment.serviceUrl + 'empresa/',
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public getCatalogoEmpresaPerfil(): Observable<any> {
    return this.http.get<any>(
      environment.serviceUrl + 'servicios/perfil/empresa/',
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public getCatEmpresa(): Observable<any> {
    return this.http.get<any>(
      environment.serviceUrl + 'mesa/empresas/',
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public getCatalogoCategorias(id: any): Observable<any> {
    return this.http.get<any>(
      environment.serviceUrl + 'empresa/' + id + '/incentivo/',
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }


  public getCatalogoUDN(): Observable<any> {
    return this.http.get<any>(
      environment.serviceUrl + 'servicios/perfil/udn/',
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public getCatalogoIncentivos(): Observable<any> {
    return this.http.get<any>(
      environment.serviceUrl + 'mesa/calculo-manual/puesto/',
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public getCatalogoPuestos(): Observable<any> {
    return this.http.get<any>(
      environment.serviceUrl + 'servicios/perfil/puesto/',
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public getCatalogoUbicacion(): Observable<any> {
    return this.http.get<any>(
      environment.serviceUrl + 'servicios/perfil/ubicacion/',
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }
  public getCatalogoDireccion(): Observable<any> {
    return this.http.get<any>(
      environment.serviceUrl + 'servicios/perfil/direccion/',
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }
  public getCatalogoSubdireccion(): Observable<any> {
    return this.http.get<any>(
      environment.serviceUrl + 'servicios/perfil/subdireccion/',
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public getCatalogoEstatus(): Observable<any> {
    return this.http.get<any>(
      environment.serviceUrl + 'status/',
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public getCatalogoPost(): Observable<any> {
    return this.http.get<any>(
      environment.serviceUrl + 'post/',
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public getCatalogoModulo(): Observable<any> {
    return this.http.get<any>(
      environment.serviceUrl + 'modulo/',
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public getCatalogoPerfiles(): Observable<any> {
    return this.http.get<any>(
      environment.serviceUrl + 'servicios/roles/',
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public getCatalogoMarcaAct(): Observable<any> {
    return this.http.get<any>(
      environment.serviceUrl + 'marca-actualizacion/',
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public getCatalogoTipoCont(): Observable<any> {
    return this.http.get<any>(
      environment.serviceUrl + 'tipo-contenido/',
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public deleteCatalogoEmpresa(data: any): Observable<any> {
    return this.http.delete<any>(
      environment.serviceUrl + `empresa/${data.data[0].empresa_id}/incentivo/${data.data[0].id}`,
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public deleteConfInicio(data: any): Observable<any> {
    return this.http.delete<any>(
      environment.serviceUrl + `logo/${data.data[0].id}` + `/`,
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public deleteDescuentoRetro(data: any, id: any): Observable<any> {
    const tokensession = sessionStorage.getItem('sesion') ? sessionStorage.getItem('sesion') : '';
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokensession}`
      }),
      body: JSON.stringify(data)
    }
    return this.http.delete<any>(
      environment.serviceUrl + `mesa/descuentoretro/${id}` + `/`, options
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public deleteCargaManual(data: any, id: any): Observable<any> {
    const tokensession = sessionStorage.getItem('sesion') ? sessionStorage.getItem('sesion') : '';
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokensession}`
      }),
      body: JSON.stringify(data)
    }
    return this.http.delete<any>(
      environment.serviceUrl + `mesa/calculo-manual/${id}` + `/`, options
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }


  public subeArchivoBuket(formData: FormData): Observable<any> {
    return this.http.post<any>(
      environment.serviceUrl + `servicios/archivos/bucket/`, formData
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public getNotificacion(): Observable<any> {
    return this.http.get<any>(
      environment.serviceUrl + 'notificacion/',
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public getFiltroNotificacion(f1: any, f2: any): Observable<any> {
    return this.http.get<any>(
      environment.serviceUrl + `notificacion/filtros/?titulo=` + f1 + `&fecha_creacion=` + f2 + ``,
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  public getAudiencia(): Observable<any> {
    return this.http.get<any>(
      environment.serviceUrl + 'audiencia/arbol/',
      { headers: this.cabecera() }
    )
      .pipe(retry(1), catchError(this.errorHandl));
  }


  errorHandl(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }

  /****************************************************************************************************************** */

}
