<incentivos-navbar [nombre]="nombreL" [nomina]="nominaL"></incentivos-navbar>

<!-- Crear una nueva empresa -->
<div class="loading-indicator" *ngIf="funcion.visibility | async">
    <img src="/assets/img/spinner.gif" alt="">
</div>

<div class="d-flex justify-content-center align-box2">
    <div class="box2">
        <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
            aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item b1" href="javascript:;">Configuración SI+</li>
                <li class="breadcrumb-item b1" href="javascript:;">Configuración del
                    Tour</li>
                <li class="breadcrumb-item b1" href="javascript:;" (click)="onClickHistorial()">Filtro / Historial</li>
                <li class="breadcrumb-item b2">{{titulo}} slide</li>
            </ol>
        </nav>
    </div>
</div>
<div class="d-flex justify-content-center align-box">
    <div class="box">
        <p class="f-t1">{{titulo}} Tour</p>
        <p class="f-t2">*Campos Obligatorios</p>

        <div class="container cuerpo_post">
            <div class="row mb-5">
                <div class="col-md-3">
                    <label for="input2" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                        <span class="h6 small bg-white text-muted px-1">Empresa*</span>
                    </label>
                    <select class="form-control form-select mt-2" id="input2" required [(ngModel)]="idempresa"
                        name="idempresa">
                        <option *ngFor="let empresa of emptour" [value]="empresa.id">{{empresa.nombre}}</option>
                    </select>
                </div>
                <div class="col-md-3">
                    <label for="input3" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                        <span class="h6 small bg-white text-muted px-1">Estatus*</span>
                    </label>
                    <select class="form-control form-select mt-2" id="input3" required [(ngModel)]="estatus"
                        name="estatus">
                        <option value=true>Activo</option>
                        <option value=false>Inactivo</option>
                    </select>
                </div>
                <div class="col-md-3">
                    <label for="input1" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                        <span class="h6 small bg-white text-muted px-1">Tipo*</span>
                    </label>
                    <select class="form-control form-select mt-2" id="input1" required [(ngModel)]="tipo" name="tipo">
                        <option value="welcome">Welcome</option>
                        <option value="release">Release</option>
                    </select>
                </div>
                <div class="col-md-3">
                    <label for="input4" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                        <span class="h6 small bg-white text-muted px-1">Rol*</span>
                    </label>
                    <select class="form-control form-select mt-2" id="input4" required [(ngModel)]="rol" name="rol">
                        <option *ngFor="let rol of perfilestour" [value]="rol.nombrePerfil">{{rol.nombrePerfil}}</option>
                    </select>
                </div>
            </div>

            <div class="row mb-5">
                <div class="col-md-3">
                    <label for="input5" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                        <span class="h6 small bg-white text-muted px-1">Imagen Portada*</span>
                    </label>
                    <input class="form-control mt-2" type="file" id="imgPortada" accept=".jpg, .jpeg, .png"
                        (change)="change_portada($event)" [(ngModel)]="imagen_portada">
                </div>
                <div class="col-md-2">
                    <button class="button mt-2 boton4" id="btnc" type="button" data-bs-toggle="modal"
                        data-bs-target="#openModal" [disabled]="!imagen_portada"><em class="bi bi-eye-fill"
                            style="pointer-events: none;"></em>&nbsp;&nbsp;Previsualizar</button>
                </div>
                <div class="col-md-3">
                    <label for="input5" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                        <span class="h6 small bg-white text-muted px-1">Tiempo de duración (minutos)*</span>
                    </label>
                    <input class="form-control mt-2" type="number" step=1 min="0" [(ngModel)]="tiempo_portada">
                </div>
                <div class="col-md-4">
                    <input type="text" placeholder="Mensaje Personalizado" [(ngModel)]="texto_portada"
                        class="form-control mt-2" required name="texto_portada">
                </div>
            </div>
            <div class="row mb-5" *ngIf="tours != undefined && tours.length > 0">
                <incentivos-tour-item [item]='tours' [idTour]="idt" [editar]="editar"
                    (guardar)="guardarArchivos($event)"></incentivos-tour-item>
            </div>
            <div class="row mb-5">
                <div class="d-flex align-items-end flex-column bd-highlight">
                    <button class="button mt-2 boton2" id="btnc" type="button" (click)="modalPrev(tours)">
                        <em class="bi bi-eye-fill"></em>&nbsp;&nbsp;Previsualizar</button>
                </div>
                <span style="text-align: right;padding-top: 1em;">NOTA: Recuerda hacer zoom out o zoom in hasta que el previsualizador se vea completo</span>
            </div>
            <br>
            <div class="row mb-5">
                <div class="col-md-6">
                    <label for="input5" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                        <span class="h6 small bg-white text-muted px-1">Fecha publicación*</span>
                    </label>
                    <input id="fechap" type="text" class="mt-2 form-control" min="{{fecha}}" onfocus="(this.type='date')"
                    onblur="if(this.value==''){this.type='text'}" onkeydown="return false" (change)="changePublicacion()" required  [(ngModel)]="fecha_publicacion"
                        name="fecha_publicacion">
                </div>
                <div class="col-md-6">
                    <label for="input6" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                        <span class="h6 small bg-white text-muted px-1">Fecha vigencia*</span>
                    </label>
                    <input id="fechav" type="text" required class="mt-2 form-control"
                        onfocus="(this.type='date')" onkeydown="return false" onblur="if(this.value==''){this.type='text'}" required
                        [(ngModel)]="fecha_vigencia" min="{{fecha_publicacion}}"  name="fecha_vigencia">
                </div>
            </div>
            <div class="col d-grid gap-5 pe-4 py-5 d-md-flex justify-content-md-end">
                <button class="button mt-2 boton" type="button" (click)="dataDef()">&nbsp;&nbsp;Cancelar</button>
                <button class="button mt-2 boton2" type="button" (click)="guardaTour()">&nbsp;&nbsp;Guardar</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal de previsualización  - Imagen Portada-->
<div class="modal fade" id="openModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-title" id="exampleModalLabel">
                <p class="f-t3">Validación de contenido</p>
                <p class="f-t4">Verifica el contenido que está subiendo</p>
            </div>
            <div class="modal-body mt-3" style="text-align: center;">
                <img alt="" src="{{filePrev}}" style="width: 20em;height: 30em;">
            </div>
            <div class="modal-footer" style="border-top: 0;">
                <div class="col d-grid gap-5 d-md-flex justify-content-md-end">
                    <button class="button mt-2 boton" data-bs-dismiss="modal" type="button">&nbsp;&nbsp;Cerrar</button>
                </div>
            </div>
        </div>
    </div>
</div>
