import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ChartComponent, ApexAxisChartSeries, ApexChart, ApexXAxis, ApexDataLabels,
  ApexStroke, ApexMarkers, ApexYAxis, ApexGrid, ApexTitleSubtitle, ApexLegend, ApexFill, ApexNonAxisChartSeries,
  ApexPlotOptions,
} from "ng-apexcharts";
import { Funciones } from '../../classes/funciones';
import { Graficas } from '../../classes/graficas';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  seriesPri: ApexNonAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  colors: string[];
  yaxis: ApexYAxis;
  grid: ApexGrid;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  labels: string[];
  plotOptions: ApexPlotOptions;
};

@Component({
  selector: 'incentivos_dashboar',
  templateUrl: './dashboar.component.html',
  styleUrls: ['./dashboar.component.css']
})
export class DashboarComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent = {} as ChartComponent;

  @Input() inputData: any = [];
  @Input() fechaIni: any;
  @Input() fechaFin: any;

  public char_Sesiones_diarias: ChartOptions = {} as ChartOptions;
  public char_Sesiones_unicas: ChartOptions = {} as ChartOptions;
  public char_sentimientos: ChartOptions = {} as ChartOptions;
  public char_radial_plan: ChartOptions = {} as ChartOptions;
  public char_radial_plan_test: ChartOptions = {} as ChartOptions;
  public char_radial_camp: ChartOptions = {} as ChartOptions;
  public char_radial_camp_test: ChartOptions = {} as ChartOptions;
  public char_radial_pod: ChartOptions = {} as ChartOptions;
  public char_radial_pod_test: ChartOptions = {} as ChartOptions;
  public datos_chart: Partial<ChartOptions> = {} as Partial<ChartOptions>;
  public chartFuncionalidad: Partial<ChartOptions> = {} as Partial<ChartOptions>;
  public chartTraficoDiario: ChartOptions = {} as ChartOptions;

  periodo: string = "";
  nombre: any = sessionStorage.getItem('nombre');
  nomina: any = sessionStorage.getItem('nomina');
  nombreL: any;
  nominaL: any;
  public empresa: number = 0;
  emp: any[] = [];
  puesto: any[] = [];
  ubicacion: any[] = [];
  categoria: any[] = [];
  datos: any[] = [];
  datos_excel: any[] = [];
  variables: any[] = [];
  categoriasa: any[] = [];
  categoriasb: any[] = [];
  aux: any;
  public objPCS: any = {
      col_aprob_test: 0,
      comentarios: "0",
      compartidos: 0,
      consultado_porciento: 0,
      disfavoritos: 0,
      dislike_porcent: 0,
      favoritos: 0,
      like_porcent: 0,
      nombre: "Planes",
      post_publicados: "0",
      sinfavoritos: 0,
      valoraciones: "0"
  }

  constructor(
    public snackBar: MatSnackBar,
    public funcion: Funciones,
    public grafica: Graficas
  ) {
    this.nombreL = this.nombre;
    this.nominaL = this.nomina;
  }

  ngOnInit(): void {
    this.datosFuncionalidad();
    this.chartFuncionalidad = this.datos_chart;
    this.datosTraficoDiario();
    this.datosSentimientos();
    this.report();
    this.periodo = this.funcion.getFechaFormat2(this.fechaIni) + " - " + this.funcion.getFechaFormat2(this.fechaFin);

  }

  datosFuncionalidad() {
    this.datos_chart = {
      series: [
        {
          name: "Funcionalidad",
          data: this.inputData.funcionalidades_mas_populares.y
        }
      ],
      chart: {
        height: 240,
        type: "bar",
        toolbar: {
          show: false
        },
        events: {
        }
      },
      colors: ["#90ADC0", "#94CDC5", "#A992BC", "#FED9BC", "#6792AD", "#64257E", "#EBA875", "#8C6CA6", "#4DAB9E", "#DB699C", "#A0CFC9", "#CA97E9"],
      plotOptions: {
        bar: {
          distributed: true,
          columnWidth: '55%',
          borderRadius: 15,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
          colors: {
            ranges: [{
              from: 0,
              to: 0,
              color: undefined
            }],
            backgroundBarColors: [],
            backgroundBarOpacity: 1,
            backgroundBarRadius: 15,
          },

        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: '10px',
          colors: ["#304758"]
        }
      },
      grid: {
        show: false
      },
      xaxis: {
        categories: this.inputData.funcionalidades_mas_populares.x,
        labels: {
          style: {
            fontSize: '10px',
          },
          formatter: function (val) {
            return "";
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            fontSize: '10px',
          },
          formatter: function (val) {
            return val + "%";
          }
        }
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
        fontSize: '7px',
        floating: false,
        offsetY: -10,
        offsetX: -30,
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: undefined,
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0
        },
      }

    };
  }

  datosTraficoDiario() {
    if (this.inputData.trafico_diario) {
      this.inputData.trafico_diario.categorias.forEach((c: string) => {
        let fs = c.split("-");
        let m = fs[1].replace('0', '');
        this.categoriasa.push(m + "." + fs[2])
      });

      this.inputData.trafico_diario.series.forEach((serie: any) => {
        let horas: any[] = [];
        serie.data.forEach((hour: any) => {
          let h = 0;
          if (typeof (hour) == 'string') {
            let shour = hour.split(":");
            h = parseInt(shour[0]) + Math.round(parseInt(shour[1]) / 60);
          }
          horas.push(h == 0 ? hour : h);
        });
        serie.data = horas;
      });

      this.chartTraficoDiario = {
        plotOptions: {
        },
        labels: [" "],
        seriesPri: [],
        series: this.inputData.trafico_diario.series,//data.series,
        chart: {
          height: 240,
          type: "line",
          toolbar: {
            show: false
          }
        },

        fill: {
        },
        colors: ["#90ADC0", "#94CDC5", "#A992BC", "#FED9BC", "#6792AD", "#64257E", "#EBA875", "#8C6CA6", "#4DAB9E", "#DB699C", "#A0CFC9", "#CA97E9"],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        title: {
          text: "",
          align: "left",
          style: {
            color: "#FFFFFF"
          }
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5
          }
        },
        markers: {
          size: 4,
          colors: ["#90ADC0", "#94CDC5", "#A992BC", "#FED9BC", "#6792AD", "#64257E", "#EBA875", "#8C6CA6", "#4DAB9E", "#DB699C", "#A0CFC9", "#CA97E9"],
          strokeWidth: 0,
          shape: "circle",
          radius: 2,
          showNullDataPoints: true,
          hover: {
            size: 1,
            sizeOffset: 3
          }
        },
        xaxis: {
          categories: this.inputData.trafico_diario.categorias,// data.categorias,
          tooltip: {
            enabled: false
          },
          labels: {
            style: {
              fontSize: '8px',
            },
            formatter: function (val) {
              if (val !== undefined) {
                let s: string[] = String(val).split('-');
                let m: number = parseInt(s[1]);
                return s[2] + " " + getMes(m.toString());
              }
              return val;
            }
          }
        },
        yaxis: {
          tooltip: {
            enabled: false
          },
          labels: {
            style: {
              fontSize: '10px',
            },
            formatter: function (val) {
              return Math.round(val) + " h";
            }
          },
          min: 1,
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          fontSize: '7px',
          floating: false,
          offsetY: 20,
          offsetX: -30
        }
      };
    }
  }

  datosSentimientos() {
    if (this.inputData.sentimientos) {
      this.inputData.sentimientos.categorias.forEach((c: string) => {
        let fs = c.split("-");
        let m = fs[1].replace('0', '');
        this.categoriasb.push(m + "." + fs[2])
      });

      this.char_sentimientos = {
        plotOptions: {
        },
        labels: [" "],
        seriesPri: [],
        series: this.inputData.sentimientos.analisis,
        chart: {
          type: "area",
          height: 230,
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false,
          }
        },
        colors: ["#FF0000", "#77C121", "#E7E5E3"],
        dataLabels: {
          enabled: false
        },
        markers: {
        },
        fill: {
          type: ['solid', 'gradient'],
          gradient: {
            opacityFrom: 20,
            opacityTo: 2
          }
        },
        legend: {

          position: "bottom",
          horizontalAlign: "center",
          floating: true,
          fontSize: '7px',
        },
        xaxis: {
          type: "datetime",
          categories: this.categoriasb,
        },
        yaxis: {
        },
        grid: {
        },
        stroke: {
          curve: "straight"
        },
        title: {
        }
      };
    }
  }

  public report() {
    
    if (this.inputData) {
      if (this.inputData.sesiones_diarias) {
        this.char_Sesiones_diarias = this.grafica.charOptions(this.inputData.sesiones_diarias.series, this.inputData.sesiones_diarias.categorias);
        this.inputData.sesiones_diarias.total = Number(this.inputData.sesiones_diarias.total).toLocaleString('en');
      }
      if (this.inputData.sesiones_unicas) {
        this.char_Sesiones_unicas = this.grafica.charOptions(this.inputData.sesiones_unicas.series, this.inputData.sesiones_unicas.categorias);
        this.inputData.sesiones_unicas.total = Number(this.inputData.sesiones_unicas.total).toLocaleString('en');
      }
      if (this.inputData.sentimientos) {
        this.setSentimientos();
      }
      this.setPlanes();
      this.setCampanias();
      this.setPodcast();

    }
  }

  setSentimientos() {
    this.inputData.sentimientos.total = Number(this.inputData.sentimientos.total).toLocaleString('en');
    this.inputData.sentimientos.negativo.valor = Number(this.inputData.sentimientos.negativo.valor).toLocaleString('en');
    this.inputData.sentimientos.neutro.valor = Number(this.inputData.sentimientos.neutro.valor).toLocaleString('en');
    this.inputData.sentimientos.positivo.valor = Number(this.inputData.sentimientos.positivo.valor).toLocaleString('en');
  }

  setPlanes() {
    if(this.inputData.planes == undefined || this.inputData.planes == null){
      this.inputData.planes = this.objPCS;
    }
    let serie_planes: any = this.inputData.planes?.consultado_porciento ? this.inputData.planes?.consultado_porciento : [0];
    this.char_radial_plan = this.grafica.charOptionsRadial(serie_planes, "#CA005D", 150);
    let serie_test_planes: any = this.inputData.planes.col_aprob_test ? this.inputData.planes.col_aprob_test : [0];
    this.char_radial_plan_test = this.grafica.charOptionsRadial(serie_test_planes, "#DD2178", 145);
    this.inputData.planes.valoraciones = Number(this.inputData.planes.valoraciones).toLocaleString('en')?Number(this.inputData.planes.valoraciones).toLocaleString('en'):0;
    this.inputData.planes.comentarios = Number(this.inputData.planes.comentarios).toLocaleString('en')?Number(this.inputData.planes.comentarios).toLocaleString('en'):0;
    this.inputData.planes.post_publicados = Number(this.inputData.planes.post_publicados).toLocaleString('en')?Number(this.inputData.planes.post_publicados).toLocaleString('en'):0;
  }

  setCampanias() {
    if(this.inputData.campanias == undefined || this.inputData.campanias == null){
      this.inputData.campanias = this.objPCS;
    }
    this.inputData.campanias.valoraciones = Number(this.inputData.campanias?.valoraciones).toLocaleString('en')?Number(this.inputData.campanias?.valoraciones).toLocaleString('en'):0;
    this.inputData.campanias.comentarios = Number(this.inputData.campanias.comentarios).toLocaleString('en');
    this.inputData.campanias.post_publicados = Number(this.inputData.campanias.post_publicados).toLocaleString('en');
    let serie_campanias: any = this.inputData.campanias.consultado_porciento ? this.inputData.campanias.consultado_porciento : [0];
    this.char_radial_camp = this.grafica.charOptionsRadial(serie_campanias, "#8C6CA6", 150);
    let serie_test_campanias: any = this.inputData.campanias.col_aprob_test ? this.inputData.campanias.col_aprob_test : [0];
    this.char_radial_camp_test = this.grafica.charOptionsRadial(serie_test_campanias, "#A88CBE", 145);

  }

  setPodcast() {
    if(this.inputData.podcast == undefined || this.inputData.podcast == null){
      this.inputData.podcast = this.objPCS;
    }
    let serie_podcast: any = this.inputData.podcast.consultado_porciento ? this.inputData.podcast.consultado_porciento : [0];
    this.char_radial_pod = this.grafica.charOptionsRadial(serie_podcast, "#4DAB9E", 150);
    let serie_test_podcast: any = this.inputData.podcast.col_aprob_test ? this.inputData.podcast.col_aprob_test : [0];
    this.char_radial_pod_test = this.grafica.charOptionsRadial(serie_test_podcast, "#64C5B7", 145);
    this.inputData.podcast.valoraciones = Number(this.inputData.podcast.valoraciones).toLocaleString('en');
    this.inputData.podcast.comentarios = Number(this.inputData.podcast.comentarios).toLocaleString('en');
    this.inputData.podcast.post_publicados = Number(this.inputData.podcast.post_publicados).toLocaleString('en');
  }
}

function getMes(mes: string) {
  switch (mes) {
    case "1":
      return "ene";
    case "2":
      return "feb";
    case "3":
      return "mar";
    case "4":
      return "abr";
    case "5":
      return "may";
    case "6":
      return "jun";
    case "7":
      return "jul";
    case "8":
      return "ago";
    case "9":
      return "sep";
    case "10":
      return "oct";
    case "11":
      return "nov";
    case "12":
      return "dic";
    default:
      return "";
  }

}


