<incentivos-navbar [nombre]="nombreL" [nomina]="nominaL"></incentivos-navbar>
<!-- SI Consola Empresa -->
<div class="loading-indicator" *ngIf="funcion.visibility | async">
  <img src="/assets/img/spinner.gif" alt="">
</div>

<div class="d-flex justify-content-center align-box">
  <div class="box2">
    <nav
      style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
      aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item b1" href="javascript:;">Configuración SI+</li>
        <li class="breadcrumb-item b1" href="javascript:;">Configuración de catálogos</li>
        <li class="breadcrumb-item b2">Catálogo de empresas</li>
      </ol>
    </nav>
  </div>
</div>

<div class="d-flex justify-content-center align-box2">
  <div class="box">
    <div class="d-grid gap-2 d-md-flex justify-content-md-end align-btn">
      <button type="button" class="btn btn-primary boton" (click)="newEmpresa()">+ Nueva empresa</button>
    </div>
    <br><br>
    <!-- se comento para pruebas -->
    <div class="row mt-4">
      <incentivos-tabla-registros [datos]="empresa" [wait]="wait" [columns]="columns_catalogo_empresa" [url]="url_empresa" [ver]="true"></incentivos-tabla-registros>
    </div>
  </div>
</div>
