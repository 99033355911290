import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';


@Injectable({ providedIn: 'root' })

export class Funciones{

  vext: any;
  visibility: BehaviorSubject<boolean>;


  constructor(public snackBar: MatSnackBar){
    this.visibility = new BehaviorSubject<boolean>(false);
  }

  padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }

  formatDate() {
    let date = new Date();
    return [
        date.getFullYear(),
        this.padTo2Digits(date.getMonth() + 1),
        this.padTo2Digits(date.getDate())
    ].join('-');
  }

  getFechaFormat(fecha: any) {
    let date = new Date(fecha);
    let month = date.toLocaleString('default', { month: 'long' });
    return date.getDay() + " " + month + " " + date.getFullYear();
  }

  getFechaFormat2(fecha: any) {
    let fec = fecha.split('-');
    fec = fec[0]+'-'+Math.abs(fec[1])+'-'+Math.abs(fec[2]);
    let date = new Date(fec);
    let day = date.getDate();
    let month = date.toLocaleString('es-MX', { month: 'long' });
    return day + " " + month + " " + date.getFullYear();
}

  downloadExcel(blob: any, type: string, filename: string) {
    let binaryData = [];
    binaryData.push(blob);
    const url = window.URL.createObjectURL(new Blob(binaryData, { type: type }));
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.href = url;
    /** Para descarga se puede cambiar por un window.open */
    a.download = filename;
    a.click();
  }

  downloadExcelArchivo(blob: any, filename: string) {

    const url = blob;
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.href = url;
    /** Para descarga se puede cambiar por un window.open */
    a.download = filename;
    a.click();
  }

  getFechaMes(fecha: any) {
   let date = new Date(fecha);
   let month = date.toLocaleString('default', { month: 'long' });
   return month + " " + date.getFullYear();
  }

  extensionValida(e: any){
    this.vext = e.target.value;
    let ext = this.vext.substring(this.vext.lastIndexOf('.'), this.vext.length);
    let archval = this.validaE(ext);
    if (archval == 1) {
      return true;
    } else {
      this.snackBar.open('Archivo inválido.', 'close', {
        duration: 2500,
      });
      return false;
    }
  }

  validaE(ext: string) {
    ext = ext.toUpperCase();
    return (ext === '.JPEG' || ext === '.JPG' || ext === '.PNG') ? 1 : 0;
  }


  show() {
    this.visibility.next(true);
  }

  hide() {
    this.visibility.next(false);
  }


}


