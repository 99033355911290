<incentivos-navbar [nombre]="nombreL" [nomina]="nominaL"></incentivos-navbar>

<!-- Importar los datos para el perfil -->
<div class="loading-indicator" *ngIf="funcion.visibility | async">
    <img src="/assets/img/spinner.gif" alt="">
</div>
    <div class="d-flex justify-content-center align-box2">
        <div class="box2">
            <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
                aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item b1" href="javascript:;" (click)="return()">Configuración de
                        Consultas
                        de Usabilidad SI+</li>
                    <li class="breadcrumb-item b2" href="javascript:;">Nuevo slide</li>
                </ol>
            </nav>
        </div>
    </div>
    <div *ngIf="principal">
    <div class="d-flex justify-content-center align-box">
        <div class="box">
            <div class="container cuerpo">
                <div class="row">
                    <div class="col-12" style="padding-bottom: 0.8em;">
                        <span style="font-size: 44px;">Configuración Reporte Usabilidad
                            SI+</span>
                    </div>
                    <div class="col-12" style="padding-bottom: 3em;">
                        <span style="font-size: 14px;">* Campos obligatorios</span>
                    </div>

                    <div class="col-4">
                        <label for="idempresa" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                            <span class="h6 small bg-white text-muted px-1">Empresa</span>
                        </label>
                        <select class="form-control form-select mt-2" id="idempresa" [(ngModel)]="idempresa"
                            name="idempresa">
                            <option [value]="0">Selecciona</option>
                            <option *ngFor="let empresa of empu" [value]="empresa.id">{{empresa.nombre}}</option>
                        </select>
                    </div>
                    <div class="col-4">
                        <label for="idUbicacionN" class="ms-2 position-absolute"
                            style="margin-top: -0.25rem !important">
                            <span class="h6 small bg-white text-muted px-1">Ubicación de negocio</span>
                        </label>
                        <select class="form-control form-select mt-2" id="idUbicacionN" [(ngModel)]="idUDN"
                            name="idUbicacionN">
                            <option [value]="0">Selecciona</option>
                            <option *ngFor="let ud of udnu" [value]="ud.id">{{ud.nombre}}</option>
                        </select>
                    </div>
                    <div class="col-4">
                        <label for="idUbicacionN" class="ms-2 position-absolute"
                            style="margin-top: -0.25rem !important">
                            <span class="h6 small bg-white text-muted px-1">Dirección</span>
                        </label>
                        <select class="form-control form-select mt-2" id="direccion" [(ngModel)]="idDireccion"
                            name="idUbicacionN">
                            <option [value]="0">Selecciona</option>
                            <option *ngFor="let direc of direccion" [value]="direc.id">{{direc.nombre}}</option>
                        </select>
                    </div>

                    <div class="col-12" style="padding-top:2em"></div>

                    <div class="col-4">
                        <label for="idUbicacionN" class="ms-2 position-absolute"
                            style="margin-top: -0.25rem !important">
                            <span class="h6 small bg-white text-muted px-1">Subdirección</span>
                        </label>
                        <select class="form-control form-select mt-2" id="subdireccion" [(ngModel)]="idSubdireccion"
                            name="idUbicacionN">
                            <option [value]="0">Selecciona</option>
                            <option *ngFor="let sub of subdireccion" [value]="sub.id">{{sub.nombre}}</option>
                        </select>
                    </div>
                    <div class="col-4">
                        <label for="idUbicacion" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                            <span class="h6 small bg-white text-muted px-1">Ubicación</span>
                        </label>
                        <select class="form-control form-select mt-2" id="idUbicacion" [(ngModel)]="idUbicacion"
                            name="idUbicacion">
                            <option [value]="0">Selecciona</option>
                            <option *ngFor="let u of ubicacionu" [value]="u.id">{{u.nombre}}</option>
                        </select>
                    </div>
                    <div class="col-4">
                        <label for="idPuesto" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                            <span class="h6 small bg-white text-muted px-1">Puesto</span>
                        </label>
                        <select class="form-control form-select mt-2" id="idPuesto" [(ngModel)]="idPuesto"
                            name="idPuesto">
                            <option [value]="0">Selecciona</option>
                            <option *ngFor="let p of puestou" [value]="p.id">{{p.nombre}}</option>
                        </select>
                    </div>
                    <div class="col-12" style="padding-top:2em"></div>
                    <div class="col-4">
                        <label for="idColaboradorUsabilidad" class="ms-2 position-absolute"
                            style="margin-top: -0.25rem !important">
                            <span class="h6 small bg-white text-muted px-1">Colaborador</span>
                        </label>
                        <input id="idColaboradorUsabilidad" type="text" class="mt-2 form-control" placeholder="Nómina"
                            [(ngModel)]="idColaboradorUsabilidad" maxlength="10" name="idColaboradorUsabilidad">
                    </div>
                    <div class="col-8"></div>
                    <div class="col-12" style="padding-top:4em"></div>
                    <div class="col-4">
                        <label for="fechaIni" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                            <span class="h6 small bg-white text-muted px-1">Fecha inicial*</span>
                        </label>
                        <input id="fechaIni" type="text" class="mt-2 form-control" onfocus="(this.type='date')"
                            onblur="if(this.value==''){this.type='text'}" onkeydown="return false" required placeholder="Selecciona"
                            [(ngModel)]="fechaIniUsabilidad" name="fechaIni">
                    </div>

                    <div class="col-4">
                        <label for="fechaFin" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                            <span class="h6 small bg-white text-muted px-1">Fecha Fin*</span>
                        </label>
                        <input id="fechaFin" type="text" class="mt-2 form-control" placeholder="Selecciona"
                            onfocus="(this.type='date')" onkeydown="return false" onblur="if(this.value==''){this.type='text'}" required
                            [(ngModel)]="fechaFinUsabilidad" min="{{fechaIniUsabilidad}}" max="{{fecha}}" name="fechaFin">
                    </div>
                    <div class="col-4">

                    </div>
                    <div class="col-12" style="padding-top:4em"></div>

                    <div class="col-6"></div>
                    <div class="col-6">
                        <button type="button" class="btn  btn-lg me-md-2 boton3"
                            (click)="onClickDashboarUsabilidad()">Dashboard</button>
                        <button type="button" class="btn btn-lg me-md-2 boton" (click)="onClickExcelUsabilidad(0)">Excel</button>
                        <button type="button" class="btn btn-lg boton" (click)="onClickExcelUsabilidad(1)">Excel clicks</button>
                    </div>
                </div>
            </div>
        </div>
        <br>
    </div>
</div>
<div *ngIf="!principal">
    <incentivos_dashboar [inputData]="data" [fechaIni]="fechaIniUsabilidad" [fechaFin]="fechaFinUsabilidad"></incentivos_dashboar>
</div>
