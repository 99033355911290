<div class="loading-indicator" *ngIf="funcion.visibility | async">
  <img src="/assets/img/spinner.gif" alt="">
</div>
<nav class="navbar navbar-expand-lg navbar-custom">
    <div class="container-fluid">
        <a class="navbar-brand" href="javascript:;" (click)="onClickContenidos()">
        <img class="logo" src="/assets/img/logo_si2.png" alt="">
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <em class="bi bi-grid-3x3-gap-fill"></em>
      </button>

      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav w-100 justify-content-evenly" >
          <li class="nav-item">
            <a class="nav-link menu-link" href="javascript:;" (click)="onClickContenidos()">Publicador contenido</a>
          </li>
          <li class="nav-item">
            <a class="nav-link menu-link" href="javascript:;" (click)="onClickNotificacion()">Notificaciones</a>
          </li>
          <li class="nav-item">
            <a class="nav-link menu-link" href="javascript:;">Retos</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link" href="javascript:;" id="navbarDropdown" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside">
              Configuración SI+
            </a>
            <ul class="dropdown-menu d-men" aria-labelledby="navbarDropdown">
              <li class="dropend">
                <a href="javascript:;" class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown">
                  Configuración de catálogos
                </a>
                <ul class="dropdown-menu shadow d-men">
                  <li class="dropdown-item" href="javascript:;" (click)="onClickEmpresas()">Catálogo de empresas</li>
                </ul>
              </li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="javascript:;" (click)="onClickImportar()">Importar datos perfil</a></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="javascript:;" (click)="onClickConfiguraPlanesCampanias()">Configuración de planes y campañas</a></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="javascript:;" (click)="onClickConfiguraInicio()">Configuración de inicio</a></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="javascript:;" (click)="onClickVerVideo()">Prueba video</a></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="javascript:;" (click)="onClickConfiguraTour()">Configuración del Tour</a></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="javascript:;" (click)="onClickCargaIncentivos()">Carga incentivos</a></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="javascript:;" (click)="onClickCargaSondeo()">Configuración Cuestionario</a></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="javascript:;" (click)="onClickPermisosPuesto()">Configuración de permisos</a></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="javascript:;" (click)="onClickCargaCalculoManual()">Carga sustitución cálculo periodo</a></li>
              
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link" href="javascript:;" id="navbarDropdown" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside">
              Data Si+
            </a>
            <ul class="dropdown-menu d-men" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" href="javascript:;" (click)="onClickUsabilidad()">Reporte Usabilidad SI+</a></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="javascript:;" (click)="onClicReporteNps()">Reporte NPS</a></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="javascript:;" >Reporte Retos</a></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="javascript:;" (click)="onClicReportePerfil()">Reporte Mi Perfil</a></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="javascript:;" (click)="onClicCuestionario()">Reporte Cuestionarios</a></li>
            </ul>
          </li>
        </ul>
        <ul class="navbar-nav" id="user">
          <li class="nav-item">
            <a class="nav-link info-link dropdown-toggle" href="javascript:;">
              {{nombre}}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link out-link" href="javascript:;" (click)="onLogout()">
              <em class="bi bi-box-arrow-right"></em>
            </a>
          </li>
        </ul>
      </div>

    </div>
</nav>
