import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServiciosImp } from '../../services/services';
import { Funciones } from '../../classes/funciones'


@Component({
  selector: 'incentivos_reporte_usabilidad',
  templateUrl: './reporte_usabilidad.component.html',
  styleUrls: ['./reporte_usabilidad.component.css']
})
export class ReporteUsabilidadComponent implements OnInit {

  nombre: any = sessionStorage.getItem('nombre');
  nomina: any = sessionStorage.getItem('nomina');
  nombreL: any;
  nominaL: any;
  public empresa: number = 0;
  empu: any[] = [];
  udnu: any[] = [];
  puestou: any[] = [];
  ubicacionu: any[] = [];
  direccion: any[] = [];
  subdireccion: any[] = [];
  categoriau: any[] = [];
  datos: any[] = [];
  datos_excel: any[] = [];
  variables: any[] = [];

  idempresa: number = 0;
  idUDN: string = '0';
  idDireccion: string = '0';
  idSubdireccion: string = '0';
  idUbicacion: string = '0';
  idPuesto: string = '0';
  idColaboradorUsabilidad: string = "";
  fechaIniUsabilidad: any;
  fechaFinUsabilidad: any;
  fecha: string = "";
  data: any[] = [];
  principal: boolean = true;

  constructor(
    private router: Router,
    public snackBar: MatSnackBar,
    public servicios: ServiciosImp,
    public funcion: Funciones
  ) {
    this.nombreL = this.nombre;
    this.nominaL = this.nomina;
  }

  ngOnInit(): void {
    this.fecha = this.funcion.formatDate();
    this.cargaUDNUsabilidad();
    this.cargaPuestosUsabilidad();
    this.cargaUbicacionUsabilidad();
    this.cargaCatalogoEmpresaUsabilidad();
    this.cargaDireccionUsabilidad();
    this.cargaSubdireccionUsabilidad();

  }

  cargaUDNUsabilidad() {
    this.servicios.getCatalogoUDN().subscribe(datosus1 => {
      if (!datosus1.error) {
        this.udnu = datosus1.data;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }

  cargaPuestosUsabilidad() {
    this.servicios.getCatalogoPuestos().subscribe(datosus2 => {
      if (!datosus2.error) {
        this.puestou = datosus2.data;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }

  cargaUbicacionUsabilidad() {
    this.servicios.getCatalogoUbicacion().subscribe(datosus3 => {
      if (!datosus3.error) {
        this.ubicacionu = datosus3.data;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }
  cargaDireccionUsabilidad() {
    this.servicios.getCatalogoDireccion().subscribe(direcusa => {
      if (!direcusa.error) {
        this.direccion = direcusa.data;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }
  cargaSubdireccionUsabilidad() {
    this.servicios.getCatalogoSubdireccion().subscribe(subdirecusa => {
      if (!subdirecusa.error) {
        this.subdireccion = subdirecusa.data;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }

  cargaCatalogoEmpresaUsabilidad() {
    this.servicios.getCatalogoEmpresaPerfil().subscribe(datosus4 => {
      if (!datosus4.error) {
        this.empu = datosus4.data;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }

  objUsabilidad(){
    return {
      "fecha_inicio": this.fechaIniUsabilidad,
      "fecha_fin": this.fechaFinUsabilidad,
      "id_empresa": Number(this.idempresa) == 0 ? 13 : Number(this.idempresa),
      "id_udn": this.idUDN == '0' ? '': this.idUDN,
      "id_puesto": this.idPuesto == '0' ? '': this.idPuesto,
      "id_ubicacion": this.idUbicacion == '0' ? '': this.idUbicacion,
      "id_direccion": this.idDireccion == '0' ? '': this.idDireccion,
      "id_subdireccion": this.idSubdireccion == '0' ? '': this.idSubdireccion,
      "nomina": Number(this.idColaboradorUsabilidad)
    };

  }

  onClickDashboarUsabilidad() {
    if (this.fechaIniUsabilidad !== undefined && this.fechaIniUsabilidad !== "" && this.fechaFinUsabilidad !== undefined && this.fechaFinUsabilidad !== "") {
      let fIni = new Date(this.fechaIniUsabilidad);
      let fFin = new Date(this.fechaFinUsabilidad);
      if (fIni <= fFin) {
        let obj = this.objUsabilidad();
        this.srvConsulta(obj);
      } else {
        this.snackBar.open("Error en las fechas seleccionadas", 'close', { duration: 2500 });
      }
    } else {
      this.snackBar.open("Debes llenar los campos obligatorios", 'close', { duration: 2500 });
    }
  }

  srvConsulta(obj: any) {
    this.servicios.postService('analitica/consulta/', obj).toPromise().then(
      (res: any) => {
        if (res.status == 401) {
          this.router.navigate(['login']);
        }
        this.data = res.data;
        console.log(this.data);
        this.principal = false;
        this.funcion.hide();
      }
    ).catch((error) => {
      this.snackBar.open(error.message, 'close', { duration: 2500 });
      this.funcion.hide();
    });
  }
  validapuestoubicacion(){
    if(this.idPuesto == '0'){
      this.idPuesto = '';
    }
    if(this.idUbicacion == '0'){
      this.idUbicacion = '';
    }
  }

  onClickExcelUsabilidad(id:any){
    if (this.fechaIniUsabilidad !== undefined && this.fechaIniUsabilidad !== "" && this.fechaFinUsabilidad !== undefined && this.fechaFinUsabilidad !== "") {
      this.validapuestoubicacion();
        let paramusab = this.objUsabilidad();
      if(id== 0){
        this.servicios.downExcel(paramusab,'consulta').subscribe(datoexcelusabilidad => {
          if (!datoexcelusabilidad.error) {
            this.funcion.downloadExcel(datoexcelusabilidad,'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'reporte_usabilidad.xlsx');
            this.funcion.hide();
          }else{
            this.router.navigate(['login']);
            this.funcion.hide();
          }
        });
      }else{
          this.excelClicks(paramusab);
      }
      } else {
        this.snackBar.open("Debes llenar los campos obligatorios", 'close', {
            duration: 2500,
        });
    }
  }
    excelClicks(paramusab:any){
      this.servicios.downExcelClicks(paramusab,'consulta').subscribe(datoexcelusabilidad => {
        if (!datoexcelusabilidad.error) {
          this.funcion.hide();
          /** this.funcion.downloadExcel(datoexcelusabilidad,'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'reporte_clicks_usabilidad.xlsx'); */
          this.snackBar.open("Tu reporte esta siendo generado, en cuanto termine se enviará una notificación ", 'close', { duration: 5000 });
          
        }else{
          this.router.navigate(['login']);
          this.funcion.hide();
        }
      });
    }

  return() {
    this.principal = true;
  }

}
