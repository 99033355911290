import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CurrencyPipe } from '@angular/common';
import { ServiciosImp } from '../../services/services';
import { Funciones } from '../../classes/funciones';



@Component({
  selector: 'incentivos-nuevo-sondeo',
  templateUrl: './nuevo_sondeo.component.html',
  styleUrls: ['./nuevo_sondeo.component.css']
})
export class NuevoSondeoComponent implements OnInit {

  wait = false;
  nombre: any = sessionStorage.getItem('nombre');
  nomina: any = sessionStorage.getItem('nomina');
  nombreL: any;
  usuarioL: any;
  nominaL: any;
  archivo: any;
  archivo_2: any;
  tipo_archivo: any;
  tipo_archivo2: any;
  datos_archivos = new FormData();
  datos_archivos2 = new FormData();

  empsond: any[] = [];
  postsond: any[] = [];
  audiencia: any[] = [];
  mostrar: string = "";
  datos_arch: any[] = [];
  datos_arch2: any[] = [];
  datos_audiencia: any[] = [];

  public titulos = 'Nuevo';
  public id_sondeo: number = 0;
  public empresa: number = 0;
  public tipo_sondeo: number = 0;
  public nombre_insignia: string = "";
  public imagen_insignia: any;
  public imagen_insignia_ruta: string = "";
  public monto_pagar: any;
  public monto_pagar_prev: any;
  public titulo_sondeo: string = "";
  public indicaciones_sondeo: string = "";
  public imagen_sondeo: any;
  public imagen_sondeo_ruta: string = "";
  public fecha_inicial: any;
  public fecha_fin: any;
  public numero_intentos: any;
  public min_aprobacion: any;
  public tiempo_sondeo: any;
  public estatus_sondeo: boolean = false;
  public post_id: any;
  public premio_saldo: boolean = false;
  public premio_insignia: boolean = false;
  public disabled1: boolean = true
  public disabled2: boolean = true
  public estatus: boolean = false
  fecha: string = "";

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public snackBar: MatSnackBar,
    public httpClient: HttpClient,
    public currencyPipe: CurrencyPipe,
    public servicios: ServiciosImp,
    public funcion: Funciones
  ) {
    this.nombreL = this.nombre;
    this.nominaL = this.nomina;
  }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(parametros => {
      this.id_sondeo = parametros.id;
      if (this.id_sondeo != 0) {
        this.titulos = 'Edita';
        let url = 'quizz/' + this.id_sondeo + '/';
        this.servicios.getService(url).toPromise().then(
          (res: any) => {
            if (res.status == 401) {
              this.router.navigate(['login']);
            }
            this.setURLToFile('imagen_insignia');
            this.setURLToFile('imagen_sondeo');

            this.disabled1 = !res.data.premio_insignia;
            this.disabled2 = !res.data.premio_saldo;
            this.titulo_sondeo = res.data.titulo_sondeo;
            this.empresa = res.data.empresa_id;
            this.estatus_sondeo = res.data.estatus;
            this.tiempo_sondeo = res.data.tiempo_sondeo;
            this.premio_saldo = res.data.premio_saldo;
            this.premio_insignia = res.data.premio_insignia;
            this.fecha_inicial = res.data.fecha_inicial;
            this.fecha_fin = res.data.fecha_final;
            this.imagen_insignia = res.data.imagen_insignia;
            this.imagen_insignia_ruta = res.data.imagen_insignia;
            this.imagen_sondeo = res.data.imagen_portada;
            this.imagen_sondeo_ruta = res.data.imagen_portada;
            this.indicaciones_sondeo = res.data.indicaciones_sondeo;
            this.numero_intentos = res.data.intentos;
            this.min_aprobacion = res.data.minimo_aprobacion;
            this.monto_pagar = res.data.monto_ganar;
            this.post_id = res.data.post_id;
            this.nombre_insignia = res.data.nombre_insignia;
            this.wait = true;
            this.funcion.hide();
          },
          (error: any) => {
            this.wait = false;
            this.snackBar.open(error.message, 'close', {
              duration: 2500,
            });
            this.funcion.hide();
          }
        )
      }
      this.fecha = this.funcion.formatDate();
    });

    this.cargaCatalogoPost();
    this.cargaCatalogoEmpresa();
  }

  cargaCatalogoEmpresa() {
    this.servicios.getCatalogoEmpresa().subscribe(datonsondeo1 => {
      if (!datonsondeo1.error) {
        this.empsond = datonsondeo1.data;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }

  cargaCatalogoPost() {
    this.servicios.getCatalogoPost().subscribe(datonsondeo2 => {
      if (!datonsondeo2.error) {
        this.postsond = datonsondeo2.data.post;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }

  array(val: any) {
    this.datos_audiencia = val.array
  }


  public guardarCuestionario() {
    if (this.validaciones1() && this.validaciones2() && this.datos_audiencia.length > 0) {
        this.configAudiencia();
        for (const element of this.datos_arch) {
          this.archivosBucket(element, 1);
        }
        for (const element of this.datos_arch2) {
          this.archivosBucket(element, 2);
        }
        if (this.id_sondeo == 0) {
          this.guardarSondeo(this.getBody());
        } else {
          this.actualizaSondeo(this.getBody());
        }
      }
  }

  validaciones1() {
    if (this.empresa !== 0 && this.premio_insignia !== undefined && this.post_id !== 0
      && this.premio_saldo !== undefined && this.min_aprobacion !== 0 && this.titulo_sondeo !== ''
      && this.indicaciones_sondeo !== '')
      return true;
    else
      return false;
  }

  validaciones2() {
    if (this.fecha_inicial !== undefined && this.fecha_fin !== undefined
      && this.numero_intentos !== undefined && this.numero_intentos > 0 && this.tiempo_sondeo !== undefined
      && this.tiempo_sondeo > 0 && this.imagen_sondeo !== undefined && this.imagen_sondeo != "")
      return true;
    else
      return false;
  }


  getBody() {
    return {
      "empresa_id": this.empresa,
      "premio_insignia": this.premio_insignia,
      "premio_saldo": this.premio_saldo,
      "titulo_sondeo": this.titulo_sondeo,
      "indicaciones_sondeo": this.indicaciones_sondeo,
      "imagen_insignia": String(localStorage.getItem('img1')),
      "imagen_portada": String(localStorage.getItem('img2')),
      "fecha_inicial": this.fecha_inicial,
      "fecha_final": this.fecha_fin,
      "intentos": this.numero_intentos,
      "minimo_aprobacion": this.min_aprobacion,
      "tiempo_sondeo": this.tiempo_sondeo,
      "estatus": this.estatus_sondeo,
      "monto_ganar": this.monto_pagar_prev,
      "post_id": this.post_id,
      "audiencia": this.audiencia,
      "nombre_insignia": this.nombre_insignia
    }
  }

  configAudiencia() {
    for (let datos of this.datos_audiencia) {
      let body_audiciencia = {
        "id_empresa": datos.id_empresa,
        "id_puesto": datos.id_puesto,
        "id_ubicacion": datos.id_ubicacion,
        "id_udn": datos.id_udn,
        "id_direccion": datos.id_direccion === null ? "" : datos.id_direccion,
        "id_subdireccion": datos.id_subdireccion === null ? "" : datos.id_subdireccion,
        "nomina": datos.nomina === '' ? 0 : datos.nomina
      }
      this.audiencia.push(body_audiciencia);
    }
  }

  archivosBucket(element: any, tipo: number) {
    this.httpClient.post(environment.serviceUrl + 'servicios/archivos/bucket/', element).subscribe(
      (data: any) => {
        if (tipo == 1) {
          this.imagen_insignia_ruta = data.data.ruta;
          localStorage.setItem('img1', this.imagen_insignia_ruta);
        } else {
          this.imagen_sondeo_ruta = data.data.ruta;
          localStorage.setItem('img2', this.imagen_sondeo_ruta);
        }
      }, (error: any) => {
        console.log(error)
        this.funcion.hide();
      }
    )
  }

  guardarSondeo(body: any) {
    debugger;
    this.servicios.regSondeo(body).subscribe(datosondeoreg => {
      if (!datosondeoreg.error) {
        this.id_sondeo = datosondeoreg.data.id;
        this.snackBar.open('Datos guardados correctamente', 'close', {
          duration: 2500,
        });
        this.funcion.hide();
        this.router.navigate(['nuevo_sondeo/' + this.id_sondeo]);
      } else {
        this.wait = false;
        this.snackBar.open('Error al guardar los datos', 'close', {
          duration: 2500,
        });
        this.funcion.hide();
      }
    });
  }

  actualizaSondeo(body: any) {
    this.servicios.actSondeo(body, this.id_sondeo).subscribe(datosondeoact => {
      if (!datosondeoact.error) {
        this.wait = false;
        this.snackBar.open('Datos actualizados correctamente', 'close', {
          duration: 2500,
        });
        this.funcion.hide();
        this.router.navigate(['nuevo_sondeo/' + this.id_sondeo]);
      } else {
        this.wait = false;
        this.snackBar.open('Error al actualizar los datos', 'close', {
          duration: 2500,
        });
        this.funcion.hide();
      }
    });
  }


  public change_premio(e: any) {
    if (e.checked) {
      this.disabled1 = false;
    } else {
      this.disabled1 = true;
    }
  }

  public change_saldo(e: any) {
    if (e.checked) {
      this.disabled2 = false;
    } else {
      this.disabled2 = true;
    }
  }

  public change_estatus(e: any) {
    if (e.checked) {
      this.estatus = true;
    } else {
      this.estatus = false;
    }
  }

  public change_insignia(e: any): void {
    if(this.funcion.extensionValida(e)){
      this.tipo_archivo = '';
      this.datos_archivos.delete('nombre_archivo');
      const index = this.datos_arch.indexOf(this.datos_archivos);
      if (index > -1) {
        this.datos_arch.splice(index, 1);
      }
      this.archivo = e.target.files[0];
      this.datos_archivos.append('nombre_archivo', this.archivo);
      this.datos_archivos.append('carpeta', 'quizz');
      this.datos_arch.push(this.datos_archivos);
    }else{
      this.imagen_insignia = '';
    }
  }

  public change_sondeo(e: any): void {
    if(this.funcion.extensionValida(e)){
      this.tipo_archivo2 = '';
      this.datos_archivos2.delete('nombre_archivo');
      const index = this.datos_arch2.indexOf(this.datos_archivos2);
      if (index > -1) {
        this.datos_arch2.splice(index, 1);
      }
      this.archivo_2 = e.target.files[0];
      this.datos_archivos2.append('nombre_archivo', this.archivo_2);
      this.datos_archivos2.append('carpeta', 'quizz');
      this.datos_arch2.push(this.datos_archivos2);
    }else{
      this.imagen_sondeo = '';
    }
  }


  public onClickFiltroSondeo() {
    this.router.navigate(['configurar_sondeo']);
  }

  public formatoPeso(e: any) {
    this.monto_pagar_prev = this.monto_pagar;
    this.monto_pagar = this.currencyPipe.transform(this.monto_pagar, '$');
    e.target.value = this.monto_pagar;
  }

  updateCurrencyField(value: string) {
    let num = value.replace(/[$,]/g, "");
    return Number(num);
  }

  setURLToFile(name: string) {
    let list = new DataTransfer();
    list.items.add(new File([], 'archivo cargado'));
    let myFileList = list.files;
    let archivo = <HTMLInputElement>document.getElementById(name);
    archivo.files = myFileList;
  }


  changeFechaInicial() {
    this.fecha_fin = "";
  }

  padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }

  clean() {
    this.disabled1 = true;
    this.disabled2 = true;
    this.titulo_sondeo = "";
    this.empresa = 0;
    this.estatus_sondeo = false;
    this.tiempo_sondeo = "";
    this.premio_saldo = false;
    this.premio_insignia = false;
    this.fecha_inicial = "";
    this.fecha_fin = "";
    this.imagen_insignia = "";
    this.imagen_insignia_ruta = "";
    this.imagen_sondeo = "";
    this.imagen_sondeo_ruta = "";
    this.indicaciones_sondeo = "";
    this.numero_intentos = "";
    this.min_aprobacion = "";
    this.monto_pagar = "";
    this.post_id = undefined;
    this.nombre_insignia = "";
  }
}
