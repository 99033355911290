import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import {
  IColumns,
  IExportExcel,
  IScroll,
  Toolbar,
  IColumnHiding,
  ICheckbox,
  IEditing,
  IColumnchooser,
  IGridOptions

} from '../../models/grid.interfaces';
import { Funciones } from '../../classes/funciones';



@Component({
  selector: 'incentivos-tabla-registros',
  templateUrl: './tabla_registros.component.html'
})
export class TablaRegistrosComponent {

  @Input() wait: any;
  @Input() url:any;
  @Input() ver:any;
  @Input() eliminar:any;
  @Input() descargar:any;
  @Input() procesar:any;
  @Input() actualiza:any;
  @Input() datos: any = [];
  @Input() columns: IColumns[] = [];
  @Output() tregistros: EventEmitter<any> = new EventEmitter();
  @Output() opcupdate: EventEmitter<any> = new EventEmitter();
  @Output() opcdelete: EventEmitter<any> = new EventEmitter();
  @Output() opcdescarga: EventEmitter<any> = new EventEmitter();
  @Output() opcprocesar: EventEmitter<any> = new EventEmitter();

   /* *************** Parametros del Grid de datos ***************** */

 toolbar: Toolbar[] = [];
 exportExcel: IExportExcel = { enabled: true, fileName: 'Contenidos' };
 gridOptions: IGridOptions = { paginacion: 8, pageSize: [8, 16, 24, 32] };
 columnHiding: IColumnHiding = { hide: true };// Parametros de columnas responsivas en caso de no usar hidding priority
 Checkbox: ICheckbox = { checkboxmode: 'none' };// *Activar con multiple*/parametros de para checkbox
 Editing: IEditing = { allowupdate: false, mode: '' };// parametros de para editar grid *cambiar a batch para editar varias celdas a la vez*/
 Columnchooser: IColumnchooser = { columnchooser: true };// parametros de para seleccion de columnas
 scroll: IScroll = { mode: 'standard' }; // Parametros de Scroll
 datosPost: any;
 datosevent: any;
 displayProgressSpinner = false;
 opc1: any;
 opc2: any;


 public onExportingFun: Function = () => {
   this.displayProgressSpinner = true;
 };
 public onExportedFun: Function = () => {
   this.displayProgressSpinner = false;
 };

  constructor(
    private router: Router,
    public snackBar: MatSnackBar,
    public funcion: Funciones
    ) {

   }

   ngOnInit(): void {
    
    if(this.ver){
    /* *************** Parametros del Grid de datos ***************** */
      this.toolbar.push(
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'auto',
          options: {
            width: 90,
            text: 'Editar',
            onClick: this.receiveMessage.bind(this, 'ver')
          },
          visible: false,
          name: 'simple'
        });
     }
     if(this.eliminar){
      this.toolbar.push(
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'auto',
          options: {
            width: 90,
            text: 'Eliminar',
            onClick: this.receiveMessage.bind(this, 'del'),
          },
          visible: false,
          name: 'simple'
        });
     }
     if(this.actualiza){
      this.toolbar.push(
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'auto',
          options: {
            width: 90,
            text: 'Editar',
            onClick: this.receiveMessage.bind(this, 'upd'),
          },
          visible: false,
          name: 'simple'
        });
     }
     if(this.descargar){
      this.toolbar.push(
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'auto',
          options: {
            width: 150,
            text: 'Descargar Archivo',
            onClick: this.receiveMessage.bind(this, 'dow'),
          },
          visible: false,
          name: 'simple'
        });
     }
     if(this.procesar){
      this.toolbar.push(
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'auto',
          options: {
            width: 150,
            text: 'Procesar Archivo',
            onClick: this.receiveMessage.bind(this, 'pro'),
          },
          visible: false,
          name: 'simple'
        });
     }
   }

   /*
Obtenemos la data del componente 'grid-component'.
*/
 datosMessage($event: any) {
  try {
    this.datosevent = $event.data;
    this.tregistros.emit(this.datosevent[0].id );
  } catch (err: any) {
    let msj = err.message;
    try {
      msj = err.error.error.message + '\n' + err.error.text;
    } catch { }
    this.snackBar.open(msj, 'close', {
      duration: 2500,
   });
  }
}

receiveMessage($event:any) {
  const senddata = {
    event: $event,
    data: this.datosevent
  };
  if ($event === 'ver') {
    this.Ver(senddata);
  }else if ($event === 'upd') {
    this.Actualizar(senddata);
  }else if($event === 'del'){
    this.Eliminar(senddata);
  }else if($event === 'dow'){
    this.Descargar(senddata);
  }else if($event === 'pro'){
    this.Procesar(senddata);
  }
}

Ver(senddata: any){
  this.router.navigate([this.url+`${senddata.data[0].id}`]);
}

Actualizar(senddata: any){
  this.opcupdate.emit(senddata);
}


public Eliminar(senddata: any){
   this.opcdelete.emit(senddata);
}

public Descargar(senddata: any){
  this.opcdescarga.emit(senddata);
}

public Procesar(senddata: any){
  this.opcprocesar.emit(senddata);
}


}
