import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Funciones } from '../../classes/funciones';
import { ServiciosImp } from '../../services/services';
import { ToastService } from '../../toast-service';

import {
  IColumns
} from '../../models/grid.interfaces';


@Component({
  selector: 'incentivos-contenidos',
  templateUrl: './resumen_post.component.html',
  styleUrls: ['./resumen_post.component.css']
})
export class ContenidosComponent implements OnInit {
  /* *************** Parametros del Grid de datos ***************** */
  columns_post: IColumns[] = [];

  /* *************** Otros ***************** */
  public estatus: string = '';
  public titulo_post: string = '';
  public fecha_pubpost: string = '';
  public empresa: string = '';
  public modulo: string = '';

  wait = false;
  nombre: any = sessionStorage.getItem('nombre');
  nomina: any = sessionStorage.getItem('nomina');
  nombreL: any;
  usuarioL: any;
  nominaL: any;
  post: any[] = [];
  modulospost: any[] = [];
  statuspost: any[] = [];
  emppost: any[] = [];
  url_post: string = "/nuevo_post/";


  constructor(
    private router: Router,
    public snackBar: MatSnackBar,
    public servicios: ServiciosImp,
    public funcion: Funciones,
    public toastService: ToastService) {
    this.nombreL = this.nombre;
    this.nominaL = this.nomina;

    /* *************** Parametros del Grid de datos ***************** */
    this.columns_post = [ // Columnas
      {
        caption: 'Estatus',
        dataField: 'status_desc',
      },
      {
        caption: 'Título del post',
        dataField: 'nombre',
      },
      {
        caption: 'Fecha publicación',
        dataField: 'fecha_publicacion',
      },
      {
        caption: 'Fecha vigencia',
        dataField: 'fecha_vencimieto',
      },
      {
        caption: 'Envío notificación',
        dataField: 'notificacion',
      }
    ];

  }


  ngOnInit(): void {
    
    this.wait = true;
      this.servicios.getCatalogoPost().subscribe(datopost => {
      if (!datopost.error) {
        this.post = datopost.data.post;
        this.funcion.hide();
        this.wait = false;
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });

    /*** Llamada a otros servicios */
    this.cargaCatalogoModuloPost();
    this.cargaCatalogoEstatusPost();
    this.cargaCatalogoEmpresaPost();
  }

  pruebaNot(){
    let not = {
      title: 'Prueba',
      body: 'Prueba',
      image: ''
    }
    this.toastService.show(not);
  }

  newPost() {
    this.router.navigate([`nuevo_post/${0}`]);
  }



  /*** Otros servicios */

  cargaCatalogoEstatusPost() {
    this.servicios.getCatalogoEstatus().subscribe(datopost1 => {
      if (!datopost1.error) {
        this.statuspost = datopost1.data;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }


  cargaCatalogoEmpresaPost() {
    this.servicios.getCatalogoEmpresa().subscribe(datopost3 => {
      if (!datopost3.error) {
        this.emppost = datopost3.data;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }


  cargaCatalogoModuloPost() {
    this.servicios.getCatalogoModulo().subscribe(datopost4 => {
      if (!datopost4.error) {
        this.modulospost = datopost4.data;
        this.funcion.hide();
      } else {
        this.funcion.hide();
        this.router.navigate(['login']);
      }
    });
  }

  /*Consulta en el grid por filtro*/
  consulta() {
    let filtro1 = this.estatus != '' ? this.estatus : '';
    let filtro2 = this.titulo_post != '' ? this.titulo_post : '';
    let filtro3 = this.fecha_pubpost != '' ? this.fecha_pubpost : '';
    let filtro4 = this.empresa != '' ? this.empresa : '';
    let filtro5 = this.modulo != '' ? this.modulo : '';
    this.wait = true;
    this.servicios.getService(`post/filtros/?status=` + filtro1 + `&titulo=` + filtro2 + `&fecha_publicacion=` + filtro3 + `&empresa_id=` + filtro4 + `&modulo_id=` + filtro5 + ``).toPromise().then(
      (res: any) => {
        if (res.status == 401) {
          this.router.navigate(['login']);
        }
        this.post = res.data.post;
        this.wait = false;
        this.funcion.hide();
      },
      (error: any) => {
        this.wait = false;
        this.snackBar.open(error.message, 'close', {
          duration: 2500,
        });
        this.funcion.hide();
      }
    )
  }

  dataDef() {
    this.estatus = '';
    this.titulo_post = '';
    this.fecha_pubpost = '';
    this.empresa = '';
    this.modulo = '';
  }

  onClickPubcont() {
    this.router.navigate(['resumen_post']);
  }


}
