<incentivos-navbar [nombre]="nombreL" [nomina]="nominaL"></incentivos-navbar>
<!-- Filtro sondeo -->

<div class="loading-indicator" *ngIf="funcion.visibility | async">
    <img src="/assets/img/spinner.gif" alt="">
</div>

<div class="d-flex justify-content-center align-box2">
    <div class="box2">
        <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' %3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
            aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item b1" href="javascript:;">Configuración SI+</li>
                <li class="breadcrumb-item b2" (click)="onClickFiltroSondeo()">Filtro Cuestionario</li>
                <li class="breadcrumb-item b2">{{titulos}} Cuestionario</li>
            </ol>
        </nav>
    </div>
</div>
<div class="d-flex justify-content-center align-box">
  <div class="box">
      <p class="f-t1">{{titulos}} Cuestionario</p>
      <p class="f-t2">*Campos Obligatorios</p>
      <div class="container cuerpo_post">
          <div class="row mb-5">
              <div class="col-md-3">
                  <label for="input2" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                      <span class="h6 small bg-white text-muted px-1">Empresa*</span>
                  </label>
                  <select class="form-control form-select mt-2 f-size-2" id="input2" required [(ngModel)]="empresa" name="empresa">
                      <option *ngFor="let empresa of empsond" [value]="empresa.id">{{empresa.nombre}}</option>
                  </select>
              </div>
              <div class="col-md-3">
                  <label for="input3" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                      <span class="h6 small bg-white text-muted px-1">Premio insignia*</span>
                  </label>
                  <div class="form-control mt-2 text-center" >
                   <mat-label style="position: absolute; margin-top: 0.5em;">No</mat-label>
                   <mat-slide-toggle class="example-margin" style="margin-left: 2.5em; margin-top: 0.5em;" [(ngModel)]="premio_insignia" (change)="change_premio($event)">
                       <label class="form-check-label" for="flexSwitchCheckDefault" style="margin-left: 12px;">Si</label>
                   </mat-slide-toggle>
                  </div>
              </div>
              <div class="col-md-3">
                  <label for="input4" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                      <span class="h6 small bg-white text-muted px-1">Nombre de la insignia</span>
                  </label>
                  <input type="text" class="form-control mt-2 f-size-2" required [(ngModel)]="nombre_insignia" name="nombre_insignia" [disabled]="disabled1">
              </div>
              <div class="col-md-3">
                <label for="input2" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                    <span class="h6 small bg-white text-muted px-1">Imagen de la insignia</span>
                </label>
                <input class="form-control mt-2" type="file" id="imagen_insignia" accept=".jpg, .jpeg, .png"
                (change)="change_insignia($event)" [(ngModel)]="imagen_insignia" [disabled]="disabled1">
              </div>
          </div>
          <div class="row mb-5">
            <div class="col-md-3">
                <label for="input2" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                    <span class="h6 small bg-white text-muted px-1">Número del post*</span>
                </label>
                <select class="form-control form-select mt-2 f-size-2" id="input2" required [(ngModel)]="post_id" name="post_id">
                    <option *ngFor="let cat_post of postsond" [value]="cat_post.id">{{cat_post.id}} -- {{cat_post.nombre}}</option>
                </select>
            </div>
            <div class="col-md-3">
                <label for="input3" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                    <span class="h6 small bg-white text-muted px-1">Premio Saldo*</span>
                </label>
                <div class="form-control mt-2 text-center">
                  <mat-label style="position: absolute; margin-top: 0.5em;">No</mat-label>
                  <mat-slide-toggle class="example-margin" style="margin-left: 2.5em; margin-top: 0.5em;" [(ngModel)]="premio_saldo" (change)="change_saldo($event)">
                      <label class="form-check-label" for="flexSwitchCheckDefault" style="margin-left: 12px;">Si</label>
                  </mat-slide-toggle>
                </div>
            </div>
            <div class="col-md-3">
                <label for="input4" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                    <span class="h6 small bg-white text-muted px-1">Monto a pagar</span>
                </label>
                <input type="text" class="form-control mt-2 f-size-2" required [(ngModel)]="monto_pagar"
                         (blur)="formatoPeso($event)"  onkeydown="return (event.keyCode >= 48 && event.keyCode <= 57) || event.keyCode == 8 || event.keyCode == 190" name="monto_pagar" [disabled]="disabled2">
            </div>
        </div>
        <div class="row mb-5">
          <div class="col-md-3">
            <label for="input5" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
              <span class="h6 small bg-white text-muted px-1">Número de intentos*</span>
          </label>
          <input class="form-control mt-2" type="number" step=1 min="1" onkeydown="return event.keyCode !== 69" [(ngModel)]="numero_intentos">
          </div>
          <div class="col-md-3">
              <label for="input2" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                  <span class="h6 small bg-white text-muted px-1" >% Mínimo de aprobación*</span>
              </label>
              <input type="text" type="number" step=1 min="1" class="form-control mt-2 f-size-2"  onkeydown="return event.keyCode !== 69" required [(ngModel)]="min_aprobacion" name="min_aprobacion">
          </div>
          <div class="col-md-3">
            <label for="input5" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
              <span class="h6 small bg-white text-muted px-1">Tiempo del cuestionario (minutos)*</span>
          </label>
          <input class="form-control mt-2" type="number" step=1 min="1"  onkeydown="return event.keyCode !== 69" [(ngModel)]="tiempo_sondeo">
          </div>
          <div class="col-md-3">
              <label for="input4" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                  <span class="h6 small bg-white text-muted px-1">Estatus</span>
              </label>
              <div class="form-control mt-2 text-center">
              <mat-label style="position: absolute; margin-top: 0.5em;">Inactivo</mat-label>
              <mat-slide-toggle class="example-margin" style="margin-left: 4.2em; margin-top: 0.5em;" [(ngModel)]="estatus_sondeo">
                  <label class="form-check-label" for="flexSwitchCheckDefault" style="margin-left: 12px;">Activo</label>
              </mat-slide-toggle>
              </div>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-md-6">
            <label for="input4" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
            <span class="h6 small bg-white text-muted px-1">Título del cuestionario*</span>
            </label>
            <input type="text" placeholder="Título*" class="form-control mt-2 f-size-1" required [(ngModel)]="titulo_sondeo" name="titulo_sondeo">
         </div>
         <div class="col-md-6">
            <label for="input4" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
            <span class="h6 small bg-white text-muted px-1">Indicaciones del cuestionario*</span>
            </label>
            <input type="text" placeholder="Texto corto*" class="form-control mt-2 f-size-1" required [(ngModel)]="indicaciones_sondeo" name="indicaciones_sondeo">
         </div>
        </div>
        <div class="row mb-5">
          <div class="col-md-4">
            <label for="input2" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
              <span class="h6 small bg-white text-muted px-1">Imagen portada del cuestionario*</span>
            </label>
            <input class="form-control mt-2" id="imagen_sondeo" type="file" accept=".jpg, .jpeg, .png"
            (change)="change_sondeo($event)" [(ngModel)]="imagen_sondeo">
          </div>
          <div class="col-md-4">
            <label for="input5" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                <span class="h6 small bg-white text-muted px-1">Fecha inicial*</span>
            </label>
            <input id="fechap" type="text" class="mt-2 form-control" min="{{fecha}}" onfocus="(this.type='date')"
            onblur="if(this.value==''){this.type='text'}" onkeydown="return false" (change)="changeFechaInicial()"
                                required [(ngModel)]="fecha_inicial" name="fecha_inicial">
          </div>
          <div class="col-md-4">
            <label for="input6" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                <span class="h6 small bg-white text-muted px-1">Fecha Final*</span>
            </label>
            <input id="fechav" type="text" required class="mt-2 form-control" min="{{fecha_inicial}}" onfocus="(this.type='date')"
            onblur="if(this.value==''){this.type='text'}" onkeydown="return false" min="{{fecha_inicial}}"
                                required [(ngModel)]="fecha_fin"  name="fecha_fin">
          </div>
        </div>
      <br><br>
      <div class="row">
          <div class="col-md-6">
              <label class="mb-4 etiqueta"><strong>Audiencia:</strong></label>
          </div>
      </div>
      <incentivos-audiencia (dtaudiencia)="array($event)"></incentivos-audiencia>
      <br><br>
      <div class="row">
          <div class="col d-grid gap-5 d-md-flex justify-content-md-end">
              <button class="button mt-2 boton"  type="button" (click)="clean()">&nbsp;&nbsp;Limpiar</button>
              <button class="button mt-2 boton2" type="button" (click)="guardarCuestionario()">&nbsp;&nbsp;Guardar</button>
          </div>
      </div>
      <br><br>
      <div *ngIf="id_sondeo != 0">
        <sondeo_pregunta [idsondeo]="id_sondeo" [tipo]="tipo_sondeo"></sondeo_pregunta>
      </div>
      <br><br>
      </div>
  </div>
</div>
