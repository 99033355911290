import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
    selector: 'incentivos-prev-tour',
    templateUrl: './prev_tour.component.html',
    styleUrls: ['./prev_tour.component.css']
})
export class PrevTourComponent implements OnInit {
    index = 0;
    tours: any[] = [];
    portada: string = "";
    portadaD: string = "";

    constructor(private router: Router,
        public dialogRef: MatDialogRef<PrevTourComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {

    }

    async ngOnInit() {
        let i = 0;
        console.log(this.data);
        if (this.data.portada != undefined || this.data.textop != "") {
            let txtarray = this.data.textop.split("{{");
            let txtPortada = this.data.textop;
            if (txtarray.length > 1) {
                txtPortada = txtarray[0];
                let nombre = String(sessionStorage.getItem('nombre'));
                txtPortada = txtPortada + (txtarray[1].includes('nombre') ? nombre : "");
            }
            this.tours.push({ "id": i, "text": txtPortada, "src": this.data.portada, "tipo": "portada", "show": true });
            this.portada = "url('" + this.data.portada + "')";
            this.portadaD = "linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)),url('" + this.data.portada + "')";
        }
        if (this.data.data_videos[0].tipo !== "") {
            i++;
            this.configitem(i, 0);
        }
        if (this.data.data_videos[1].tipo !== "") {
            i++;
            this.configitem(i, 1);
        }
        if (this.data.data_videos[2].tipo !== "") {
            i++;
            this.configitem(i, 2);
        }
        if (this.data.data_videos[3].tipo !== "") {
            i++;
            this.configitem(i, 3);
        }
        if (this.data.data_videos[4].tipo !== "") {
            i++;
            this.configitem(i, 4);
        }
        if (this.data.data_videos[5].tipo !== "") {
            i++;
            this.configitem(i, 5);
        }
        if (this.tours.length > 0) {
            i++;
            this.tours.push({ "id": i, "text": "", "src": "", "tipo": "fin", "show": false });
        }
    }

    async configitem(i: number, x: number) {
        let src = this.data.data_videos[x].src == "" ? await this.getAsByteArray(this.data.data_videos[x].archivo) : this.data.data_videos[x].src;
        this.tours.push({ "id": i, "text": this.data.data_videos[x].texto, "src": src, "tipo": this.data.data_videos[x].tipo, "show": false });
    }

    jumpToSlide(i: number) {
        this.tours.forEach(t => {
            t.show = false;
        });
        this.tours[i].show = true;
    }

    finalizar() {
        this.dialogRef.close();
    }

    volverEmpezar() {
        this.tours.forEach(t => {
            t.show = false;
        });
        this.tours[0].show = true;
    }

    async getAsByteArray(file: File) {
        await this.getBase64(file);
    }

    getBase64(file: File) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onload = function () {
                reader.addEventListener("loadend", e => resolve(reader.result))
            };
            reader.onerror = function (error) {
                reader.addEventListener("error", reject)
            };
        });
    }


}
