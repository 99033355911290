import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
    ChartComponent, ApexAxisChartSeries, ApexChart, ApexXAxis, ApexDataLabels,
    ApexStroke, ApexMarkers, ApexYAxis, ApexGrid, ApexTitleSubtitle, ApexLegend, ApexFill,
    ApexNonAxisChartSeries, ApexPlotOptions,
} from "ng-apexcharts";
import { Funciones } from '../../classes/funciones';


export type ChartOptions = {
    series: ApexAxisChartSeries;
    seriesPri: ApexNonAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    stroke: ApexStroke;
    dataLabels: ApexDataLabels;
    markers: ApexMarkers;
    colors: string[];
    yaxis: ApexYAxis;
    grid: ApexGrid;
    legend: ApexLegend;
    title: ApexTitleSubtitle;
    fill: ApexFill;
    labels: string[];
    plotOptions: ApexPlotOptions;
};

@Component({
    selector: 'incentivos_dashboard_nps',
    templateUrl: './dashboard_nps.component.html',
    styleUrls: ['./dashboard_nps.component.css']
})
export class DashboardNpsComponent implements OnInit {
    @ViewChild("chart") chart: ChartComponent = {} as ChartComponent;
    public chartCalificacionPromedio: ChartOptions = {} as ChartOptions;
    public chartCalificacionAcumulada: Partial<ChartOptions> = {} as ChartOptions;


    @Input() fechaI: any;
    @Input() fechaF: any;
    @Input() inputData: any = [];
    @Input() inputData2: any = [];

    periodo: string = "";
    puesto: string = "0";
    estrellas: any[] = [];

    constructor(
        public snackBar: MatSnackBar,
        public funcion: Funciones
    ) {
    }

    ngOnInit(): void {
        
        this.periodo = this.funcion.getFechaFormat2(this.inputData.fecha_inicial) + " - " + this.funcion.getFechaFormat2(this.inputData.fecha_final);
        this.dataCalificacionPromedio();
        this.dataCalificacionAcumulada();
    }

    dataCalificacionPromedio() {

        if (this.inputData) {
            this.chartCalificacionPromedio = {
                plotOptions: {
                },
                labels: [" "],
                seriesPri: [],
                series: [{ name: "Calificaciones", data: this.inputData.historial.series }],
                chart: {
                    height: 240,
                    type: "line",
                    toolbar: {
                        show: false
                    }
                },
                fill: {
                },
                colors: ["#E6CB3E"],
                dataLabels: {
                    enabled: true,
                    style:{
                      fontSize: "8px"
                    }
                },
                stroke: {
                    curve: "smooth"
                },
                title: {
                    text: "Calificaciones pendientes",
                    align: "left",
                    style: {
                        color: "#FFFFFF"
                    }
                },
                grid: {
                    borderColor: "#e7e7e7",
                    row: {
                        colors: ["#f3f3f3", "transparent"],
                        opacity: 0.5,
                    }
                },
                markers: {
                    size: 1
                },
                xaxis: {
                    categories: this.inputData.historial.categorias.map((item: any) => {
                        return this.funcion.getFechaMes(item)
                    }
                    ),
                    title: {
                        text: "Mes",
                        style: {
                            color: 'rgba(44, 103, 141, 0.7)',
                            fontSize: '12px',
                            fontFamily: 'BreeRegular'
                        }
                    }, labels: {
                        style: {
                            fontSize: '8px',
                            fontFamily: 'BreeRegular'
                        }
                    }
                },
                yaxis: {
                    title: {
                        text: "Calificaciones Pendientes",
                        style: {
                            color: 'rgba(44, 103, 141, 0.7)',
                            fontSize: '12px',
                            fontFamily: 'BreeRegular'
                        }
                    },
                    labels:{
                      offsetX: -5
                    }
                    //min: 1,
                },
                legend: {
                    position: "top",
                    horizontalAlign: "center",
                    floating: false,
                    fontSize: '7px',
                    offsetY: -25,
                    offsetX: -5
                }
            };
        }
    }

    dataCalificacionAcumulada() {
        if (this.inputData) {
            this.setEstrellas();
            this.chartCalificacionAcumulada = {
                series:
                    [{
                        name: "Calificacion",
                        data: this.inputData.acumulada.series,
                    }],
                chart: {
                    type: "bar",
                    height: 200,
                    toolbar: {
                        show: false
                    }
                },
                colors: ["#E6CB3E"],
                plotOptions: {
                    bar: {
                        columnWidth: '20%',
                        borderRadius: 15,
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: this.inputData.acumulada.series.reverse(),
                    labels: {
                        show: false,
                        style: {
                            fontSize: '10px',
                        },
                        formatter: function (val) {
                            return val;
                        }
                    }
                },
                yaxis: {
                    opposite: true,
                    labels: {
                        style: {
                            colors: 'rgba(158, 160, 170, 3)',
                            fontSize: '12px',
                            fontFamily: 'BreeRegular'
                        },
                        show: true,
                        formatter: function (val: any) {
                            return val + "%";
                        }
                    }
                },
            };
        }
    }

    setEstrellas() {
        let decimal = this.inputData.acumulada.total_perfil % 1;
        let entero = this.inputData.acumulada.total_perfil - decimal;
        for (let i = 0; i < 5; i++) {
            let ruta = "/assets/images/";
            let r = decimal > 0.5 ? "cfill" : "half";
            let r1 = entero == i && decimal > 0 ? r : "empty";
            ruta = ruta + (i < entero ? "fill" : r1) + "_star.png";
            this.estrellas.push({ ruta: ruta });
        }
    }


}
