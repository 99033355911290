import { Component } from '@angular/core';
import { Funciones } from '../classes/funciones';



@Component({
  selector: 'incentivos-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  nombre: any = sessionStorage.getItem('nombre');
  nomina: any = sessionStorage.getItem('nomina');
  nombreL: any;
  usuarioL: any;
  nominaL: any;

  constructor(public funcion: Funciones) {
    this.nombreL = this.nombre;
    this.nominaL = this.nomina;
  }
}
