import { Component, Input, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServiciosImp } from '../../services/services'
import { Funciones } from '../../classes/funciones';
import { Router } from '@angular/router';

@Component({
  selector: 'incentivos-form-excel',
  templateUrl: './form_excel.component.html',
  styleUrls: ['./form_excel.component.css']
})
export class FormExcelComponent implements OnInit {

  @Input() Tipo: any;

  selecciona: number = 0;
  empex: any[] = [];
  udnex: any[] = [];
  puestoex: any[] = [];
  ubicacionex: any[] = [];
  categoriaex: any[] = [];
  datos: any[] = [];
  datos_excel: any[] = [];
  variables: any[] = [];
  aux: any;
  nombre_empresa: string = "";
  excel: any;
  empresa_id: number = 0;
  empresa: number = 0;
  url_tipo: any;


  constructor(
    public snackBar: MatSnackBar,
    public funcion: Funciones,
    public servicios: ServiciosImp,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.cargaCatalogoEmpresaExcel();
    if (this.Tipo == 2) {
      this.cargaUDNExcel();
      this.cargaPuestosExcel();
      this.cargaUbicacionExcel();
    }
  }
  public funcionHide(){
    this.funcion.hide();
  }

  public seleccionaEmpresa(event: any, tipo: any) {
    this.selecciona = 1;
    if (tipo == 1) {
      this.nombre_empresa = event.target.value;
    } else {
      this.empresa_id = event.target.value;
      this.cargaCategoriasExcel();
    }
  }

  cargaCatalogoEmpresaExcel() {
    this.servicios.getCatalogoEmpresa().subscribe(dexcel1 => {
      if (!dexcel1.error) {
        this.empex = dexcel1.data;
        this.funcionHide();
      } else {
        this.funcionHide();
        this.router.navigate(['login']);
      }
    });
  }
  

  cargaCategoriasExcel() {
    this.servicios.getCatalogoCategorias(this.empresa_id).subscribe(dexcel2 => {
      if (!dexcel2.error) {
        this.categoriaex = dexcel2.data;
        this.funcionHide();
      } else {
        this.funcionHide();
        this.router.navigate(['login']);
      }
    });
  }

  cargaUDNExcel() {
    this.servicios.getCatalogoUDN().subscribe(dexcel3 => {
      if (!dexcel3.error) {
        this.udnex = dexcel3.data;
        this.funcionHide();
      } else {
        this.funcionHide();
        this.router.navigate(['login']);
      }
    });
  }

  cargaPuestosExcel() {
    this.servicios.getCatalogoPuestos().subscribe(dexcel4 => {
      if (!dexcel4.error) {
        this.puestoex = dexcel4.data;
        this.funcionHide();
      } else {
        this.funcionHide();
        this.router.navigate(['login']);
      }
    });
  }

  cargaUbicacionExcel() {
    this.servicios.getCatalogoUbicacion().subscribe(dexcel5 => {
      if (!dexcel5.error) {
        this.ubicacionex = dexcel5.data;
        this.funcionHide();
      } else {
        this.funcionHide();
        this.router.navigate(['login']);
      }
    });
  }


  uploadFileExcel(event: any, tipo: any) {
    if (event.target.files.length > 0) {
      let file1 = event.target.files[0];
      let fileReader1 = new FileReader();
      fileReader1.readAsBinaryString(file1);
      fileReader1.onload = (e) => {
        let workBook = XLSX.read(fileReader1.result, { type: 'binary' });
        let sheetsNames = workBook.SheetNames;
        this.datos = XLSX.utils.sheet_to_json(workBook.Sheets[sheetsNames[0]]);
        this.datos.forEach((item: any) => {
          this.datos_excel.push(this.getBodyFile(tipo, item));
        });
        console.log(this.datos_excel);
        if (tipo == 1) {
          this.aux = { "empresa": this.nombre_empresa, "mesa": this.datos_excel }
        } else {
          this.aux = { "perfil": this.datos_excel }
        }

      }
    } else {
      this.datos_excel = [];
      this.aux = [];
    }
  }

  getBodyFile(tipo: any, item: any) {
    let body: any = [];
    if (tipo == 1) {
      body = {
        "puesto": item.puesto,
        "nombre_plan": item.nombre_plan,
        "nombre_n2": item.nombre_n2,
        "id_n2": item.id_n2,
        "nombre_n1": item.nombre_n1,
        "id_n1": item.id_n1,
        "nombre_variable": item.nombre_variable,
        "nombre_reto": item.nombre_reto == undefined ? "" : item.nombre_reto,
        "nombre_campania": item.nombre_campania,
        "id_contrato": item.id_contrato
      }
    } else {
      let variables = []
      for (let v in item) {
        if (v.includes("_cumplimiento_")) {
          variables.push({ "nombre": v.replace("_cumplimiento_", ''), "valor": item[v] });
        }
      }
      body = {
        "nomina": item.nomina,
        "nombre": item.nombre,
        "id_empresa": Number(this.empresa_id),
        "udn": item.udn,
        "ubicacion": item.ubicacion,
        "puesto": item.puesto,
        "categoria": item.categoria,
        "b_verificado": item.estatus,
        "estrategia": item.estrategia,
        "direccion": item.direccion,
        "subdireccion": item.subdireccion,
        "incentivo_prom": item.incentivo_prom,
        "num_incentivos": item.num_incentivo,
        "nom_jefe": item.nom_jefe,
        "id_udn": this.get_valor(item.udn, this.udnex),
        "id_ubicacion": this.get_valor(item.ubicacion.replace(/\s{2,}/g, ' ').trim(), this.ubicacionex),
        "id_puesto": this.get_valor(item.puesto.replace(/\s{2,}/g, ' ').trim(), this.puestoex),
        "id_categoria": this.get_valor(item.categoria.replace(/\s{2,}/g, ' ').trim(), this.categoriaex),
        "id_verificado": item.estatus == "ACTIVO" ? 1 : 0,
        "variables": variables
      }
    }
    return body;
  }

  public get_valor(valor: any, array: any) {
    let filtro = array.filter((item: { nombre: any; }) => item.nombre == valor);
    return filtro[0].id;
  }

  public subirExcel(tipo: any) {
    this.url_tipo = tipo == 1 ? "mesa/" : "servicios/perfil/";
    if (this.empresa !== 0) {
      if (this.excel !== undefined && this.excel !== "") {
        this.servicios.postService(this.url_tipo, this.aux).toPromise().then(
          () => {
            this.funcionHide();
             this.snackBar.open('Datos registrados correctamente', 'close', {
              duration: 2500,
            });
          }
        ).catch((error) => {
          if (error.status == 400 || error.status == 500) {
           this.snackBar.open('Error al guardar los datos ' + error, 'close', {
              duration: 2500,
            });
            this.funcionHide();
          } else {
           this.snackBar.open("Error en la estructura del archivo, favor de corroborar las cabeceras y los datos del archivo", 'close', {
              duration: 2500,
            });
            this.funcionHide();
          }
        });

      } else {
        this.snackBar.open("Cargue un archivo antes de subir la información", 'close', {
          duration: 2500,
        });
      }
    } else {
      this.snackBar.open("Seleccione una empresa", 'close', {

        duration: 2500,
      });
    }
  }

}
