
<!-- Grárficas del reporte -->
<div class="loading-indicator" *ngIf="funcion.visibility | async">
    <img src="/assets/img/spinner.gif" alt="">
</div>


<div style="padding: 3.5em 4em 1em 5em;">
    <div class="overlay">
        <div class="row " style="padding-left: 0.5em;">
            <div class="row">
                <div class="col-6 titulo" style="text-align: left;">
                    <span>Reporte de usabilidad SI+</span>
                </div>
                <div class="col-6 subtitulo" style="text-align: right;">
                    <span>Periodo: {{periodo}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row m-1" style="padding: 1em 0em 1em 0em;">
    <div class="col-6 ">
        <div class="row segundaseccion">
            <div class="row" style="padding-left: 3em;">
                <div class="col-8" style="margin-top: 13px; border-bottom: 1px solid #2C678D;">
                    <span class="cardTitle">Sesiones diarias</span>
                </div>
                <div class="col-4 secciontotales" style="margin-top: 13px; text-align: center;">
                    <div style="margin-top: 3px;">
                        <span class="labeltotales">Total de sesiones &nbsp;&nbsp; <span
                                *ngIf="inputData.sesiones_diarias"
                                style="font-size: 18px;">{{inputData.sesiones_diarias.total}}</span></span>
                    </div>
                </div>
                <div class="col-12">
                    <div class="row">
                        <div *ngIf="inputData.sesiones_diarias">
                            <apx-chart [series]="char_Sesiones_diarias.series" [chart]="char_Sesiones_diarias.chart"
                                [xaxis]="char_Sesiones_diarias.xaxis" [stroke]="char_Sesiones_diarias.stroke"
                                [colors]="char_Sesiones_diarias.colors" [dataLabels]="char_Sesiones_diarias.dataLabels"
                                [legend]="char_Sesiones_diarias.legend" [markers]="char_Sesiones_diarias.markers"
                                [grid]="char_Sesiones_diarias.grid" [yaxis]="char_Sesiones_diarias.yaxis"
                                [title]="char_Sesiones_diarias.title"></apx-chart>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

    <div class="col-6">
        <div class="row segundaseccion" style="left: 10px;">
            <div class="row" style="padding-left: 1em;">
                <div class="col-8" style="margin-top: 13px; border-bottom: 1px solid #2C678D;">
                    <span class="cardTitle">Sesiones únicas</span>
                </div>
                <div class="col-4 secciontotales" style="margin-top: 13px; text-align: center;">
                    <div style="margin-top: 3px;">
                        <span class="labeltotales">Total de sesiones &nbsp;&nbsp; <span
                                *ngIf="inputData.sesiones_unicas"
                                style="font-size: 18px;">{{inputData.sesiones_unicas.total}}</span></span>
                    </div>
                </div>
                <div class="col-12">
                    <div class="row" *ngIf="inputData.sesiones_unicas">
                        <apx-chart [series]="char_Sesiones_unicas.series" [chart]="char_Sesiones_unicas.chart"
                            [xaxis]="char_Sesiones_unicas.xaxis" [stroke]="char_Sesiones_unicas.stroke"
                            [colors]="char_Sesiones_unicas.colors" [dataLabels]="char_Sesiones_unicas.dataLabels"
                            [legend]="char_Sesiones_unicas.legend" [markers]="char_Sesiones_unicas.markers"
                            [grid]="char_Sesiones_unicas.grid" [yaxis]="char_Sesiones_unicas.yaxis"
                            [title]="char_Sesiones_unicas.title"></apx-chart>
                    </div>
                </div>
            </div>

        </div>

    </div>

    <div class="col-6 ">
        <div class="row segundaseccion" style="top: 25px;">
            <div class="row" style="padding-left: 1em;">
                <div class="col-12" style="margin-top: 13px; border-bottom: 1px solid #2C678D;">
                    <span class="cardTitle">Porcentaje de uso por funcionalidad</span>
                </div>
                <div class="col-12">
                    <div class="row" *ngIf="inputData.funcionalidades_mas_populares">
                        <apx-chart [series]="chartFuncionalidad.series!" [chart]="chartFuncionalidad.chart!"
                            [dataLabels]="chartFuncionalidad.dataLabels!"
                            [plotOptions]="chartFuncionalidad.plotOptions!" [yaxis]="chartFuncionalidad.yaxis!"
                            [xaxis]="chartFuncionalidad.xaxis!" [legend]="chartFuncionalidad.legend!"
                            [colors]="chartFuncionalidad.colors!" [grid]="chartFuncionalidad.grid!"></apx-chart>
                    </div>
                </div>
            </div>

        </div>

    </div>
    <div class="col-6">
        <div class="row segundaseccion" style="top: 25px; left: 10px;">
            <div class="row" style="padding-left: 1em;">
                <div class="col-12" style="margin-top: 13px; border-bottom: 1px solid #2C678D;">
                    <span class="cardTitle">Tráfico de actividad diaria por funcionalidad</span>
                </div>
                <div class="col-12">
                    <div class="row" *ngIf="inputData.trafico_diario">
                        <apx-chart [series]="chartTraficoDiario.series" [chart]="chartTraficoDiario.chart"
                            [xaxis]="chartTraficoDiario.xaxis" [stroke]="chartTraficoDiario.stroke"
                            [colors]="chartTraficoDiario.colors" [dataLabels]="chartTraficoDiario.dataLabels"
                            [legend]="chartTraficoDiario.legend" [markers]="chartTraficoDiario.markers"
                            [grid]="chartTraficoDiario.grid" [yaxis]="chartTraficoDiario.yaxis"
                            [title]="chartTraficoDiario.title"></apx-chart>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>
<div class="col-12">
    <div class="row seccionFila" style="top: 50px;">
        <div class="row" style="padding-left: 1em;">
            <div class="col-10" style="margin-top: 13px; border-bottom: 1px solid #2C678D;">
                <span class="cardTitle">Análisis de sentimientos en comentarios totales </span>
            </div>
            <div class="col-2 secciontotales" style="margin-top: 13px; text-align: center;">
                <div style="margin-top: 3px;">
                    <span class="labeltotales">Comentarios &nbsp;&nbsp; <span *ngIf="inputData.sentimientos"
                            style="font-size: 18px;">{{inputData.sentimientos.total}}</span></span>
                </div>
            </div>
            <div class="col-9">
                <div class="row" *ngIf="inputData.sentimientos">
                    <apx-chart [series]="char_sentimientos.series" [chart]="char_sentimientos.chart"
                        [xaxis]="char_sentimientos.xaxis" [dataLabels]="char_sentimientos.dataLabels"
                        [yaxis]="char_sentimientos.yaxis" [colors]="char_sentimientos.colors"
                        [fill]="char_sentimientos.fill" [grid]="char_sentimientos.grid"
                        [stroke]="char_sentimientos.stroke" [title]="char_sentimientos.title"></apx-chart>
                </div>
            </div>
            <div class="col-3 row">
                <div class="row" *ngIf="inputData.sentimientos">
                    <div class="col-4" style="margin-top: 45px;">
                        <div style="border-bottom: 1px solid #BDD6E3; height: 40px;">
                            <img src="/assets/images/negativo.png" alt="" style="width: 25px;">&nbsp;<span
                                class="nomSent">Negativo</span>
                        </div>
                    </div>
                    <div class="col-4" style="margin-top: 45px; text-align: center;">
                        <div style="border-bottom: 1px solid #BDD6E3; height: 40px;">
                            <span class="valorSent">{{inputData.sentimientos.negativo.valor}}</span>
                        </div>
                    </div>
                    <div class="col-4" style="margin-top: 45px; text-align: center;">
                        <div style="border-bottom: 1px solid #BDD6E3; height: 40px;">
                            <span class="valorSent">{{inputData.sentimientos.negativo.porcentaje}}%</span>
                        </div>
                    </div>
                    <div class="col-4" >
                        <div style="border-bottom: 1px solid #BDD6E3; height: 40px;">
                            <img src="/assets/images/positivo.png" alt="" style="width: 25px;">&nbsp;<span
                                class="nomSent">Positivo</span>
                        </div>
                    </div>
                    <div class="col-4" style="text-align: center;">
                        <div style="border-bottom: 1px solid #BDD6E3; height: 40px;">
                            <span class="valorSent">{{inputData.sentimientos.positivo.valor}}</span>
                        </div>
                    </div>
                    <div class="col-4" style="text-align: center;">
                        <div style="border-bottom: 1px solid #BDD6E3; height: 40px;">
                            <span class="valorSent">{{inputData.sentimientos.positivo.porcentaje}}%</span>
                        </div>
                    </div>
                    <div class="col-4">
                        <div style="border-bottom: 1px solid #BDD6E3; height: 40px;">
                            <img src="/assets/images/neutro.png" alt="" style="width: 25px;">&nbsp;<span
                                class="nomSent">Neutro</span>
                        </div>
                    </div>
                    <div class="col-4" style="text-align: center;">
                        <div style="border-bottom: 1px solid #BDD6E3; height: 40px;">
                            <span class="valorSent">{{inputData.sentimientos.neutro.valor}}</span>
                        </div>
                    </div>
                    <div class="col-4" style="text-align: center;">
                        <div style="border-bottom: 1px solid #BDD6E3; height: 40px;">
                            <span class="valorSent">{{inputData.sentimientos.neutro.porcentaje}}%</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>


    <div class="col-12">
        <div class="row seccionFila" style="top: 75px;">
            <div class="row" style="padding-left: 1em;">
                <div class="row">
                    <div class="col-4" style=" border-right: 1px solid #BDD6E3">
                        <div class="row">
                            <div class="col-4">
                                <div>
                                    <span class="planes" style="color: #CA005D;">Planes</span>
                                </div>
                                <div style="text-align: center; margin-top: 32px;">
                                    <span class="labelgen" *ngIf="inputData.planes"
                                        style="font-size: 44px;">{{inputData.planes.post_publicados}}</span>
                                </div>
                                <div style="text-align: center;">
                                    <span class="labelgen" style="font-size: 14px;">Post publicados</span>
                                </div>
                            </div>
                            <div class="col-4">
                                <div *ngIf="inputData.planes">
                                    <apx-chart [series]="char_radial_plan.seriesPri" [chart]="char_radial_plan.chart"
                                        [plotOptions]="char_radial_plan.plotOptions" [labels]="char_radial_plan.labels"
                                        [colors]="char_radial_plan.colors"></apx-chart>
                                </div>
                                <div style="text-align: center;">
                                    <span class="labelplan">% Colaboradores que
                                        consultaron su plan</span>
                                </div>
                            </div>
                            <div class="col-4">
                                <div *ngIf="inputData.planes">
                                    <apx-chart [series]="char_radial_plan_test.seriesPri"
                                        [chart]="char_radial_plan_test.chart"
                                        [plotOptions]="char_radial_plan_test.plotOptions"
                                        [labels]="char_radial_plan_test.labels" [colors]="char_radial_plan_test.colors">
                                    </apx-chart>
                                </div>
                                <div style="text-align: center; margin-top: 5px;">
                                    <span class="labelplan">% Colaboradores que
                                        aprobaron test</span>
                                </div>
                            </div>
                            <div class="col-8 row seccionplan">
                                <div class="col-6" style=" text-align: center;">
                                    <div>
                                        <span class="labelgen" *ngIf="inputData.planes"
                                            style="font-size: 40px; text-align: center;">{{inputData.planes.valoraciones}}</span>
                                    </div>
                                    <div>
                                        <span class="labelgen"
                                            style="font-size: 14px; text-align: center;">Valoraciones</span>
                                    </div>
                                </div>
                                <div class="col-6" style="text-align: center;">
                                    <div *ngIf="inputData.planes">
                                        <div style="border-bottom: 1px solid #BDD6E3; height: 50px;">
                                            <div>
                                                <img src="/assets/images/megusta.png" alt=""
                                                    style="width: 20px; margin-top: -10px; margin-left: -11px;">&nbsp;&nbsp;&nbsp;<span
                                                    class="labelgen"
                                                    style="font-size: 25px; text-align: right;">{{inputData.planes.like_porcent?inputData.planes.like_porcent:0}}%</span>
                                            </div>
                                        </div>
                                        <div>
                                            <img src="/assets/images/nomegusta.png" alt=""
                                                style="width: 20px; margin-left: -12px;">&nbsp;&nbsp;&nbsp;<span
                                                class="labelgen"
                                                style="font-size: 25px; text-align: right;">{{inputData.planes.dislike_porcent?inputData.planes.dislike_porcent:0}}%</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-4 row seccionplanCompar">
                                <div *ngIf="inputData.planes">
                                    <div style="text-align: center; margin-top: 14px;">
                                        <img src="/assets/images/compartirplan.png" alt=""
                                            style="width: 15px; margin-top: -16px;">&nbsp;&nbsp;&nbsp;&nbsp;<span
                                            class="labelgen"
                                            style="font-size: 40px; text-align: center;">{{inputData.planes.compartidos}}</span>
                                    </div>
                                    <div style="text-align: center; margin-top: -16px;">
                                        <span class="labelgen"
                                            style="font-size: 14px; text-align: center;">Compartidas</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 row seccionplan" style="margin-top: 6px;">
                                <div class="row" *ngIf="inputData.planes">
                                    <div class="col-3" style="border-right: 1px solid #BDD6E3;">
                                        <div style="text-align: center; margin-left: -18px; ">
                                            <img src="/assets/images/mesajeplan.png" alt=""
                                                style="width: 15px; margin-top: -5px;">
                                            &nbsp;&nbsp;<span class="labelgen"
                                                style="font-size: 18px; ">{{inputData.planes.comentarios}}</span>
                                        </div>
                                    </div>
                                    <div class="col-3" style="border-right: 1px solid #BDD6E3;">
                                        <div style="text-align: center;">
                                            <img src="/assets/images/positivo.png" alt=""
                                                style="width: 15px; margin-top: -5px;">
                                            &nbsp;&nbsp;<span class="labelgen"
                                                style="font-size: 18px; ">{{inputData.planes.favoritos}}%</span>
                                        </div>
                                    </div>
                                    <div class="col-3" style="border-right: 1px solid #BDD6E3;">
                                        <div style="text-align: center;">
                                            <img src="/assets/images/negativo.png" alt=""
                                                style="width: 15px; margin-top: -5px;">
                                            &nbsp;&nbsp;<span class="labelgen"
                                                style="font-size: 18px; ">{{inputData.planes.disfavoritos}}%</span>
                                        </div>
                                    </div>
                                    <div class="col-3" style="text-align: center;">
                                        <div style="text-align: center;">
                                            <img src="/assets/images/neutro.png" alt=""
                                                style="width: 15px; margin-top: -5px;">
                                            &nbsp;&nbsp;<span class="labelgen"
                                                style="font-size: 18px; ">{{inputData.planes.sinfavoritos}}%</span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4" style=" border-right: 1px solid #BDD6E3">
                        <div class="row">
                            <div class="col-4">
                                <div>
                                    <span class="planes" style="color: #8C6CA6;">Campañas</span>
                                </div>
                                <div style="text-align: center; margin-top: 32px;">
                                    <span class="labelgen" *ngIf="inputData.campanias"
                                        style="font-size: 44px;">{{inputData.campanias.post_publicados}}</span>
                                </div>
                                <div style="text-align: center;">
                                    <span class="labelgen" style="font-size: 14px;">Post publicados</span>
                                </div>
                            </div>
                            <div class="col-4">
                                <div *ngIf="inputData.campanias">
                                    <apx-chart [series]="char_radial_camp.seriesPri" [chart]="char_radial_camp.chart"
                                        [plotOptions]="char_radial_camp.plotOptions" [labels]="char_radial_camp.labels"
                                        [colors]="char_radial_camp.colors"></apx-chart>
                                </div>
                                <div style="text-align: center;">
                                    <span class="labelplan">% Colaboradores que
                                        consultaron su compaña</span>
                                </div>
                            </div>
                            <div class="col-4">
                                <div *ngIf="inputData.campanias">
                                    <apx-chart [series]="char_radial_camp_test.seriesPri"
                                        [chart]="char_radial_camp_test.chart"
                                        [plotOptions]="char_radial_camp_test.plotOptions"
                                        [labels]="char_radial_camp_test.labels" [colors]="char_radial_camp_test.colors">
                                    </apx-chart>
                                </div>
                                <div style="text-align: center; margin-top: 5px;">
                                    <span class="labelplan">% Colaboradores que
                                        aprobaron test</span>
                                </div>
                            </div>
                            <div class="col-8 row seccioncampanas" style="margin-left: 11px;">
                                <div class="col-6" style=" text-align: center;">
                                    <div>
                                        <span class="labelgen" *ngIf="inputData.campanias"
                                            style="font-size: 40px;">{{inputData.campanias.valoraciones}}</span>
                                    </div>
                                    <div>
                                        <span class="labelgen"
                                            style="font-size: 14px; text-align: center;">Valoraciones</span>
                                    </div>
                                </div>
                                <div class="col-6" style="text-align: center;">
                                    <div *ngIf="inputData.campanias">
                                        <div style="border-bottom: 1px solid #BDD6E3; height: 50px;">
                                            <div>
                                                <img src="/assets/images/megusta.png" alt=""
                                                    style="width: 20px; margin-top: -10px; margin-left: -11px;">&nbsp;&nbsp;&nbsp;<span
                                                    class="labelgen"
                                                    style="font-size: 25px; text-align: right;">{{inputData.campanias.like_porcent?inputData.campanias.like_porcent:0}}%</span>
                                            </div>
                                        </div>
                                        <div>
                                            <img src="/assets/images/nomegusta.png" alt=""
                                                style="width: 20px; margin-left: -12px;">&nbsp;&nbsp;&nbsp;<span
                                                class="labelgen"
                                                style="font-size: 25px; text-align: right;">{{inputData.campanias.dislike_porcent?inputData.campanias.dislike_porcent:0}}%</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-4 row seccioncampCompar">
                                <div *ngIf="inputData.campanias">
                                    <div style="text-align: center; margin-top: 14px;">
                                        <img src="/assets/images/compartircamp.png" alt=""
                                            style="width: 15px; margin-top: -16px;">&nbsp;&nbsp;&nbsp;&nbsp;<span
                                            class="labelgen"
                                            style="font-size: 40px; text-align: center;">{{inputData.campanias.compartidos}}</span>
                                    </div>
                                    <div style="text-align: center; margin-top: -16px;">
                                        <span class="labelgen"
                                            style="font-size: 14px; text-align: center;">Compartidas</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 row segseccioncampanas" style="margin-top: 6px;  margin-left: 11px;">
                                <div class="row" *ngIf="inputData.campanias">
                                    <div class="col-3" style="border-right: 1px solid #BDD6E3;">
                                        <div style="text-align: center; margin-left: -18px; ">
                                            <img src="/assets/images/mensajecamp.png" alt=""
                                                style="width: 15px; margin-top: -5px; color: #8C6CA6;">
                                            &nbsp;&nbsp;<span class="labelgen"
                                                style="font-size: 18px; ">{{inputData.campanias.comentarios}}</span>
                                        </div>
                                    </div>
                                    <div class="col-3" style="border-right: 1px solid #BDD6E3;">
                                        <div style="text-align: center;">
                                            <img src="/assets/images/positivo.png" alt=""
                                                style="width: 15px; margin-top: -5px;">
                                            &nbsp;&nbsp;<span class="labelgen"
                                                style="font-size: 18px; ">{{inputData.campanias.favoritos}}%</span>
                                        </div>
                                    </div>
                                    <div class="col-3" style="border-right: 1px solid #BDD6E3;">
                                        <div style="text-align: center;">
                                            <img src="/assets/images/negativo.png" alt=""
                                                style="width: 15px; margin-top: -5px;">
                                            &nbsp;&nbsp;<span class="labelgen"
                                                style="font-size: 18px; ">{{inputData.campanias.disfavoritos}}%</span>
                                        </div>
                                    </div>
                                    <div class="col-3" style="text-align: center;">
                                        <div style="text-align: center;">
                                            <img src="/assets/images/neutro.png" alt=""
                                                style="width: 15px; margin-top: -5px;">
                                            &nbsp;&nbsp;<span class="labelgen"
                                                style="font-size: 18px; ">{{inputData.campanias.sinfavoritos}}%</span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="row">
                            <div class="col-4">
                                <div>
                                    <span class="planes" style="color: #4DAB9E;">Si Comunica</span>
                                </div>
                                <div style="text-align: center; margin-top: 32px;">
                                    <span class="labelgen" *ngIf="inputData.podcast"
                                        style="font-size: 44px;">{{inputData.podcast.post_publicados}}</span>
                                </div>
                                <div style="text-align: center;">
                                    <span class="labelgen" style="font-size: 14px;">Post publicados</span>
                                </div>
                            </div>
                            <div class="col-4">
                                <div *ngIf="inputData.podcast">
                                    <apx-chart [series]="char_radial_pod.seriesPri" [chart]="char_radial_pod.chart"
                                        [plotOptions]="char_radial_pod.plotOptions" [labels]="char_radial_pod.labels"
                                        [colors]="char_radial_pod.colors"></apx-chart>
                                </div>
                                <div style="text-align: center;">
                                    <span class="labelplan">% Colaboradores que
                                        consultaron Si comunica</span>
                                </div>
                            </div>
                            <div class="col-4">
                                <div *ngIf="inputData.podcast">
                                    <apx-chart [series]="char_radial_pod_test.seriesPri"
                                        [chart]="char_radial_pod_test.chart"
                                        [plotOptions]="char_radial_pod_test.plotOptions"
                                        [labels]="char_radial_pod_test.labels" [colors]="char_radial_pod_test.colors">
                                    </apx-chart>
                                </div>
                                <div style="text-align: center; margin-top: 5px;">
                                    <span class="labelplan">% Colaboradores que
                                        aprobaron test</span>
                                </div>
                            </div>
                            <div class="col-8 row seccioncampanas" style="margin-left: 11px;">
                                <div class="col-6" style=" text-align: center;">
                                    <div>
                                        <span class="labelgen" *ngIf="inputData.podcast"
                                            style="font-size: 40px; text-align: center;">{{inputData.podcast.valoraciones}}</span>
                                    </div>
                                    <div>
                                        <span class="labelgen"
                                            style="font-size: 14px; text-align: center;">Valoraciones</span>
                                    </div>
                                </div>
                                <div class="col-6" style="text-align: center;">
                                    <div *ngIf="inputData.podcast">
                                        <div style="border-bottom: 1px solid #BDD6E3; height: 50px;">
                                            <div>
                                                <img src="/assets/images/megusta.png" alt=""
                                                    style="width: 20px; margin-top: -10px; margin-left: -11px;">&nbsp;&nbsp;&nbsp;<span
                                                    class="labelgen"
                                                    style="font-size: 25px; text-align: right;">{{inputData.podcast.like_porcent?inputData.podcast.like_porcent:0}}%</span>
                                            </div>
                                        </div>
                                        <div>
                                            <img src="/assets/images/nomegusta.png" alt=""
                                                style="width: 20px; margin-left: -12px;">&nbsp;&nbsp;&nbsp;<span
                                                class="labelgen"
                                                style="font-size: 25px; text-align: right;">{{inputData.podcast.dislike_porcent?inputData.podcast.dislike_porcent:0}}%</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-4 row seccioncampCompar">
                                <div *ngIf="inputData.podcast">
                                    <div style="text-align: center; margin-top: 14px;">
                                        <img src="/assets/images/compartirpod.png" alt=""
                                            style="width: 15px; margin-top: -16px;">&nbsp;&nbsp;&nbsp;&nbsp;<span
                                            class="labelgen"
                                            style="font-size: 40px; text-align: center;">{{inputData.podcast.compartidos}}</span>
                                    </div>
                                    <div style="text-align: center; margin-top: -16px;">
                                        <span class="labelgen"
                                            style="font-size: 14px; text-align: center;">Compartidas</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 row segseccioncampanas" style="margin-top: 6px;  margin-left: 11px;">
                                <div class="row" *ngIf="inputData.podcast">
                                    <div class="col-3" style="border-right: 1px solid #BDD6E3;">
                                        <div style="text-align: center; margin-left: -18px; ">
                                            <img src="/assets/images/mensajepod.png" alt=""
                                                style="width: 15px; margin-top: -5px; color: #8C6CA6;">
                                            &nbsp;&nbsp;<span class="labelgen"
                                                style="font-size: 18px; ">{{inputData.podcast.comentarios}}</span>
                                        </div>
                                    </div>
                                    <div class="col-3" style="border-right: 1px solid #BDD6E3;">
                                        <div style="text-align: center;">
                                            <img src="/assets/images/positivo.png" alt=""
                                                style="width: 15px; margin-top: -5px;">
                                            &nbsp;&nbsp;<span class="labelgen"
                                                style="font-size: 18px; ">{{inputData.podcast.favoritos}}%</span>
                                        </div>
                                    </div>
                                    <div class="col-3" style="border-right: 1px solid #BDD6E3;">
                                        <div style="text-align: center;">
                                            <img src="/assets/images/negativo.png" alt=""
                                                style="width: 15px; margin-top: -5px;">
                                            &nbsp;&nbsp;<span class="labelgen"
                                                style="font-size: 18px; ">{{inputData.podcast.disfavoritos}}%</span>
                                        </div>
                                    </div>
                                    <div class="col-3" style="text-align: center;">
                                        <div style="text-align: center;">
                                            <img src="/assets/images/neutro.png" alt=""
                                                style="width: 15px; margin-top: -5px;">
                                            &nbsp;&nbsp;<span class="labelgen"
                                                style="font-size: 18px; ">{{inputData.podcast.sinfavoritos}}%</span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12" style="margin-top: 10px;"></div>
            </div>
        </div>
    </div>
</div>

<div style="padding: 5em 4em 1em 5em;">
    <div class="overlay">
        <div class="row " style="padding-left: 0.5em;">
            <div class="col-12 info" style="text-align: center;">
                <span>*La información de los comentarios totales, valoraciones y publicaciones totales corresponde a los
                    post activos en el Sistema de Incentivos</span>
            </div>
        </div>
    </div>
</div>
