<incentivos-navbar [nombre]="nombreL" [nomina]="nominaL"></incentivos-navbar>

<div class="d-flex justify-content-center align-box2">
    <div class="box2">
        <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
            aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item b1">Reproductor</li>
                <li class="breadcrumb-item b2">Video</li>
            </ol>
        </nav>
    </div>
</div>

<div class="d-flex justify-content-center align-box">
    <div class="box">
        <div class="mt-5 d-flex justify-content-center">
            <video #media id="video" preload="auto" controls>
                <source src="https://incentivos-dev.gc.gentera.com.mx/back/servicios/archivos/?ruta=1/2/GENTERA_SI_MASTER1.mp4" type="video/mp4">
                <track label="Español" kind="captions" src="" default>
            </video>
        </div>
    </div>
</div>