import { Component, OnInit, ViewChild, ElementRef, Pipe } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServiciosImp } from '../../../services/services';
import { Funciones } from '../../../classes/funciones'


@Component({
  selector: 'incentivos-registro-plan-campania',
  templateUrl: './registro_plan_campania.component.html',
  styleUrls: ['./registro_plan_campania.component.css']
})
export class RegistroPlanCampaniaComponent implements OnInit {

  @Pipe({ name: 'safe' })

  @ViewChild('modalincentivos') modal: ElementRef | undefined;
  nombre: any = sessionStorage.getItem('nombre');
  nomina: any = sessionStorage.getItem('nomina');
  nombreL: any;
  usuarioL: any;
  nominaL: any;
  empresaPC: any[] = [];
  tipoPC: any[] = [];
  empresa: any;
  tipo: any;
  fecha: string = "";

  // Campos en el front
  public accion: boolean = false;
  public accion_nivel: boolean = false;
  wait: boolean = false;
  public nombrePC: string = '';
  public idPC: number = 0;
  public titulo = 'Nuevo';
  fecha_inicio: any;
  fecha_fin: any;
  displayProgressSpinner = false;

  onExportingFun: Function = () => {
    this.displayProgressSpinner = true;
  };
  onExportedFun: Function = () => {
    this.displayProgressSpinner = false;
  };

  constructor(
    private router: Router,
    public snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    public servicios: ServiciosImp,
    public funcion: Funciones
  ) {
    this.nombreL = this.nombre;
    this.nominaL = this.nomina;


  }

  ngOnInit(): void {

    this.inicio();
    this.fecha = this.funcion.formatDate();

    this.servicios.getService('empresa/').toPromise().then(
      (res: any) => {
        if (res.status == 401) {
          this.router.navigate(['login']);
        }
        this.empresaPC = res.data;
        this.funcion.hide();
      },
      (error: any) => {
        this.snack(error);
        this.funcion.hide();
      }
    )


    this.servicios.getService('tipo-campania/').toPromise().then(
      (res: any) => {
        if (res.status == 401) {
          this.router.navigate(['login']);
        }
        this.tipoPC = res.data;
        this.funcion.hide();
      },
      (error: any) => {
        this.snack(error);
        this.funcion.hide();
      }
    )
  }

  public snack(error: any) {
    this.snackBar.open(error.message, 'close', {
      duration: 2500,
    });
  }

  public inicio() {
    this.activatedRoute.params.subscribe(parametros => {
      this.idPC = parametros.id;
      if (this.idPC != 0) {
        this.wait = true;
        this.titulo = 'Edita'
        let url = 'campania/' + this.idPC + '/';
        this.servicios.getService(url).toPromise().then(
          (res: any) => {
            console.log(res);
            if (res.data.status == 401) {
              this.router.navigate(['login']);
            }
            this.nombrePC = res.data.nombre;
            this.tipo = res.data.tipo;
            this.empresa = res.data.empresa_id;
            this.fecha_inicio = res.data.fecha_inicio;
            this.fecha_fin = res.data.fecha_final;
            this.funcion.hide();
          },
          (error: any) => {
            this.snack(error);
            this.funcion.hide();
          }
        )
      } else {
        this.wait = false;
      }
    });
  }


  public dataDef() {
    this.nombrePC = "";
    this.tipo = "";
    this.empresa = "";
    this.fecha_inicio = "";
    this.fecha_fin = "";
  }

  public guardaPC() {
    if (this.nombrePC !== '' && this.tipo !== '' && this.empresa !== ''
      && this.fecha_inicio !== '' && this.fecha_fin !== '') {
      let body = {
        "nombre": this.nombrePC,
        "tipo": this.tipo,
        "empresa_id": this.empresa,
        "fecha_inicio": this.fecha_inicio,
        "fecha_final": this.fecha_fin,
      }
      if (this.idPC == 0) {
        this.nuevaCampania(body);
      } else {
        this.actualizaCampania(body);
      }
    } else {
      this.snackBar.open('Por favor, ingrese todos los campos', 'close', {
        duration: 2500,
      });
    }
  }

  nuevaCampania(body: any) {
    this.servicios.regCampania(body).subscribe(respcamp1 => {
      if (!respcamp1.error) {
        this.snackBar.open('Datos guardados correctamente', 'close', {
          duration: 2500,
        });
        this.funcion.hide();
        this.router.navigate(['registro_plan_campania/' + respcamp1.data.id]);
      } else {
        this.snackBar.open('Error al guardar los datos', 'close', {
          duration: 2500,
        });
        this.funcion.hide();
      }
    });
  }

  actualizaCampania(body: any) {
    this.servicios.actCampania(body, this.idPC).subscribe(respcamp2 => {
      if (!respcamp2.error) {
        this.snackBar.open('Datos actualizados correctamente', 'close', {
          duration: 2500,
        });
        this.funcion.hide();
        this.router.navigate(['registro_plan_campania/' + this.idPC]);
      } else {
        this.snackBar.open('Error al actualizar los datos', 'close', {
          duration: 2500,
        });
        this.funcion.hide();
      }
    });
  }

  onClickConfsi() {
    this.router.navigate(['resumen_post']);
  }

  onClickRegPlanCamp() {
    this.router.navigate(['configuracion_planes_campanias']);
  }


  changeFechaInicio() {
    this.fecha_fin = "";
  }


}
