<incentivos-navbar [nombre]="nombreL" [nomina]="nominaL"></incentivos-navbar>

<!-- Crear una notificacion -->
<div class="loading-indicator" *ngIf="funcion.visibility | async">
    <img src="/assets/img/spinner.gif" alt="">
</div>

<div class="d-flex justify-content-center align-box2">
    <div class="box2">
        <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
            aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item b1" (click)="onClickCatNot()">Notificaciones</li>
                <li class="breadcrumb-item b2">{{titulonot}}</li>
            </ol>
        </nav>
    </div>
</div>
<div class="d-flex justify-content-center align-box">
    <div class="box">
        <p class="f-t1">{{titulonot}} notificación</p>
        <p class="f-t2">*Campos Obligatorios</p>
        <div class="container cuerpo_post">
            <div class="row mb-5">
                <div class="col-12">
                    <input type="text" placeholder="Título*" [(ngModel)]="titulo" name="titulo" class="form-control"
                        required>
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-12">
                    <input type="text" placeholder="Texto de notificación*" [(ngModel)]="texto_notificacion"
                        name="texto_notificacion" class="form-control" required>
                </div>
            </div>
            <br><br>
            <div class="row">
                <div class="col-12">
                    <label class="mb-4 etiqueta"><strong>Audiencia:</strong></label>
                </div>
            </div>
            <div *ngIf="!notificacionaudiencia">
                <button class="button mt-2 boton3" type="button" (click)="abrirModal(this.modal)">&nbsp;&nbsp;Cargar
                    nominas</button>
            </div>
            <div class="row" *ngIf="!cargaNominas">
                <dx-tree-list #zone id="audiencia" [dataSource]="audiencia_notificacion" parentIdExpr="idPadre"
                    keyExpr="id" (onSelectionChanged)="getSelection($event)" [showRowLines]="true" [showBorders]="true"
                    [expandedRowKeys]="isExpanded" [columnAutoWidth]="true">
                    <dxo-selection mode="multiple"></dxo-selection>
                    <dxo-search-panel [visible]="true" [width]="searchPanel.width"
                        [placeholder]="searchPanel.placeholder">
                    </dxo-search-panel>
                    <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
                    <dxo-filter-row [visible]="searchPanel.filterRow"></dxo-filter-row>
                    <dxi-column dataField="descripcion" caption="Audiencia"></dxi-column>
                    <dxi-column [visible]="false" dataField="id" caption="Id"></dxi-column>
                    <dxi-column dataField="nomina" caption='Nómina'></dxi-column>
                    <dxi-column dataField="descripcion_nivel" caption="Descripción nivel"></dxi-column>
                    <dxi-column [visible]="false" dataField="id_empresa"></dxi-column>
                    <dxi-column [visible]="false" dataField="id_puesto"></dxi-column>
                    <dxi-column [visible]="false" dataField="id_direccion"></dxi-column>
                    <dxi-column [visible]="false" dataField="id_subdireccion"></dxi-column>
                    <dxi-column [visible]="false" dataField="id_ubicacion" caption="Ubicación"></dxi-column>
                    <dxi-column [visible]="false" dataField="id_udn"></dxi-column>
                    <dxi-column [visible]="false" dataField="tipo"></dxi-column>
                    <dxi-column [visible]="false" dataField="idPadre"></dxi-column>
                    <dxi-column [visible]="false" dataField="nombre"></dxi-column>
                    <dxi-column [visible]="false" dataField="puesto"></dxi-column>
                    <dxi-column [visible]="false" dataField="ubicacion" caption="Ubicación"></dxi-column>
                    <dxi-column [visible]="false" dataField="udn"></dxi-column>
                </dx-tree-list>
            </div>
            <br><br>
            <div class="col d-grid gap-5 d-md-flex justify-content-md-end" *ngIf="ver==true">
                <button class="button mt-2 boton" type="button" (click)="dataDef()">&nbsp;&nbsp;Limpiar</button>
                <button class="button mt-2 boton2" type="button" (click)="publicar()"><em
                        class="bi bi-window-desktop"></em>&nbsp;&nbsp;Enviar</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal de incentivos-->
<ng-template #modalnominas>
    <div class="modal-content">
        <div class="modal-title">
            <p class="f-t3">Agrega el listado de nominas</p>
        </div>
        <br><br>
        <div class="modal-body">
            <div class="row mb-2 mt-4">
                <div class="col-12">
                    <textarea class="textarea" cols=90 rows=12 [(ngModel)]="listaNominas"></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer" style="border-top: 0;">
            <div class="col d-grid gap-5 d-md-flex justify-content-md-end">
                <button class="button mt-2 boton" data-bs-dismiss="modal" type="button" (click)="cerrarModal()">&nbsp;&nbsp;Cerrar</button>
                <button class="btn mt-2 boton2" type="button" (click)="guardarNominas()">&nbsp;&nbsp;Aceptar</button>
            </div>
        </div>
    </div>
</ng-template>