<incentivos-navbar [nombre]="nombreL" [nomina]="nominaL"></incentivos-navbar>

<!-- Configurar icono y mensaje clara de inicio -->
<div class="loading-indicator" *ngIf="funcion.visibility | async">
    <img src="/assets/img/spinner.gif" alt="">
</div>

<div class="d-flex justify-content-center align-box2">
    <div class="box2">
        <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
            aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item b1" href="javascript:;" (click)="onClickConfsi()">Configuración SI+</li>
                <li class="breadcrumb-item b1" href="javascript:;" (click)="onClickConfInicio()">Configuración de inicio</li>
                <li class="breadcrumb-item b2" href="javascript:;">{{titulo}} logo y mensaje Clara</li>
            </ol>
        </nav>
    </div>
</div>
<div class="d-flex justify-content-center align-box">
        <div class="box">
            <p class="f-t1">{{titulo}} logo y mensaje Clara</p>
            <p class="f-t2">*Campos Obligatorios</p>
            <div class="container cuerpo">
                    <div class="row mb-5">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-12">
                                    <label>Mensaje clara*:</label>
                                </div>
                            </div>
                            <div class="row">
                                <input  class="form-control mt-2" type="text" placeholder="Ingrese un mensaje para clara*"
                                            [(ngModel)]="mensaje_clara" name="mensaje_clara" required>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-12" id="espacio2">
                                    <label >Logo inicio*:</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6" id="espacio2">
                                    <input class="form-control mt-2 f-size" type="file" id="filec1"
                                    (change)="logoInicio($event)" [(ngModel)]="logo_inicio" required accept="image/*">
                                </div>
                                <div class="col-md-6" id="espacio">
                                    <button class="button mt-2 boton3" type="button" data-bs-toggle="modal"
                                        data-bs-target="#openModal" [disabled]="!logo_inicio">
                                        <em class="bi bi-eye-fill" style="pointer-events: none;"></em>&nbsp;&nbsp;Previsualizar</button>
                                </div>
                                <img  *ngIf="img_inicio" hidden src="{{img_inicio}}" alt="img_inicio">
                            </div>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="col-md-6">
                            <label for="input5" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                                <span class="h6 small bg-white text-muted px-1">Fecha publicación*</span>
                            </label>
                            <input id="fechai" type="text" class="mt-2 form-control" onfocus="(this.type='date')"
                            onblur="if(this.value==''){this.type='text'}" onkeydown="return false"
                                                required [(ngModel)]="fecha_inicio" name="fecha_inicio">
                        </div>
                        <div class="col-md-6">
                            <label for="input6" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                                <span class="h6 small bg-white text-muted px-1">Fecha vigencia*</span>
                            </label>
                            <input id="fechaf" type="text" required class="mt-2 form-control" min="{{fecha_inicio}}" onfocus="(this.type='date')"
                            onblur="if(this.value==''){this.type='text'}" onkeydown="return false"
                                            required [(ngModel)]="fecha_fin"  name="fecha_vigencia">
                        </div>
                    </div>
                    <br>
                    <div class="col d-grid gap-5 d-md-flex justify-content-md-end">
                        <button class="button mt-2 boton" type="button" (click)="dataDef()">&nbsp;&nbsp;Limpiar</button>
                        <button class="button mt-2 boton2" type="button" (click)="guardar()"><em class="bi bi-window-save"></em>&nbsp;&nbsp;Guardar</button>
                    </div>
                </div>
    </div>
</div>

<!-- Modal de previsualización image-->
<div class="modal fade" id="openModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-title" id="exampleModalLabel">
                <p class="f-t3">Validación de contenido</p>
                <p class="f-t4">Verifica el contenido que está subiendo</p>
            </div>
            <div class="modal-body mt-3">
                <iframe *ngIf="filePrev" src="{{filePrev}}" title="Imagen Prev" height="80%"
                    width="100%" sandbox></iframe>
            </div>
            <div class="modal-footer" style="border-top: 0;">
                <div class="col d-grid gap-5 d-md-flex justify-content-md-end">
                    <button class="button mt-2 boton" data-bs-dismiss="modal"
                        type="button">&nbsp;&nbsp;Cerrar</button>
                </div>
            </div>
        </div>
    </div>
</div>
