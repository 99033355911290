<div>
  <dx-data-grid #grid id="gridContainer" [dataSource]="datos" name="grid"
    [masterDetail]="{ enabled: Detail.detail, template: 'detail'}" [showBorders]="true" [columnAutoWidth]="true"
    [showRowLines]="Color.filas" [showColumnLines]="Color.columnas" [remoteOperations]="remoteOperations"
    [hoverStateEnabled]="true"
    [rowAlternationEnabled]="Color.alternar"
    (onToolbarPreparing)="onToolbarPreparing($event)"
    (onSelectionChanged)="onSelectionChanged($event)"
    (onContentReady)="onContentReady($event)"
    (onInitialized)="onInitializedMaster($event)"
    (onRowUpdated)="OnRowUpdated($event)"
    [columnHidingEnabled]="columnHiding.hide"
    (onRowUpdating)="onRowUpdating($event)"
    (onRowPrepared)="onRowPrepared($event)"
    (onExporting)="onExporting($event)"
    (onExported)="onExported($event)"
  >
<!-- -------------------- EDITAR ------------------------------------------>
<dxo-editing [mode]="Editing.mode" [allowUpdating]="Editing.allowupdate"></dxo-editing>

<!-- -------------------- CHECKBOX ----------------------------------------   -->
<dxo-selection [mode]="selectionMode"></dxo-selection>


<!-- -------------------- SELECCIONADOR DE COLUMNAS ------------------------------------------>
<dxo-column-chooser [enabled]="Columnchooser.columnchooser" mode="select"></dxo-column-chooser>


<!-- -------------------- PAGINACION ------------------------------------------>
<dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="gridOptions.pageSize" [showInfo]="true"></dxo-pager>
<dxo-paging [pageSize]="gridOptions.paginacion"></dxo-paging>

<!-- -------------------- FILTROS ------------------------------------------>

<dxo-search-panel [visible]="searchPanel.visible" [width]="searchPanel.width"
  [placeholder]="searchPanel.placeholder"></dxo-search-panel>
<dxo-header-filter [visible]="searchPanel.filterHeader" [allowSearch]="true"></dxo-header-filter>
<dxo-filter-row [visible]="searchPanel.filterRow"></dxo-filter-row>
<dxo-filter-panel [visible]="true"></dxo-filter-panel>

<!-- -------------------- SCROLL ------------------------------------------>

<dxo-scrolling [mode]="scroll.mode" [rowRenderingMode]="scroll.mode"></dxo-scrolling>
<dxo-scrolling [columnRenderingMode]="scroll.mode" [scrollByContent]="true"></dxo-scrolling>

<!-- -------------------- EXPORT ------------------------------------------>

<dxo-export [fileName]="exportExcel.fileName" [enabled]="exportExcel.enabled"></dxo-export>


<!-- -------------------- COLUMNAS ------------------------------------------>

<dxi-column *ngFor="let column of columns" [dataField]="column.dataField" [caption]="column.caption"
  [width]="column.width" [groupIndex]="column.groupIndex" [cellTemplate]="column.cellTemplate"
  [hidingPriority]="column.hiddingPriority" [allowEditing]="column.allowEditing" [dataType]="column.dataType"
  [format]="column.format">
</dxi-column>
 
<!-- -------------------- TEMPLATE TOTAL DE REGISTROS ------------------------------------------>
<div class="totalcolor" [ngClass]="{'totalcolorgris': Color.color === 'gris'}"
  *dxTemplate="let data of 'Totalderegistros'">
  <div class="informer">
    <h2 class="totalcount">{{datos ? datos.length : 0}}</h2>
    <span class="name">Registros</span>
  </div>
</div>

<!-- -------------------- TEMPLATE REGISTROS SELECCIONADOS ------------------------------------------>
<div class="countcolor" *dxTemplate="let data of 'Contarseleccionados'">
  <div class="informer">
    <h2 class="countcount">{{contador}}</h2>
    <span class="countname">Seleccionados</span>
  </div>
</div>

<div *dxTemplate="let data of 'detail'">
  <dx-data-grid #griddetail id="gridContainerDetail" [dataSource]="data.data.detalle" [showBorders]="true"
    [columnAutoWidth]="true" [showRowLines]="Color.filas" [showColumnLines]="Color.columnas"
    [remoteOperations]="remoteOperations" [rowAlternationEnabled]="Color.alternar"
    (onToolbarPreparing)=" onToolbarPreparingDetail($event)" (onSelectionChanged)="onSelectionChangedDetail($event)"
    (onContentReady)="onContentReadyDetail($event)" [columnHidingEnabled]="columnHiding.hide"
    (onRowUpdating)=" onRowUpdatingDetail($event)" (onRowPrepared)="onRowPrepared($event)" >
    <!-- -------------------- EDITAR ------------------------------------------>
    <dxo-editing [mode]="Editing.mode" [allowUpdating]="Editing.allowupdate"></dxo-editing>

     <!-- -------------------- CHECKBOX ----------------------------------------   -->
    <dxo-selection [mode]="selectionMode"></dxo-selection>

    <!-- -------------------- SELECCIONADOR DE COLUMNAS ------------------------------------------>
    <dxo-column-chooser [enabled]="Columnchooser.columnchooser" mode="select"></dxo-column-chooser>


    <!-- -------------------- PAGINACION ------------------------------------------>
    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="gridOptions.pageSize" [showInfo]="true">
    </dxo-pager>
    <dxo-paging [pageSize]="gridOptions.paginacion"></dxo-paging>

    <!-- -------------------- FILTROS ------------------------------------------>

    <dxo-search-panel [visible]="searchPanel.visible" [width]="searchPanel.width"
      [placeholder]="searchPanel.placeholder"></dxo-search-panel>
      <dxo-header-filter [visible]="searchPanel.filterHeader" [allowSearch]="true" ></dxo-header-filter>
    <dxo-filter-row [visible]="searchPanel.filterRow"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>

    <!-- -------------------- SCROLL ------------------------------------------>

    <dxo-scrolling [mode]="scroll.mode" [rowRenderingMode]="scroll.mode"></dxo-scrolling>
    <dxo-scrolling [columnRenderingMode]="scroll.mode" [scrollByContent]="true"></dxo-scrolling>

    <!-- -------------------- EXPORT ------------------------------------------>

    <dxo-export [fileName]="exportExcel.fileName" [enabled]="exportExcel.enabled"></dxo-export>

    <!-- -------------------- COLUMNAS ------------------------------------------>

    <dxi-column *ngFor="let column of columnsdetail" [dataField]="column.dataField" [caption]="column.caption"
      [width]="column.width" [groupIndex]="column.groupIndex" [cellTemplate]="column.cellTemplate"
      [hidingPriority]="column.hiddingPriority" [allowEditing]="column.allowEditing" [dataType]="column.dataType"
      [format]="column.format">
    </dxi-column>

    <!-- -------------------- TEMPLATE TOTAL DE REGISTROS ------------------------------------------>
    <div class="totalcolordetail" [ngClass]="{'totalcolorgris': Color.color === 'gris'}" *dxTemplate="let datadetail of 'Totalderegistrosdetail'">
      <div class="informer">
        <h2 class="totalcount">{{data.data.detalle ? data.data.detalle.length : 0}}</h2>
        <span class="name">Registros</span>
      </div>
    </div>

    <!-- -------------------- TEMPLATE REGISTROS SELECCIONADOS ------------------------------------------>
    <div class="countcolor" *dxTemplate="let datadetail of 'Contarseleccionadosdetail'">
      <div class="informer">
        <h2 class="countcount" id="contador">0</h2>
        <span class="countname">Seleccionados</span>
      </div>
    </div>
  </dx-data-grid>
</div>

</dx-data-grid>