<incentivos-navbar [nombre]="nombreL" [nomina]="nominaL"></incentivos-navbar>

<!-- Notificacion Empresa -->
<div class="loading-indicator" *ngIf="funcion.visibility | async">
    <img src="/assets/img/spinner.gif" alt="">
</div>

<div class="d-flex justify-content-center align-box">
  <div class="box2">
    <nav
      style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
      aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item b1" href="javascript:;" (click)="onClickHome()">Notificaciones</li>
      </ol>
    </nav>
  </div>
</div>
<br><br>
<div class="d-flex justify-content-center align-box">
    <div class="box">
        <div class="d-flex align-items-center py-4">
            <div class="container">
                <form>
                        <div class="row mb-5">
                            <div class="col-md-6">
                                <label for="input6" class="ms-6 position-absolute" style="margin-top: -0.25rem !important">
                                    <span class="h6 small bg-white text-muted px-1">Título</span>
                                </label>
                                <input type="text" class="form-control" [(ngModel)]="titulo" name="titulo">
                            </div>
                            <div class="col-md-6">
                                <label for="input6" class="ms-6 position-absolute" style="margin-top: -0.25rem !important">
                                    <span class="h6 small bg-white text-muted px-1">Fecha de publicación</span>
                                </label>
                                <input id="fecha" type="text" required class="form-control" onfocus="(this.type='date')" onblur="if(this.value==''){this.type='text'}" [(ngModel)]="fecha" name="fecha">
                            </div>
                        </div>
                        <div class="row">
                            <div class="mb-4">
                                <button type="button" class="btn  btn-lg me-md-2 boton" (click)="consulta()">Consultar</button>
                                <button type="button" class="btn  btn-lg boton2" (click)="dataDef()">Limpiar</button>
                            </div>
                        </div>
                </form>
            </div>
        </div>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end align-btn">
            <button type="button" class="btn btn-primary boton3" (click)="newNotificacion()">+ Nueva Notificación</button>
        </div>
        <br><br>
        <div class="row mt-4">
          <incentivos-tabla-registros [datos]="notificacion" [wait]="wait" [columns]="columns_notificacion"></incentivos-tabla-registros>
        </div>
        <br>
        <br>
    </div>
</div>
