<div class="d-flex justify-content-center align-box">
  <div class="box">
      <div class="container cuerpo">
          <div class="row mb-5">
                  <div class="row">
                      <div class="col-md-12">
                          <label class="f-t">Seleccione una empresa y adjunte un archivo en formato excel para la carga masiva*:</label>
                      </div>
                  </div>
                  <div class="row py-5">
                      <div class="col-md-4">
                          <label for="input2" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
                              <span class="h6 small bg-white text-muted px-1">Empresa*</span>
                          </label>
                          <select *ngIf="Tipo==1" class="form-control form-select mt-2" id="input2" required [(ngModel)]="empresa" name="empresa" (change)="seleccionaEmpresa($event,Tipo)">
                              <option *ngFor="let empresa of empex" [value]="empresa.nombre">{{empresa.nombre}}</option>
                          </select>
                          <select *ngIf="Tipo==2" class="form-control form-select mt-2" id="input2" required [(ngModel)]="empresa" name="empresa" (change)="seleccionaEmpresa($event,Tipo)">
                            <option *ngFor="let empresa of empex" [value]="empresa.id">{{empresa.nombre}}</option>
                        </select>
                      </div>
                      <div class="col-md-4" *ngIf="selecciona == 1">
                          <input class="form-control mt-2" type="file" id="filec1" [(ngModel)]="excel" (change)="uploadFileExcel($event,Tipo)" multiple="false"
                              required accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                      </div>
                      <div class="col-md-4">
                          <button class="button mt-2 boton" type="button" (click)="subirExcel(Tipo)"><em class="bi bi-upload"></em>&nbsp;&nbsp;Subir</button>
                      </div>
                  </div>
          </div>
  </div>
</div>
</div>
