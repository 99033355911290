import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Funciones } from '../../classes/funciones';
import { ServiciosImp } from '../../services/services'



import {
  IColumns
} from '../../models/grid.interfaces';

@Component({
  selector: 'incentivos-empresas',
  templateUrl: './catalogo_notificacion.component.html',
  styleUrls: ['./catalogo_notificacion.component.css']
})
export class CatalogoNotificacionComponent implements OnInit {
  /* *************** Parametros del Grid de datos ***************** */
  columns_notificacion: IColumns[] = [];
  /* *************** Otros ***************** */
  public estatus: string = '';
  public titulo: string = '';
  public fecha: string = '';
  public empresa: string = '';
  public modulo: string = '';

  wait = false;
  nombre: any = sessionStorage.getItem('nombre');
  nomina: any = sessionStorage.getItem('nomina');
  nombreL: any;
  usuarioL: any;
  nominaL: any;
  notificacion: any[] = [];
  modulos: any[] = [];
  statusnot: any[] = [];
  empnot: any[] = [];
  tp: any[] = [];
  url_notificacion: string = "/nueva_notificacion/";


  constructor(
    private router: Router,
    public snackBar: MatSnackBar,
    public funcion: Funciones,
    public servicios: ServiciosImp) {
    this.nombreL = this.nombre;
    this.nominaL = this.nomina;

    /* *************** Parametros del Grid de datos ***************** */
    this.columns_notificacion = [ // Columnas
      {
        caption: 'Título',
        dataField: 'titulo',
      },
      {
        caption: 'Texto notificación',
        dataField: 'texto',
        width: 400
      },
      {
        caption: 'Fecha envío',
        dataField: 'fecha_creacion',
      }
    ];

  }


  ngOnInit(): void {
    this.wait = true;
      this.servicios.getNotificacion().subscribe(datosnotifica => {
        if (!datosnotifica.error) {
        let notificaciones_array_carga = [];
        for (let carga in datosnotifica.data.notificaciones) {
          notificaciones_array_carga.push(
            datosnotifica.data.notificaciones[carga]
          );
        }
        this.notificacion = notificaciones_array_carga;
        this.wait = false;
        this.funcion.hide();
        }else{
        this.wait = false;
        this.snackBar.open("Error al consultar", 'close', {
          duration: 2500,
        });
        this.funcion.hide();
        }
      });
  }

  newNotificacion() {
    this.router.navigate([`nueva_notificacion/${0}`]);
  }


  /*Consulta en el grid por filtro*/
  consulta() {
    let filtro1 = this.titulo != '' ? this.titulo : '';
    let filtro2 = this.fecha != '' ? this.fecha : '';
    this.wait = true;
    this.servicios.getFiltroNotificacion(filtro1, filtro2).subscribe(datosfiltronotifica => {
      if (!datosfiltronotifica.error) {
      let notificaciones_array = [];
      for (let element in datosfiltronotifica.data.notificaciones) {
        notificaciones_array.push(
          datosfiltronotifica.data.notificaciones[element]
        );
      }
      this.notificacion = notificaciones_array;
      this.wait = false;
      this.funcion.hide();
      }else{
      this.wait = false;
      this.snackBar.open("Error al consultar", 'close', {
        duration: 2500,
      });
      this.funcion.hide();
      }
    });
  }


  dataDef() {
    this.titulo = '';
    this.fecha = '';
  }

  onClickHome() {
    this.router.navigate(['notificaciones']);
  }





}
