import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { EmpresasComponent } from './modulo_empresa/catalogo_empresa/catalogo_empresa.component';
import { NewPostComponent } from './modulo_publicador/nuevo_post/nuevo_post.component';
import { ContenidosComponent } from './modulo_publicador/resumen_post/resumen_post.component';
import { NewEmpresaComponent } from './modulo_empresa/nueva_empresa/nueva_empresa.component';
import { CatalogoNotificacionComponent } from './modulo_notificacion/catalogo_notificacion/catalogo_notificacion.component';
import { NuevaNotificacionComponent } from './modulo_notificacion/nueva_notificacion/nueva_notificacion.component';
import { ImportarDatosComponent } from './modulo_empresa/importar_datos/importar_datos.component';
import { VideoComponent } from './modulo_empresa/video/video.component';
import { ConfiguracionInicioComponent } from './modulo_inicio/configuracion_inicio/configuracion_inicio.component';
import { RegistroLogoMsgComponent } from './modulo_inicio/registro_logo_msg/registro_logo_msg.component';
import { ConfiguracionPlanCampaniaComponent } from './modulo_empresa/planes_campanias/configuracion_plan_campania/configuracion_plan_campania.component';
import { RegistroPlanCampaniaComponent } from './modulo_empresa/planes_campanias/registro_plan_campania/registro_plan_campania.component';
import { ConfiguracionTourComponent } from './modulo_tour/configuracion_tour/configuracion_tour.component';
import { NuevoTourComponent } from './modulo_tour/nuevo_tour/nuevo-tour.component';
import { PrevTourComponent } from './modulo_tour/prev_tour/prev_tour.component';
import { TourItemComponent } from './modulo_tour/tour-item/tour-item.component';
import { CargaIncentivoComponent } from './carga_incentivo/carga_incentivo.component';
import { AuthGuard } from './core/guards/auth.guard';
import { ReporteUsabilidadComponent } from './modulo_data/reporte_usabilidad/reporte_usabilidad.component';
import { DashboarComponent } from './modulo_data/dashboar/dashboar.component'
import { ModuloSondeoComponent } from './modulo_sondeo/filtro_sondeo/modulo_sondeo.component'
import { ReporteNpsComponent } from './modulo_data/reporte_nps/reporte_nps.component';
import { DashboardNpsComponent } from './modulo_data/dashboard_nps/dashboard_nps.component';
import { NuevoSondeoComponent } from './modulo_sondeo/nuevo_sondeo/nuevo_sondeo.component'
import { SondeoPreguntaComponent } from './modulo_sondeo/sondeo_pregunta/sondeo_pregunta.component'
import { ReportePerfilComponent } from './modulo_data/reporte_perfil/reporte_perfil.component'
import { DashboardPerfilComponent } from './modulo_data/dashboard_perfil/dashboard_perfil.component';
import { ReporteCuestionarioComponent } from './modulo_data/reporte_cuestionario/reporte_cuestionario.component';
import { DashboardCuestionarioComponent } from './modulo_data/dashboard_cuestionario/dashboard_cuestionario.component';
import { AudienciaComponent } from './components/audiencia/audiencia.component';
import { FormExcelComponent } from './components/form_excel/form_excel.component';
import { GridComponentComponent } from './components/grid-component/grid-component.component';
import { TablaRegistrosComponent } from './components/tabla_registros/tabla_registros.component';
import { PermisosComponent } from './modulo_permisos/permisos/permisos.component';
import { CargaCalculoManualComponent } from './modulo_carga_manual_calculo/carga_calculo_manual/carga_calculo_manual.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'catalogo_empresa', component: EmpresasComponent, canActivate: [AuthGuard] },
  { path: 'resumen_post', component: ContenidosComponent, canActivate: [AuthGuard] },
  { path: 'notificaciones', component: CatalogoNotificacionComponent, canActivate: [AuthGuard] },
  { path: 'nuevo_post/:id', component: NewPostComponent, canActivate: [AuthGuard] },
  { path: 'nueva_empresa/:id', component: NewEmpresaComponent, canActivate: [AuthGuard] },
  { path: 'nueva_notificacion/:id', component: NuevaNotificacionComponent, canActivate: [AuthGuard] },
  { path: 'importar_datos_perfil', component: ImportarDatosComponent, canActivate: [AuthGuard] },
  { path: 'reporte_usabilidad', component: ReporteUsabilidadComponent, canActivate: [AuthGuard] },
  { path: 'dashboar', component: DashboarComponent, canActivate: [AuthGuard] },
  { path: 'video', component: VideoComponent, canActivate: [AuthGuard] },
  { path: 'configuracion_inicio', component: ConfiguracionInicioComponent, canActivate: [AuthGuard] },
  { path: 'registro_logo_mensaje/:id', component: RegistroLogoMsgComponent, canActivate: [AuthGuard] },
  { path: 'configuracion_planes_campanias', component: ConfiguracionPlanCampaniaComponent, canActivate: [AuthGuard] },
  { path: 'registro_plan_campania/:id', component: RegistroPlanCampaniaComponent, canActivate: [AuthGuard] },
  { path: 'configuracion_tour', component: ConfiguracionTourComponent, canActivate: [AuthGuard] },
  { path: 'nuevo_tour/:id', component: NuevoTourComponent, canActivate: [AuthGuard] },
  { path: 'visualizar_tour', component: PrevTourComponent, canActivate: [AuthGuard] },
  { path: 'carga_incentivos', component: CargaIncentivoComponent, canActivate: [AuthGuard] },
  { path: 'configurar_sondeo', component: ModuloSondeoComponent, canActivate: [AuthGuard] },
  { path: 'nuevo_sondeo/:id', component: NuevoSondeoComponent, canActivate: [AuthGuard] },
  { path: 'reporte_nps', component: ReporteNpsComponent, canActivate: [AuthGuard] },
  { path: 'reporte_perfil', component: ReportePerfilComponent, canActivate: [AuthGuard] },
  { path: 'reporte_cuestionario', component: ReporteCuestionarioComponent, canActivate: [AuthGuard] },
  { path: 'permisos_puesto', component: PermisosComponent, canActivate: [AuthGuard] },
  { path: 'carga_manual_calculo', component: CargaCalculoManualComponent, canActivate: [AuthGuard]},
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

//para no importar vista por vista en app.module
export const routingComponents =
  [LoginComponent,
    HomeComponent,
    EmpresasComponent,
    ContenidosComponent,
    NewPostComponent,
    NewEmpresaComponent,
    CatalogoNotificacionComponent,
    NuevaNotificacionComponent,
    ImportarDatosComponent,
    VideoComponent,
    ConfiguracionInicioComponent,
    RegistroLogoMsgComponent,
    ConfiguracionPlanCampaniaComponent,
    RegistroPlanCampaniaComponent,
    ConfiguracionTourComponent,
    NuevoTourComponent,
    PrevTourComponent,
    TourItemComponent,
    CargaIncentivoComponent,
    ReporteUsabilidadComponent,
    DashboarComponent,
    ModuloSondeoComponent,
    NuevoSondeoComponent,
    SondeoPreguntaComponent,
    ReporteNpsComponent,
    DashboardNpsComponent,
    ReportePerfilComponent,
    DashboardPerfilComponent,
    ReporteCuestionarioComponent,
    DashboardCuestionarioComponent,
    AudienciaComponent,
    GridComponentComponent,
    FormExcelComponent,
    TablaRegistrosComponent,
    PermisosComponent,
    CargaCalculoManualComponent
    
    ]
