import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, OnChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Funciones } from '../../classes/funciones';


@Component({
  selector: 'incentivos-tour-item',
  templateUrl: './tour-item.component.html',
  styleUrls: ['./tour-item.component.css']
})
export class TourItemComponent implements OnChanges {

  emp: any[] = [];
  archivos: any[] = [];
  archivos_limpio: any[] = [];
  datos_archivo = new FormData();
  datos_arch: any[] = [];
  valor: number = 0;
  mostrar: string = '';
  val_tipo: number = 0;

  @Input() item: any[] = []

  @Input() idTour: any = {}

  public tiempo_duracion: any;
  public archivo!: File;
  //public carga_archivo: any;
  public opc: any;
  public texto_video: string = "";
  public src: any;
  public tipo: any;
  abrir: boolean = false;
  setabrir: string = "";
  isDisabled = true;

  @Output() guardar = new EventEmitter<{ file: File, id: number }>();

  @ViewChild('modal') modal: ElementRef | undefined;

  @Input() public veredit: any;

  @Input() public editar: boolean = false


  tourPrev: any = [];



  constructor(private modalService: NgbModal, public funcion: Funciones) { }


  ngOnChanges(): void {
    if (this.editar) {
      this.item.forEach(i => {
        this.archivos.push({
          "id": i.id,
          "tipo": i.tipo,
          "src": i.src
        });
        if (i.tipo != "") {
          let base64Image = "";
          this.toDataUrl(i.src, function (x: any) {
            base64Image = x;
            let list = new DataTransfer();
            list.items.add(new File([base64Image], 'archivo cargado'));
            let myFileList = list.files;
            let archivo = <HTMLInputElement>document.getElementById('archivo' + i.id);
            archivo.disabled = false
            archivo.files = myFileList;
          });
        }
      });
    }
    this.tiempo_duracion = this.tiempo_duracion != undefined ? this.tiempo_duracion : 0
  }

  public seleccionTipo(event: any, tour: any) {
    this.mostrar = event.target.value;
    let archivo = <HTMLInputElement>document.getElementById('archivo' + tour.id);
    let el = <HTMLInputElement>document.getElementById('temporizador' + tour.id);
    archivo.value = ""
    /// vamos a validar que input esta seleccionado a traves de javascript
    if (this.mostrar == '1') {
      this.val_tipo = 1
      archivo.disabled = false
      archivo.accept = ".mp4"
      el.disabled = true
      tour.tiempo = 0;
    } else if (this.mostrar == '2') {
      this.val_tipo = 2
      archivo.disabled = false
      archivo.accept = ".jpg, .jpeg, .png"
      el.disabled = false
      tour.tiempo = 0
    } else if (this.mostrar == '') {
      archivo.disabled = true
      el.disabled = true
    }
    let a = this.archivos.findIndex((item => item.id == tour.id))
    if (a == -1) {
      this.archivos.push({
        "id": tour.id,
        "tipo": this.val_tipo
      });
    } else {
      this.archivos[a].tipo = this.val_tipo
    }
  }

  abrirModal(modal: any, tour: any) {
    this.tourPrev = [];
    if (tour.src != '' && this.editar) {
      this.tourPrev = tour;
      this.modalService.open(modal, {
        centered: true,
        //size: 'lg',
        ariaLabelledBy: 'modal-basic-title',
        scrollable: false,
        windowClass: 'my-class'
      });
    } else {
      if (this.archivo != undefined) {
        this.tourPrev = this.archivos.find((e: any) => e.id == tour.id)
        this.modalService.open(modal, {
          centered: true,
          //size: 'lg',
          ariaLabelledBy: 'modal-basic-title',
          scrollable: false,
          windowClass: 'my-class'
        });
      }
    }
  }


  cerrarModal() {
    this.modalService.dismissAll();
  }


  /*base64 de imagen cargadas y video*/
  public change_archivo($event: any, tour: any): void {
    //Para carga
    if ($event.target.files.length > 0) {
      this.archivo = $event.target.files[0];
      if (this.val_tipo == 1) {
        let video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadedmetadata = function () {
          window.URL.revokeObjectURL(video.src);
          let duration = video.duration;
          let convert = (Math.round(duration * 100) / 100).toFixed(2);
          tour.tiempo = convert;
        }
        video.src = URL.createObjectURL(this.archivo);
      }
      this.guardar.emit({ 'file': this.archivo, 'id': tour.id })
      this.datos_archivo.delete('nombre_archivo');
      const index = this.datos_arch.indexOf(this.datos_archivo);
      if (index > -1) {
        this.datos_arch.splice(index, 1);
      }
      this.datos_archivo.append('nombre_archivo', this.archivo);
      this.datos_archivo.append('id_empresa', '13');
      this.datos_archivo.append('id_tour', this.idTour);
      let n = "video" + tour.id
      this.datos_archivo.append('tipo', n);
      this.datos_arch.push(this.datos_archivo);
      tour.archivo = this.archivo
      //Para visualizar
      let readera: FileReader = new FileReader();
      readera.onloadend = () => {
        let a = this.archivos.findIndex((item => item.id == tour.id))
        if (a == -1) {
          this.archivos.push({
            "id": tour.id,
            "tipo": this.val_tipo,
            "src": readera.result
          });
        } else {
          this.archivos[a].src = readera.result
        }

        let t = this.item.findIndex(item => item.id == tour.id);
        if (t !== -1) {
          this.item[t].src = "";
        }

      }
      readera.readAsBinaryString(this.archivo);
    } else {
      let list = new DataTransfer();
      let a = this.item.find((item => item.id == tour.id))
      list.items.add(a.archivo);
      let myFileList = list.files;
      $event.target.files = myFileList;
    }
  }

  toDataUrl(url: any, callback: any) {
    let xhr = new XMLHttpRequest();
    xhr.onload = function () {
      let reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      }
      reader.readAsBinaryString(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

}
