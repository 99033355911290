<incentivos-navbar [nombre]="nombreL" [nomina]="nominaL"></incentivos-navbar>

<!-- Importar los datos para incentivos -->
<div class="loading-indicator" *ngIf="funcion.visibility | async">
  <img src="/assets/img/spinner.gif" alt="">
</div>

<div class="d-flex justify-content-center align-box2">
  <div class="box2">
    <nav
      style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
      aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item b1" href="javascript:;">Configuración SI+</li>
        <li class="breadcrumb-item b2" href="javascript:;">Configuración de permisos por puesto</li>
      </ol>
    </nav>
  </div>
</div>

<div class="d-flex justify-content-center align-box">
  <div class="box">
    <p class="f-t1">Configuración de permisos por puesto</p>
    <p class="f-t2">*Campos Obligatorios</p>
    <div class="container cuerpo">
      <div class="row">
        <div class="col-md-4">
          <label for="input6" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
            <span class="h6 small bg-white text-muted px-1">Empresa</span>
          </label>
          <select class="form-select" aria-label="Default select example" [(ngModel)]="empresa" required name="empresa">
            <option *ngFor="let empresa of empper" [value]="empresa.nombre">{{empresa.nombre}}</option>
          </select>
        </div>
        <div class="col-md-4">
          <label for="input6" class="ms-2 position-absolute" style="margin-top: -0.25rem !important">
            <span class="h6 small bg-white text-muted px-1">Puesto</span>
          </label>
          <input type="text" class="form-control" required [(ngModel)]="puesto" name="puesto"
          (keypress)="consultaPermiso()" oninput="this.value = this.value.toUpperCase()">
        </div>
        <div class="col-md-4">
          <button class="button boton" type="button" (click)="consultaPermiso()"><em
              class="bi bi-filter"></em>&nbsp;&nbsp;Filtrar</button>
        </div>
      </div>
      <br><br><br>
      <div class="row mt-5">
        <table class="table table-responsive" aria-describedby="">
          <thead>
            <tr>
              <th scope="col">PUESTO</th>
              <th scope="col" *ngFor="let permiso of listapermiso; let id = index">{{permiso.nombre}} <img
                  src="/assets/images/edit-icon.png" alt="" (click)="validaSelect(permiso)" class="format-ima-per" style="cursor: pointer;" />
                <div *ngIf="permiso.id == selecPermiso && activaSelect"
                  style="background-color: #fff;margin-top: 1em; height: 2.5em;">
                  <mat-select [(ngModel)]="permisoSelected" (selectionChange)="onChangeElige(this.modal,undefined, undefined)">
                    <mat-option [value]=1>
                      Editar
                    </mat-option>
                    <mat-option [value]=2>
                      Eliminar
                    </mat-option>
                  </mat-select>
                </div>
              </th>
              <th scope="col" (click)="abrirModal(this.modal)">
                <img src="/assets/images/cross.png" alt="" class="format-ima-per" />
                 AGREGAR PERMISO
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="col"></th>
              <th scope="col" *ngFor="let permiso of listapermiso; let id = index">
                <mat-slide-toggle class="example-margin" (change)="valorPermisoGen($event, permiso)">
                  <label class="form-check-label" for="flexSwitchCheckDefault" style="margin-left: 12px;">Seleccionar
                    todo</label>
                </mat-slide-toggle>
              </th>
              <th scope="col">
              </th>
            </tr>
            <tr *ngFor="let puesto of listapuesto; let id = index">
              <th scope="row"><img
                src="/assets/images/edit-icon.png" alt="" (click)="onChangeElige(this.modal, 'puesto', puesto.puesto)" 
                class="format-ima-per" style="cursor: pointer;" />&nbsp;&nbsp;{{puesto.puesto}}</th>
              <td *ngFor="let permiso of puesto.permisos; let id = index">
                <mat-slide-toggle class="example-margin" (change)="valorPermiso($event, permiso, puesto)"
                  checked="{{ permiso.statuspermiso }}">
                  <label class="form-check-label" for="flexSwitchCheckDefault" style="margin-left: 12px;">Dar
                    permiso</label>
                </mat-slide-toggle>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>

      </div>
      <br><br><br>
      <div class="row">
        <div class="col d-grid gap-5 d-md-flex justify-content-md-end">
          <button class="button mt-2 boton2" type="button" (click)="cancelar()">&nbsp;&nbsp;Cancelar</button>
          <button class="button mt-2 boton3" (click)="guardarPermiso()" type="button">&nbsp;&nbsp;Guardar</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal de incentivos-->
<ng-template #modalpermiso let-modal>
  <div class="modal-content">
    <div class="modal-title" id="exampleModalLabel">
      <p *ngIf="indicadorPuesto" class="f-t3">Editar listado de puestos</p>
      <div *ngIf="!indicadorPuesto">
      <p *ngIf="permisoSelected !== 1 && permisoSelected !== 2" class="f-t3">Agregar permiso</p>
      <p *ngIf="permisoSelected == 1" class="f-t3">Cambiar permiso</p>
      <p *ngIf="permisoSelected == 2" class="fomratEliminar" style="margin-top: 45px;">El permiso se eliminará permanentemente</p>
      <p *ngIf="permisoSelected == 2" class="fomratEliminar"> ¿Estás seguro de querer hacerlo?</p>
      </div>
    </div>
    <br><br>
    <div class="modal-body" *ngIf="permisoSelected !== 2">
      <div class="row mb-2 mt-4">
        <div class="col-12" *ngIf="!indicadorPuesto">
          <input type="text" placeholder="Nuevo Permiso*" class="form-control" required [(ngModel)]="npermiso"
            oninput="this.value = this.value.toUpperCase()">
        </div>
        <div class="col-12" *ngIf="indicadorPuesto">
          <textarea class="textarea" cols=63 rows=12 
          [(ngModel)]="listaPuestoA" ></textarea>
        </div>
      </div>
    </div>
    <div class="modal-footer" style="border-top: 0;">
      <div class="col d-grid gap-5 d-md-flex justify-content-md-end">
        <button class="btn mt-2 boton2" type="button" (click)="cerrarModal()">&nbsp;&nbsp;Cerrar</button>
        <button *ngIf="permisoSelected !== 2 && !indicadorPuesto" class="btn mt-2 boton3" type="button" (click)="guardarPermisos()">&nbsp;&nbsp;Guardar</button>
        <button *ngIf="permisoSelected == 2 && !indicadorPuesto" class="btn mt-2 boton4" type="button" (click)="guardarPermisos()">&nbsp;&nbsp;Eliminar</button>
        <button *ngIf="indicadorPuesto" class="btn mt-2 boton4" type="button" (click)="guardarPuesto()">&nbsp;&nbsp;Guardar</button>

      </div>
    </div>
  </div>
</ng-template>