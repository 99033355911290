<div style="background-color:#fff;">
    <div *ngFor="let tour of tours;">
        <div *ngIf="tour.show && tour.tipo!=='fin'">
            <div class="imgPortada" [ngClass]="tour.tipo=='2' || tour.tipo=='1'? 'videoHeight': 'portadaHeight'"
                [style.background-image]="tour.tipo=='2' || tour.tipo=='1'? portadaD: portada">
                <div *ngIf="tour.tipo=='1'" class="item video">
                    <vg-player>
                        <video #media id="singleVideo" preload="auto" autoplay>
                            <source [src]="tour.src" type="video/mp4">
                            <track label="Español" kind="captions" src="" default>
                        </video>
                    </vg-player>
                </div>
                <div *ngIf="tour.tipo=='2'" class="item">
                    <img alt="" src="{{tour.src}}" class="img">
                </div>
                <div *ngIf="tour.show && tour.tipo!=='portada'" class="col-12 d-flex justify-content-center"
                    style="margin-top: 1em;">
                    <a class="label">{{tour.text}}</a>
                </div>
                <div *ngIf="tour.show && tour.tipo=='portada'" class="col-12 d-flex justify-content-center"
                    style="padding-top: 35em;">
                    <a class="label">{{tour.text}}</a>
                </div>
            </div>

        </div>
        <div class="row" *ngIf="tour.show && tour.tipo=='fin'" style="padding: 10em 0em;margin: 0em 4em;">
            <div class="col-12 d-flex justify-content-center">
                <img alt="" style="width: 230px;" src="/assets/img/si_icon2.png" />
            </div>
            <div class="col-12 d-flex justify-content-center" style="margin-top: 8em;">
                <a class="label">Disfruta de las mejoras de tu Sistema de Incentivos</a>
            </div>
            <div class="col-12 d-flex justify-content-center" style="margin-top: 8em;">
                <button (click)="finalizar()" class="buttonA">Finalizar</button>
            </div>
            <div class="col-12 d-flex justify-content-center" style="margin-top:2em">
                <a style="color: #0d6efd;" (click)="volverEmpezar()">Volver a empezar</a>
            </div>
        </div>
    </div>
</div>
<div class="bottom-control">
    <button *ngFor="let tour of tours;let i=index;" class="circular-button buttonCircular"
        [ngClass]="tour.show==false? 'btnColorDisabled':'btnColorEnabled'" (click)="jumpToSlide(i)"></button>
</div>
